import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { SchemaDesignerLogicService, SchemaChangeLogicService } from '@/components/schemaDesigner/services';
import { useSchemaChangeLogicService, useSchemaDesignerLogicService } from '@/components/schemaDesigner/hooks';

interface SchemaDesignerContextValue {
  designerService: SchemaDesignerLogicService;
  changeService: SchemaChangeLogicService;
}

const schemaDesignerContext = createContext<SchemaDesignerContextValue>({} as SchemaDesignerContextValue);

const useSchemaDesignerContext = () => {
  const context = useContext(schemaDesignerContext);

  return {
    ...context,
  };
};

const SchemaDesignerProvider: FC<PropsWithChildren> = (props) => {
  const SchemaDesignerContextValue: SchemaDesignerContextValue = {
    designerService: useSchemaDesignerLogicService(),
    changeService: useSchemaChangeLogicService(),
  };

  return <schemaDesignerContext.Provider value={SchemaDesignerContextValue} {...props} />;
};

export { SchemaDesignerProvider, useSchemaDesignerContext };
