import { styled } from '@tigergraph/app-ui-lib/Theme';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';

export const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '24px',
  color: $theme.colors.primary900,
}));

export function LoadingIndicator() {
  return (
    <Container>
      <Spinner $size={'20px'} $borderWidth={'2px'} />
    </Container>
  );
}
