import TextButton from '@/components/TextButton';
import { selectedAddonsAtom } from '@/components/addons/commonAtom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import ConnectionMenuAddons from '@/pages/workgroup/tab/ConnectionMenu/ConnectionMenuAddons';
import { RestppDrawer } from '@/pages/workgroup/tab/restPP/RestppDrawer.tsx';
import { DatabaseT, WorkspaceT, isStatusActive, isStatusIdle } from '@/pages/workgroup/type';
import trackUtil from '@/utils/analytics-service.ts';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ChevronDown } from 'baseui/icon';
import { useAtomValue } from 'jotai';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditorIcon, ExplorerIcon, IngestionIcon, IntegrationIcon, LinkIcon } from '@/pages/home/icons';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import SchemaIcon from '@/assets/graph-schema.svg';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { Sidebar } from '@/components/Sidebar';
import SchemaDesigner from '@/components/schemaDesigner';
import InsightsIcon from '@/pages/workgroup/tab/icons/insights.svg?react';
import CreateQuickInsights from '@/pages/dashboard/createInsights';
import { GraphResultRef } from '@/components/graphEditor/GraphResult';
import SchemaDesignerDeactiveDialog from '@/components/schemaDesigner/SchemaDesignerDeactiveDialog';

const IconContainer = styled('span', ({ $theme }) => {
  return {
    width: '18px',
    height: '18px',
    display: 'flex',
  };
});

export default function ConnectionMenu({ workspace, database }: { workspace: WorkspaceT; database?: DatabaseT }) {
  const { setCurrentWorkspace } = useWorkspaceContext();
  const [showSchemaDesigner, setShowSchemaDesigner] = useState(false);
  const [showCreateInsights, setShowCreateInsights] = useState(false);
  const [css] = useStyletron();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isAddonsDrawerOpen, setIsAddonsDrawerOpen] = useState(false);
  const [showSchemaChangeModal, setShowSchemaChangeModal] = useState(false);
  const { selectedAddonsMeta, selectedEnabledAddons } = useAtomValue(selectedAddonsAtom);
  const graphResultRef = useRef<GraphResultRef>(null);

  const enabled = isStatusActive(workspace.status) || isStatusIdle(workspace.status);

  const handleCloseSchemaDesigner = () => {
    if (graphResultRef.current?.isSchemaChanged) {
      setShowSchemaChangeModal(true);
    } else {
      setShowSchemaDesigner(false);
    }
  };

  const confirmCloseSchemaDesigner = () => {
    setShowSchemaDesigner(false);
    setShowSchemaChangeModal(false);
  };

  return (
    <>
      <DropdownMenu>
        <Sidebar isDrawer={true} isOpen={showSchemaDesigner} onClose={handleCloseSchemaDesigner}>
          <SchemaDesigner ref={graphResultRef} title={'Schema Designer'} showHideButton={false} isInDrawer={true} />
        </Sidebar>
        <SchemaDesignerDeactiveDialog
          graphResultRef={graphResultRef}
          showPrompt={showSchemaDesigner}
          showSchemaChangeModal={showSchemaChangeModal}
          onConfirmCloseSchemaDesigner={confirmCloseSchemaDesigner}
          onCancelCloseSchemaDesigner={() => setShowSchemaChangeModal(false)}
        />
        <DropdownMenuTrigger asChild disabled={!enabled}>
          <TextButton
            disabled={!enabled}
            $style={{
              padding: '2px 3px 2px 8px',
            }}
          >
            <span
              className={css({
                paddingRight: '4px',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#222',
                borderRight: '1px solid #AAB5BF',
              })}
            >
              Connect
            </span>
            <span
              className={css({
                fontSize: '16px',
              })}
            >
              <ChevronDown />
            </span>
          </TextButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className={css({ width: '240px', marginLeft: '160px' })}>
          {(isStatusActive(workspace.status) || isStatusIdle(workspace.status)) && workspace.is_rw && (
            <DropdownMenuItem
              onClick={() => {
                setCurrentWorkspace(workspace);
                navigate('/design-schema?workspace_id=' + workspace.workspace_id);
              }}
            >
              <IconContainer>
                <img src={SchemaIcon} style={{ width: '18px', height: '18px', marginRight: '-2px' }} />
              </IconContainer>
              <Body2>Design Schema</Body2>
            </DropdownMenuItem>
          )}
          {(isStatusActive(workspace.status) || isStatusIdle(workspace.status)) && workspace.is_rw && (
            <DropdownMenuItem
              onClick={() => {
                navigate('/ingestion?workspace_id=' + workspace.workspace_id);
              }}
            >
              <IconContainer>
                <IngestionIcon />
              </IconContainer>
              <Body2>Load Data</Body2>
            </DropdownMenuItem>
          )}
          {(isStatusActive(workspace.status) || isStatusIdle(workspace.status)) && (
            <DropdownMenuItem
              onClick={() => {
                trackUtil.trackEnterGSQLEditor('workspace menu');
                navigate(`/editor?workspace_id=${encodeURIComponent(workspace.workspace_id)}`);
              }}
            >
              <IconContainer>
                <EditorIcon />
              </IconContainer>
              <Body2>GSQL Editor</Body2>
            </DropdownMenuItem>
          )}
          {(isStatusActive(workspace.status) || isStatusIdle(workspace.status)) && (
            <DropdownMenuItem
              onClick={() => {
                navigate(`/explore?workspace_id=${encodeURIComponent(workspace.workspace_id)}`);
              }}
            >
              <IconContainer>
                <ExplorerIcon />
              </IconContainer>
              <Body2>Explore Graph</Body2>
            </DropdownMenuItem>
          )}
          {(isStatusActive(workspace.status) || isStatusIdle(workspace.status)) && <DropdownMenuSeparator />}
          {(isStatusActive(workspace.status) || isStatusIdle(workspace.status)) && (
            <DropdownMenuItem onClick={() => setShowCreateInsights(true)}>
              <IconContainer>
                <InsightsIcon />
              </IconContainer>
              <Body2>Get Data Profile</Body2>
            </DropdownMenuItem>
          )}
          {(isStatusActive(workspace.status) || isStatusIdle(workspace.status)) && workspace.is_rw && (
            <DropdownMenuItem
              onClick={() => {
                navigate(`/groups/${workspace.workgroup_id}?tab=solution`);
              }}
            >
              <IconContainer>
                <IntegrationIcon />
              </IconContainer>
              <Body2>Solutions</Body2>
            </DropdownMenuItem>
          )}
          {(isStatusActive(workspace.status) || isStatusIdle(workspace.status)) && <DropdownMenuSeparator />}
          <DropdownMenuItem onClick={() => setIsOpen(true)}>
            <IconContainer className={css({ marginTop: '-5px' })}>
              <LinkIcon />
            </IconContainer>
            <Body2>Connect from API</Body2>
          </DropdownMenuItem>
          <ConnectionMenuAddons workspace={workspace} onOpenDrawer={() => setIsAddonsDrawerOpen(true)} />
        </DropdownMenuContent>
      </DropdownMenu>
      {showCreateInsights ? (
        <CreateQuickInsights
          isOpen={showCreateInsights}
          onClose={() => setShowCreateInsights(false)}
          databaseName={database?.name}
          workspace_id={workspace.workspace_id}
          workgroup_id={workspace.workgroup_id}
          nginx_host={workspace.nginx_host}
        />
      ) : null}
      <RestppDrawer
        isOpen={isOpen}
        workspace={workspace}
        onClose={() => {
          setIsAddonsDrawerOpen(false);
          setIsOpen(false);
        }}
      />
    </>
  );
}
