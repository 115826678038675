import { Color } from '@tigergraph/tools-models/gvis/color';
import { Graph } from '@tigergraph/tools-models/topology';
import { SHORTCUT_ICON, SHORTCUT_OPERATION } from '@tigergraph/app-ui-lib/shortcuts-menu';

/**
 * Get a random string with specified length.
 *
 * @private
 * @param {number} length
 * @memberof SchemaDesignerComponent
 */
export function randomString(length: number) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

/**
 * Init a style for each entity, ignore the position and assign a color.
 *
 * @private
 * @param {Graph} schema
 * @param {Color} color
 * @memberof SchemaViewerComponent
 */
export function initStyle(schema: Graph, color: Color) {
  schema.vertexTypes.forEach((vertex) => {
    if (vertex.style === undefined) {
      vertex.style = schema.getNewVertexStyle(vertex.name, color);
    } else {
      color.setColor(vertex.name, vertex.style.fillColor);
    }
  });
  schema.edgeTypes.forEach((edge) => {
    if (edge.style === undefined) {
      edge.style = schema.getNewEdgeStyle(edge.name, color);
    } else {
      color.setColor(edge.name, edge.style.fillColor);
    }
  });
}

export function buildShortcut({ enableSave }: { enableSave: boolean }) {
  let shortcuts = [
    {
      name: 'Create new vertex',
      hotkeys: [
        {
          hotkey: 'V',
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: SHORTCUT_ICON.leftClick,
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
    {
      name: 'Create new edge',
      hotkeys: [
        {
          hotkey: SHORTCUT_ICON.dragBorder,
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: SHORTCUT_ICON.move,
          operation: SHORTCUT_OPERATION.or,
        },
        {
          hotkey: SHORTCUT_ICON.drag,
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: SHORTCUT_ICON.moveRight,
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
    {
      name: 'Undo',
      hotkeys: [
        {
          hotkey: SHORTCUT_ICON.command,
          operation: SHORTCUT_OPERATION.or,
        },
        {
          hotkey: 'Ctrl',
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: 'Z',
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
    {
      name: 'Redo',
      hotkeys: [
        {
          hotkey: SHORTCUT_ICON.command,
          operation: SHORTCUT_OPERATION.or,
        },
        {
          hotkey: 'Ctrl',
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: SHORTCUT_ICON.shift,
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: 'Z',
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
    {
      name: 'Zoom in/out',
      hotkeys: [
        {
          hotkey: SHORTCUT_ICON.scroll,
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
    {
      name: 'Delete',
      hotkeys: [
        {
          hotkey: SHORTCUT_ICON.backspace,
          operation: SHORTCUT_OPERATION.or,
        },
        {
          hotkey: 'Del',
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
    {
      name: 'Save',
      hotkeys: [
        {
          hotkey: SHORTCUT_ICON.command,
          operation: SHORTCUT_OPERATION.or,
        },
        {
          hotkey: 'Ctrl',
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: 'S',
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
    // {
    //   name: 'Toggle global mode',
    //   hotkeys: [
    //     {
    //       hotkey: SHORTCUT_ICON.command,
    //       operation: SHORTCUT_OPERATION.or,
    //     },
    //     {
    //       hotkey: 'Ctrl',
    //       operation: SHORTCUT_OPERATION.and,
    //     },
    //     {
    //       hotkey: 'G',
    //       operation: SHORTCUT_OPERATION.null,
    //     },
    //   ],
    // },
    {
      name: 'Multi select',
      hotkeys: [
        {
          hotkey: SHORTCUT_ICON.command,
          operation: SHORTCUT_OPERATION.or,
        },
        {
          hotkey: 'Ctrl',
          operation: SHORTCUT_OPERATION.or,
        },
        {
          hotkey: SHORTCUT_ICON.shift,
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: SHORTCUT_ICON.move,
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
    {
      name: 'Reset viewport',
      hotkeys: [
        {
          hotkey: SHORTCUT_ICON.command,
          operation: SHORTCUT_OPERATION.or,
        },
        {
          hotkey: 'Ctrl',
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: SHORTCUT_ICON.shift,
          operation: SHORTCUT_OPERATION.and,
        },
        {
          hotkey: 'R',
          operation: SHORTCUT_OPERATION.null,
        },
      ],
    },
  ];
  if (!enableSave) {
    shortcuts = shortcuts.filter((shortcut) => shortcut.name !== 'Save');
  }
  return shortcuts;
}
