import { RoleType } from '@/lib/models';

export type Region = {
  name: string;
  description: string;
};

export type TGVersion = {
  name: string;
  supportS3: false;
};

export type WorkSpaceType = {
  typeName: string;
  storage: string;
  cpu: string;
  memory: string;
  cost_per_hour: number;
};

export type WorkSpaceMeta = {
  regions: Region[];
  tgVersions: TGVersion[];
  workspaceTypes: WorkSpaceType[];
};

export type DatabaseT = {
  database_id: string;
  name: string;
  workgroup_id: string;
  tg_version: string;
};

export type WorkspaceStatus = 'Active' | 'Idle' | 'Stopped' | 'Error' | 'Pending';
export type WorkspaceRefreshStatus = 'Init' | 'Exporting' | 'Importing' | 'Failed' | 'Success' | 'Interrupted' | '';
export type ConditionType =
  | 'Normal'
  | 'InitializeRoll'
  | 'InitializePost'
  | 'UpdateRoll'
  | 'UpgradeRoll'
  | 'UpgradePost'
  | 'ExpandRoll'
  | 'ExpandPost'
  | 'ExpandRollBack'
  | 'ShrinkPre'
  | 'ShrinkRoll'
  | 'ConfigUpdate'
  | 'PauseRoll'
  | 'Paused'
  | 'ResumeRoll'
  | 'HAUpdate'
  | 'NotReady';

export type WorkspaceT = {
  name: string;
  status: WorkspaceStatus;
  condition_type: ConditionType;
  workspace_id: string;
  workgroup_id: string;
  workgroup_name: string;
  database_id: string;
  tg_version: string;
  created_at: string;
  creator: string;
  last_modified_time: string;
  workspace_type: WorkSpaceType;
  is_rw: boolean;
  nginx_host: string;
  role: RoleType;
  enable_auto_start: boolean;
  enable_copilot: boolean;
  copilot_llm_provider_config_id: string;
  auto_stop_minutes: number;
  snapshot_time: string;
  refresh_status: WorkspaceRefreshStatus;
  addons?: WorkspaceAddons[];
};

export type WorkGroupT = {
  workgroup_id: string;
  name: string;
  org_id: string;
  region: string;
  tg_databases: DatabaseT[];
  workspaces: WorkspaceT[];
  creator: string;
  role: RoleType;
};

export type CreateWorkGroupRequest = {
  name: string;
  region: string;
  platform: string;
};

export type CreateWorkSpaceRequest = {
  workspace_type_name: string;
  tg_database_id: string;
  tg_version: string;

  name: string;
  size: number;
  is_rw: boolean;
  enable_auto_start: boolean;
  auto_stop_minutes: number;
  enable_copilot?: boolean;
  copilot_llm_provider_config_id?: string;

  workgroup_id: string;
  addons: {
    addons_id: string;
    enable: boolean;
    enable_configuration: Record<string, undefined | string | number>;
  }[];
};

export type UpdateWorkspaceRequest = {
  workspace_type_name?: string;
  enable_auto_start?: boolean;
  auto_stop_minutes?: number;
  enable_copilot?: boolean;
  workspace_name?: string;
  addons?: {
    addons_id: string;
    enable: boolean;
    enable_configuration: Record<string, undefined | string | number>;
  }[];
};

export type CreateDatabaseRequest = {
  name: string;
  tg_version: string;
  workgroup_id: string;
};

export type BackupSchedule = {
  schedule: string;
  pause: boolean;
};

export type WorkspaceAddons = {
  enable: boolean;
  addons_id: string;
  // workspace_id: string;
  enable_configuration: Record<string, string>;
};

export function isStatusActive(status: WorkspaceStatus) {
  return status === 'Active';
}

// Paused + Auto Start On
export function isStatusIdle(status: WorkspaceStatus) {
  return status === 'Idle';
}

// Paused + Auto Start off
export function isStatusPaused(status: WorkspaceStatus) {
  return status === 'Stopped';
}

export function isStatusError(status: WorkspaceStatus) {
  return status === 'Error';
}

export function isStatusPending(status: WorkspaceStatus) {
  return status === 'Pending';
}

export function isRefreshIng(refresh_status: WorkspaceRefreshStatus) {
  return (
    refresh_status === 'Init' ||
    refresh_status === 'Exporting' ||
    refresh_status === 'Importing' ||
    refresh_status === 'Interrupted'
  );
}

export function canRefresh(refresh_status: WorkspaceRefreshStatus) {
  return !refresh_status || refresh_status === 'Success' || refresh_status === 'Failed';
}
