import { CustomTheme, styled } from '@tigergraph/app-ui-lib/Theme';
import { expand } from 'inline-style-expand-shorthand';
import { InternalAxiosRequestConfig } from 'axios';

export const Heading = styled('div', () => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
  color: '#294560',
  marginBottom: '8px',
}));

export const testButtonOverride = (theme: CustomTheme) => {
  return {
    BaseButton: {
      style: {
        backgroundColor: 'transparent',
        height: '32px',
        borderTopLeftRadius: '2px',
        borderBottomLeftRadius: '2px',
        borderTopRightRadius: '2px',
        borderBottomRightRadius: '2px',
        color: theme.colors.gray900,
        ':hover': {
          backgroundColor: 'transparent',
          color: theme.colors.gray900,
        },
        ':active': {
          backgroundColor: 'transparent',
          color: theme.colors.gray900,
        },
        ...expand({
          border: `1px solid ${theme.colors.gray900}`,
        }),
      },
    },
  };
};

export function axiosToCurl(requestConfig: InternalAxiosRequestConfig) {
  const { method, baseURL, url, headers, data } = requestConfig;
  const fullUrl = new URL(url as string, baseURL).href;
  let curlCommand = `curl '${fullUrl}' \\\n`;

  if (headers) {
    const length = Object.keys(headers).length;
    for (const [index, [key, value]] of Object.entries(headers).entries()) {
      if (index === length - 1) {
        curlCommand += ` -H '${key}: ${value}'`;
      } else {
        curlCommand += ` -H '${key}: ${value}' \\\n`;
      }
    }
  }

  if (data) {
    // remove backslashes from the JSON string
    const dataString = JSON.stringify(data, null, 2);
    curlCommand += `\\\n --data-raw ${dataString}`;
  }

  return curlCommand;
}

export function generateCurl(requestConfig: { url: string; headers: Record<string, string>; data?: any }) {
  const { url, headers, data } = requestConfig;
  let curlCommand = `curl '${url}' \\\n`;

  if (headers) {
    const length = Object.keys(headers).length;
    for (const [index, [key, value]] of Object.entries(headers).entries()) {
      if (index === length - 1) {
        curlCommand += ` -H '${key}: ${value}'`;
      } else {
        curlCommand += ` -H '${key}: ${value}' \\\n`;
      }
    }
  }

  if (data) {
    // remove backslashes from the JSON string
    const dataString = JSON.stringify(data, null, 2);
    curlCommand += `\\\n --data-raw ${dataString}`;
  }

  return curlCommand;
}

export function generateJavascript(requestConfig: { url: string; headers: Record<string, string>; data?: any }) {
  const { url, headers, data } = requestConfig;

  const jsCommand = `fetch('${url}', {\n`;
  const headersString = headers ? JSON.stringify(headers, null, 4) : '';
  const dataString = data ? JSON.stringify(data, null, 2) : '';
  const jsCommandEnd = '});';
  return `${jsCommand}  headers: ${headersString},\n  body: ${dataString}\n${jsCommandEnd}`;
}

export function generatePython(requestConfig: { url: string; headers: Record<string, string>; data?: any }) {
  const { url, headers, data } = requestConfig;

  let pythonCode = 'import requests\n\n';
  pythonCode += `url = '${url}'\n`;
  pythonCode += 'headers = {\n';
  for (const key in headers) {
    pythonCode += `    '${key}': '${headers[key]}',\n`;
  }
  pythonCode += '}\n';

  if (data) {
    pythonCode += `data = ${JSON.stringify(data)}\n\n`;
    pythonCode += 'response = requests.post(url, headers=headers, json=data)\n';
  } else {
    pythonCode += 'response = requests.get(url, headers=headers)\n';

    pythonCode += 'print(response.text)\n';
  }
  return pythonCode;
}

export const ReadyApi = [
  'DELETE /graph/{graph_name}/delete_by_type/vertices/{vertex_type}/',
  'DELETE /graph/{graph_name}/edges/{source_vertex_type}/{source_vertex_id}/{edge_type}/{target_vertex_type}/{target_vertex_id}',
  'DELETE /graph/{graph_name}/edges/{source_vertex_type}/{source_vertex_id}/{edge_type}/{target_vertex_type}/{target_vertex_id}/{discriminator}',
  'DELETE /graph/{graph_name}/vertices/{vertex_type}/{vertex_id}',
  'GET /abortquery/{graph_name}',
  'GET /data_consistency_check',
  'GET /deleted_vertex_check/{graph_name}',
  'GET /endpoints/{graph_name}',
  'GET /graph/{graph_name}/edges/{source_vertex_type}/{source_vertex_id}/{edge_type}/{target_vertex_type}/{target_vertex_id}',
  'GET /graph/{graph_name}/edges/{source_vertex_type}/{source_vertex_id}/{edge_type}/{target_vertex_type}/{target_vertex_id}/{discriminator}',
  'GET /graph/{graph_name}/vertices/{vertex_type}/{vertex_id}',
  'GET /query_result/{requestid}',
  'GET /rebuildnow/{graph_name}',
  'GET /showdelayedlistall',
  'GET /showprocesslist/{graph_name}',
  'GET /showprocesslistall',
  'GET /statistics/{graph_name}',
  'POST /ddl/{graph_name}',
];
