import { EnabledAddonsType, useListEnabledAddons } from '@/hooks/useListEnabledAddons';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Skeleton } from 'baseui/skeleton';
import { MetaAddonsType, useAddonsMeta } from '@/hooks/useAddonsMeta';
import { FC, useEffect, useMemo, useState } from 'react';
import WorkspaceAddonsEmpty from './Empty';
import clsx from 'clsx';
import defaultAvatar from '@/assets/tg-avatar.png';
import EnabledIcon from '@/assets/enabled.svg?react';
import WorkspaceAddonsEnableDrawer from './drawer';
import { useAddonsIsEnabled, useDisableAddonsCopilot } from '@/pages/workgroup/form/addons/store';
import { AddonsMetaID } from '@/components/addons/consts';

const WorkspaceAddonsItem: FC<{
  addonsMeta?: MetaAddonsType;
  onClick?(): void;
}> = ({ onClick, addonsMeta }) => {
  const isEnabled = useAddonsIsEnabled(addonsMeta?.ID as string);

  return (
    <li
      className={clsx(
        isEnabled && 'border-[#3F5870] border-2',
        'relative flex items-center space-x-1 px-2 py-4 border rounded-[2px] w-full h-full cursor-pointer'
      )}
      onClick={() => onClick?.()}
    >
      <div className="flex items-center space-x-3">
        <img className="w-8 h-8 object-contain" src={addonsMeta?.WorkspaceAvatar || defaultAvatar} />
        <div className="flex-grow">
          <h4 className="font-[Roboto] truncate font-semibold leading-6 text-[#222]">{addonsMeta?.Title}</h4>
          <p className="text-xs text-[#656565]">{addonsMeta?.Subtitle}</p>
        </div>
      </div>
      {isEnabled && <EnabledIcon className="absolute right-0 top-0" />}
    </li>
  );
};

const sortEnabledAddons = (data: EnabledAddonsType[] | undefined) =>
  data?.sort((a, b) => {
    if (a.AddonsID === AddonsMetaID.COPILOT) {
      return -1;
    }
    if (b.AddonsID === AddonsMetaID.COPILOT) {
      return 1;
    }
    return 0;
  });

const WorkspaceAddonSelect: FC<{
  disableCopilot?: boolean;
  onChange?(params: Record<string, boolean | number | string>): void;
}> = ({ disableCopilot, onChange }) => {
  const [css, theme] = useStyletron();
  const [selectedEnabledAddons, setSelectedEnabledAddons] = useState<EnabledAddonsType>();
  const [addonsParams, setAddonsParams] = useState<Record<string, string | number | boolean>>();
  const { data: enabledAddons, isLoading: loadingEnabledAddons } = useListEnabledAddons({
    select: sortEnabledAddons,
    refetchOnWindowFocus: true,
  });
  const { data: addonsMeta, isLoading: loadingAddonsMeta } = useAddonsMeta();
  const metaMap = useMemo(() => new Map(addonsMeta?.AddonsList.map((m) => [m.ID, m])), [addonsMeta]);
  const disableEnabledAddonsCopilot = useDisableAddonsCopilot();
  const selectedMetaAddons = metaMap.get(selectedEnabledAddons?.AddonsID as string);
  const isLoading = loadingEnabledAddons || loadingAddonsMeta;

  const handleAddonsEnableChange = (params: Record<string, string | number | boolean>) => {
    const newParams = {
      ...addonsParams,
      ...params,
    };

    setAddonsParams(newParams);
    setSelectedEnabledAddons(undefined);

    onChange?.(newParams);
  };

  const filteredEnabledAddons = disableCopilot
    ? enabledAddons?.filter((i) => i.AddonsID !== AddonsMetaID.COPILOT)
    : enabledAddons;

  useEffect(() => {
    if (disableCopilot) {
      disableEnabledAddonsCopilot();
    }
  }, [disableCopilot, disableEnabledAddonsCopilot]);

  return (
    <div>
      <h1 className={css({ ...theme.typography.HeadingMedium })}>Add-Ons</h1>
      <p className={css({ ...theme.typography.Body2, color: '#656565', marginBottom: '8px' })}>
        Extend the capabilities of TigerGraph Cloud with add-ons tailored to your needs.
      </p>
      <ul className="grid gap-3 grid-cols-[repeat(4,245px)] [&>*]:h-[80px]">
        {isLoading
          ? Array.from({ length: 4 }).map((_, i) => <Skeleton rows={0} width="100%" animation key={i} />)
          : filteredEnabledAddons?.map((d) => (
              <WorkspaceAddonsItem
                key={d.ID}
                addonsMeta={metaMap.get(d.AddonsID)}
                onClick={() => setSelectedEnabledAddons(d)}
              />
            ))}
        {!isLoading && enabledAddons?.length === 0 && <WorkspaceAddonsEmpty />}
      </ul>
      <p className="text-xs font-[Roboto] space-x-1 mt-4">
        <span className="text-[#656565]">Can{"'"}t find the Add-on you want?</span> Click
        <a className="text-[#1976D2]" href="/marketplace/addons" target="_blank">
          here
        </a>{' '}
        to add.
      </p>
      <WorkspaceAddonsEnableDrawer
        addonsMeta={selectedMetaAddons}
        enabledAddons={selectedEnabledAddons}
        onChange={handleAddonsEnableChange}
        onCancel={() => setSelectedEnabledAddons(undefined)}
      />
    </div>
  );
};

export default WorkspaceAddonSelect;
