import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { KIND } from 'baseui/button';
import { expand } from 'inline-style-expand-shorthand';

interface ConfirmButtonsProps {
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
}

export default function ConfirmButtons(props: ConfirmButtonsProps) {
  const [css] = useStyletron();

  const {
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    onConfirm,
    onCancel,
    confirmDisabled,
    confirmLoading,
  } = props;
  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'flex-end',
      })}
    >
      {onCancel && (
        <Button
          kind={KIND.tertiary}
          onClick={onCancel}
          overrides={{
            BaseButton: {
              style: {
                color: '#656565',
                ...expand({
                  borderRadius: '2px',
                }),
              },
            },
          }}
        >
          {cancelLabel}
        </Button>
      )}
      {onConfirm && (
        <Button
          kind={KIND.primary}
          disabled={confirmDisabled}
          isLoading={confirmLoading}
          onClick={onConfirm}
          overrides={{
            BaseButton: {
              style: {
                marginLeft: '16px',
                ...expand({
                  borderRadius: '2px',
                }),
              },
            },
          }}
        >
          {confirmLabel}
        </Button>
      )}
    </div>
  );
}
