import { Input } from 'baseui/input';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useEffect, useState } from 'react';
import { RiEditLine } from 'react-icons/ri';
import IconButton from '@/components/IconButton';

export type EditableInputProps = {
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  width?: string;
};

export function EditableInput({ value, onChange, readOnly, width, ...props }: EditableInputProps) {
  const [css] = useStyletron();
  const [inputValue, setInputValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      {!isEditing && (
        <span
          className={css({
            lineHeight: '32px',
            height: '32px',
            display: 'inline-flex',
            alignItems: 'center',
          })}
        >
          <span
            className={css({
              maxWidth: '100px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            })}
            title={value}
          >
            {value}
          </span>
          {!readOnly && (
            <IconButton onClick={() => setIsEditing(true)}>
              <RiEditLine
                className={css({
                  fontSize: '16px',
                  marginLeft: '4px',
                })}
              />
            </IconButton>
          )}
        </span>
      )}
      {isEditing && (
        <Input
          onChange={(e) => {
            setInputValue(e.currentTarget.value);
          }}
          onBlur={() => {
            setIsEditing(false);
            onChange(inputValue);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsEditing(false);
              onChange(inputValue);
            }
          }}
          autoFocus
          value={inputValue}
          overrides={{
            Root: {
              style: {
                width: width || '128px',
              },
            },
            Input: {
              style: {
                height: '30px',
                paddingLeft: '2px',
                width: '100%',
                paddingRight: '0px',
              },
            },
          }}
          {...props}
        />
      )}
    </>
  );
}
