import { ID_TOKEN_KEY } from '@/contexts/userContext';
import { Return } from '@/lib/type';
import axios from 'axios';
import { useQuery } from 'react-query';

type ListQueriesResponse = Return<{
  queries: { queryName: string; description: string }[];
}>;

export const useListQueries = (nginxHost: string, graphName?: string) => {
  return useQuery({
    queryKey: ['list', 'queries', nginxHost, graphName],
    queryFn: async () => {
      const res = await axios.get<ListQueriesResponse>(
        `/api/gsql-server/gsql/description?graph=${graphName}&query=all`,
        {
          baseURL: `https://${nginxHost}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          },
        }
      );

      const queries = res.data.results?.queries;

      return queries?.filter((q) => 'description' in q);
    },
    enabled: Boolean(nginxHost && graphName),
  });
};
