import { styled } from '@tigergraph/app-ui-lib/Theme';
import { StyledTableBodyCell as BaseBodyCell, StyledTableHeadCell as BaseTableCell } from 'baseui/table-semantic';

export const StyledTableBodyCell = styled(BaseBodyCell, () => {
  return {
    padding: '8px !important',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#222',
  };
});

export const StyledTableHeadCell = styled(BaseTableCell, () => ({
  padding: '8px !important',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 600,
  color: '#222',
}));
