import { useQuery } from 'react-query';

import { axiosCluster } from '@/lib/network';
import { Schema } from '@tigergraph/tools-ui/graph/type';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { GLOBAL_GRAPH_NAME } from '@tigergraph/tools-models/utils';

// const emptySchema = { graph: { vertices: {}, edges: {} }, results: {} };
export const reqGraphSchema = (graphName: string): Promise<Response> => {
  const params = graphName === GLOBAL_GRAPH_NAME ? {} : { graph: graphName };
  return axiosCluster.get(`/api/gsql-server/gsql/schema`, { params }).then((res) => res.data);
};

export const reqGraphStyles = (graphName: string): Promise<Response> => {
  const url = graphName === GLOBAL_GRAPH_NAME ? `/api/graph-styles/global` : `/api/graph-styles/local/` + graphName;
  return axiosCluster.get(url).then((res) => res.data);
};

export function useSchemaStyle(graph: string, styleJSON?: any) {
  const { currentWorkspace } = useWorkspaceContext();
  return useQuery(
    ['schemaStyles', graph, currentWorkspace?.nginx_host],
    async () => {
      // if no current workspace, return empty schema
      if (!currentWorkspace?.nginx_host) {
        return {
          vertexStyles: {},
          edgeStyles: {},
        };
      }
      const response = styleJSON
        ? styleJSON
        : ((await reqGraphStyles(graph)) as Response & {
            results: { vertexStyles: any; edgeStyles: any };
            error: any;
          });
      const { vertexStyles, edgeStyles } = response['results'];
      return { vertexStyles, edgeStyles };
    },
    {
      enabled: !!graph,
    }
  );
}

export function useSchema(graph: string, schemaJson?: any) {
  const { currentWorkspace } = useWorkspaceContext();
  return useQuery(
    ['schema', graph, schemaJson, currentWorkspace?.nginx_host],
    async () => {
      // if no current workspace, return empty schema
      if (!currentWorkspace?.nginx_host) {
        return {
          GraphName: '',
          VertexTypes: [],
          EdgeTypes: [],
        } as Schema;
      }
      const response = schemaJson
        ? schemaJson
        : ((await reqGraphSchema(graph)) as Response & {
            results: { VertexTypes: any[]; EdgeTypes: any[] };
            error: any;
          });
      const schemaVertices: { [key: string]: any } = {},
        schemaEdges: { [key: string]: any } = {};
      if (!response['error']) {
        const results = response['results'];
        for (const vertex of results['VertexTypes']) {
          const type = vertex['Name'];
          schemaVertices[type] = vertex;
        }
        for (const edge of results['EdgeTypes']) {
          const type = edge['Name'];
          schemaEdges[type] = edge;
        }
      }

      // update schema with style
      try {
        const graphStylesResponse = (await reqGraphStyles(graph)) as Response & {
          results: { vertexStyles: any; edgeStyles: any };
          error: any;
        };

        if (!graphStylesResponse['error']) {
          const { vertexStyles, edgeStyles } = graphStylesResponse['results'];
          Object.entries(vertexStyles).forEach(([type, style]) => {
            schemaVertices[type]['style'] = style;
          });
          Object.entries(edgeStyles).forEach(([type, style]) => {
            schemaEdges[type]['style'] = style;
          });
        }
      } catch (error) {
        // ignore error here
      }

      const schema = response['results'] as Schema;

      return schema;
    },
    {
      enabled: !!graph,
    }
  );
}
