import { styled } from '@tigergraph/app-ui-lib/Theme';

export const FormSectionTitle = styled('div', ({ $theme }) => ({
  fontFamily: 'Urbanist',
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '32px',
  color: $theme.colors.gray1000,
  marginBottom: '16px',
}));

export const FormSection = styled('div', {
  marginBottom: '24px',
});

export const ToggleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '8px',
});
