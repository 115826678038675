import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from '@tigergraph/app-ui-lib/input';
import { AddIconNoCircle } from '@/pages/home/icons';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox } from 'baseui/checkbox';
import { Edge } from '@tigergraph/tools-models/topology';
import { FromToVertexPairTable } from '@/components/schemaDesigner/FromToVertexPairTable';
import { EdgeAttributeTable } from '@/components/schemaDesigner/EdgeAttributeTable';
import { AddTableRowButton } from '@/utils/schemaDesigner/styleObject';
import { Attribute, ValidateResult } from '@tigergraph/tools-models';
import { randomString } from '@/utils/schemaDesigner';
import { DefaultAttributeType } from '@/utils/schemaDesigner/data';
import ErrorMessage from '@/components/ErrorMessage';

export interface EdgeEditorProps {
  isOpen: boolean;
  onClose: () => void;
  initEdge: Edge | undefined;
  onSave: (edge: Edge) => ValidateResult | undefined;
  readOnly?: boolean;
}

export function EdgeEditor({ isOpen, onClose, initEdge, onSave, readOnly }: EdgeEditorProps) {
  const [css, theme] = useStyletron();
  // const [color] = useState('red');
  const [edgeName, setEdgeName] = useState('');
  const [direct, setDirect] = useState(false);
  const [hasReverseEdge, setHasReverseEdge] = useState(initEdge?.hasReverseEdge);
  const [attributes, setAttributes] = useState(initEdge?.attributes || []);
  const [fromToVertexTypePairs, setFromToVertexTypePairs] = useState(initEdge?.fromToVertexTypePairs || []);
  const [validateResult, setValidateResult] = useState<ValidateResult | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setEdgeName(initEdge?.name || '');
    setFromToVertexTypePairs(initEdge?.fromToVertexTypePairs || []);
    setDirect(initEdge?.directed || false);
    setAttributes(initEdge?.attributes || []);
  }, [initEdge]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  const handleSave = useCallback(
    (
      edgeName: string,
      fromToVertexTypePairs: { from: string; to: string }[],
      attributes: Attribute[],
      direct: boolean
    ) => {
      const edge = initEdge!.clone();
      edge.name = edgeName!;
      edge.directed = direct!;
      edge.hasReverseEdge = hasReverseEdge!;
      edge.fromToVertexTypePairs = fromToVertexTypePairs;
      if (edge!.hasReverseEdge) {
        edge!.reverseEdge = 'reverse_' + edgeName;
      }
      edge.attributes = attributes;
      const res = onSave(edge);
      setValidateResult(res);
    },
    [hasReverseEdge, initEdge, onSave]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.key === 'Enter' || event.key === 'Escape') && isOpen) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  const handleChangeEdgeName = (name: string) => {
    setEdgeName(name);
    handleSave(name, fromToVertexTypePairs, attributes, direct);
  };

  const handleDirectChange = (checked: boolean) => {
    setDirect(checked);
    handleSave(edgeName, fromToVertexTypePairs, attributes, checked);
  };

  const handleAddNewPair = () => {
    setFromToVertexTypePairs([...fromToVertexTypePairs, { from: '', to: '' }]);
    handleSave(edgeName, [...fromToVertexTypePairs, { from: '', to: '' }], attributes, direct);
  };

  const handleFromToVertexTypePairsChange = (pairs: { from: string; to: string }[]) => {
    setFromToVertexTypePairs(pairs);
    handleSave(edgeName, pairs, attributes, direct);
  };

  const handleAddAttribute = () => {
    const attr = new Attribute();
    attr.name = `newAttr_${randomString(3)}`;
    attr.type.name = DefaultAttributeType;
    attr.defaultValue = 'default value';
    setAttributes([...attributes, attr]);
    handleSave(edgeName, fromToVertexTypePairs, [...attributes, attr], direct);
  };

  const isInvalidEdgeName = useMemo(() => {
    return validateResult && !validateResult.success && validateResult.message?.includes('edge name');
  }, [validateResult]);

  return (
    <div>
      <div
        className={css({
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: `${theme.colors.border}`,
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 600,
          color: theme.colors.gray1000,
          padding: '14px 0',
        })}
      >
        Edit Edge
      </div>
      <div
        className={css({
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: `${theme.colors.border}`,
          paddingBottom: '8px',
        })}
      >
        <div
          className={css({
            paddingTop: '8px',
            paddingBottom: '4px',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
          })}
        >
          Edge name
        </div>
        <div
          className={css({
            display: 'flex',
            height: '32px',
            gap: '4px',
          })}
        >
          <Input
            ref={inputRef}
            onChange={(e) => handleChangeEdgeName(e.currentTarget.value)}
            value={edgeName}
            disabled={readOnly}
            overrides={{
              Root: {
                style: {
                  width: '70%',
                  flexGrow: 1,
                },
              },
            }}
            placeholder={'name'}
            error={isInvalidEdgeName}
          />
          {/*<button*/}
          {/*  className={css({*/}
          {/*    width: '32px',*/}
          {/*    backgroundColor: `${color}`,*/}
          {/*    borderRadius: '4px',*/}
          {/*  })}*/}
          {/*></button>*/}
          <Checkbox
            onChange={(e) => handleDirectChange(e.currentTarget.checked)}
            checked={direct}
            disabled={readOnly}
            overrides={{
              Root: {
                style: {
                  alignItems: 'end',
                },
              },
            }}
          >
            Directed
          </Checkbox>
        </div>
        {direct && (
          <>
            <ParagraphSmall
              className={css({
                paddingTop: '8px',
                paddingBottom: '4px',
              })}
            >
              Reverse edge type
            </ParagraphSmall>
            <div
              className={css({
                display: 'flex',
                height: '32px',
                gap: '4px',
              })}
            >
              <Input
                value={'reverse_' + edgeName}
                disabled
                overrides={{
                  Root: {
                    style: {
                      width: '70%',
                      flexGrow: 1,
                    },
                  },
                }}
              />
              <Checkbox
                checked={hasReverseEdge}
                onChange={(e) => {
                  setHasReverseEdge(e.currentTarget.checked);
                }}
                disabled={readOnly}
                overrides={{
                  Root: {
                    style: {
                      alignItems: 'end',
                    },
                  },
                }}
              >
                Reverse edge
              </Checkbox>
            </div>
          </>
        )}
        {isInvalidEdgeName && <ErrorMessage message={validateResult?.message ?? ''} />}
      </div>
      <div
        className={css({
          paddingTop: '8px',
          paddingBottom: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <ParagraphMedium>Source and target vertex types</ParagraphMedium>
        <Button onClick={handleAddNewPair} disabled={readOnly} overrides={AddTableRowButton(theme)}>
          <AddIconNoCircle />
          Add new
        </Button>
      </div>
      <div
        className={css({
          paddingBottom: '8px',
        })}
      >
        <FromToVertexPairTable
          fromToVertexTypePairs={fromToVertexTypePairs}
          readOnly={readOnly}
          onChange={(pairs) => handleFromToVertexTypePairsChange(pairs)}
        />
      </div>
      <div
        className={css({
          paddingTop: '8px',
          paddingBottom: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <ParagraphMedium>Attribute</ParagraphMedium>
        <Button onClick={handleAddAttribute} disabled={readOnly} overrides={AddTableRowButton(theme)}>
          <AddIconNoCircle />
          Add Attribute
        </Button>
      </div>
      <div
        className={css({
          paddingBottom: '8px',
        })}
      >
        <EdgeAttributeTable
          attributes={attributes}
          onChange={(attributes) => {
            handleSave(edgeName, fromToVertexTypePairs, attributes, direct);
            setAttributes(attributes);
          }}
          validateResult={validateResult}
          readOnly={readOnly}
        />
      </div>
      <ParagraphSmall
        className={css({
          textAlign: 'center',
          color: `${theme.colors.gray500}`,
        })}
      >
        Click &quot;+ Add Attribute&quot; to add attributes.
      </ParagraphSmall>
    </div>
  );
}
