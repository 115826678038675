import { Drawer, DrawerHeader } from '@/components/Drawer';
import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import toast from 'react-hot-toast';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { MdOutlineAddToQueue } from 'react-icons/md';
import Empty from './icon/empty.svg?react';
import { useNavigate } from 'react-router-dom';
import { expand } from 'inline-style-expand-shorthand';
import IconButton from '@/components/IconButton';
import LinkButton from '@/components/LinkButton';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import { actionColumnOverrides, columnOverrides } from '@/components/table';
import { TableContainer } from '@/lib/styled';
import { Result } from '@/lib/type';
import { listSolution } from '@/pages/marketplace/solution/api';
import { useMutationDeleteSolution, useQueryGetSolutionCatalog } from '@/pages/marketplace/solution/hook';
import { SolutionInstance } from '@/pages/marketplace/solution/type';
import { solutionsAtom } from '@/pages/workgroup/atom';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { Trash2Icon } from 'lucide-react';
import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { GSQL_COMMAND } from '@/utils/graphEditor';
import { calculateRoleForSpace } from '@/pages/admin/user/type';
import { useOrgContext } from '@/contexts/orgContext';
import { getErrorMessage } from '@/utils/utils';
import { parseDate } from '@/lib/date';
import clsx from 'clsx';
import useMutationObserver from 'ahooks/lib/useMutationObserver';
import EmptyState from '@/pages/workgroup/EmptyState';
import { useConfig } from '@/lib/config';
import InsightsIcon from '@/pages/workgroup/tab/icons/insights.svg?react';
import { getToolsLink } from '@/hooks/useToolsLink';

type Props = {
  group: WorkGroupT;
};

export default function SolutionList({ group }: Props) {
  const [css, theme] = useStyletron();

  const { userInfo, currentOrg } = useOrgContext();
  const { appConfig } = useConfig();

  const navigate = useNavigate();
  const [selectSolutionId, setSelectSolutionId] = useState<string | undefined>(undefined);
  const ref = useRef<HTMLPreElement>(null);
  useMutationObserver(
    () => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    },
    ref,
    { childList: true, characterData: true, attributes: true, subtree: true }
  );

  const { data: solutionCatalogs = [] } = useQueryGetSolutionCatalog();

  const [solutions, setSolutions] = useAtom(solutionsAtom);
  const { data, isLoading, isError, error } = useQuery<Result<SolutionInstance[]>, AxiosError>(
    ['solutions', group.workgroup_id],
    async () => {
      return listSolution(group.workgroup_id);
    },
    {
      onSuccess: (data) => {
        const results = data?.Result || [];
        for (let solution of results) {
          if (solution.solution_status === 'install_pending' || solution.solution_status === 'uninstall_pending') {
            if (!solutions.find((item) => item.id === solution.id)) {
              setSolutions((solutions) =>
                solutions.concat({
                  ...solution,
                  workspace_name: group.workspaces.find((ws) => ws.workspace_id === solution.workspace_id)?.name || '',
                })
              );
            }
          }
        }
      },
    }
  );

  const deleteSolutionMutation = useMutationDeleteSolution();

  const onDeleteSolution = (workgroup_id: string, workspace_id: string, solution_id: string) => {
    const promise = deleteSolutionMutation.mutateAsync({
      solution_id,
      workgroup_id,
      workspace_id,
    });
    toast.promise(
      promise,
      {
        loading: 'Deleting Solution',
        success: (data) => `${data.Message}`,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <ErrorDisplay label="Server Error:" error={error} />;
  }

  const { workspaces, tg_databases } = group;
  const sols = (data?.Result || []).map((solution) => {
    const {
      id,
      workgroup_id,
      workspace_id,
      solution_catalog_id,
      name,
      graph_name,
      owner_email,
      created_at,
      updated_at,
      solution_status,
      gsql_log,
    } = solution;
    let database = '';
    let workspace: WorkspaceT | undefined = undefined;
    for (let ws of workspaces) {
      if (ws.workspace_id === workspace_id) {
        workspace = ws;
        for (let db of tg_databases) {
          if (db.database_id === ws.database_id) {
            database = db.name;
            break;
          }
        }
        break;
      }
    }

    let hasInsights = false;
    for (let s of solutionCatalogs) {
      if (s.name === solution_catalog_id) {
        hasInsights = s.hasInsights;
        break;
      }
    }

    return {
      id,
      workgroup_id,
      workspace_id,
      solution_catalog_id,
      name,
      graph_name,
      owner_email,
      created_at,
      updated_at,
      database,
      workspace,
      solution_status,
      gsql_log,
      hasInsights,
    };
  });

  const selectSolution = sols.find((item) => item.id === selectSolutionId);

  return (
    <>
      {sols.length > 0 && (
        <TableContainer>
          <TableBuilder data={sols}>
            <TableBuilderColumn header="Solution Name" id="name">
              {(row) => row.name}
            </TableBuilderColumn>
            <TableBuilderColumn header="Status" id="status" overrides={columnOverrides}>
              {(row) => <Status solution={row} />}
            </TableBuilderColumn>
            <TableBuilderColumn header="Workspace" id="workspace">
              {(row) => row.workspace?.name}
            </TableBuilderColumn>
            <TableBuilderColumn header="Solution" id="solution">
              {(row) => row.solution_catalog_id}
            </TableBuilderColumn>
            <TableBuilderColumn header="Insights" id="insights">
              {(row) => {
                return row.hasInsights ? (
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={getToolsLink(appConfig, currentOrg, row.workspace, '/insights/apps')}
                  >
                    <InsightsIcon />
                  </a>
                ) : null;
              }}
            </TableBuilderColumn>
            <TableBuilderColumn header="Provider" id="provider">
              {(row) => 'TigerGraph'}
            </TableBuilderColumn>
            <TableBuilderColumn header="Created on" id="createDate">
              {(row) => format(parseDate(row.created_at), 'yyyy-MM-dd HH:mm:ss')}
            </TableBuilderColumn>
            <TableBuilderColumn header="Owner" id="owner">
              {(row) => row.owner_email}
            </TableBuilderColumn>
            <TableBuilderColumn header="Log" id="log">
              {(row) => <LinkButton onClick={() => setSelectSolutionId(row.id)}>view</LinkButton>}
            </TableBuilderColumn>
            <TableBuilderColumn overrides={actionColumnOverrides} header="Actions">
              {(row) => {
                const effectRole = calculateRoleForSpace(userInfo.roles, row.workgroup_id, row.workspace_id);

                return (
                  <div
                    className={clsx(
                      css({
                        display: 'flex',
                        gap: '8px',
                      })
                      // 'hoverToShow'
                    )}
                  >
                    {effectRole === 'workspace-admins' ? (
                      <ConfirmStatefulPopover
                        confirmLabel={`Are you sure you want to delete the solution and all the data, queries on the graph ${row.graph_name}?`}
                        onConfirm={() => onDeleteSolution(row.workgroup_id, row.workspace_id, row.id)}
                      >
                        <IconButton>
                          <Trash2Icon size={16} />
                        </IconButton>
                      </ConfirmStatefulPopover>
                    ) : null}
                  </div>
                );
              }}
            </TableBuilderColumn>
          </TableBuilder>
        </TableContainer>
      )}
      {sols.length == 0 && (
        <TableContainer
          className={css({
            height: 'calc(100vh - 96px)',
          })}
        >
          {workspaces.length == 0 && <EmptyState />}
          {workspaces.length > 0 && (
            <Container>
              <Empty />
              <Body2>Click “Add Solution” to install your first solution.</Body2>
              <Button
                onClick={() => navigate('/marketplace/solutions')}
                overrides={{
                  BaseButton: {
                    style: {
                      width: '148px',
                      height: '32px',
                      ...expand({
                        padding: '4px 16px',
                        borderRadius: '2px',
                        borderWidth: '0',
                      }),
                      ':active': {
                        ...expand({
                          borderWidth: '0',
                        }),
                      },
                    },
                  },
                }}
              >
                <MdOutlineAddToQueue size={20} />
                <span
                  className={css({
                    paddingLeft: '8px',
                    fontSize: '14px',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                  })}
                >
                  Add Solution
                </span>
              </Button>
            </Container>
          )}
        </TableContainer>
      )}

      {selectSolution ? (
        <Drawer isOpen={!!selectSolution} onClose={() => setSelectSolutionId(undefined)}>
          <DrawerHeader>
            Solution Log
            {selectSolution.solution_status === 'install_pending' ||
            selectSolution.solution_status === 'uninstall_pending' ? (
              <Spinner $size="16px" $borderWidth="2px" />
            ) : null}
          </DrawerHeader>
          <pre
            ref={ref}
            className={css({
              width: '658px',
              height: '100%',
              overflowY: 'auto',
              whiteSpace: 'pre-wrap',
              backgroundColor: 'black',
              color: theme.colors.gray200,
              fontSize: '12px',
              padding: '8px',
            })}
          >
            {stripLog(selectSolution.gsql_log)}
          </pre>
        </Drawer>
      ) : null}
    </>
  );
}

// todo(lin): handle different status
function Status({ solution }: { solution: SolutionInstance }) {
  const { solution_status } = solution;
  if (solution_status === 'installed') {
    return (
      <Tag closeable={false} kind="positive">
        Installed
      </Tag>
    );
  }

  if (solution_status === 'uninstalled') {
    return (
      <Tag closeable={false} kind="positive">
        Ready
      </Tag>
    );
  }

  if (solution_status === 'install_error' || solution_status === 'uninstall_error') {
    return (
      <Tag closeable={false} kind="negative">
        Failed
      </Tag>
    );
  }

  if (solution_status === 'install_pending') {
    return (
      <Tag
        closeable={false}
        kind="warning"
        overrides={{
          Text: {
            style: {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            },
          },
        }}
      >
        Initializing
        <Spinner $size="16px" $borderWidth="2px" />
      </Tag>
    );
  }

  return <span>solution_status</span>;
}

function stripLog(gsql_log: string) {
  let lines = gsql_log.split('\n');
  lines = lines.filter((line) => !line.startsWith(GSQL_COMMAND.COOKIE) && !line.startsWith(GSQL_COMMAND.RETURN_CODE));

  let result = '';
  let cursorIdx = 0;
  lines.forEach((line, lineIdx) => {
    if (lineIdx !== lines.length - 1) {
      line += '\n';
    }
    if (line.startsWith(GSQL_COMMAND.MOVE_CURSOR_UP)) {
      let num = parseInt(line.split(',')[1]);
      let idx = cursorIdx;
      while (num && (idx = result.lastIndexOf('\n', idx - 1)) > -1) {
        --num;
      }
      idx = result.lastIndexOf('\n', idx - 1);
      cursorIdx = idx === -1 ? 0 : idx + 1;
    } else if (line.startsWith(GSQL_COMMAND.CLEAN_LINE)) {
      const newLineIdx = result.indexOf('\n', cursorIdx);
      if (newLineIdx > -1) {
        result = result.slice(0, cursorIdx) + result.slice(newLineIdx + 1);
      }
    } else if (line.indexOf('\r') > -1) {
      const lastCarriageIdx = line.lastIndexOf('\r');
      const lastLine = result.lastIndexOf('\n');
      result = result.slice(0, lastLine + 1) + line.slice(lastCarriageIdx + 1);
      cursorIdx = result.length;
    } else {
      result = result.slice(0, cursorIdx) + line + result.slice(cursorIdx);
      cursorIdx += line.length;
    }
  });

  return result;
}

const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: $theme.colors.gray1000,
  ...$theme.typography.Body1,
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  height: '100%',
  textAlign: 'center',
  gap: '16px',
}));
