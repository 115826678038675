import { Skeleton, SkeletonPropsT } from 'baseui/skeleton';

export function BaseSkeleton({ ...props }: SkeletonPropsT) {
  return (
    <Skeleton
      {...props}
      overrides={{
        Root: {
          style: {
            backgroundColor: '#EAECEF',
          },
        },
      }}
      animation
    />
  );
}
