import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';

import { format } from 'date-fns';
import { parseDate } from '@/lib/date';

import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { WorkGroupT } from '@/pages/workgroup/type';
import { TableContainer } from '@/lib/styled';
import EmptyState from '@/pages/workgroup/EmptyState';
import ConnectionMenu from '@/pages/workgroup/tab/ConnectionMenu';
import { actionColumnOverrides } from '@/components/table';
import { SettingMenuForWorkSpace } from '@/pages/workgroup/tab/SettingMenu';
import clsx from 'clsx';

export default function Workspace({ group }: { group: WorkGroupT }) {
  const [css] = useStyletron();
  const { workspaces, tg_databases } = group;

  return (
    <>
      {workspaces.length > 0 && (
        <TableContainer>
          <TableBuilder data={workspaces}>
            <TableBuilderColumn header="Workspace Name" id="name">
              {(row) => row.name}
            </TableBuilderColumn>
            <TableBuilderColumn header="Workspace Id" id="workspace_id">
              {(row) => row.workspace_id}
            </TableBuilderColumn>
            <TableBuilderColumn header="Status" id="status">
              {(row) => row.status}
            </TableBuilderColumn>
            <TableBuilderColumn header="Type" id="type">
              {(row) => (row.is_rw ? 'R/W' : 'R/O')}
            </TableBuilderColumn>
            <TableBuilderColumn header="TigerGraph Version" id="version">
              {(row) => row.tg_version}
            </TableBuilderColumn>
            <TableBuilderColumn header="vCPU" id="cpuUsage">
              {(row) => `${row.workspace_type.cpu}`}
            </TableBuilderColumn>
            <TableBuilderColumn header="Memory" id="memoryUsage">
              {(row) => `${row.workspace_type.memory}`}
            </TableBuilderColumn>
            <TableBuilderColumn header="Owner" id="owner">
              {(row) => row.creator}
            </TableBuilderColumn>
            <TableBuilderColumn header="Created on" id="createDate">
              {(row) => {
                return format(parseDate(row.created_at), 'yyyy-MM-dd HH:mm:ss');
              }}
            </TableBuilderColumn>
            <TableBuilderColumn overrides={actionColumnOverrides} header="Actions">
              {(row) => (
                <div
                  className={clsx(
                    css({
                      display: 'flex',
                      gap: '6px',
                    })
                    // 'hoverToShow'
                  )}
                >
                  <ConnectionMenu
                    workspace={row}
                    database={tg_databases.find((db) => db.database_id === row.database_id)}
                  />
                  <SettingMenuForWorkSpace
                    workspace={row}
                    database={tg_databases.find((db) => db.database_id === row.database_id)}
                  />
                </div>
              )}
            </TableBuilderColumn>
          </TableBuilder>
        </TableContainer>
      )}
      {workspaces.length == 0 && (
        <TableContainer
          className={css({
            height: 'calc(100vh - 96px)',
          })}
        >
          <EmptyState createWorkGroup={false} />
        </TableContainer>
      )}
    </>
  );
}
