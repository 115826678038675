import { useState } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useMutation } from 'react-query';
import { axiosCluster } from '@/lib/network';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@tigergraph/app-ui-lib/modal';
import { Input } from '@tigergraph/app-ui-lib/input';
import { useUserContext } from '@/contexts/userContext';
import { Button } from '@tigergraph/app-ui-lib/button';
import { FormatValidator } from '@tigergraph/tools-models/utils';
import { AxiosError } from 'axios';
import ErrorMessage from '../ErrorMessage';
import ConfirmButtons from '@/components/ConfirmButtons';
import { expand } from 'inline-style-expand-shorthand';
import { showToast } from '@/components/styledToasterContainer';
import { KIND } from 'baseui/toast';

export interface CreateNewGraphProps {
  isOpen: boolean;
  onClose: () => void;
}
const schemaURL = '/api/gsql-server/gsql/schema';

export default function CreateNewGraphModal(props: CreateNewGraphProps) {
  const [css] = useStyletron();
  const { isOpen, onClose } = props;
  const { setCurrentGraph } = useUserContext();
  const [graphName, setGraphName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const { mutate: mutateCreateGraph, isLoading } = useMutation(
    ['createGraph'],
    async (graphName: string) => {
      const response = await axiosCluster.post(schemaURL, {
        GraphName: graphName,
        VertexTypes: [],
        EdgeTypes: [],
      });
      return response;
    },
    {
      onSuccess: () => {
        handleClose();
        setCurrentGraph(graphName);
        showToast({
          kind: KIND.positive,
          message: `Successfully created graph ${graphName}.`,
        });
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setErrorMessage(error.response?.data?.message);
        showToast({
          kind: KIND.negative,
          message: `Failed to create graph ${graphName}\n${error.response?.data?.message}`,
        });
      },
    }
  );

  const handleClose = () => {
    onClose();
    setGraphName('');
    setErrorMessage(undefined);
  };

  return (
    <div
      className={css({
        display: 'flex',
        width: '100%',
      })}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !errorMessage) {
          mutateCreateGraph(graphName);
        }
      }}
    >
      <Modal
        onClose={handleClose}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: {
              zIndex: 2,
            },
          },
        }}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Urbanist',
            fontSize: '16px',
            fontWeight: 600,
            ...expand({
              padding: '0',
            }),
          })}
        >
          Create New Graph
        </ModalHeader>
        <ModalBody>
          <div
            className={css({
              marginBottom: '8px',
              fontSize: '14px',
              fontWeight: 400,
            })}
          >
            Graph Name
          </div>
          <Input
            value={graphName}
            error={!!errorMessage}
            onChange={(event) => {
              setGraphName(event.currentTarget.value);
              const formatCheck = FormatValidator.isName(event.currentTarget.value);
              if (!formatCheck.success) {
                setErrorMessage(formatCheck.message);
              } else {
                setErrorMessage(undefined);
              }
            }}
            overrides={{
              Root: {
                style: {
                  ...expand({
                    borderRadius: '2px',
                  }),
                },
              },
            }}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
        </ModalBody>
        <ModalFooter>
          {isLoading ? (
            <Button isLoading={isLoading} />
          ) : (
            <ConfirmButtons
              confirmLabel={'Done'}
              cancelLabel={'Cancel'}
              confirmDisabled={!!errorMessage}
              onConfirm={() => {
                mutateCreateGraph(graphName);
              }}
              onCancel={handleClose}
            />
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
