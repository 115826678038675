import { styled } from '@tigergraph/app-ui-lib/Theme';
import { ComponentPropsWithRef } from 'react';

type Size = 'compact' | 'default' | 'large';

type Props = ComponentPropsWithRef<'span'> & { size: Size };

export const Avatar = styled<'span', Props>('span', ({ size }) => {
  return {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#27845B',
    color: '#fff',
    ...getStyle(size),
  };
});

function getStyle(size: Size) {
  switch (size) {
    case 'compact':
      return {
        width: '24px',
        height: '24px',
        fontSize: '10px',
      };

    case 'default':
      return {
        width: '32px',
        height: '32px',
        fontSize: '14px',
      };
    case 'large':
      return {
        width: '56px',
        height: '56px',
        fontSize: '25px',
      };
    default:
      break;
  }
}

export function formatName({
  firstName,
  lastName,
  username,
  email,
}: {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
}) {
  let name = '';
  if (firstName) {
    if (lastName) {
      name = firstName[0] + lastName[0];
    } else {
      name = firstName.slice(0, 2);
    }
  } else {
    name = (username || email).slice(0, 2);
  }
  return name.toUpperCase();
}
