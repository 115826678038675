import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import {
  CreateDatabaseRequest,
  CreateWorkGroupRequest,
  CreateWorkSpaceRequest,
  DatabaseT,
  UpdateWorkspaceRequest,
  WorkGroupT,
  WorkSpaceMeta,
  WorkspaceT,
} from '@/pages/workgroup/type';

export async function getMeta(): Promise<Result<WorkSpaceMeta>> {
  const response = await axiosController.get('v2/workspaces/meta');
  return response.data;
}

export async function createWorkGroup(data: CreateWorkGroupRequest): Promise<Result<WorkGroupT>> {
  const response = await axiosController.post('v2/workgroups', data);
  return response.data;
}

export async function getWorkGroups(): Promise<Result<WorkGroupT[]>> {
  const response = await axiosController.get('v2/workgroups');
  return response.data;
}

export async function getWorkGroupDetail(group_id: string): Promise<Result<WorkGroupT>> {
  const response = await axiosController.get(`v2/workgroups/${group_id}`);
  return response.data;
}

export async function renameWorkGroup(group_id: string, workgroup_name: string): Promise<Result<void>> {
  const response = await axiosController.put(`v2/workgroups/${group_id}`, {
    workgroup_name,
  });
  return response.data;
}

export async function deleteWorkGroup(group_id: string): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/workgroups/${group_id}`);
  return response.data;
}

export async function createWorkSpace(data: CreateWorkSpaceRequest): Promise<Result<WorkspaceT>> {
  const response = await axiosController.post(`v2/workgroups/${data.workgroup_id}/workspaces`, data);
  return response.data;
}

export async function getWorkSpaceDetail(group_id: string, space_id: string): Promise<Result<WorkspaceT>> {
  const response = await axiosController.get(`v2/workgroups/${group_id}/workspaces/${space_id}`);
  return response.data;
}

export async function deleteWorkSpace(group_id: string, space_id: string): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/workgroups/${group_id}/workspaces/${space_id}`);
  return response.data;
}

export async function pauseWorkSpace(group_id: string, space_id: string): Promise<Result<void>> {
  const response = await axiosController.post(`v2/workgroups/${group_id}/workspaces/${space_id}/pause`);
  return response.data;
}

export async function resumeWorkSpace(group_id: string, space_id: string): Promise<Result<void>> {
  const response = await axiosController.post(`v2/workgroups/${group_id}/workspaces/${space_id}/resume`);
  return response.data;
}

export async function refreshWorkSpace(group_id: string, space_id: string): Promise<Result<void>> {
  const response = await axiosController.post(`v2/workgroups/${group_id}/workspaces/${space_id}/refresh`);
  return response.data;
}

export async function updateWorkSpace(
  group_id: string,
  space_id: string,
  data: UpdateWorkspaceRequest
): Promise<Result<WorkspaceT>> {
  const response = await axiosController.put(`v2/workgroups/${group_id}/workspaces/${space_id}`, data);
  return response.data;
}

export async function createDatabase(data: CreateDatabaseRequest): Promise<Result<DatabaseT>> {
  const response = await axiosController.post(`v2/workgroups/${data.workgroup_id}/databases`, data);
  return response.data;
}

export async function renameDatabase(group_id: string, db_id: string, database_name: string): Promise<Result<void>> {
  const response = await axiosController.put(`v2/workgroups/${group_id}/databases/${db_id}`, {
    tg_database_name: database_name,
  });
  return response.data;
}

export async function deleteDatabase(group_id: string, db_id: string): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/workgroups/${group_id}/databases/${db_id}`);
  return response.data;
}
