import { ReactNode } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Toast, KIND, ToastProps } from 'baseui/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { expand } from 'inline-style-expand-shorthand';

export const SIZE = {
  compact: 'compact',
  default: 'default',
};

export interface StyledToastProps extends ToastProps {
  message?: ReactNode;
  title?: ReactNode;
  action?: () => void;
  size?: keyof typeof SIZE;
}

const styleMap = {
  [KIND.positive]: {
    color: '#30A442',
    backgroundColor: '#EBF6ED',
  },
  [KIND.negative]: {
    color: '#B93535',
    backgroundColor: '#F8EBEA',
  },
  [KIND.warning]: {
    color: '#E49A0A',
    backgroundColor: '#FDF5E7',
  },
  [KIND.info]: {
    color: '#0A88E4',
    backgroundColor: '#E7F4FC',
  },
};

const sizeMap = {
  [SIZE.default]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  [SIZE.compact]: {
    fontSize: '14px',
    lineHeight: '16px',
  },
};

const iconMap = {
  [KIND.positive]: faCircleCheck,
  [KIND.negative]: faCircleExclamation,
  [KIND.warning]: faTriangleExclamation,
  [KIND.info]: faCircleInfo,
};

const StyledToast = (props: StyledToastProps) => {
  const [css, theme] = useStyletron();
  const { kind = KIND.info, message, children, size = SIZE.compact, title, action, ...rest } = props;

  return (
    <Toast
      {...rest}
      kind={kind}
      overrides={{
        Body: {
          style: {
            width: '100%',
            fontWeight: 400,
            color: '#222222',
            backgroundColor: styleMap[kind].backgroundColor,
            borderLeft: `4px solid ${styleMap[kind].color}`,
            boxSizing: 'border-box',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            ...sizeMap[size],
            ...expand({
              margin: '0',
              borderRadius: '4px',
            }),
          },
        },
        CloseIcon: {
          style: {
            width: sizeMap[size].lineHeight,
            height: sizeMap[size].lineHeight,
            color: '#3F5870',
          },
        },
        InnerContainer: {
          style: {
            width: '100%',
          },
        },
        ...props.overrides,
      }}
    >
      {(params) => {
        return (
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            })}
          >
            <div
              className={css({
                display: 'flex',
              })}
            >
              <ToastIcon kind={kind} />
              <div
                className={css({
                  flex: 1,
                })}
              >
                {title ? (
                  <div
                    className={css({
                      marginBottom: '8px',
                      fontWeight: 600,
                    })}
                  >
                    {title}
                  </div>
                ) : null}
                <div
                  className={css({
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                    maxHeight: '400px',
                    overflowY: 'auto',
                  })}
                >
                  {message}
                </div>
              </div>
            </div>
            {action ? (
              <button
                onClick={() => {
                  params.dismiss();
                  action();
                }}
                className={css({
                  alignSelf: 'flex-end',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                  color: theme.colors.secondary800,
                })}
              >
                View more
              </button>
            ) : null}
            {typeof children === 'function' ? children(params) : children}
          </div>
        );
      }}
    </Toast>
  );
};

const ToastIcon = ({ kind }: { kind: keyof typeof KIND }) => {
  const [css] = useStyletron();
  const specificIcon = iconMap[kind];
  return (
    <div className={css({ marginRight: '8px' })}>
      <FontAwesomeIcon icon={specificIcon as IconProp} color={styleMap[kind].color} />
    </div>
  );
};

export default StyledToast;
