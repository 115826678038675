import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { ButtonOverrides } from 'baseui/button';
import { expand } from 'inline-style-expand-shorthand';

export const SchemaSaveButton = (theme: CustomTheme): ButtonOverrides => ({
  BaseButton: {
    style: {
      height: '24px',
      width: '24px',
      lineHeight: '28px',
      backgroundColor: '#fff',
      ...expand({
        padding: '0px 0px',
        border: `1px solid ${theme.colors.gray600}`,
        borderRadius: '2px',
      }),
      color: `${theme.colors.gray900}`,
      ':disabled': {
        border: `1px solid ${theme.colors.gray400}`,
        backgroundColor: '#ffffff',
      },
      ':hover': {
        backgroundColor: '#fff',
        boxShadow: `${theme.colors.gray100} 0px 2px 4px`,
      },
      ':active': {
        backgroundColor: '#fff',
        boxShadow: `${theme.colors.gray100} 0px 2px 4px`,
        ...expand({
          border: `1px solid ${theme.colors.gray900}`,
        }),
      },
      ':hover:disabled': {
        backgroundColor: '#d4dadf',
        boxShadow: 'none',
      },
      ':active:disabled': {
        boxShadow: 'none',
        backgroundColor: '#d4dadf',
        ...expand({
          border: `1px solid ${theme.colors.gray400}`,
        }),
      },
    },
  },
});

export const AddTableRowButton = (theme: CustomTheme): ButtonOverrides => ({
  BaseButton: {
    style: {
      fontSize: '12px',
      height: '28px',
      lineHeight: '28px',
      backgroundColor: '#fff',
      ...expand({
        border: `1px solid ${theme.colors.secondary800}`,
      }),
      color: `${theme.colors.secondary800}`,
      ':hover': {
        backgroundColor: '#fff',
        boxShadow: `${theme.colors.secondary400} 0px 4px 6px`,
      },
      ':active': {
        ...expand({
          border: `1px solid ${theme.colors.secondary800}`,
        }),
      },
    },
  },
});
