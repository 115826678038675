import { useNavigate } from 'react-router-dom';
import { styled } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { KIND } from 'baseui/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import currency from 'currency.js';
import { formatPricePerHour } from '@/utils/format';

type Props = {
  computePrice: currency;
  storagePrice: currency;
  addOnPrice: currency;
  isLoading: boolean;
  cancelLabel?: string;
  confirmLabel: string;
};

const PriceCalculator = ({ computePrice, storagePrice, addOnPrice, isLoading, cancelLabel, confirmLabel }: Props) => {
  const [css] = useStyletron();
  const navigate = useNavigate();

  return (
    <Container>
      <div
        className={css({
          fontSize: '16px',
          lineHeight: '24px',
          display: 'grid',
          gridTemplateColumns: 'auto 160px',
          marginRight: 'auto',
        })}
      >
        <div>Compute</div>
        <div
          className={css({
            justifySelf: 'end',
          })}
        >
          {formatPricePerHour(computePrice)}
        </div>
        <div>Add-Ons</div>
        <div
          className={css({
            justifySelf: 'end',
          })}
        >
          {formatPricePerHour(addOnPrice)}
        </div>
        <div>Storage</div>
        <div
          className={css({
            justifySelf: 'end',
          })}
        >
          {storagePrice.format()}/GB/month
        </div>
      </div>
      <div>
        <div
          className={css({
            fontSize: '12px',
            lineHeight: '16px',
            marginBottom: '4px',
          })}
        >
          Hourly Rate From
        </div>
        <div
          className={css({
            fontSize: '32px',
            lineHeight: '40px',
            fontWeight: '600',
          })}
        >
          {formatPricePerHour(computePrice.add(addOnPrice))}
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          gap: '16px',
        })}
      >
        <Button type="button" onClick={() => navigate(-1)} kind={KIND.tertiary}>
          {cancelLabel || 'Cancel'}
        </Button>
        <Button isLoading={isLoading} type="submit">
          {confirmLabel}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled('section', ({ $theme }) => ({
  borderTop: `1px solid ${$theme.colors.divider}`,
  display: 'flex',
  alignItems: 'center',
  gap: '80px',
  padding: '16px 48px',
}));

export default PriceCalculator;
