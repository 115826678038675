import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { expand } from 'inline-style-expand-shorthand';
import { useQuery } from 'react-query';
import { axiosController } from '@/lib/network.ts';
import { useState } from 'react';
import { BaseSkeleton } from '@/components/BaseSkeleton.tsx';
import BackgroundImg from '@/assets/tg_bg.png';

interface NewsFeedItem {
  title?: string;
  description?: string;
  link?: string;
  img?: string;
}

export function NewsFeed() {
  const [css, theme] = useStyletron();
  const [items, setItems] = useState<NewsFeedItem[] | null>(null);
  const [firstItem, setFirstItem] = useState<NewsFeedItem | null>(null);

  const { data, isFetching } = useQuery(
    'newsFeed',
    async () => {
      return axiosController.get('/config/feed').then(({ data }) => {
        const newsFeed = new window.DOMParser().parseFromString(data, 'text/xml');
        const items = newsFeed.querySelectorAll('item');
        return Array.from(items).map((item) => {
          const content = item.getElementsByTagName('content:encoded')[0]?.innerHTML;
          const regex = /<img[\s\S]*?src="([^">]+)"/g;
          const matches = content.match(regex);
          const firstImg = matches ? matches[0] : '';

          let firstImgSrc = '';
          if (firstImg) {
            const srcRegex = /src="([^">]+)"/;
            const srcMatch = firstImg.match(srcRegex);
            if (srcMatch) {
              firstImgSrc = srcMatch[1];
            }
          }

          if (item.querySelector('title') && item.querySelector('description') && item.querySelector('link')) {
            return {
              title: item.querySelector('title')?.textContent || '',
              description: item.querySelector('description')?.textContent || '',
              link: item.querySelector('link')?.textContent || '',
              img: firstImgSrc || BackgroundImg,
            };
          } else {
            return { title: '', description: '', link: '' };
          }
        });
      });
    },
    {
      onSuccess: (data) => {
        // only show the first 8 items
        setItems(data.slice(1, 9));
        setFirstItem(data[0]);
      },
    }
  );

  const handleNavigateBlog = (item: NewsFeedItem) => {
    item?.link && window.open(item.link, '_blank');
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        ...expand({
          padding: '24px',
          border: `1px solid ${theme.colors.gray200}`,
          borderRadius: '4px',
          backgroundColor: 'white',
        }),
      })}
    >
      <div
        className={css({
          height: '24px',
          fontFamily: 'Urbanist',
          fontSize: '18px',
          marginBottom: '16px',
        })}
      >
        Recent TigerGraph Blogs
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '24px',
        })}
      >
        <div
          className={css({
            flexGrow: 1,
            width: '45%',
          })}
        >
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              height: '100%',
            })}
          >
            {items ? (
              <>
                <div
                  onClick={() => handleNavigateBlog(firstItem!)}
                  className={css({
                    backgroundImage: `url(${firstItem?.img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: 'center',
                    width: '100%',
                    flexGrow: 1,
                    backgroundSize: 'cover',
                    cursor: 'pointer',
                  })}
                ></div>
                <div
                  className={css({
                    cursor: 'pointer',
                    ':hover': {
                      color: theme.colors.primary,
                    },
                    fontFamily: 'Urbanist',
                    fontSize: '16px',
                  })}
                  onClick={() => handleNavigateBlog(firstItem!)}
                >
                  {firstItem?.title}
                </div>
              </>
            ) : (
              <>
                <div
                  className={css({
                    width: '100%',
                    flexGrow: 1,
                  })}
                >
                  <BaseSkeleton height="100%" />
                </div>
                <div>
                  <BaseSkeleton width="70%" height="24px" />
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={css({
            width: '1px',
            backgroundColor: theme.colors.gray200,
          })}
        ></div>

        <div
          className={css({
            flexGrow: 1,
            width: '45%',
          })}
        >
          {!items ? (
            <ul>
              {[...Array(8)].map((_, index) => (
                <li
                  key={index}
                  className={css({
                    lineHeight: '24px',
                    fontSize: '12px',
                    fontFamily: 'Urbanist',
                    padding: '8px 0',
                    ':not(:last-child)': {
                      borderBottom: `1px solid ${theme.colors.gray200}`,
                    },
                  })}
                >
                  <BaseSkeleton width="100%" height="24px" />
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              {items.map((item, index) => (
                <li
                  onClick={() => handleNavigateBlog(item)}
                  key={index}
                  className={css({
                    fontFamily: 'Urbanist',
                    lineHeight: '24px',
                    fontSize: '12px',
                    padding: '8px 0',
                    cursor: 'pointer',
                    ':hover': {
                      color: theme.colors.primary,
                    },
                    ':not(:last-child)': {
                      borderBottom: `1px solid ${theme.colors.gray200}`,
                    },
                  })}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
