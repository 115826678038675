import { FormControl } from '@/components/FormControl';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Controller, useForm } from 'react-hook-form';
import { DBUser } from '@/pages/admin/user/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { FormControlOverrides } from 'baseui/form-control';
import { CopyIcon } from 'lucide-react';
import IconButton from '@/components/IconButton';
import Generator from 'generate-password-ts';
import useCopyClipboard from 'react-use-clipboard';
import { useEffect } from 'react';
import { showToast } from '@/components/styledToasterContainer';
import { useMutationCreateDBUser, useMutationUpdateDBUser } from '@/pages/admin/user/hook';
import { WorkspaceT } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';

export default function UserForm({
  dbUser,
  onCancel,
  workspace,
}: {
  dbUser?: DBUser;
  onCancel: () => void;
  workspace: WorkspaceT;
}) {
  const [css] = useStyletron();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      username: dbUser?.username || '',
      password: dbUser?.password || '',
    },
  });
  const isUpdate = Boolean(dbUser);
  const password = watch('password');

  const [passwordCopied, setPasswordCopied] = useCopyClipboard(password, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (passwordCopied) {
      showToast({
        kind: 'positive',
        message: 'password copied successfully.',
      });
    }
  }, [passwordCopied]);

  const formControlOverrides: FormControlOverrides = {
    Label: {
      style: () => {
        return {
          fontSize: '12px',
          lineHeight: '16px',
          marginBottom: '-4px',
        };
      },
    },
  };

  const createMutation = useMutationCreateDBUser();
  const updateMutation = useMutationUpdateDBUser();

  const onSubmit = async (formData: { username: string; password: string }) => {
    if (isUpdate) {
      updateMutation.mutate(
        {
          ...formData,
          groupID: workspace.workgroup_id,
          spaceID: workspace.workspace_id,
        },
        {
          onSuccess: () => {
            showToast({
              kind: 'positive',
              message: 'The user has been successfully updated.',
            });
            onCancel();
          },
          onError(error) {
            showToast({
              kind: 'negative',
              message: getErrorMessage(error),
            });
          },
        }
      );
    } else {
      createMutation.mutate(
        {
          ...formData,
          groupID: workspace.workgroup_id,
          spaceID: workspace.workspace_id,
        },
        {
          onSuccess: () => {
            showToast({
              kind: 'positive',
              message: 'The user has been successfully created.',
            });
            onCancel();
          },
          onError(error) {
            showToast({
              kind: 'negative',
              message: getErrorMessage(error),
            });
          },
        }
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={css({
        padding: '16px',
        background: '#F8F8F8',
        borderRight: '1px solid #D4DADF',
        borderBottom: isUpdate ? '1px solid #D4DADF' : 'none',
        borderLeft: '1px solid #D4DADF',
      })}
    >
      <FormControl label="Username" error={errors.username?.message} overrides={formControlOverrides}>
        <Controller
          name="username"
          rules={{ required: 'required' }}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              size="compact"
              placeholder="Please enter username"
              autoComplete="off"
              error={Boolean(errors.username)}
              disabled={isUpdate}
            />
          )}
        />
      </FormControl>
      <FormControl label="Password" error={errors.password?.message} overrides={formControlOverrides}>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          })}
        >
          <div
            className={css({
              flex: 1,
            })}
          >
            <Controller
              name="password"
              rules={{ required: 'required' }}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  size="compact"
                  placeholder="Please enter password"
                  type="password"
                  error={Boolean(errors.password)}
                  endEnhancer={
                    <IconButton $style={{ marginLeft: '8px' }} type="button" onClick={setPasswordCopied}>
                      <CopyIcon size={14} />
                    </IconButton>
                  }
                />
              )}
            />
          </div>
          <Button
            type="button"
            kind="tertiary"
            overrides={{
              Root: {
                style: {
                  flexShrink: 1,
                },
              },
              BaseButton: {
                style: {
                  height: '32px',
                },
              },
            }}
            onClick={() => setValue('password', Generator.generate({ length: 16, numbers: true }))}
          >
            Auto Generate
          </Button>
        </div>
      </FormControl>
      <div className="space-x-2 text-right">
        <Button
          kind="tertiary"
          size="compact"
          onClick={() => onCancel?.()}
          overrides={{
            BaseButton: {
              style: {
                color: '#656565',
                border: '1px solid #AAB5BF !important',
              },
            },
          }}
        >
          Cancel
        </Button>
        <Button
          size="compact"
          type="submit"
          kind="tertiary"
          overrides={{
            BaseButton: {
              style: {
                color: '#1976D2',
                border: '1px solid #1976D2 !important',
              },
            },
            LoadingSpinnerContainer: {
              style: {
                margin: '2px !important',
              },
            },
          }}
          // isLoading={isLoading}
        >
          {isUpdate ? 'Update' : 'Create'}
        </Button>
      </div>
    </form>
  );
}
