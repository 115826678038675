import OrgIcon from '@/assets/org.svg?react';
import { Avatar, formatName } from '@/components/Avatar';
import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { showToast } from '@/components/styledToasterContainer';
import { useOrgContext } from '@/contexts/orgContext';
import { MyOrg, RoleType, UserRole } from '@/lib/models';
import { GroupDiff, TreeDiff, useMutationAssignUserRole } from '@/pages/admin/user/hook';
import {
  User,
  calculateRoleForBilling,
  calculateRoleForGroup,
  calculateRoleForSpace,
  isBillingAdmin,
  isBillingViewer,
  isGroupAdmin,
  isOrgAdmin,
  isSpaceAdmin,
  isSpaceUser,
} from '@/pages/admin/user/type';
import { useQueryGetGroups } from '@/pages/workgroup/hook';
import { WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Select } from '@tigergraph/app-ui-lib/select';
import { Body2, HeadingMenu, Label } from '@tigergraph/app-ui-lib/typography';
import { KIND } from 'baseui/button';
import { SelectOverrides } from 'baseui/select';
import { TreeLabelInteractable, TreeNode, TreeView, toggleIsExpanded } from 'baseui/tree-view';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { BillIcon, GroupIcon, SpaceIcon } from '../../home/icons';
import { UserXL } from '../../home/icons';
import { DrawerHeadContainer } from '@/lib/styled.tsx';
import { parseDate } from '@/lib/date';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  user: User;
};

function calculateGroupRoles(groups: WorkGroupT[] | undefined, user: User) {
  const roles = user.userInfo.roles;
  let ret: WorkGroupT[] = [];
  if (!groups) {
    return ret;
  }
  for (let group of groups) {
    const role = calculateRoleForGroup(roles, group.workgroup_id);
    const g = {
      ...group,
      role,
      workspaces: [] as WorkspaceT[],
    };
    for (let space of group.workspaces) {
      const role = calculateRoleForSpace(roles, group.workgroup_id, space.workspace_id);
      g.workspaces.push({
        ...space,
        role,
      });
    }
    ret.push(g);
  }
  return ret;
}

function changeOrgRole(
  groups: WorkGroupT[] | undefined,
  user: User,
  userBefore: User,
  groupsWithRolesBefore: WorkGroupT[]
) {
  const roles = user.userInfo.roles;
  const isAdminBefore = isOrgAdmin(userBefore.userInfo.roles);

  // swith to org admin
  if (isOrgAdmin(roles)) {
    const groupWithRoles = calculateGroupRoles(groups, user);
    return {
      groupWithRoles,
      billingRole: 'billing-admins' as RoleType,
    };
  }

  const billingRole: RoleType = isBillingAdmin(userBefore.roles)
    ? 'billing-admins'
    : isBillingViewer(userBefore.roles)
    ? 'billing-viewers'
    : 'users';

  let ret: WorkGroupT[] = [];
  if (!groups) {
    return {
      groupWithRoles: ret,
      billingRole,
    };
  }
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    const role: RoleType = isAdminBefore ? 'users' : groupsWithRolesBefore[i].role;
    const g = {
      ...group,
      role,
      workspaces: [] as WorkspaceT[],
    };
    for (let j = 0; j < group.workspaces.length; j++) {
      const space = group.workspaces[j];
      const role: RoleType = isAdminBefore ? 'users' : groupsWithRolesBefore[i].workspaces[j].role;
      g.workspaces.push({
        ...space,
        role,
      });
    }
    ret.push(g);
  }
  return {
    groupWithRoles: ret,
    billingRole,
  };
}

function changeGroupRole(group: WorkGroupT, index: number, groupsWithRolesBefore: WorkGroupT[]): WorkGroupT {
  if (group.role === 'workgroup-admins') {
    return {
      ...group,
      workspaces: group.workspaces.map((w) => ({
        ...w,
        role: 'workspace-admins',
      })),
    };
  }

  const isAdminBefore = groupsWithRolesBefore[index].role === 'workgroup-admins';
  const spaces: WorkspaceT[] = [];
  for (let j = 0; j < group.workspaces.length; j++) {
    const space = group.workspaces[j];
    const role: RoleType = isAdminBefore ? 'users' : groupsWithRolesBefore[index].workspaces[j].role;
    spaces.push({
      ...space,
      role,
    });
  }

  return {
    ...group,
    workspaces: spaces,
  };
}

function diffTree(
  groups: WorkGroupT[],
  groupsWithRolesBefore: WorkGroupT[],
  billingRole: RoleType,
  billingRoleBefore: RoleType,
  user: User,
  userBefore: User
) {
  const role: RoleType = isOrgAdmin(user.userInfo.roles) ? 'super-admins' : 'users';
  const roleBefore: RoleType = isOrgAdmin(userBefore.userInfo.roles) ? 'super-admins' : 'users';

  const ret: TreeDiff = {
    orgRole: undefined,
    revokeBillingRole: false,
    assignBillingRole: undefined,
    groups: new Map<string, GroupDiff>(),
  };

  if (role === 'super-admins') {
    if (roleBefore === 'super-admins') {
      // super admin is special, just return
      return ret;
    }

    ret.orgRole = role;
    return ret;
  }

  if (role === 'users') {
    if (roleBefore === 'super-admins') {
      ret.orgRole = role;
    }
  }

  const isBillingAdminRole = billingRole === 'billing-admins';
  const isBillingViewerRole = billingRole === 'billing-viewers';
  const isBillingAdminRoleBefore = isBillingAdmin(userBefore.roles);
  const isBillingViewerRoleBefore = isBillingViewer(userBefore.roles);
  if (billingRole === 'users' && (isBillingAdminRoleBefore || isBillingViewerRoleBefore)) {
    ret.revokeBillingRole = true;
  } else if (isBillingAdminRole && !isBillingAdminRoleBefore) {
    ret.assignBillingRole = 'billing-admins';
  } else if (isBillingViewerRole && !isBillingViewerRoleBefore) {
    ret.assignBillingRole = 'billing-viewers';
  }

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    const groupBefore = groupsWithRolesBefore[i];
    if (!groupBefore) {
      continue;
    }

    // 1. for display role, use role in groups
    // 2. when diff role for api, calculate role
    const isGroupAdminBefore = isGroupAdmin(user.userInfo.roles, groupBefore.workgroup_id);

    // is is workgroup admin
    if (group.role === 'workgroup-admins') {
      if (isGroupAdminBefore) {
        continue;
      }

      ret.groups.set(group.workgroup_id, {
        groupRole: group.role,
        adminSpaces: [],
        userSpaces: [],
        revokedSpaces: [],
      });
      continue;
    }

    if (group.role === 'users') {
      if (isGroupAdminBefore) {
        ret.groups.set(group.workgroup_id, {
          groupRole: group.role,
          adminSpaces: [],
          userSpaces: [],
          revokedSpaces: [],
        });
      }
    }

    for (let j = 0; j < group.workspaces.length; j++) {
      const space = group.workspaces[j];
      const spaceBefore = groupBefore.workspaces[j];
      if (!spaceBefore) {
        continue;
      }

      const isSpaceAdminBefore = isSpaceAdmin(user.userInfo.roles, spaceBefore.workgroup_id, spaceBefore.workspace_id);
      const isSpaceUserBefore = isSpaceUser(user.userInfo.roles, spaceBefore.workgroup_id, spaceBefore.workspace_id);

      const diff = ret.groups.get(group.workgroup_id) || {
        groupRole: undefined,
        adminSpaces: [],
        userSpaces: [],
        revokedSpaces: [],
      };

      if (space.role === 'workspace-admins') {
        if (isSpaceAdminBefore) {
          continue;
        }
        diff.adminSpaces.push(space.workspace_id);
      } else if (space.role === 'workspace-users') {
        if (isSpaceUserBefore) {
          continue;
        }
        diff.userSpaces.push(space.workspace_id);
      } else if (space.role === 'users') {
        if (!isSpaceAdminBefore && !isSpaceUserBefore) {
          continue;
        }
        diff.revokedSpaces.push(space.workspace_id);
      }

      ret.groups.set(group.workgroup_id, diff);
    }
  }

  return ret;
}

export default function UserDetail({ isOpen, onClose, user }: Props) {
  const [css] = useStyletron();

  const { currentOrg } = useOrgContext();
  const { data } = useQueryGetGroups();
  const groups = data?.Result;
  const groupsWithRolesBefore = useMemo(() => {
    return calculateGroupRoles(groups, user);
  }, [groups, user]);
  const [groupWithRoles, setGroupWithRoles] = useState<WorkGroupT[]>([]);

  const billingRoleBefore = calculateRoleForBilling(user.roles);
  const [billingRole, setBillingRole] = useState<RoleType>(billingRoleBefore);
  const [editUser, setEditUser] = useState(user);
  const role: RoleType = isOrgAdmin(editUser.userInfo.roles) ? 'super-admins' : 'users';

  const modifyUserRoleMutation = useMutationAssignUserRole();

  // this tree data only use to track expand status
  const [toggledTreeData, setToggledTreeData] = useState<TreeNode[]>([]);
  const diff = diffTree(groupWithRoles, groupsWithRolesBefore, billingRole, billingRoleBefore, editUser, user);

  useEffect(() => {
    if (groupsWithRolesBefore) {
      setGroupWithRoles(groupsWithRolesBefore);

      const initialData: TreeNode[] = [
        {
          label: '',
          isExpanded: true,
          id: user.userId,
          children: groupsWithRolesBefore.map((group) => ({
            id: group.workgroup_id,
            label: '',
            isExpanded: true,
            children: group.workspaces.map((space) => ({
              id: space.workspace_id,
              label: '',
            })),
          })),
        },
      ];

      setToggledTreeData(initialData);
    }
  }, [groupsWithRolesBefore, user.userId]);

  // generate real tree data based on
  // 1. toggledTreeData
  // 2. user & role
  const treeData = useMemo(() => {
    const findNode = (node: TreeNode, id: string): TreeNode | undefined => {
      if (node.id === id) {
        return node;
      }
      if (node.children) {
        for (let child of node.children) {
          const ret = findNode(child, id);
          if (ret) {
            return ret;
          }
        }
      }
      return undefined;
    };

    const getExpand = (id: string) => {
      for (let node of toggledTreeData) {
        const ret = findNode(node, id);
        if (ret) {
          return ret.isExpanded;
        }
      }
      return false;
    };

    const billingChildren = {
      id: 'billing',
      isExpanded: false,
      info: {
        billingRole,
      },
      label: (node: TreeNode<any>) => (
        <BillingTreeLabel
          node={node}
          disabled={isOrgAdmin(editUser.userInfo.roles)}
          onChangeRoleType={(newRole) => {
            setBillingRole(newRole);
          }}
        />
      ),
    };

    const workgroupChildren = groupWithRoles.map((group, i) => ({
      id: group.workgroup_id,
      isExpanded: getExpand(group.workgroup_id),
      info: {
        group,
      },
      label: (node: TreeNode<any>) => (
        <WorkgroupTreeLabel
          node={node}
          disabled={isOrgAdmin(editUser.userInfo.roles)}
          onChangeRoleType={(newRole) => {
            let newGroup = {
              ...group,
              role: newRole,
            };

            newGroup = changeGroupRole(newGroup, i, groupsWithRolesBefore);

            setGroupWithRoles([...groupWithRoles.slice(0, i), newGroup, ...groupWithRoles.slice(i + 1)]);
          }}
        />
      ),
      children: group.workspaces.map((space, j) => ({
        id: space.workspace_id,
        isExpanded: getExpand(space.workspace_id),
        info: {
          group,
          space,
        },
        label: (node: TreeNode<any>) => (
          <WorkspaceLabel
            node={node}
            disabled={group.role === 'workgroup-admins'}
            onChangeRoleType={(newRole) => {
              const newGroup: WorkGroupT = {
                ...group,
                workspaces: [
                  ...group.workspaces.slice(0, j),
                  {
                    ...space,
                    role: newRole,
                  },
                  ...group.workspaces.slice(j + 1),
                ],
              };
              setGroupWithRoles([...groupWithRoles.slice(0, i), newGroup, ...groupWithRoles.slice(i + 1)]);
            }}
          />
        ),
      })),
    }));

    const initialData: TreeNode[] = [
      {
        label: (node) => (
          <OrgTreeLabel
            node={node}
            onChangeRoleType={(role) => {
              let newUser = editUser;
              if (role === 'users') {
                newUser = {
                  ...editUser,
                  userInfo: {
                    ...editUser.userInfo,
                    roles: editUser.userInfo.roles.filter((r) => r.name !== 'super-admins'),
                  },
                };
              } else {
                const superUser = {
                  name: 'super-admins',
                } as UserRole;
                newUser = {
                  ...editUser,
                  userInfo: {
                    ...editUser.userInfo,
                    roles: editUser.userInfo.roles.concat(superUser),
                  },
                };
              }
              setEditUser(newUser);

              const { groupWithRoles, billingRole } = changeOrgRole(groups, newUser, user, groupsWithRolesBefore);
              setGroupWithRoles(groupWithRoles);
              setBillingRole(billingRole);
            }}
          />
        ),
        isExpanded: getExpand(user.userId),
        info: {
          role,
          currentOrg,
        },
        id: user.userId,
        children: [billingChildren, ...workgroupChildren],
      },
    ];
    return initialData;
  }, [toggledTreeData, user, editUser, groupWithRoles, role, currentOrg, groups, groupsWithRolesBefore, billingRole]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <DrawerHeadContainer>
          <UserXL />
          User Detail
        </DrawerHeadContainer>
      </DrawerHeader>
      <DrawerBody
        $style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className={css({
            display: 'flex',
            gap: '8px',
          })}
        >
          <Avatar size="large">{formatName(user.userInfo)}</Avatar>
          <div>
            <div>
              <HeadingMenu $style={{ fontWeight: '600' }}>{user.name}</HeadingMenu>
            </div>
            <Label $style={{ color: '#656565', marginTop: '8px', marginBottom: '4px' }}>
              Email: <StyledLabel>{user.email}</StyledLabel>
            </Label>
            <Label $style={{ color: '#656565' }}>
              Last login:{' '}
              <StyledLabel>
                {user.lastActive ? format(parseDate(user.lastActive), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}
              </StyledLabel>
            </Label>
          </div>
        </div>
        <StyledTitle>Permissions</StyledTitle>
        <div
          className={css({
            overflowY: 'auto',
            flexBasis: 0,
            flexGrow: 1,
            minHeight: 0,
          })}
        >
          <TreeView
            data={treeData}
            onToggle={(node) => setToggledTreeData((prevData) => toggleIsExpanded(prevData, node))}
            overrides={{
              IconContainer: {
                style: {
                  color: '#3F5870',
                },
              },
            }}
          />
        </div>
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind={KIND.tertiary}>
          Cancel
        </Button>
        <Button
          disabled={
            diff.orgRole === undefined && diff.groups.size === 0 && !diff.revokeBillingRole && !diff.assignBillingRole
          }
          type="button"
          onClick={async () => {
            modifyUserRoleMutation.mutate(
              { diff, userId: user.userId, userEmail: user.email },
              {
                onSuccess: async () => {
                  showToast({
                    kind: 'positive',
                    message: 'User updated successfully.',
                  });

                  onClose();
                },
                onError: (error) => {
                  showToast({
                    kind: 'negative',
                    message: getErrorMessage(error),
                  });
                },
              }
            );
          }}
          isLoading={modifyUserRoleMutation.isLoading}
        >
          Save
        </Button>
      </DrawerAction>
    </Drawer>
  );
}

export const StyledLabel = styled('span', {
  color: '#222',
});

export const StyledTitle = styled('div', {
  color: '#294560',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  marginTop: '16px',
});

type LabelProps = {
  node: TreeNode;
  onChangeRoleType: (role: RoleType) => void;
  disabled?: boolean;
};

const OrgTreeLabel = ({ node, onChangeRoleType }: LabelProps) => {
  const [css] = useStyletron();
  const { currentOrg, role } = node.info as { user: User; currentOrg: MyOrg; role: RoleType };
  return (
    <TreeLabelInteractable>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        })}
      >
        <Body2 $style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <OrgIcon />
          {currentOrg.org_name}
        </Body2>
        <Select
          size="mini"
          clearable={false}
          searchable={false}
          value={[{ id: role }]}
          options={[
            {
              id: 'super-admins',
              label: 'Org Admin',
            },
            {
              id: 'users',
              label: 'Not Specified',
            },
          ]}
          onChange={(params) => onChangeRoleType(params.value[0].id as RoleType)}
          overrides={selectOverrides}
        />
      </div>
    </TreeLabelInteractable>
  );
};

const BillingTreeLabel = ({ node, onChangeRoleType, disabled }: LabelProps) => {
  const [css] = useStyletron();
  const { billingRole } = node.info as { billingRole: RoleType };
  return (
    <TreeLabelInteractable>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        })}
      >
        <Body2 $style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <BillIcon />
          Billing
        </Body2>
        <Select
          disabled={disabled}
          size="mini"
          clearable={false}
          searchable={false}
          value={[{ id: billingRole }]}
          options={[
            {
              id: 'billing-admins',
              label: 'Billing Admin',
            },
            {
              id: 'billing-viewers',
              label: 'Billing Viewer',
            },
            {
              id: 'users',
              label: 'Not Specified',
            },
          ]}
          onChange={(params) => onChangeRoleType(params.value[0].id as RoleType)}
          overrides={selectOverrides}
        />
      </div>
    </TreeLabelInteractable>
  );
};

const WorkgroupTreeLabel = ({ node, onChangeRoleType, disabled }: LabelProps) => {
  const [css] = useStyletron();
  const { group } = node.info as { group: WorkGroupT };
  return (
    <TreeLabelInteractable>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        })}
      >
        <Body2 $style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <GroupIcon />
          {group.name}
        </Body2>
        <Select
          disabled={disabled}
          size="mini"
          clearable={false}
          searchable={false}
          value={[{ id: group.role }]}
          options={[
            {
              id: 'workgroup-admins',
              label: 'Workgroup Admin',
            },
            {
              id: 'users',
              label: 'Not Specified',
            },
          ]}
          onChange={(params) => onChangeRoleType(params.value[0].id as RoleType)}
          overrides={selectOverrides}
        />
      </div>
    </TreeLabelInteractable>
  );
};

const WorkspaceLabel = ({ node, onChangeRoleType, disabled }: LabelProps) => {
  const [css] = useStyletron();
  const { space } = node.info as { group: WorkGroupT; space: WorkspaceT };
  return (
    <TreeLabelInteractable>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        })}
      >
        <Body2 $style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <SpaceIcon />
          {space.name}
        </Body2>
        <Select
          disabled={disabled}
          size="mini"
          clearable={false}
          searchable={false}
          value={[{ id: space.role }]}
          options={[
            {
              id: 'workspace-admins',
              label: 'Workspace Admin',
            },
            {
              id: 'workspace-users',
              label: 'Workspace User',
            },
            {
              id: 'users',
              label: 'Not Specified',
            },
          ]}
          onChange={(params) => onChangeRoleType(params.value[0].id as RoleType)}
          overrides={selectOverrides}
        />
      </div>
    </TreeLabelInteractable>
  );
};

const selectOverrides: SelectOverrides = {
  Root: {
    style: {
      width: '164px',
    },
  },
  ControlContainer: {
    style: {
      width: '164px',
    },
  },
  DropdownListItem: {
    style: {
      fontSize: '12px',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
};
