import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Block } from 'baseui/block';
import { Caution, DatabaseIcon, SpaceIcon } from '@/pages/home/icons';
import { Button } from '@tigergraph/app-ui-lib/button';
import { KIND } from 'baseui/button';
import { useMutation, useQuery } from 'react-query';
import { axiosController } from '@/lib/network';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { ProgressBar } from 'baseui/progress-bar';
import { useEffect, useState } from 'react';
import { BackupStatus } from '@/pages/workgroup/tab/backupRestore/BackupDrawer';
import { BackupT } from '@/pages/workgroup/tab/backupRestore/workspaceBackup';
import { format } from 'date-fns';
import { bytesToSize } from '@/utils/utils';
import { WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { RestoreConfirmModal } from '@/pages/workgroup/tab/backupRestore/RestoreConfirmModal';
import { parseDate } from '@/lib/date';

export interface BackupRestoreProps {
  isOpen: boolean;
  onClose: () => void;
  workgroup: WorkGroupT;
  workspace: WorkspaceT;
  backup: BackupT | null;
  onRestoreSuccess?: () => void;
}

export function RestoreDrawer({ isOpen, onClose, workspace, workgroup, backup, onRestoreSuccess }: BackupRestoreProps) {
  const [css, theme] = useStyletron();
  const [restoreId, setRestoreId] = useState('');
  const [restoreLogs, setRestoreLogs] = useState('');
  const [progress, setProgress] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const workspaceId = workspace.workspace_id;
  const workgroupId = workgroup.workgroup_id;

  // estimate restore time is 180 seconds
  const maxEstimateProgress = 180;

  const { mutate, isLoading } = useMutation(
    [],
    async (backupId: string) => {
      const response = await axiosController.post(
        `/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups/restore`,
        { backup_id: backupId }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setRestoreId(data.Result);
      },
    }
  );

  const handleClose = () => {
    onClose && onClose();
    setRestoreId('');
  };

  useEffect(() => {
    if (restoreId) {
      // fake progress
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < maxEstimateProgress - 1) {
            return prev + 1;
          } else {
            return prev;
          }
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [restoreId]);

  useQuery(
    'queryRestoreStatus',
    async () => {
      const response = await axiosController.get(
        `/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups/${restoreId}/status`
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data.Result.status === BackupStatus.Succeed) {
          setProgress(0);
          setRestoreId('');
          onRestoreSuccess && onRestoreSuccess();
        }
        setRestoreLogs(data.Result.output);
      },
      refetchInterval: 5000,
      enabled: progress !== maxEstimateProgress && !!restoreId,
    }
  );

  return (
    <>
      <Drawer isOpen={isOpen} onClose={handleClose}>
        <DrawerHeader>Restore</DrawerHeader>
        {backup && (
          <DrawerBody>
            <div>
              TigerGraph Cloud allows you to easily recover your data from a backup in case of accidental data loss or
              system failures. With this, you can restore your database to a previous state, effectively rolling back
              any unwanted changes or recovering from unforeseen issues. Once a restore is initiated, it cannot be
              canceled, the process will recreate the database using the selected backup, ensuring that your data is
              restored to its original state. You can monitor the progress of the restore operation and review the
              status and details of completed restores.
            </div>
            <div
              className={css({
                height: '1px',
                backgroundColor: '#E8E8E8',
                margin: '16px -10px',
                width: 'calc(100% + 20px)',
              })}
            ></div>
            <Block>Backup File</Block>
            <Block>{backup?.tag}</Block>

            <Block
              display={'flex'}
              flexWrap={true}
              justifyContent={'space-between'}
              marginTop={'16px'}
              gridRowGap={'16px'}
            >
              <Block width={'300px'}>
                <Block flex={1}>Backup Time</Block>
                <Block>{format(parseDate(backup.time), 'yyyy-MM-dd HH:mm:ss')}</Block>
              </Block>

              <Block width={'300px'}>
                <Block flex={1}>Backup Size</Block>
                <Block>{bytesToSize(+backup.size_bytes)}</Block>
              </Block>

              <Block width={'300px'}>
                <Block flex={1}>Restore Using Workspace</Block>
                <Block display={'flex'} alignItems={'center'}>
                  <SpaceIcon />
                  {workspace.name}
                </Block>
              </Block>

              <Block width={'300px'}>
                <Block flex={1}>Database</Block>
                <Block display={'flex'} alignItems={'center'}>
                  <DatabaseIcon />{' '}
                  {workgroup.tg_databases.find((db) => db.database_id === workspace?.database_id)?.name}
                </Block>
              </Block>
            </Block>

            {restoreId && (
              <>
                <Block display={'flex'} marginTop={'16px'} flexDirection={'column'}>
                  <Body2>Restore Progress</Body2>
                  <Block display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <ProgressBar
                      overrides={{
                        Root: {
                          style: {
                            width: '60%',
                            flexGrow: 1,
                          },
                        },
                        BarContainer: {
                          style: {
                            marginLeft: '0',
                            marginRight: '0',
                          },
                        },
                      }}
                      value={progress}
                      maxValue={maxEstimateProgress}
                    />
                    <span
                      className={css({
                        marginLeft: '16px',
                      })}
                    >
                      Estimate: 2 mins
                    </span>
                  </Block>
                </Block>
                <Block marginTop={'18px'}>
                  <Body2 marginBottom={'8px'}>Restore Logs</Body2>
                  {restoreLogs && (
                    <div
                      className={css({
                        whiteSpace: 'pre-line',
                        fontSize: '12px',
                        fontFamily: 'Monaco',
                        color: `${theme.colors.black01}`,
                        padding: '10px',
                        backgroundColor: `${theme.colors.gray50}`,
                        borderRadius: '4px',
                        maxHeight: '380px',
                        overflowY: 'auto',
                      })}
                    >
                      {restoreLogs}
                    </div>
                  )}
                </Block>
              </>
            )}

            {!restoreId && (
              <Block
                $style={{
                  fontSize: '16px',
                }}
                backgroundColor={`${theme.colors.errorLight}`}
                padding={'8px'}
                marginLeft={'-16px'}
                display={'flex'}
                gridGap={'8px'}
                position={'absolute'}
                bottom={'100px'}
              >
                <Caution />
                <div>
                  <b>Caution</b>: Restoring will overwrite your previous data. Ensure you have a backup before
                  proceeding
                </div>
              </Block>
            )}
          </DrawerBody>
        )}
        <DrawerAction>
          <Button kind={KIND.tertiary} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!!restoreId}
            isLoading={isLoading}
            overrides={{
              BaseButton: {
                style: {
                  color: `${theme.colors.error}`,
                  backgroundColor: `${theme.colors.white}`,
                  border: `1px solid ${theme.colors.error}`,
                  ':hover': {
                    backgroundColor: ``,
                  },
                  ':active': {
                    backgroundColor: ``,
                    border: `1px solid ${theme.colors.error}`,
                  },
                },
              },
              LoadingSpinner: {
                style: {
                  borderLeftColor: `${theme.colors.error}`,
                  borderTopColor: `${theme.colors.error}`,
                },
              },
            }}
            onClick={() => {
              setShowModal(true);
            }}
          >
            Restore
          </Button>
        </DrawerAction>
      </Drawer>
      <RestoreConfirmModal
        backupTag={backup?.tag}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={() => {
          mutate(backup!.id);
          setShowModal(false);
        }}
      />
    </>
  );
}
