import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import {
  Access,
  AddUpdateShare,
  ChartData,
  DeleteShare,
  FileChangeType,
  FilePermission,
  FilePermissionForm,
  FileRecordT,
  FileShare,
  FileShareType,
  FileSortType,
  FileStore,
  UpdateFileShareRequest,
} from '@/utils/graphEditor/data';
import { parseGraph } from '@tigergraph/tools-models';
import { TabOverrides } from 'baseui/tabs-motion';
import { Result } from '@/lib/type';
import { parseDate } from '@/lib/date';

export enum GSQL_COMMAND {
  COOKIE = '__GSQL__COOKIES__',
  RETURN_CODE = '__GSQL__RETURN__CODE__',
  MOVE_CURSOR_UP = '__GSQL__MOVE__CURSOR___UP__',
  CLEAN_LINE = '__GSQL__CLEAN__LINE__',
}

interface ParsedResult {
  ret: string;
  cookie: { [key: string]: any } | null;
  retCode: number;
}

export function parseRes(ret: string): ParsedResult {
  let cookie = null;
  let retCode = -1;

  // handle cookie firstly, the script may contain multiple commands and
  // each command result will be followed by a cookie line
  let cmdStartIdx: number;
  while ((cmdStartIdx = ret.indexOf(GSQL_COMMAND.COOKIE)) > -1) {
    const cmdNewline = ret.indexOf('\n', cmdStartIdx);
    const cookieLine = ret.slice(cmdStartIdx, cmdNewline + 1);
    const cookieText = cookieLine.slice(GSQL_COMMAND.COOKIE.length + 1);
    try {
      cookie = JSON.parse(cookieText);
    } catch (e) {
      console.log('parse cookie error', e);
    }
    ret = ret.replace(cookieLine, '');
  }

  // after remove all cookie lines, the remain text will be result content and return code
  cmdStartIdx = ret.indexOf(GSQL_COMMAND.RETURN_CODE);
  if (cmdStartIdx > -1) {
    const cmdNewline = ret.indexOf('\n', cmdStartIdx);
    const returnCodeLine = ret.slice(cmdStartIdx, cmdNewline + 1);
    const returnCodeText = returnCodeLine.slice(GSQL_COMMAND.RETURN_CODE.length + 1);
    ret = ret.slice(0, cmdStartIdx);
    retCode = parseInt(returnCodeText);
  }

  return { ret, cookie, retCode };
}

export enum ColumnType {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
  ARRAY = 'array',
  OBJECT = 'object',
  NULL = 'null',
}

export const generateTabOverrides = (height: number, theme: CustomTheme): TabOverrides => {
  return {
    Tab: {
      style: () => ({
        height: '40px',
        width: '150px',
        justifyContent: 'left',
        paddingTop: '0px',
        paddingBottom: '0px',
        background: theme.colors.white,
        borderRightWidth: '1px',
        borderRightStyle: 'solid',
        borderImage: `linear-gradient(to bottom, #ffffff, #ffffff 20%, ${theme.colors.divider} 20%, ${theme.colors.divider} 80%, #ffffff 80%, #ffffff 90%, ${theme.colors.divider} 100%) 1`,
      }),
    },
    TabPanel: {
      style: () => ({
        maxHeight: `${height - 46}px`,
        height: '100%',
        overflow: 'auto',
        paddingRight: '0px',
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        background: theme.colors.white,
      }),
    },
  };
};

export function parseResults(results: any): ChartData {
  const graph = parseGraph(results);
  let chartData = {
    graph,
    results,
  };
  return chartData;
}

export const DefaultResultPanelHeight = 286;

export const DefaultFileListWidth = 220;

export function generateDefaultName(): string {
  // yyyy-mm-dd hh:mm am
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}${
    date.getHours() > 12 ? 'pm' : 'am'
  }`;
}

export function sortFile(fileList: FileStore[], type: FileSortType): FileStore[] {
  return fileList.sort((a, b) => {
    switch (type) {
      case FileSortType.DateCreated:
        return parseDate(b.created_at).getTime() - parseDate(a.created_at).getTime();
      case FileSortType.LastModified:
        return parseDate(b.updated_at).getTime() - parseDate(a.updated_at).getTime();
      case FileSortType.Name:
        return a.name.localeCompare(b.name);
    }
  });
}

export const generateFileShareRequest = (form: FilePermissionForm, fileShares: FileShare[]): UpdateFileShareRequest => {
  const userFileShares = form.userFileShares;
  let delete_shares: DeleteShare[] = [];
  let update_shares: AddUpdateShare[] = [];
  let add_shares: AddUpdateShare[] = [];

  update_shares = userFileShares
    .filter((user) => {
      const found = fileShares!.find((fileShare) => fileShare.target === user.target);
      return found && found.permission !== user.permission;
    })
    .map(
      (fileShare) =>
        ({ type: FileShareType.User, target: fileShare.target, permission: fileShare.permission } as AddUpdateShare)
    );

  delete_shares = fileShares!
    .filter((fileShare) => {
      if (fileShare.type !== FileShareType.User) {
        return false;
      }
      const found = userFileShares.find((user) => user.target === fileShare.target);
      return !found;
    })
    .map((fileShare) => ({ type: FileShareType.User, target: fileShare.target }));

  add_shares = userFileShares
    .filter((user) => {
      const found = fileShares!.find((fileShare) => fileShare.target === user.target);
      return !found;
    })
    .map((fileShare) => ({
      type: FileShareType.User,
      target: fileShare.target,
      permission: fileShare.permission,
    }));

  const orgShareInfo = fileShares!.find((share) => share.type === FileShareType.Org);

  switch (form.access) {
    case Access.OrgUsersEdit:
      if (orgShareInfo && orgShareInfo.permission === FilePermission.View) {
        update_shares.push({ type: FileShareType.Org, permission: FilePermission.Edit });
      } else if (!orgShareInfo) {
        add_shares.push({ type: FileShareType.Org, permission: FilePermission.Edit });
      }
      break;
    case Access.OrgUsersView:
      if (orgShareInfo && orgShareInfo.permission === FilePermission.Edit) {
        update_shares.push({ type: FileShareType.Org, permission: FilePermission.View });
      } else if (!orgShareInfo) {
        add_shares.push({ type: FileShareType.Org, permission: FilePermission.View });
      }
      break;
    case Access.Restricted:
      orgShareInfo && delete_shares.push({ type: FileShareType.Org });
  }

  return {
    delete_shares,
    update_shares,
    add_shares,
  };
};

export const fileStoreChange = (
  oldData: Result<FileRecordT> | undefined,
  type: FileChangeType,
  file: FileStore
): Result<FileRecordT> | undefined => {
  if (!oldData?.Result) {
    return oldData;
  }
  switch (type) {
    case FileChangeType.CREATE: {
      const fileList = oldData.Result.owned_files;
      const isFolder = file.is_folder;
      const parentId = file.parent_id;
      file.is_editing = true;
      if (!isFolder && parentId) {
        const parentIndex = fileList.findIndex((item) => item.id === parentId);
        if (parentIndex !== -1) {
          fileList[parentIndex].files.push(file);
        }
      } else {
        fileList.push(file);
      }
      return { ...oldData, Result: { ...oldData.Result, owned_files: fileList } };
    }
    case FileChangeType.DELETE: {
      const deletedId = file.id;
      const fileList = oldData.Result.owned_files;
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].id === deletedId) {
          fileList.splice(i, 1);
          break;
        }
        if (!fileList[i].is_folder) {
          continue;
        }
        const files = fileList[i].files;
        for (let j = 0; j < files.length; j++) {
          if (files[j].id === deletedId) {
            files.splice(j, 1);
            break;
          }
        }
      }
      return { ...oldData, Result: { ...oldData.Result, owned_files: fileList } };
    }
    case FileChangeType.RENAME: {
      const ownFileList = oldData.Result.owned_files;
      const sharedFileList = oldData.Result.shared_files;
      const renamedId = file.id;
      const handleRename = (fileList: FileStore[]) => {
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i].id === renamedId) {
            fileList[i].name = file.name;
            break;
          }
          if (!fileList[i].is_folder) {
            continue;
          }
          const files = fileList[i].files;
          for (let j = 0; j < files.length; j++) {
            if (files[j].id === renamedId) {
              files[j].name = file.name;
              break;
            }
          }
        }
      };
      handleRename(ownFileList);
      handleRename(sharedFileList);
      return {
        ...oldData,
        Result: { ...oldData.Result, owned_files: ownFileList, shared_files: sharedFileList },
      };
    }
    case FileChangeType.MOVE: {
      const ownFileList = oldData.Result.owned_files;
      const movedId = file.id;
      const newParenId = file.parent_id;
      const handleFileList = () => {
        for (let i = 0; i < ownFileList.length; i++) {
          if (ownFileList[i].id === movedId) {
            ownFileList.splice(i, 1);
            break;
          }
          if (!ownFileList[i].is_folder) {
            continue;
          }
          const files = ownFileList[i].files;
          for (let j = 0; j < files.length; j++) {
            if (files[j].id === movedId) {
              files.splice(j, 1);
              break;
            }
          }
        }
      };
      if (newParenId) {
        handleFileList();
        const parentIndex = ownFileList.findIndex((item) => item.id === newParenId);
        if (parentIndex !== -1) {
          ownFileList[parentIndex].files.push(file);
        }
      } else {
        handleFileList();
        ownFileList.push(file);
      }
      return {
        ...oldData,
        Result: { ...oldData.Result, owned_files: ownFileList },
      };
    }
  }
};
