import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ComponentPropsWithRef } from 'react';
import { FaCheck } from 'react-icons/fa';

type Props = ComponentPropsWithRef<'button'> & { selected: boolean };

const StyledCheckButton = styled<'button', Props>('button', ({ selected, disabled, $theme }) => {
  return {
    border: selected ? `2px solid ${$theme.colors.gray900}` : `1px solid ${$theme.colors.gray200}`,
    fontWeight: selected ? '600' : '400',
    borderRadius: '2px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled || !selected ? 0.5 : 1,
    position: 'relative',
  };
});

export default function CheckButton({ children, selected, ...props }: Props) {
  const [css, theme] = useStyletron();

  return (
    <StyledCheckButton {...props} selected={selected}>
      {selected && (
        <div
          className={css({
            position: 'absolute',
            right: '0',
            top: '0',
            borderTop: `20px solid ${theme.colors.gray900}`,
            borderLeft: `20px solid #fff`,
          })}
        >
          <FaCheck
            size={10}
            style={{
              color: '#fff',
              position: 'absolute',
              right: '1px',
              top: '-20px',
            }}
          />
        </div>
      )}
      {children}
    </StyledCheckButton>
  );
}
