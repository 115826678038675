import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Button } from '@tigergraph/app-ui-lib/button';
import { AddIconNoCircle } from '@/pages/home/icons';
import { Table } from 'baseui/table-semantic';
import IconButton from '@/components/IconButton';
import { FormEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Attribute, ValidateResult, Vertex } from '@tigergraph/tools-models';
import { randomString } from '@/utils/schemaDesigner';
import { EditableInput } from '@/components/editableInput';
import { AttributeTypeSelect } from '@/components/schemaDesigner/AttributeTypeSelect';
import { AttributeKey } from '@/components/schemaDesigner/AttributeKey';
import { AttributeValueColumn } from '@/components/schemaDesigner/AttributeValueColumn';
import { DefaultAttributeType } from '@/utils/schemaDesigner/data';
import { VertexStyle } from '@tigergraph/tools-models/topology';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { ChromePicker } from 'react-color';
import { IconSelector } from '@/components/schemaDesigner/IconSelector';
import ErrorMessage from '@/components/ErrorMessage';
import { MdDeleteOutline } from 'react-icons/md';

export interface VertexEditorProps {
  editorWrapperRef: MutableRefObject<HTMLElement | null>;
  onClose: () => void;
  onSave: (vertex: Vertex) => ValidateResult | undefined;
  isOpen: boolean;
  initColor?: string;
  initVertex?: Vertex;
  readOnly?: boolean;
}

export function VertexEditor({ editorWrapperRef, isOpen, onClose, onSave, initVertex, readOnly }: VertexEditorProps) {
  const [css, theme] = useStyletron();
  const [color, setColor] = useState(initVertex?.style?.fillColor || '#cccccc');
  const [primaryId, setPrimaryId] = useState<Attribute>(initVertex!.primaryId);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [vertexName, setVertexName] = useState('');
  const [validateResult, setValidateResult] = useState<ValidateResult | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setVertexName(initVertex?.name || '');
    const attrs = initVertex?.attributes || [];
    const primaryAttr = initVertex?.primaryId;
    if (primaryAttr) {
      setPrimaryId(primaryAttr);
      primaryAttr.isPrimaryKey = true;
    }
    if (initVertex && !initVertex?.style) {
      initVertex['style'] = new VertexStyle();
    }
    setAttributes(attrs);
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    setColor(initVertex?.style?.fillColor || randomColor);
  }, [initVertex]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  const handleSave = useCallback(
    (vertexName: string, attributes: Attribute[], color: string) => {
      initVertex!.name = vertexName;
      // remove the first attribute primary key
      initVertex!.primaryId = primaryId as Attribute;
      initVertex!.attributes = attributes;
      initVertex!.config = {
        ...initVertex!.config,
        PRIMARY_ID_AS_ATTRIBUTE: primaryId?.primaryIdAsAttribute,
      };
      initVertex!.style.fillColor = color;
      const res = onSave(initVertex!);
      setValidateResult(res);
    },
    [initVertex, onSave, primaryId]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.key === 'Enter' || event.key === 'Escape') && isOpen) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  const handleChangeInfoName = (e: FormEvent<HTMLInputElement>) => {
    setVertexName(e.currentTarget.value);
    handleSave(e.currentTarget.value, attributes, color);
  };

  const handleAddAttribute = () => {
    const attr = new Attribute();
    attr.name = `newAttr_${randomString(3)}`;
    attr.type.name = DefaultAttributeType;
    attr.defaultValue = 'default value';
    setAttributes([...attributes, attr]);
    handleSave(vertexName, [...attributes, attr], color);
  };

  const handleAttributeNameChange = (name: string, index: number) => {
    if (index === 0) {
      setPrimaryId((primaryId) => {
        primaryId.name = name;
        return primaryId;
      });
      handleSave(vertexName, attributes, color);
    } else {
      setAttributes((attributes) => {
        attributes[index - 1].name = name;
        return [...attributes];
      });
      handleSave(vertexName, attributes, color);
    }
  };

  const handleAttributeChange = (newAttribute: Attribute, index: number) => {
    if (index === 0) {
      setPrimaryId((primaryId) => {
        primaryId.type = newAttribute.type;
        primaryId.defaultValue = newAttribute.defaultValue;
        primaryId.isPrimaryKey = true;
        primaryId.primaryIdAsAttribute = newAttribute.primaryIdAsAttribute;
        return primaryId;
      });
      handleSave(vertexName, attributes, color);
    } else {
      setAttributes((attributes) => {
        attributes[index - 1] = newAttribute;
        return [...attributes];
      });
      handleSave(vertexName, attributes, color);
    }
  };

  const isInvalidVertexName = useMemo(() => {
    return validateResult && !validateResult.success && validateResult.message?.includes('vertex name');
  }, [validateResult]);

  const isInvalidAttributeName = useMemo(() => {
    return validateResult && !validateResult.success && validateResult.message?.includes('attribute name');
  }, [validateResult]);

  return (
    <div>
      <div
        className={css({
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: `${theme.colors.border}`,
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 600,
          color: theme.colors.gray1000,
          padding: '14px 0',
        })}
      >
        Edit Vertex
      </div>
      <div
        className={css({
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: `${theme.colors.border}`,
          paddingBottom: '8px',
        })}
      >
        <div
          className={css({
            paddingTop: '8px',
            paddingBottom: '4px',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
          })}
        >
          Vertex name
        </div>
        <div
          className={css({
            display: 'flex',
            height: '32px',
            gap: '4px',
          })}
        >
          <Input
            ref={inputRef}
            onChange={handleChangeInfoName}
            value={vertexName}
            disabled={readOnly}
            overrides={{
              Root: {
                style: {
                  width: '70%',
                  flexGrow: 1,
                },
              },
            }}
            placeholder={'name'}
            error={isInvalidVertexName}
          />
          <IconSelector
            mountNode={editorWrapperRef.current as HTMLElement}
            value={initVertex?.style?.icon || ''}
            onSelect={(iconUrl: string) => {
              if (initVertex?.style) {
                initVertex.style.icon = iconUrl;
              }
              handleSave(vertexName, attributes, color);
            }}
          />
          <StatefulPopover
            mountNode={editorWrapperRef.current as HTMLElement}
            overrides={{
              Body: {
                style: {
                  backgroundColor: 'white',
                  zIndex: 2,
                },
              },
              Inner: {
                style: {
                  backgroundColor: 'white',
                },
              },
            }}
            content={() => {
              return (
                <ChromePicker
                  disableAlpha
                  color={color}
                  className={css({
                    zIndex: 1,
                    boxShadow: 'none !important',
                  })}
                  onChangeComplete={(color) => {
                    handleSave(vertexName, attributes, color.hex);
                    setColor(color.hex);
                  }}
                />
              );
            }}
          >
            <button
              disabled={readOnly}
              className={css({
                width: '32px',
                backgroundColor: `${color}`,
                borderRadius: '4px',
              })}
            />
          </StatefulPopover>
        </div>
        {isInvalidVertexName && <ErrorMessage message={validateResult?.message ?? ''} />}
      </div>
      <div
        className={css({
          paddingTop: '8px',
          paddingBottom: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <ParagraphMedium>Attribute</ParagraphMedium>
        <Button
          onClick={handleAddAttribute}
          disabled={readOnly}
          overrides={{
            BaseButton: {
              style: {
                fontSize: '12px',
                height: '28px',
                lineHeight: '28px',
                backgroundColor: '#fff',
                borderLeftWidth: '1px',
                borderRightWidth: '1px',
                borderTopWidth: '1px',
                borderBottomWidth: '1px',
                borderLeftColor: `${theme.colors.secondary800}`,
                borderRightColor: `${theme.colors.secondary800}`,
                borderTopColor: `${theme.colors.secondary800}`,
                borderBottomColor: `${theme.colors.secondary800}`,
                color: `${theme.colors.secondary800}`,
                ':hover': {
                  backgroundColor: '#fff',
                  boxShadow: `${theme.colors.secondary400} 0px 4px 6px`,
                },
                ':active': {
                  borderLeftWidth: '1px',
                  borderRightWidth: '1px',
                  borderTopWidth: '1px',
                  borderBottomWidth: '1px',
                  borderLeftColor: `${theme.colors.secondary800}`,
                  borderRightColor: `${theme.colors.secondary800}`,
                  borderTopColor: `${theme.colors.secondary800}`,
                  borderBottomColor: `${theme.colors.secondary800}`,
                },
              },
            },
          }}
        >
          <AddIconNoCircle />
          Add Attribute
        </Button>
      </div>
      <div
        className={css({
          minHeight: '180px',
        })}
      >
        <Table
          divider={'horizontal'}
          overrides={{
            TableHeadCell: {
              style: {
                backgroundColor: `#F4F8FD`,
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '4px',
                paddingRight: '14px',
                minWidth: '70px',
                ':last-child': {
                  boxSizing: 'border-box',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  minWidth: '20px !important',
                },
              },
            },
            TableBodyCell: {
              style: {
                paddingTop: '2px',
                paddingBottom: '2px',
                paddingLeft: '4px',
                paddingRight: '4px',
                ':last-child': {
                  boxSizing: 'content-box',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                },
                ':last-child > :last-child': {
                  visibility: 'hidden',
                },
              },
            },
            TableBodyRow: {
              style: ({ $rowIndex }) => {
                const match = validateResult?.message?.match(/"([^"]*)"/);
                if (match && match[1] !== undefined) {
                  const quotedWord = match[1];
                  if ($rowIndex === attributes.findIndex((attribute) => attribute.name === quotedWord) + 1) {
                    return {
                      backgroundColor: '#F8EBEB',
                      border: `2px solid ${theme.colors.error}`,
                      ':hover': {
                        backgroundColor: '#F8EBEB',
                      },
                      ':hover > :last-child > :last-child': {
                        visibility: 'visible',
                      },
                    };
                  }
                }
                return {
                  ':hover > :last-child > :last-child': {
                    visibility: 'visible',
                  },
                };
              },
            },
          }}
          columns={['Attribute name', 'Type', 'Key', '', '']}
          data={[primaryId, ...attributes].map((attribute, index) => {
            return [
              <div
                key={`Attribute_name_${attribute.name}_${index}`}
                className={css({
                  minWidth: '128px',
                })}
              >
                <EditableInput
                  key={index}
                  value={attribute.name}
                  readOnly={readOnly}
                  onChange={(name) => handleAttributeNameChange(name, index)}
                />
              </div>,
              <div
                key={`Type_${attribute.name}_${index}`}
                className={css({
                  minWidth: '100px',
                })}
              >
                <AttributeTypeSelect
                  attribute={attribute}
                  readOnly={readOnly}
                  onChange={(attr) => handleAttributeChange(attr, index)}
                />
              </div>,
              <AttributeKey
                key={`Key_${attribute.name}_${index}`}
                attribute={attribute}
                readOnly={readOnly}
                onChangeKey={(attr) => handleAttributeChange(attr, index)}
              />,
              <div
                key={`Default_value_${attribute.name}_${index}`}
                className={css({
                  minWidth: '100px',
                })}
              >
                <AttributeValueColumn
                  attribute={attribute}
                  readOnly={readOnly}
                  onChange={(attr) => handleAttributeChange(attr, index)}
                />
              </div>,
              <div
                key={`Delete_${attribute.name}_${index}`}
                className={css({
                  maxWidth: '24px',
                  width: '24px',
                })}
              >
                {!attribute.isPrimaryKey && (
                  <IconButton
                    onClick={() => {
                      const newAttributes = [...attributes];
                      newAttributes.splice(index - 1, 1);
                      setAttributes(newAttributes);
                      handleSave(vertexName, newAttributes, color);
                    }}
                    $style={{
                      height: '32px',
                      lineHeight: '32px',
                      width: '20px',
                      display: readOnly ? 'none' : 'block',
                    }}
                  >
                    <MdDeleteOutline size={16} color={theme.colors.error} />
                  </IconButton>
                )}
              </div>,
            ];
          })}
        />
        {isInvalidAttributeName && <ErrorMessage message={validateResult?.message ?? ''} />}
        <ParagraphSmall
          className={css({
            textAlign: 'center',
            color: `${theme.colors.gray500}`,
            marginTop: '8px',
          })}
        >
          Click &quot;+ Add Attribute&quot; to add attributes.
        </ParagraphSmall>
      </div>
    </div>
  );
}
