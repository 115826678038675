// 2024-04-10 11:19:19.65042 +0000 UTC
export function parseDate(str: string) {
  if (!str) {
    return new Date(0);
  }
  const date = new Date(str);
  if (isFinite(date.getTime())) {
    return date;
  }
  // to support safari
  str = str.slice(0, str.indexOf('.'));

  const [s1, s2] = str.split(' ');
  const [year, month, day] = s1.split('-').map(Number);
  const [hour, minute, second] = s2.split(':').map(Number);

  return new Date(year, month, day, hour, minute, second);
}
