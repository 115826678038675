import { PhotoLibrary } from '@/pages/home/icons';
import IconButton from '@/components/IconButton';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { TRIGGER_TYPE } from 'baseui/popover';
import { expand } from 'inline-style-expand-shorthand';
import { SelectIconContainer } from '@tigergraph/tools-ui/insights/components/selectIcon';
import { useEffect, useState } from 'react';
import { getInstanceAxios } from '@tigergraph/tools-ui/insights/api/ajax';
import { ID_TOKEN_KEY } from '@/contexts/userContext';
import { setBaseURL } from '@tigergraph/tools-ui/insights/config/config-interface';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import axios from 'axios';
import { getUserUploadedIconPathPrefix, uploadedIconPath } from '@tigergraph/tools-ui/insights/utils/path';
import { iconMap } from '@tigergraph/tools-ui/graph/icons/iconMap';

export interface IconSelectorProps {
  onSelect: (iconName: string) => void;
  value?: string;
  mountNode?: HTMLElement;
}

export function IconSelector({ onSelect, value, mountNode }: IconSelectorProps) {
  const [css, theme] = useStyletron();
  const [iconUrl, setIconUrl] = useState<string>('');
  const { currentWorkspace } = useWorkspaceContext();

  useEffect(() => {
    const userUploadedIconPathPrefix = getUserUploadedIconPathPrefix(true);
    const imgUrl =
      value && iconMap[value]
        ? `${getInstanceAxios()?.defaults?.baseURL || ''}/studio/assets/gvis/icons/builtin/${iconMap[value]}`
        : userUploadedIconPathPrefix && value
        ? `${userUploadedIconPathPrefix}/${value}`
        : '';
    setIconUrl(imgUrl || '');
  }, [value]);

  useEffect(() => {
    if (currentWorkspace) {
      const workspaceURL = `https://${currentWorkspace?.nginx_host}`;
      if (getInstanceAxios()?.defaults?.baseURL !== workspaceURL) {
        let idToken = sessionStorage.getItem(ID_TOKEN_KEY)!;
        setBaseURL(axios.create({}), workspaceURL, {
          idToken,
        });
      }
    }
  }, [currentWorkspace]);

  return (
    <>
      <StatefulPopover
        showArrow={false}
        mountNode={mountNode}
        triggerType={TRIGGER_TYPE.click}
        overrides={{
          Body: {
            style: {
              padding: '8px',
              maxWidth: '370px',
              maxHeight: '250px',
              backgroundColor: theme.colors.white,
              zIndex: 2,
            },
          },
          Inner: {
            style: {
              ...expand({
                padding: '8px',
              }),
              backgroundColor: theme.colors.white,
            },
          },
        }}
        content={({ close }) => {
          return (
            <div>
              <SelectIconContainer
                iconURL={iconUrl}
                onIconSelected={(url, name) => {
                  console.log('icon selected', url, name);
                  setIconUrl(url);
                  onSelect(name!);
                  close();
                }}
                isCloud={true}
              />
            </div>
          );
        }}
      >
        <IconButton
          $style={{
            width: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${theme.colors.gray400}`,
          }}
        >
          {iconUrl ? (
            <img
              src={iconUrl.includes('https') ? iconUrl : uploadedIconPath(iconUrl, true)}
              style={{
                background: 'black',
              }}
            />
          ) : (
            <PhotoLibrary />
          )}
        </IconButton>
      </StatefulPopover>
    </>
  );
}
