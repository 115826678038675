import { parseDate } from '@/lib/date';
import { DatabaseT, WorkGroupT, WorkspaceT, isStatusActive, isStatusPending } from '@/pages/workgroup/type';
import { Edge, Node } from 'reactflow';

export type GroupedWorkspace = {
  database?: DatabaseT;
  workspaces: WorkspaceT[];
};

export function groupWorkSpacesByDatabase(group: WorkGroupT): GroupedWorkspace[] {
  let ret: GroupedWorkspace[] = [];

  for (let database of group.tg_databases) {
    let workspacesForDatabase: WorkspaceT[] = [];
    for (let workspace of group.workspaces) {
      if (workspace.database_id === database.database_id) {
        workspacesForDatabase.push(workspace);
      }
    }
    ret.push({
      database: database,
      workspaces: workspacesForDatabase,
    });
  }

  for (let workspace of group.workspaces) {
    if (!workspace.database_id) {
      ret.push({
        workspaces: [workspace],
      });
    }
  }

  for (let i = 0; i < ret.length; i++) {
    let workspaces = ret[i].workspaces;
    workspaces.sort((a, b) => {
      if (a.is_rw) {
        return -1;
      }
      if (b.is_rw) {
        return 1;
      }
      const d1 = parseDate(a.created_at);
      const d2 = parseDate(b.created_at);
      return d1 < d2 ? -1 : d1 > d2 ? 1 : 0;
    });
  }
  return ret;
}

export const NodeHeightNormal = 93;
export const NodeHeightInProgress = 115;

export const SpaceWidth = 316;
export const DatabaseWidth = 268;

const DatabaseGapY = 48;
const GapY = 48;

// Gap between database group
const GroupGapY = 16;

const Indent = 40;

function calculateWorkspaceHeight(workspace: WorkspaceT) {
  const gap = workspace.is_rw ? DatabaseGapY : GapY;
  return isStatusPending(workspace.status) ? NodeHeightInProgress + gap : NodeHeightNormal + gap;
}

function calculateWorkspacesHeight(workspaces: WorkspaceT[]) {
  if (workspaces.length === 0) {
    return 0;
  }
  let height = workspaces.map((w) => calculateWorkspaceHeight(w)).reduce((acc, h) => acc + h, 0);

  // special handling for single rw workspace
  if (workspaces.length === 1 && workspaces[0].is_rw) {
    height = height - DatabaseGapY + GapY;
  }

  // special handling for no rw workspace
  if (!workspaces[0].is_rw) {
    height += calculateWorkspaceHeight(workspaces[0]);
  }

  return height;
}

const readOnlyEdgeStyle: Partial<Edge> = {
  style: {
    strokeWidth: 2,
    stroke: '#B3B3B3',
    strokeDasharray: '8 4',
  },
  labelStyle: {
    fill: '#B3B3B3',
    fontWeight: '600',
    fontSize: '14px',
  },
  labelShowBg: true,
  labelBgPadding: [8, 6] as [number, number],
  labelBgStyle: {
    fill: '#f8f8f8',
  },
};

const edgeStyle: Partial<Edge> = {
  style: {
    strokeWidth: 2,
    stroke: '#B3B3B3',
  },
  labelStyle: {
    fill: '#B3B3B3',
    fontWeight: '600',
    fontSize: 14,
  },
  labelShowBg: true,
  labelBgPadding: [8, 6] as [number, number],
  labelBgStyle: {
    fill: '#f8f8f8',
  },
};

export function calculateNodesAndEdges(
  groupedWorkspaces: GroupedWorkspace[],
  width?: number
): {
  nodes: Node[];
  edges: Edge[];
  totalHeight: number;
} {
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  let y = 0;

  if (!width) {
    return {
      nodes,
      edges,
      totalHeight: y,
    };
  }

  for (let groupedWorkspace of groupedWorkspaces) {
    const { database, workspaces } = groupedWorkspace;
    let spaceY = y;

    if (database) {
      nodes.push({
        id: database.database_id,
        type: 'database',
        data: {
          database,
          disableDelete: workspaces.length > 0,
        },
        position: {
          x: 0,
          y: y + (isStatusPending(workspaces[0]?.status) ? (NodeHeightInProgress - NodeHeightNormal) / 2 : 0),
        },
      });

      if (workspaces.length > 0) {
        y += calculateWorkspacesHeight(workspaces);
      } else {
        y += NodeHeightNormal + GapY;
      }
    }

    y += GroupGapY;

    for (let i = 0; i < workspaces.length; i++) {
      const workspace = workspaces[i];

      // if no rw workspace in database, we need to consider the height it takes
      if (i === 0 && !workspace.is_rw) {
        spaceY += calculateWorkspaceHeight(workspace);
      }

      nodes.push({
        id: workspace.workspace_id,
        type: 'workspace',
        data: {
          workspace,
          database,
        },
        position: {
          x: DatabaseWidth + width,
          y: spaceY,
        },
      });

      if (database) {
        if (workspace.is_rw) {
          edges.push({
            label: 'R/W',
            source: database.database_id,
            target: workspace.workspace_id,
            id: `${database.database_id}-${workspace.workspace_id}`,
            ...edgeStyle,
            sourceHandle: 'right',
            targetHandle: 'left',
          });
        } else {
          // for readonly workspace
          // add a new database node for readonly workspace
          nodes.push({
            id: `${database.database_id}-${workspace.workspace_id}`,
            type: 'database',
            data: {
              database,
              disableDelete: workspaces.length > 0,
              is_ro: true,
              disableUpdate: !workspaces.some((w) => w.is_rw && isStatusActive(w.status)),
              workspace,
            },
            position: {
              x: DatabaseWidth / 2 + Indent,
              y: spaceY + (isStatusPending(workspace.status) ? (NodeHeightInProgress - NodeHeightNormal) / 2 : 0),
            },
          });

          edges.push({
            label: 'R/O',
            source: `${database.database_id}-${workspace.workspace_id}`,
            target: workspace.workspace_id,
            id: `${database.database_id}-${workspace.workspace_id}`,
            ...edgeStyle,
            sourceHandle: 'right',
            targetHandle: 'left',
          });

          edges.push({
            source: `${database.database_id}`,
            target: `${database.database_id}-${workspace.workspace_id}`,
            id: `${database.database_id}-${database.database_id}-${workspace.workspace_id}`,
            ...readOnlyEdgeStyle,
            // @ts-ignore
            hideLabel: true,
            sourceHandle: 'bottom',
            targetHandle: 'left',
            type: 'databaseEdge',
          });
        }
      }

      spaceY += calculateWorkspaceHeight(workspace);
    }
  }

  return {
    nodes,
    edges,
    totalHeight: y,
  };
}
