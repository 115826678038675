import IconButton from '@/components/IconButton';
import LinkButton from '@/components/LinkButton';
import { expand } from 'inline-style-expand-shorthand';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { actionColumnOverrides, columnOverrides } from '@/components/table';
import { useOrgContext } from '@/contexts/orgContext';
import { RoleType, UserRole } from '@/lib/models';
import { TableContainer } from '@/lib/styled';
import { useMutationDeleteUser, useMutationResendInvitation, useQueryListUsers } from '@/pages/admin/user/hook';
import InviteUser from '@/pages/admin/user/invite_user';
import { InlineBlock } from '@/pages/admin/user/styled';
import UserDetail from '@/pages/admin/user/user_detail';
import { getErrorMessage } from '@/utils/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Pagination } from '@tigergraph/app-ui-lib/pagination';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { Select } from '@tigergraph/app-ui-lib/select';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { Body1 } from '@tigergraph/app-ui-lib/typography';
import { Search } from 'baseui/icon';
import { Value } from 'baseui/select';
import { TableBuilderColumn } from 'baseui/table-semantic';
import clsx from 'clsx';
import { format } from 'date-fns';
import { MailIcon, Trash2Icon } from 'lucide-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { User, isOrgAdmin, isRoleType, labelForRole, roles } from './type';
import { MdOutlinePersonAdd } from 'react-icons/md';
import { parseDate } from '@/lib/date';

const numPerPage = 12;

export default function Users() {
  const [css, theme] = useStyletron();

  const { currentOrg, userInfo } = useOrgContext();
  const orgId = currentOrg?.org_id;
  const invitationDisabled = currentOrg?.org_settings?.sso_settings?.invitation === false;

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pattern, setPattern] = useState('');
  const [roleOption, setRoleOption] = useState<Value>([{ label: 'Any role', id: 'All' }]);

  useEffect(() => {
    setPageNumber(1);
  }, [roleOption, pattern]);

  const [showInvite, setShowInvite] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectUser, setSelectUser] = useState<User | undefined>(undefined);

  const { isLoading, isFetching, isError, error, data: allUser } = useQueryListUsers();

  const deleteUserMutation = useMutationDeleteUser();
  const resendInvitationMutation = useMutationResendInvitation();

  const onDeleteUser = (userId: string) => {
    const promise = deleteUserMutation.mutateAsync({
      userId,
    });
    toast.promise(
      promise,
      {
        loading: 'Deleting user',
        success: () => `User deleted successfully.`,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const onResendInvitation = (email: string) => {
    const promise = resendInvitationMutation.mutateAsync({
      orgId,
      email,
    });
    toast.promise(
      promise,
      {
        loading: `Resending invitation`,
        success: () => `Invitation resent successfully`,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <ErrorDisplay label="Server Error:" error={error} />;
  }

  if (!allUser) {
    return null;
  }

  const filterUsers = allUser
    .filter((user) => {
      return (
        user.name.toLowerCase().includes(pattern.toLowerCase()) ||
        user.email.toLowerCase().includes(pattern.toLowerCase())
      );
    })
    .filter((user) => {
      if (roleOption[0].id === 'All') {
        return true;
      }
      const roleType = roleOption[0].id as RoleType;

      for (let role of user.roles) {
        if (role.name === roleType) {
          return true;
        }
      }
    });

  const pagedUsers = filterUsers.slice((pageNumber - 1) * numPerPage, pageNumber * numPerPage);

  return (
    <div>
      <InlineBlock
        style={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        {isFetching ? <Spinner $size="16px" $borderWidth="2px" /> : null}
        <Input
          value={pattern}
          size="compact"
          onChange={(e) => setPattern(e.currentTarget.value)}
          placeholder="Search by username or email"
          startEnhancer={<Search size="18px" />}
          overrides={{
            Root: {
              style: {
                width: '240px',
                marginRight: '8px',
              },
            },
          }}
        />
        <Select
          size="compact"
          clearable={false}
          searchable={false}
          options={[{ label: 'Any role', id: 'All' }].concat(roles)}
          labelKey="label"
          valueKey="id"
          value={roleOption}
          onChange={(params) => setRoleOption(params.value)}
          overrides={{
            Root: {
              style: {
                width: '219px',
              },
            },
            ControlContainer: {
              style: {
                width: '219px',
              },
            },
          }}
        />
        {invitationDisabled ? (
          <StatefulPopover>
            <Button
              disabled={true}
              overrides={{
                BaseButton: {
                  style: {
                    width: '138px',
                    height: '32px',
                    ...expand({
                      padding: '4px 16px',
                      borderRadius: '2px',
                      borderWidth: '0',
                    }),
                    ':active': {
                      ...expand({
                        borderWidth: '0',
                      }),
                    },
                  },
                },
              }}
            >
              <MdOutlinePersonAdd size={20} />
              <span
                className={css({
                  paddingLeft: '8px',
                  fontSize: '14px',
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                })}
              >
                Invite Users
              </span>
            </Button>
          </StatefulPopover>
        ) : isOrgAdmin(userInfo.roles) ? (
          <Button
            onClick={() => setShowInvite(true)}
            overrides={{
              BaseButton: {
                style: {
                  width: '138px',
                  height: '32px',
                  ...expand({
                    padding: '4px 16px',
                    borderRadius: '2px',
                    borderWidth: '0',
                  }),
                  ':active': {
                    ...expand({
                      borderWidth: '0',
                    }),
                  },
                },
              },
            }}
          >
            <MdOutlinePersonAdd size={20} />
            <span
              className={css({
                paddingLeft: '8px',
                fontSize: '14px',
                fontWeight: 600,
                whiteSpace: 'nowrap',
              })}
            >
              Invite Users
            </span>
          </Button>
        ) : null}
      </InlineBlock>
      <TableContainer>
        <TableBuilder data={pagedUsers}>
          <TableBuilderColumn header="User Name" id="username">
            {(row: User) => (
              <LinkButton
                disabled={!row.lastActive}
                onClick={() => {
                  if (row.lastActive) {
                    setShowDetail(true);
                    setSelectUser(row);
                  }
                }}
              >
                {row.name}
              </LinkButton>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn header="Workspace" id="workspace">
            {(row: User) => row.email}
          </TableBuilderColumn>
          <TableBuilderColumn header="Role" id="role" overrides={columnOverrides}>
            {(row: User) => <RoleName roles={row.roles} />}
          </TableBuilderColumn>
          <TableBuilderColumn header="Last Login" id="lastLogin" overrides={columnOverrides}>
            {(row: User) =>
              row.lastActive ? (
                format(parseDate(row.lastActive), 'yyyy-MM-dd HH:mm:ss')
              ) : (
                <Tag closeable={false} kind="warning">
                  Invitation pending
                </Tag>
              )
            }
          </TableBuilderColumn>
          <TableBuilderColumn header="Created on" id="createTime">
            {(row: User) => {
              const updated_at = parseDate(row.created);
              return format(updated_at, 'yyyy-MM-dd HH:mm:ss');
            }}
          </TableBuilderColumn>
          <TableBuilderColumn overrides={actionColumnOverrides} header="">
            {(row: User) => (
              <div
                className={clsx(
                  css({
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    columnGap: '8px',
                  }),
                  'hoverToShow'
                )}
              >
                {row.lastActive ? null : (
                  <ConfirmStatefulPopover
                    confirmLabel="Want to resend invitation?"
                    onConfirm={() => onResendInvitation(row.email)}
                  >
                    <IconButton title="Resend invitation">
                      <MailIcon size={16} />
                    </IconButton>
                  </ConfirmStatefulPopover>
                )}
                <ConfirmStatefulPopover confirmLabel="Confirm to delete" onConfirm={() => onDeleteUser(row.userId)}>
                  <IconButton title="Delete User">
                    <Trash2Icon size={16} />
                  </IconButton>
                </ConfirmStatefulPopover>
              </div>
            )}
          </TableBuilderColumn>
        </TableBuilder>
        <InlineBlock
          className={css({
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '16px',
            marginTop: '24px',
          })}
        >
          <Body1 color={theme.colors.gray800}>
            {`${(pageNumber - 1) * numPerPage + 1} - ${Math.min(pageNumber * numPerPage, filterUsers.length)} of ${
              filterUsers.length
            } items`}
          </Body1>
          <Pagination
            totalPage={Math.ceil(filterUsers.length / numPerPage)}
            pageNumber={pageNumber}
            setPageNumber={(page) => setPageNumber(page)}
          />
        </InlineBlock>
      </TableContainer>
      {showInvite ? <InviteUser isOpen={showInvite} onClose={() => setShowInvite(false)} /> : null}
      {selectUser && showDetail ? (
        <UserDetail isOpen={showDetail} onClose={() => setShowDetail(false)} user={selectUser} />
      ) : null}
    </div>
  );
}

function RoleName({ roles }: { roles: UserRole[] }) {
  const filteredRoles = roles.filter((role) => isRoleType(role.name));
  return <RealRoleName roles={filteredRoles} />;
}

function RealRoleName({ roles }: { roles: UserRole[] }) {
  if (roles.length === 0) {
    return (
      <Tag closeable={false} kind="neutral">
        N/A
      </Tag>
    );
  }

  return (
    <>
      <Tag closeable={false} kind="neutral">
        {labelForRole(roles[0].name)}
      </Tag>
      {roles.length > 1 ? (
        <Tag kind="neutral" closeable={false}>
          +{roles.length - 1}
        </Tag>
      ) : null}
    </>
  );
}
