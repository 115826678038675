import { CustomTheme, styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Select } from '@tigergraph/app-ui-lib/select';
import { KIND } from 'baseui/button';
import { STYLE_TYPE } from 'baseui/checkbox';
import { FormControlOverrides } from 'baseui/form-control';
import { Controller, useForm } from 'react-hook-form';

import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { FormControl } from '@/components/FormControl';
import TooltipLabel from '@/components/TooltipLabel';

import { WorkSpaceType, WorkspaceT } from '@/pages/workgroup/type';
import { filterProps } from '@tigergraph/tools-ui/insights/utils';
import { forwardRef } from 'react';
import { FormSectionTitle, ToggleContainer } from '@/components/styled';
import { Desc, Form } from './StyledComponent';
import { CustomizeWorkspace } from '@/pages/home/icons.tsx';
import { DrawerHeadContainer } from '@/lib/styled.tsx';
import { formatPricePerHour } from '@/utils/format';
import { useOrgContext } from '@/contexts/orgContext';
import WorkspaceAddonsConfiguration from '@/pages/workgroup/form/addons/configuration';
import { SubFormData } from '@/pages/workgroup/form/type';

export type Props = {
  isOpen: boolean;
  forceSuspend?: boolean;
  onClose: () => void;
  subFormData: SubFormData;
  onSubFormDataChanged: (subFormData: SubFormData) => void;
  workspaceTypes: WorkSpaceType[];
  disableResize?: boolean;

  // for edit workspace
  workspace?: WorkspaceT;
};

export default function WorkSpaceSubForm({
  isOpen,
  forceSuspend,
  onClose,
  subFormData,
  onSubFormDataChanged,
  workspaceTypes,
  disableResize,
  workspace,
}: Props) {
  const [css, theme] = useStyletron();
  const { currentOrg, userInfo } = useOrgContext();
  const form = useForm<SubFormData>({
    defaultValues: subFormData,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = form;

  const url = `mailto:beta-support@tigergraph.com?subject=TigerGraph Cloud 4 Beta Support - Request New Workspace Size - ${userInfo.email} - ${currentOrg.org_id} - ${currentOrg.org_name}&body=Hi Support Team,%0A%0A%0A%0A%0A%0ARegards,%0A${userInfo.email}`;

  const autoScale = watch('autoScale');
  const autoSuspend = watch('autoSuspend');
  const minCluster = watch('minCluster');
  const maxCluster = watch('maxCluster');

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <DrawerHeadContainer>
          <CustomizeWorkspace />
          Workspace Configuration
        </DrawerHeadContainer>
      </DrawerHeader>
      <DrawerBody>
        <Form>
          <FormSectionTitle>Customize Workspace Size</FormSectionTitle>
          <div
            className={css({
              height: '100%',
              overflowY: 'auto',
            })}
          >
            <Controller
              control={control}
              name="workspace_type_name"
              render={({ field: { value, onChange, ref, ...field } }) => {
                return (
                  <Select
                    options={workspaceTypes}
                    value={[{ id: value }]}
                    onChange={(params) => {
                      onChange(params.value[0].typeName);
                    }}
                    clearable={false}
                    inputRef={ref}
                    {...field}
                    disabled={disableResize}
                    searchable={false}
                    overrides={{
                      DropdownListItem: {
                        component: WorkspaceSize,
                      },
                      SingleValue: {
                        component: ({ value }: { value: string }) => {
                          const workspaceType = workspaceTypes.find((item) => item.typeName === value);
                          return <WorkspaceLabel workspaceType={workspaceType} typeName={value} />;
                        },
                      },
                    }}
                  />
                );
              }}
            />
            <Desc
              $style={{
                marginTop: '12px',
                marginBottom: '24px',
              }}
            >
              Can&apos;t find your need? Please{' '}
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className={css({
                  color: theme.colors.secondary800,
                })}
              >
                contact us
              </a>{' '}
              if you need more compute resources
            </Desc>
            <FormSectionTitle>Advanced Settings</FormSectionTitle>
            {/* hide auto scale temporarily */}
            {/*<ToggleContainer>*/}
            {/*  <TooltipLabel label="Enable Auto Scale" tooltip="Enable Auto Scale" />*/}
            {/*  <Controller*/}
            {/*    control={control}*/}
            {/*    name="autoScale"*/}
            {/*    render={({ field: { value, onChange, ref: _, ...field } }) => (*/}
            {/*      <Checkbox*/}
            {/*        checked={value}*/}
            {/*        labelPlacement="left"*/}
            {/*        onChange={() => {*/}
            {/*          onChange(!value);*/}
            {/*        }}*/}
            {/*        {...field}*/}
            {/*        checkmarkType={STYLE_TYPE.toggle_round}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  />*/}
            {/*</ToggleContainer>*/}
            {autoScale ? (
              <div
                className={css({
                  gap: '32px',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                })}
              >
                <div>
                  <FormControl
                    label="Min Cluster Count"
                    error={errors?.minCluster?.message}
                    overrides={formControlOverrides}
                  >
                    <Controller
                      rules={{
                        validate: (value) => {
                          if (autoScale && (!value || value > maxCluster)) return 'invalid count';
                          return true;
                        },
                      }}
                      control={control}
                      name="minCluster"
                      render={({ field }) => (
                        <Input {...field} error={!!errors?.minCluster} type="number" disabled={!autoScale} />
                      )}
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    label="Max Cluster Count"
                    error={errors?.maxCluster?.message}
                    overrides={formControlOverrides}
                  >
                    <Controller
                      rules={{
                        validate: (value) => {
                          if (autoScale && (!value || value < minCluster)) return 'invalid count';
                          return true;
                        },
                      }}
                      control={control}
                      name="maxCluster"
                      render={({ field }) => (
                        <Input {...field} error={!!errors?.maxCluster} type="number" disabled={!autoScale} />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
            ) : null}

            <ToggleContainer>
              <TooltipLabel label="Auto Suspend" tooltip="" />
              <Controller
                control={control}
                name="autoSuspend"
                render={({ field: { value, onChange, ref: _, ...field } }) => (
                  <Checkbox
                    checked={value}
                    labelPlacement="left"
                    onChange={() => {
                      if (forceSuspend) return;
                      onChange(!value);
                    }}
                    {...field}
                    checkmarkType={STYLE_TYPE.toggle_round}
                  />
                )}
              />
            </ToggleContainer>
            <Desc
              $style={{
                marginTop: '12px',
                marginBottom: '24px',
              }}
            >
              Automatically puts a compute instance to sleep when it&apos;s not actively working. This helps save
              resources and costs by stopping the instance from consuming unnecessary computing power when it&apos;s not
              needed. You can easily wake up the instance whenever you want to use it again.
            </Desc>
            {autoSuspend ? (
              <div
                className={css({
                  display: 'flex',
                  flexDirection: 'column',
                })}
              >
                <FormControl
                  label="Auto suspend time in minutes."
                  error={errors?.auto_stop_minutes?.message}
                  overrides={formControlOverrides}
                >
                  <Controller
                    rules={{
                      min: {
                        value: 10,
                        message: 'The minimum auto suspend waiting time is 10 minutes',
                      },
                      max: {
                        value: 360,
                        message: 'The maximum auto suspend waiting time is 360 minutes',
                      },
                    }}
                    control={control}
                    name="auto_stop_minutes"
                    render={({ field }) => (
                      <Input {...field} error={!!errors?.auto_stop_minutes} type="number" disabled={!autoSuspend} />
                    )}
                  />
                </FormControl>
              </div>
            ) : null}
            <ToggleContainer>
              <TooltipLabel label="Auto Resume" tooltip="" />
              <Controller
                control={control}
                name="enable_auto_start"
                render={({ field: { value, onChange, ref: _, ...field } }) => (
                  <Checkbox
                    checked={value}
                    labelPlacement="left"
                    onChange={() => {
                      onChange(!value);
                    }}
                    {...field}
                    checkmarkType={STYLE_TYPE.toggle_round}
                  />
                )}
              />
            </ToggleContainer>
            <Desc
              $style={{
                marginTop: '12px',
                marginBottom: '24px',
              }}
            >
              Automatically activates a previously suspended workspace when it&apos;s needed. This ensures that the
              workspace is ready to handle workloads without any manual intervention. You can wake up the workspace
              using REST APIs or by accessing the UI.
            </Desc>
            {workspace && <WorkspaceAddonsConfiguration form={form} workspace={workspace} />}
          </div>
        </Form>
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind={KIND.tertiary}>
          Cancel
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(async (data, e) => {
            onSubFormDataChanged(data);
            onClose();
            e?.stopPropagation();
          })}
        >
          Save
        </Button>
      </DrawerAction>
    </Drawer>
  );
}

const StyleTypeItem = styled('div', ({ $theme }) => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: $theme.colors.gray700,
  paddingLeft: '10px',
  paddingRight: '10px',
  borderLeft: `1px solid ${$theme.colors.gray200}`,
}));

const StyleTypeValue = styled('span', ({ $theme }) => ({
  color: $theme.colors.gray1000,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 'normal',
}));

const formControlOverrides: FormControlOverrides = {
  Label: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        ...theme.typography.Body2,
        color: '#222',
        marginTop: '-8px',
      };
    },
  },
};

type ItemProps = {
  [x: string]: any;
  workspaceType: WorkSpaceType;
};

const WorkspaceLabel = ({ workspaceType, typeName }: { workspaceType?: WorkSpaceType; typeName: string }) => {
  const [css] = useStyletron();
  if (!workspaceType) {
    return <div>{typeName}</div>;
  }
  return (
    <div
      className={css({
        flex: 1,
      })}
    >
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1.3fr',
        })}
      >
        <StyleTypeValue>{workspaceType.typeName}</StyleTypeValue>
        <StyleTypeItem>
          CPU:<StyleTypeValue>{workspaceType.cpu}</StyleTypeValue>
        </StyleTypeItem>
        <StyleTypeItem>
          MEMORY:<StyleTypeValue>{workspaceType.memory}</StyleTypeValue>
        </StyleTypeItem>
        <StyleTypeItem>
          Cost: <StyleTypeValue>{formatPricePerHour(workspaceType.cost_per_hour / 100)}</StyleTypeValue>
        </StyleTypeItem>
      </div>
    </div>
  );
};

const WorkspaceSize = forwardRef<HTMLLIElement, ItemProps>((props, ref) => {
  const [css] = useStyletron();
  const { item: workspaceType, ...rest } = props;
  return (
    <li
      // todo(lin):
      // for now, we ignore focus/active state
      {...filterProps(rest)}
      ref={ref}
      className={css({
        cursor: 'pointer',
        listStyle: 'none',
        padding: '8px 40px 8px 16px',
        borderBottom: '1px solid #F4F4F4',
      })}
    >
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1.3fr',
        })}
      >
        <StyleTypeValue>{workspaceType.typeName}</StyleTypeValue>
        <StyleTypeItem>
          CPU:<StyleTypeValue>{workspaceType.cpu}</StyleTypeValue>
        </StyleTypeItem>
        <StyleTypeItem>
          MEMORY:<StyleTypeValue>{workspaceType.memory}</StyleTypeValue>
        </StyleTypeItem>
        <StyleTypeItem>
          Cost: <StyleTypeValue>{formatPricePerHour(workspaceType.cost_per_hour / 100)}</StyleTypeValue>
        </StyleTypeItem>
      </div>
    </li>
  );
});
