import { useLocalStorageState } from 'ahooks';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { axiosCluster } from '@/lib/network';

export const GLOBAL_VIEW = '1';
export const MAX_HISTORY_ITEMS = 50;
export const EDITOR_HISTORY_LOCAL_KEY = 'editor-history';
export const CURRENT_GRAPH_LOCAL_KEY = 'current-graph-gshell';

export const ID_TOKEN_KEY = 'id_token';

interface UserContextValue {
  userName: string;
  setUserName(name: string): void;
  graphNames: string[];
  setGraphNames(graphs: string[]): void;
  currentGraph: string;
  setCurrentGraph(name?: string): void;
  cookie: { [key: string]: string | boolean | number };
  setCookie(cookie: object): void;
  getUserGraph: () => void;
}

const userContext = createContext<UserContextValue>({} as UserContextValue);

const getUserInfo = async () => {
  if (axiosCluster.defaults.baseURL) {
    return axiosCluster.get(`/api/gsql-server/gsql/simpleauth`).then((res) => res.data);
  } else {
    return Promise.resolve({ error: true, message: 'No workspace selected' });
  }
};

const useUserContext = () => {
  const context = useContext(userContext);
  return context;
};

const UserProvider: FC<PropsWithChildren> = (props) => {
  const [userName, setUserName] = useState<string>('');
  const [graphNames, setGraphNames] = useState<string[]>([]);
  const [currentGraph, setCurrentGraph] = useLocalStorageState<string>(CURRENT_GRAPH_LOCAL_KEY, {
    defaultValue: GLOBAL_VIEW,
  }) as any;
  const [cookie, setCookie] = useLocalStorageState<object>('gsql-cookie') as any;

  const getUserGraph = useCallback(async () => {
    const { error, message, results } = await getUserInfo();
    if (error) {
      console.error(message);
    } else {
      const names = Object.keys(results.roles);
      setGraphNames(names);
      setUserName(results.name);
      if (names.indexOf(currentGraph) === -1) {
        setCurrentGraph(GLOBAL_VIEW);
        setCookie({});
      }
    }
  }, [currentGraph, setCookie, setCurrentGraph, setGraphNames, setUserName]);

  const UserContextValue: UserContextValue = {
    userName,
    setUserName,
    currentGraph,
    setCurrentGraph,
    graphNames,
    setGraphNames,
    cookie,
    setCookie,
    getUserGraph,
  };

  return <userContext.Provider value={UserContextValue} {...props} />;
};

export { UserProvider, useUserContext };
