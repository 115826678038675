import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Modal, ModalBody } from '@tigergraph/app-ui-lib/modal';
import { ParagraphSmall } from 'baseui/typography';
import { HeadingMedium } from '@tigergraph/app-ui-lib/typography';
import CongratulationIcon from '@/assets/congratulation.png';
import { Button } from '@tigergraph/app-ui-lib/button';
import CongratulationGif from '@/assets/congratulation.gif';

export interface CongratulationPopoverProps {
  showModal: boolean;
  handleClose: () => void;
  onTakeTour: () => void;
}

export function CongratulationPopover({ showModal, handleClose, onTakeTour }: CongratulationPopoverProps) {
  const [css, theme] = useStyletron();

  return (
    <Modal isOpen={showModal} onClose={handleClose}>
      <ModalBody $style={{ margin: '16px 24px 32px 24px', position: 'relative' }}>
        <img
          src={CongratulationGif}
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            userSelect: 'none',
            pointerEvents: 'none',
          }}
        />
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            padding: '8px 16px',
            marginBottom: '12px',
          })}
        >
          <img
            src={CongratulationIcon}
            alt="congratulation"
            style={{
              width: '120px',
              height: '89px',
              margin: '0 auto',
            }}
          />
          <HeadingMedium>Congratulations!</HeadingMedium>
          <ParagraphSmall
            className={css({
              color: theme.colors.black03,
            })}
          >
            Your workspace has been set up successfully. We invite you to join our guided tour, which will assist you in
            getting started on your graph analytics journey.
          </ParagraphSmall>
        </div>
        <div
          className={css({
            textAlign: 'center',
          })}
        >
          <Button
            kind={'tertiary'}
            overrides={{
              BaseButton: {
                style: {
                  padding: '8px',
                  marginRight: '12px',
                },
              },
            }}
            onClick={handleClose}
          >
            Skip
          </Button>

          <Button
            overrides={{
              BaseButton: {
                style: {
                  padding: '8px',
                },
              },
            }}
            onClick={onTakeTour}
          >
            Take a tour
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
