import { ExternalGraph } from '@tigergraph/tools-models';

export interface Vertex {
  v_type: string;
  v_id: string;
  icon?: string;
  [key: string]: any;
}

export interface Edge {
  from_type?: string;
  from_id?: string;
  e_type?: string;
  to_type?: string;
  to_id?: string;
  icon?: string;
  [key: string]: any;
}

export interface NodeState {
  [type: string]: {
    [key: string]: any;
  };
}

export interface GraphState {
  vertices: NodeState;
  edges: NodeState;
}

export interface ChartSchema {
  results: any; // original schema data
  graph: GraphState; // visualizing schema vertices and edges
}

export type Graph = ExternalGraph;

export interface ChartData {
  graph: Graph;
  results: any;
}

export interface Command {
  id: string;
  value: string;
  result?: string;
}

export type FileRecordT = {
  owned_files: FileStore[];
  shared_files: FileStore[];
};

export type FileStore = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  org_id: string;
  is_folder: boolean;
  content: string;
  parent_id: string;
  is_editing?: boolean;
  files: FileStore[];
  owner_email: string;
  permission: FilePermission;
  is_shared_folder?: boolean;
};

export type CreateFileStore = {
  name: string;
  is_folder: boolean;
  content: string;
  parent_id: string | null;
};

export enum FileChangeType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  RENAME = 'RENAME',
  MOVE = 'MOVE',
}

export enum FileSortType {
  DateCreated = 'DateCreated',
  LastModified = 'LastModified',
  Name = 'Name',
}

export enum FilePermission {
  Owner = 'owner',
  View = 'view',
  Edit = 'edit',
  None = 'none',
}

export enum FileShareType {
  Org = 'org',
  User = 'user',
}

export type FileShare = {
  created_at?: string;
  updated_at?: string;
  file_store_id: string;
  target: string;
  permission: FilePermission;
  type: FileShareType;
};

export type AddUpdateShare = {
  type: FileShareType;
  target?: string;
  permission: FilePermission;
};

export type DeleteShare = {
  type: FileShareType;
  target?: string;
};

export type UpdateFileShareRequest = {
  add_shares: AddUpdateShare[];
  update_shares: AddUpdateShare[];
  delete_shares: DeleteShare[];
};

export enum Access {
  Restricted = 0,
  OrgUsersView = 1,
  OrgUsersEdit = 2,
}

export type Action =
  | { type: 'SET_ALL'; data: FilePermissionForm }
  | { type: 'CHANGE_USER'; data: FileShare }
  | { type: 'ADD_USERS'; data: FileShare[] }
  | { type: 'SET_ACCESS'; data: Access }
  | { type: 'REMOVE_USER'; data: FileShare };

export type FilePermissionForm = {
  access: Access;
  userFileShares: FileShare[];
};
