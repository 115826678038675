import useInterval from 'ahooks/lib/useInterval';
import { ProgressBar } from 'baseui/progress-bar';
import clsx from 'clsx';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactFlow, { BaseEdge, EdgeLabelRenderer, EdgeProps, Handle, NodeProps, Position } from 'reactflow';
import { format } from 'date-fns';
import 'reactflow/dist/style.css';
import { CopyIcon, Info } from 'lucide-react';
import useCopyClipboard from 'react-use-clipboard';
import { GroupIcon } from '@/pages/home/icons';
import { useIsMutating } from 'react-query';
import WaitingGif from '../icons/waiting.gif';

import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';

import {
  DatabaseT,
  WorkGroupT,
  WorkspaceStatus,
  WorkspaceT,
  isRefreshIng,
  isStatusActive,
  isStatusError,
  isStatusPending,
  isStatusIdle,
} from '@/pages/workgroup/type';

import { DatabaseIcon, SpaceIcon } from '@/pages/home/icons';
import ConnectionMenu from '@/pages/workgroup/tab/ConnectionMenu';
import { SettingMenuForDatabase, SettingMenuForWorkSpace } from '@/pages/workgroup/tab/SettingMenu';
import {
  calculateNodesAndEdges,
  groupWorkSpacesByDatabase,
  SpaceWidth,
  NodeHeightNormal,
  NodeHeightInProgress,
  DatabaseWidth,
} from '@/pages/workgroup/tab/Graph';
import useSize from 'ahooks/lib/useSize';
import { calculateRoleForGroup, calculateRoleForSpace } from '@/pages/admin/user/type';
import { useOrgContext } from '@/contexts/orgContext';
import ParagraphLabel from '@/components/PagagraphLabel';
import {
  useMutationRefreshWorkspace,
  useMutationRenameDatabase,
  useMutationRenameWorkGroup,
  useMutationUpdateWorkspace,
} from '@/pages/workgroup/hook';
import { getErrorMessage } from '@/utils/utils';
import { showToast } from '@/components/styledToasterContainer';
import { Button } from '@tigergraph/app-ui-lib/button';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { parseDate } from '@/lib/date';
import { MdOutlineAddToQueue } from 'react-icons/md';
import { expand } from 'inline-style-expand-shorthand';
import IconButton from '@/components/IconButton';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { Label } from '@tigergraph/app-ui-lib/typography';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import EditableText, { EditableTextHandle } from '@/components/EditableText';
import { isWorkspaceCopilotEnable } from '@/utils/addons/copilot';

export default function General({ group }: { group: WorkGroupT }) {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);
  const size = useSize(ref);
  const maxWidth = Math.min(size?.width || 0, 280);

  const groupedWorkspaces = useMemo(() => {
    return groupWorkSpacesByDatabase(group);
  }, [group]);

  const nodeTypes = useMemo(() => ({ database: DatabaseNode, workspace: WorkspaceNode }), []);
  const edgeTypes = useMemo(
    () => ({
      databaseEdge: DatabaseEdge,
    }),
    []
  );
  const { nodes, edges, totalHeight } = useMemo(() => {
    return calculateNodesAndEdges(groupedWorkspaces, maxWidth);
  }, [groupedWorkspaces, maxWidth]);

  const { userInfo } = useOrgContext();
  const effectRole = calculateRoleForGroup(userInfo.roles, group.workgroup_id);

  const isCreatingDatabase = useIsMutating({
    mutationKey: ['createDatabase', group.name],
  });

  const isCreatingWorkspace = useIsMutating({
    mutationKey: ['createWorkspace', group.name],
  });

  const updateName = useMutationRenameWorkGroup();
  const handleRef = useRef<EditableTextHandle | null>(null);

  return (
    <div
      ref={ref}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginLeft: '-24px',
        marginRight: '-24px',
        marginTop: '-16px',
        marginBottom: '-16px',
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '16px',
      })}
    >
      {isCreatingDatabase || isCreatingWorkspace ? (
        <div
          className={css({
            backgroundColor: 'white',
            borderRadius: '2px',
            boxShadow: '0px 4px 6px 0px rgba(170, 181, 191, 0.30)',
            marginLeft: '-24px',
            marginRight: '-24px',
            marginTop: '-16px',
            height: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
          })}
        >
          <img
            src={WaitingGif}
            alt="waiting"
            className={css({
              width: '48px',
              height: '48px',
            })}
          />
          <Label>Creating your workspace ...</Label>
        </div>
      ) : null}

      <div
        className={css({
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
        })}
      >
        <GroupTitle className={css({ display: 'inline' })}>
          <div
            className={css({
              lineHeight: '30px',
              marginRight: '10px',
              marginTop: '8px',
              display: 'inline-flex',
              height: '30px',
              position: 'relative',
              top: '3px',
            })}
          >
            <GroupIcon />
          </div>
          <EditableText
            ref={handleRef}
            text={group.name}
            disableEdit={effectRole !== 'workgroup-admins'}
            onTextChange={(name) => {
              updateName.mutate(
                {
                  group_id: group.workgroup_id,
                  workgroup_name: name,
                },
                {
                  onError(error, variables, context) {
                    showToast({
                      kind: 'negative',
                      message: `${getErrorMessage(error)}`,
                    });
                    if (handleRef.current) {
                      handleRef.current.reset();
                    }
                  },
                }
              );
            }}
          />
          <span
            className={css({
              color: theme.colors.gray700,
              padding: '4px 8px',
              background: theme.colors.gray300,
              marginLeft: '12px',
              fontSize: '12px',
              display: 'inline-flex',
            })}
          >
            {group.region.toUpperCase()}
          </span>
        </GroupTitle>
      </div>
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: `${DatabaseWidth}px ${maxWidth}px ${SpaceWidth}px`,
        })}
      >
        <SpaceTitle>Database</SpaceTitle>
        <div />
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          })}
        >
          <SpaceTitle>Workspace</SpaceTitle>
          {effectRole === 'workgroup-admins' ? (
            <Button
              onClick={() => {
                navigate('spaces/config');
              }}
              overrides={{
                BaseButton: {
                  style: {
                    width: '98px',
                    height: '24px',
                    ...expand({
                      padding: '4px 16px',
                      borderRadius: '2px',
                      borderWidth: '0',
                    }),
                    ':active': {
                      ...expand({
                        borderWidth: '0',
                      }),
                    },
                  },
                },
              }}
            >
              <MdOutlineAddToQueue size={20} />
              <span
                className={css({
                  paddingLeft: '8px',
                  fontSize: '14px',
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                })}
              >
                Create
              </span>
            </Button>
          ) : null}
        </div>
      </div>
      <div
        className={css({
          height: `${totalHeight}px`,
        })}
      >
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={{
            // hide React flow watermark
            hideAttribution: true,
          }}
          nodes={nodes}
          edges={edges}
          zoomOnScroll={false}
          zoomOnPinch={false}
          panOnDrag={false}
          panOnScroll={false}
          zoomOnDoubleClick={false}
          selectionOnDrag={false}
          nodesDraggable={false}
          nodesConnectable={false}
          edgesFocusable={false}
          preventScrolling={false}
        />
      </div>
    </div>
  );
}

export function Space({
  workspace,
  database,
  workgroup,
  showRWTag,
  editable = true,
  renderConnectionMenu,
  workspaceInfoPlacement = PLACEMENT.right,
}: {
  workspace: WorkspaceT;
  workgroup?: WorkGroupT;
  showRWTag?: boolean;
  database?: DatabaseT;
  editable?: boolean;
  renderConnectionMenu?: ReactNode;
  workspaceInfoPlacement?: string;
}) {
  const [css] = useStyletron();
  const updateName = useMutationUpdateWorkspace();
  const { userInfo } = useOrgContext();
  const effectRole = calculateRoleForSpace(userInfo.roles, workspace.workgroup_id, workspace.workspace_id);
  const handleRef = useRef<EditableTextHandle | null>(null);
  const isWorkspaceEnabled = isWorkspaceCopilotEnable(workspace);

  return (
    <Box
      className={clsx(
        css({
          cursor: 'initial',
          width: `${SpaceWidth}px`,
          height: `${isStatusPending(workspace.status) ? NodeHeightInProgress : NodeHeightNormal}px`,
        }),
        'workspace-box',
        'nodrag'
      )}
    >
      <Row>
        <span
          className={css({
            flex: 0,
            gap: '6px',
          })}
        >
          <SpaceIcon />
        </span>
        {workgroup && (
          <>
            <span className="text-[#909090]">{workgroup.name}</span>
            <span className="text-[#909090]">/</span>
          </>
        )}
        <EditableText
          ref={handleRef}
          text={workspace.name}
          onTextChange={(name) => {
            updateName.mutate(
              {
                group_id: workspace.workgroup_id,
                space_id: workspace.workspace_id,
                data: {
                  workspace_name: name,
                },
              },
              {
                onError(error) {
                  showToast({
                    kind: 'negative',
                    message: `${getErrorMessage(error)}`,
                  });
                  if (handleRef.current) {
                    handleRef.current.reset();
                  }
                },
              }
            );
          }}
          compact={true}
          maxWidth={isStatusPending(workspace.status) ? '83px' : '220px'}
          disableEdit={effectRole !== 'workspace-admins' || !editable}
        />
        <StatefulTipsPopover
          content={<WorkspaceInfo workspace={workspace} />}
          triggerType={TRIGGER_TYPE.hover}
          placement={workspaceInfoPlacement}
        >
          <IconButton
            className={css({
              marginRight: 'auto',
            })}
          >
            <Info size={12} />
          </IconButton>
        </StatefulTipsPopover>
        {isStatusPending(workspace.status) && workspace.last_modified_time ? (
          <EstimateTime workspace={workspace} />
        ) : (
          <StatusIndicator status={workspace.status} />
        )}
      </Row>
      {isStatusPending(workspace.status) ? <Progress workspace={workspace} /> : null}
      <Row>
        <span className="mr-auto space-x-2">
          {[
            workspace.workspace_type.typeName,
            showRWTag && (workspace.is_rw ? 'R/W' : 'R/O'),
            isWorkspaceEnabled && 'CoPilot',
          ]
            .filter(Boolean)
            .map((name) => (
              <span
                key={String(name)}
                className="rounded-2xl leading-[18px] px-3 text-xs border border-[#AAB5BF] text-[#57686E]"
              >
                {name}
              </span>
            ))}
        </span>
        {renderConnectionMenu ? (
          renderConnectionMenu
        ) : (
          <>
            <ConnectionMenu workspace={workspace} database={database} />
            <SettingMenuForWorkSpace workspace={workspace} database={database} />
          </>
        )}
      </Row>
    </Box>
  );
}

function Database({
  database,
  disableDelete,
  is_ro,
  workspace,
  disableUpdate,
}: {
  database: DatabaseT;
  disableDelete: boolean;
  is_ro?: boolean;
  workspace?: WorkspaceT;
  disableUpdate?: boolean;
}) {
  const [css, theme] = useStyletron();

  const updateName = useMutationRenameDatabase();
  const handleRef = useRef<EditableTextHandle | null>(null);

  const refreshMutation = useMutationRefreshWorkspace();

  const { userInfo } = useOrgContext();
  const effectRole = calculateRoleForSpace(
    userInfo.roles,
    workspace?.workgroup_id || '',
    workspace?.workspace_id || ''
  );

  const onRefresh = () => {
    if (!workspace) {
      return;
    }

    refreshMutation.mutate(
      {
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
      },
      {
        onError: (err) => {
          showToast({
            kind: 'negative',
            message: `${getErrorMessage(err)}`,
          });
        },
      }
    );
  };

  return (
    <Box
      className={clsx(
        css({
          alignSelf: 'center',
          width: `${DatabaseWidth}px`,
          height: `${NodeHeightNormal}px`,
          cursor: 'initial',
          justifyContent: 'center',
        }),
        'database-box',
        'nodrag'
      )}
    >
      <Row>
        <span
          className={css({
            flex: 0,
          })}
        >
          <DatabaseIcon />
        </span>
        <span
          className={css({
            flexBasis: 0,
            flex: 1,
            minWidth: 0,
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
          })}
        >
          <EditableText
            ref={handleRef}
            disableEdit={is_ro || effectRole !== 'workspace-admins'}
            text={database.name}
            onTextChange={(name) => {
              updateName.mutate(
                {
                  group_id: database.workgroup_id,
                  db_id: database.database_id,
                  database_name: name,
                },
                {
                  onError(error) {
                    showToast({
                      kind: 'negative',
                      message: `${getErrorMessage(error)}`,
                    });
                    if (handleRef.current) {
                      handleRef.current.reset();
                    }
                  },
                }
              );
            }}
            compact={true}
          />
          {is_ro && (
            <span
              className={css({
                lineHeight: '16px',
                marginLeft: '4px',
                fontSize: '12px',
                padding: '2px 4px',
                backgroundColor: theme.colors.gray100,
                flexShrink: 0,
              })}
            >
              Snapshot
            </span>
          )}
        </span>
        {!is_ro ? <SettingMenuForDatabase database={database} disableDelete={disableDelete} /> : null}
      </Row>
      {is_ro && workspace ? (
        <Row $style={{ justifyContent: 'space-between' }}>
          <ParagraphLabel>
            {workspace.snapshot_time ? format(parseDate(workspace.snapshot_time), 'yyyy-MM-dd HH:mm:ss') : ''}
          </ParagraphLabel>
          {isRefreshIng(workspace.refresh_status) ? (
            <Spinner $size={'16px'} $borderWidth={'2px'} />
          ) : (
            <ConfirmStatefulPopover
              confirmLabel={`Are you sure you want to update the database?`}
              onConfirm={onRefresh}
            >
              <Button
                kind="tertiary"
                size="compact"
                disabled={
                  refreshMutation.isLoading ||
                  // 1. if no rw workspace, disable update
                  // 2. if the workspace is not active, we should disable the update button
                  disableUpdate ||
                  !isStatusActive(workspace.status)
                }
                isLoading={refreshMutation.isLoading}
              >
                Update
              </Button>
            </ConfirmStatefulPopover>
          )}
        </Row>
      ) : null}
    </Box>
  );
}

export function StatusIndicator({ status }: { status: WorkspaceStatus }) {
  const [css, theme] = useStyletron();
  return (
    <StatefulTipsPopover content={status} triggerType={TRIGGER_TYPE.hover} placement={PLACEMENT.right}>
      <div
        className={css({
          padding: '2px 6px',
          borderRadius: '24px',
          height: '12px',
          backgroundColor: isStatusActive(status)
            ? '#30A442'
            : isStatusError(status)
            ? theme.colors.negative100
            : isStatusPending(status)
            ? theme.colors.backgroundLightWarning
            : isStatusIdle(status)
            ? theme.colors.gray300
            : theme.colors.gray700,
          color: isStatusActive(status)
            ? '#1F6C2B'
            : isStatusError(status)
            ? theme.colors.negative700
            : theme.colors.gray700,
          fontSize: '12px',
          lineHeight: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
        })}
      ></div>
    </StatefulTipsPopover>
  );
}

const estimateDuration = 4 * 60;

function EstimateTime({ workspace }: { workspace: WorkspaceT }) {
  const { last_modified_time } = workspace;
  const now = Date.now();
  const lastTransitionAt = parseDate(last_modified_time);
  const diff = (now - lastTransitionAt.getTime()) / 1000;
  const remains = estimateDuration - diff;
  const m = Math.trunc(remains / 60);
  let s = Math.floor(remains - m * 60);
  s = Math.max(s, 1);

  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  const [css, theme] = useStyletron();
  return (
    <span
      className={css({
        fontSize: '12px',
        color: theme.colors.gray800,
      })}
    >
      {(m == 0 && s < 10) || m < 0
        ? 'Nearly done'
        : 'Estimate:' +
          (m > 0 ? `${m}${m > 1 ? ' mins ' : ' min '}` : ' ') +
          (s > 0 ? `${s}${s > 1 ? ' seconds' : ' second'}` : '')}
    </span>
  );
}

function calculatePercent(last_modified_time: string, estimateInSeconds: number) {
  // the create workspace api return empty value.
  if (!last_modified_time) {
    return 0;
  }
  const now = Date.now();
  const lastTransitionAt = parseDate(last_modified_time);
  const diff = (now - lastTransitionAt.getTime()) / 1000;
  // at most 98%
  const percent = Math.floor((diff / estimateInSeconds) * 100);
  return Math.min(percent, 98);
}

function Progress({ workspace }: { workspace: WorkspaceT }) {
  const { last_modified_time } = workspace;

  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  const percent = calculatePercent(last_modified_time, estimateDuration);

  return (
    <ProgressBar
      value={percent}
      size="medium"
      overrides={{
        Root: {
          style: {
            marginTop: '-11px',
            marginBottom: '-11px',
          },
        },
        BarContainer: {
          style: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      }}
    />
  );
}

export const GroupTitle = styled('div', ({ $theme }) => ({
  ...$theme.typography.HeadingMenu,
}));

export const SpaceGroupContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '316px 204px 316px',
  rowGap: '50px',
  marginBottom: '50px',
});

export const SpaceTitle = styled('div', ({ $theme }) => ({
  ...$theme.typography.HeadingMenu,
}));

export const Box = styled('div', ({ $theme }) => ({
  padding: '16px 12px',
  borderRadius: '4px',
  border: `1px solid ${$theme.colors.divider}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  backgroundColor: '#fff',
}));

export const Row = styled('div', {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

function DatabaseNode({
  data,
}: NodeProps<{
  database: DatabaseT;
  disableDelete: boolean;
  is_ro?: boolean;
  workspace?: WorkspaceT;
  disableUpdate?: boolean;
}>) {
  return (
    <>
      <Handle type="source" position={Position.Right} isConnectable={false} id="right" />
      <Handle type="source" position={Position.Bottom} isConnectable={false} id="bottom" />
      <Handle type="target" position={Position.Left} isConnectable={false} id="left" />
      <Database
        database={data.database}
        disableDelete={data.disableDelete}
        is_ro={data.is_ro}
        workspace={data.workspace}
        disableUpdate={data.disableUpdate}
      />
    </>
  );
}

function WorkspaceNode({ data }: NodeProps<{ database?: DatabaseT; workspace: WorkspaceT }>) {
  return (
    <>
      <Handle type="target" position={Position.Left} isConnectable={false} id="left" />
      <Space database={data.database} workspace={data.workspace} />
    </>
  );
}

// M start point
// L end point
// A arc (rx ry x-axis-rotation large-arc-flag sweep-flag x y)
export function DatabaseEdge(props: EdgeProps & { hideLabel?: boolean }) {
  const { sourceX, sourceY, targetX, targetY, label, hideLabel, ...rest } = props;
  const radiusX = 20;
  const radiusY = 30;
  const edgePath = `M ${sourceX} ${sourceY} L ${sourceX} ${targetY - radiusY} A ${radiusX} ${radiusY} 0 0 0  ${
    sourceX + radiusX
  } ${targetY} L ${targetX} ${targetY}`;

  const labelX = (sourceX + targetX) / 2;
  const labelY = targetY;

  return (
    <>
      <BaseEdge path={edgePath} {...rest} />
      {hideLabel || true ? null : (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              background: '#f8f8f8',
              color: '#B3B3B3',
              fontWeight: '600',
              fontSize: 15,
              padding: '6px 8px',
            }}
            className="nodrag nopan"
          >
            {label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

const InfoSection = styled('div', ({ $theme }) => {
  return {
    display: 'flex',
    gap: '8px',
  };
});

const InfoTitle = styled('span', ({ $theme }) => {
  return {
    fontSize: '12px',
    fontWeight: 500,
  };
});

const InfoContent = styled('span', ({ $theme }) => {
  return {
    fontSize: '12px',
  };
});

function WorkspaceInfo({ workspace }: { workspace: WorkspaceT }) {
  const [hostURLCopied, setHostURLCopied] = useCopyClipboard(workspace.nginx_host, {
    successDuration: 1000,
  });

  const [workgroupIdCopied, setWorkgroupIdCopied] = useCopyClipboard(workspace.workgroup_id, {
    successDuration: 1000,
  });

  const [workspaceIdCopied, setWorkspaceIdCopied] = useCopyClipboard(workspace.workspace_id, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (hostURLCopied) {
      showToast({
        kind: 'positive',
        message: 'Workspace host url copied successfully.',
      });
    }
  }, [hostURLCopied]);

  useEffect(() => {
    if (workgroupIdCopied) {
      showToast({
        kind: 'positive',
        message: 'Workgroup ID copied successfully.',
      });
    }
  }, [workgroupIdCopied]);

  useEffect(() => {
    if (workspaceIdCopied) {
      showToast({
        kind: 'positive',
        message: 'Workspace ID copied successfully.',
      });
    }
  }, [workspaceIdCopied]);

  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <InfoSection>
        <InfoTitle className={css({ width: '80px' })}>ID:</InfoTitle>
        <InfoContent>
          <span className="mr-2">{workspace.workspace_id}</span>
          <IconButton type="button" onClick={setWorkspaceIdCopied}>
            <CopyIcon size="12" />
          </IconButton>
        </InfoContent>
      </InfoSection>
      <InfoSection>
        <InfoTitle className={css({ width: '80px' })}>Workgroup ID:</InfoTitle>
        <InfoContent>
          <span className="mr-2">{workspace.workgroup_id}</span>
          <IconButton type="button" onClick={setWorkgroupIdCopied}>
            <CopyIcon size="12" />
          </IconButton>
        </InfoContent>
      </InfoSection>
      <InfoSection>
        <InfoTitle className={css({ width: '80px' })}>Host:</InfoTitle>
        <InfoContent>
          <span className="mr-2">{workspace.nginx_host}</span>
          <IconButton type="button" onClick={setHostURLCopied}>
            <CopyIcon size="12" />
          </IconButton>
        </InfoContent>
      </InfoSection>
      <InfoSection>
        <InfoTitle className={css({ width: '80px' })}>Type:</InfoTitle>
        <InfoContent>{workspace.is_rw ? 'Read-Write' : 'Read-Only'}</InfoContent>
      </InfoSection>
      <InfoSection>
        <InfoTitle className={css({ width: '80px' })}>Version:</InfoTitle>
        <InfoContent>{workspace.tg_version}</InfoContent>
      </InfoSection>
      <InfoSection>
        <InfoTitle className={css({ width: '80px' })}>Owner:</InfoTitle>
        <InfoContent>{workspace.creator}</InfoContent>
      </InfoSection>
      <InfoSection>
        <InfoTitle className={css({ width: '80px' })}>Auto Start:</InfoTitle>
        <InfoContent>{workspace.enable_auto_start ? 'Enabled' : 'Disabled'}</InfoContent>
      </InfoSection>
      <InfoSection>
        <InfoTitle className={css({ width: '80px' })}>Auto Suspend:</InfoTitle>
        <InfoContent>
          {workspace.auto_stop_minutes === 0
            ? 'Disabled'
            : workspace.auto_stop_minutes + (workspace.auto_stop_minutes === 1 ? ' min' : ' mins')}
        </InfoContent>
      </InfoSection>
    </div>
  );
}
