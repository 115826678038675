import { Table } from 'baseui/table-semantic';
import { Attribute, ValidateResult, supportedDiscriminatorAttributeTypes } from '@tigergraph/tools-models';
import { EditableInput } from '@/components/editableInput';
import { AttributeTypeSelect } from '@/components/schemaDesigner/AttributeTypeSelect';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { AttributeValueColumn } from '@/components/schemaDesigner/AttributeValueColumn';
import IconButton from '@/components/IconButton';
import { MdDeleteOutline } from 'react-icons/md';
import { useMemo } from 'react';
import ErrorMessage from '@/components/ErrorMessage';

export interface EdgeAttributeTableProps {
  attributes: Attribute[];
  onChange: (attributes: Attribute[]) => void;
  validateResult: ValidateResult | undefined;
  readOnly?: boolean;
}

export function EdgeAttributeTable({ attributes, onChange, readOnly, validateResult }: EdgeAttributeTableProps) {
  const [css, theme] = useStyletron();
  const handleAttributeNameChange = (name: string, index: number) => {
    const newAttributes = [...attributes];
    newAttributes[index].name = name;
    onChange(newAttributes);
  };

  const handleAttributeTypeChange = (attr: Attribute, index: number) => {
    const newAttributes = [...attributes];
    newAttributes[index] = attr;
    onChange(newAttributes);
  };

  const handleAttributeIsDiscriminatorChange = (isDiscriminator: boolean, index: number) => {
    const newAttributes = [...attributes];
    newAttributes[index].isDiscriminator = isDiscriminator;
    onChange(newAttributes);
  };

  const handleAttributeChange = (attr: Attribute, index: number) => {
    const newAttributes = [...attributes];
    newAttributes[index] = attr;
    onChange(newAttributes);
  };

  const isInvalidAttributeName = useMemo(() => {
    return validateResult && !validateResult.success && validateResult.message?.includes('attribute name');
  }, [validateResult]);

  return (
    <>
      <Table
        divider={'horizontal'}
        overrides={{
          TableHeadCell: {
            style: {
              backgroundColor: `#F4F8FD`,
              paddingTop: '8px',
              paddingBottom: '8px',
              paddingLeft: '4px',
              paddingRight: '10px',
              minWidth: '50px',
              ':last-child': {
                boxSizing: 'border-box',
                paddingLeft: '0px',
                paddingRight: '0px',
                minWidth: '20px !important',
              },
            },
          },
          TableBodyCell: {
            style: {
              paddingTop: '2px',
              paddingBottom: '2px',
              paddingLeft: '4px',
              paddingRight: '4px',
              maxWidth: '100%',
              ':last-child': {
                boxSizing: 'content-box',
                paddingLeft: '0px',
                paddingRight: '0px',
              },
              ':last-child > :last-child': {
                visibility: 'hidden',
              },
            },
          },
          TableBodyRow: {
            style: ({ $rowIndex }) => {
              const match = validateResult?.message?.match(/"([^"]*)"/);
              if (match && match[1] !== undefined) {
                const quotedWord = match[1];
                if ($rowIndex === attributes.findIndex((attribute) => attribute.name === quotedWord)) {
                  return {
                    backgroundColor: '#F8EBEB',
                    border: `2px solid ${theme.colors.error}`,
                    ':hover': {
                      backgroundColor: '#F8EBEB',
                    },
                    ':hover > :last-child > :last-child': {
                      visibility: 'visible',
                    },
                  };
                }
              }
              return {
                ':hover > :last-child > :last-child': {
                  visibility: 'visible',
                },
              };
            },
          },
        }}
        columns={['Attribute name', 'Type', 'Discriminator', '', '']}
        data={attributes.map((attribute, index) => {
          return [
            <EditableInput
              key={`Attribute_name_${attribute.name}_${index}`}
              value={attribute.name}
              readOnly={readOnly}
              onChange={(name) => handleAttributeNameChange(name, index)}
            />,
            <div
              key={`Type_${attribute.name}_${index}`}
              className={css({
                minWidth: '100px',
              })}
            >
              <AttributeTypeSelect
                attribute={attribute}
                readOnly={readOnly}
                onChange={(attr) => handleAttributeTypeChange(attr, index)}
              />
            </div>,
            <div
              key={`Key_${attribute.name}_${index}`}
              className={css({
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '30px',
              })}
            >
              <Checkbox
                disabled={!supportedDiscriminatorAttributeTypes.includes(attribute.type.name) || readOnly}
                checked={attribute.isDiscriminator}
                onChange={(e) => {
                  handleAttributeIsDiscriminatorChange(e.currentTarget.checked, index);
                }}
              />
            </div>,
            <div
              key={`Default_value_${attribute.name}_${index}`}
              className={css({
                minWidth: '100px',
              })}
            >
              <AttributeValueColumn attribute={attribute} onChange={(attr) => handleAttributeChange(attr, index)} />
            </div>,
            <IconButton
              onClick={() => {
                const newAttributes = [...attributes];
                newAttributes.splice(index, 1);
                onChange(newAttributes);
              }}
              key={`Delete_${attribute.name}_${index}`}
              $style={{
                height: '32px',
                lineHeight: '32px',
                width: '20px',
                display: readOnly ? 'none' : 'block',
              }}
            >
              <MdDeleteOutline size={16} color={theme.colors.error} />
            </IconButton>,
          ];
        })}
      />
      {isInvalidAttributeName && <ErrorMessage message={validateResult?.message ?? ''} />}
    </>
  );
}
