import { DrawerBody, DrawerHeader } from '@/components/Drawer';
import { MetaAddonsType } from '@/hooks/useAddonsMeta';
import { EnabledAddonsType } from '@/hooks/useListEnabledAddons';
import { DrawerHeadContainer } from '@/lib/styled';
import { CustomizeWorkspace } from '@/pages/home/icons';
import { drawerConfigComponentMap } from '@/pages/workgroup/form/addons/edit-drawer/config';
import { SubFormData } from '@/pages/workgroup/form/type';
import { WorkspaceT } from '@/pages/workgroup/type';
import clsx from 'clsx';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

const EditWorkspaceAddons: FC<{
  form: UseFormReturn<SubFormData>;
  isOpen?: boolean;
  workspace: WorkspaceT;
  selectedAddonsMeta?: MetaAddonsType;
  selectedEnabledAddons?: EnabledAddonsType;
}> = ({ form, isOpen, workspace, selectedAddonsMeta, selectedEnabledAddons }) => {
  const Component = drawerConfigComponentMap.get(selectedAddonsMeta?.ID || '');

  if (!Component) {
    return null;
  }

  return (
    <div
      className={clsx(
        isOpen && '-translate-x-full',
        'h-full absolute left-0 top-0 bg-white -z-10 border-r border-[#D4DADF] transition duration-200 ease-out'
      )}
    >
      <DrawerHeader>
        <DrawerHeadContainer>
          {selectedAddonsMeta?.WorkspaceAvatar ? (
            <img className="w-8" src={selectedAddonsMeta.WorkspaceAvatar} />
          ) : (
            <CustomizeWorkspace />
          )}
          Configurue {selectedAddonsMeta?.Title}
        </DrawerHeadContainer>
      </DrawerHeader>
      <DrawerBody>
        <Component
          form={form}
          workspace={workspace}
          selectedAddonsMeta={selectedAddonsMeta}
          selectedEnabledAddons={selectedEnabledAddons}
        />
      </DrawerBody>
    </div>
  );
};

export default EditWorkspaceAddons;
