import { Avatar, formatName } from '@/components/Avatar';
import { DrawerAction, DrawerBody } from '@/components/Drawer';
import IconButton from '@/components/IconButton';
import { leftRadioOverrides, rightRadioOverrides } from '@/components/radio';
import { showToast } from '@/components/styledToasterContainer';
import { UserInfo, UserRole } from '@/lib/models';
import {
  getGroupAdmins,
  getOrgAdmins,
  getSpaceAdmins,
  getSpaceUsers,
  useMutationAssignSpaceRole,
  useQueryListUsers,
} from '@/pages/admin/user/hook';
import { WorkGroupT, WorkspaceT, isStatusActive } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { Select } from '@tigergraph/app-ui-lib/select';
import useHover from 'ahooks/lib/useHover';
import { KIND } from 'baseui/button';
import { TRIGGER_TYPE, PLACEMENT } from 'baseui/popover';
import { Radio, RadioGroup } from 'baseui/radio';
import { Value } from 'baseui/select';
import { SVGProps, useEffect, useMemo, useRef, useState } from 'react';
import SettingIcon from './db_user/setting.svg?react';
import { GroupAdminItem, UserDropDownItem, filterOptions, getDropdownUserList } from './common';

import DeleteIcon from '@/assets/delete.svg?react';
import InfoIcon from '@/assets/info.svg?react';
import UserIcon from '@/assets/user.svg?react';
import { isSpaceAdmin, isSpaceUser } from '@/pages/admin/user/type';
import AssignRoles from '@/pages/workgroup/tab/iam/db_user/AssignRoles';
import { useCallbackRef } from '@/hooks/useCallbackRef';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { LoadingIndicator } from '@/components/loading-indicator';
import { ErrorDisplay } from '@/components/error';

export type Props = {
  onClose: () => void;
  group: WorkGroupT;
  space: WorkspaceT;
};

export default function SpaceAdminDetail({ onClose, group, space }: Props) {
  const [css] = useStyletron();

  const { data: allUser = [], isFetching, isError, error } = useQueryListUsers();
  const groupsAdmin = getGroupAdmins(allUser, group.workgroup_id);
  const orgAdmins = getOrgAdmins(allUser).sort((a, b) => a.email.localeCompare(b.email));

  const spaceUsersBefore = getSpaceAdmins(allUser, group.workgroup_id, space.workspace_id).concat(
    getSpaceUsers(allUser, group.workgroup_id, space.workspace_id)
  );

  const [spaceUsersAfter, setSpaceUsersAfter] = useState<UserInfo[]>([]);
  const [isReady, setIsReady] = useState(false);
  const [selectValue, setSelectValue] = useState<Value>([]);

  useEffect(() => {
    // wait until the data is fetching and spaceUsersBefore is ready
    if (spaceUsersBefore && !isReady && !isFetching) {
      setSpaceUsersAfter(spaceUsersBefore);
      setIsReady(true);
    }
  }, [spaceUsersBefore, isReady, isFetching]);

  function addUser(user: UserInfo) {
    setSpaceUsersAfter([user, ...spaceUsersAfter]);
    setSelectValue([]);
  }

  function removeUser(userEmail: string) {
    setSpaceUsersAfter((spaceUsersAfter) => {
      const index = spaceUsersAfter.findIndex((item) => item.email === userEmail);
      return spaceUsersAfter.slice(0, index).concat(spaceUsersAfter.slice(index + 1));
    });
  }

  const dropdownUserList = useMemo(() => {
    return getDropdownUserList(allUser, groupsAdmin.concat(spaceUsersAfter));
  }, [allUser, groupsAdmin, spaceUsersAfter]);

  if (isFetching) {
    return (
      <DrawerBody>
        <LoadingIndicator />
      </DrawerBody>
    );
  }

  if (isError) {
    return (
      <DrawerBody>
        <ErrorDisplay error={error} />
      </DrawerBody>
    );
  }

  return (
    <>
      <DrawerBody
        $style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '14px',
            fontWeight: '600',
          })}
        >
          <UserIcon />
          <span>Add new users to {space.name}</span>
          <StatefulPopover
            triggerType={TRIGGER_TYPE.hover}
            placement={PLACEMENT.top}
            content="You can add new users to your workspace."
          >
            <IconButton tabIndex={-1}>
              <InfoIcon />
            </IconButton>
          </StatefulPopover>
        </div>
        <div
          className={css({
            display: 'flex',
            alignItems: 'flex-start',
            gap: '20px',
          })}
        >
          <FormControl
            overrides={{
              ControlContainer: {
                style: {
                  marginBottom: '0px',
                },
              },
            }}
          >
            <Select
              isLoading={isFetching}
              size="compact"
              type="search"
              placeholder="Search user"
              options={dropdownUserList}
              value={selectValue}
              maxDropdownHeight={'400px'}
              labelKey="email"
              filterOptions={filterOptions}
              onChange={(params) => {
                setSelectValue(params.value);
                addUser({
                  ...params.value[0].userInfo,
                  roles: [
                    {
                      // add user default as workspaces users.
                      name: 'workspace-users',
                      path: `/${group.workgroup_id}/${space.workspace_id}/workspace-users`,
                    },
                  ],
                  isNewAdded: true,
                });
              }}
              overrides={{
                Dropdown: {
                  style: {
                    marginTop: '8px',
                  },
                },
                DropdownListItem: {
                  component: UserDropDownItem,
                },
              }}
            />
          </FormControl>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            maxHeight: '280px',
            overflowY: 'auto',
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
            minHeight: 0,
          })}
        >
          {groupsAdmin.concat(orgAdmins).map((user) => (
            <GroupAdminItem key={user.email} user={user} />
          ))}
        </div>
        <div
          className={css({
            background: '#D4DADF',
            height: '1px',
          })}
        />
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
            minHeight: 0,
            overflowY: 'auto',
          })}
        >
          {spaceUsersAfter.map((user) => (
            <UserItem
              key={user.email}
              user={user}
              group={group}
              space={space}
              onDelete={(userEmail) => removeUser(userEmail)}
              onChange={(user) => {
                const newAdmins: UserInfo[] = spaceUsersAfter.map((u) => (u.id !== user.id ? u : user));
                setSpaceUsersAfter(newAdmins);
              }}
            />
          ))}
        </div>
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind={KIND.tertiary}>
          Cancel
        </Button>
      </DrawerAction>
    </>
  );
}

type ItemProps = {
  user: UserInfo & { isNewAdded?: boolean };
  group: WorkGroupT;
  space: WorkspaceT;
  onDelete: (userEmail: string) => void;
  onChange: (user: UserInfo) => void;
};

function UserItem({ user, group, space, onDelete, onChange }: ItemProps) {
  const [css, theme] = useStyletron();
  const [showSetting, setShowSetting] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const hover = useHover(ref);
  const isAdmin = isSpaceAdmin(user.roles, group.workgroup_id, space.workspace_id);
  const disabled = !isStatusActive(space.status);

  const onDeleteRef = useCallbackRef(onDelete);

  const { mutate: addMutation, isLoading: isAdding } = useMutationAssignSpaceRole();

  const toggleUser = useMutationAssignSpaceRole();
  const deleteUser = useMutationAssignSpaceRole();

  useEffect(() => {
    if (user.isNewAdded) {
      addMutation(
        {
          groupID: group.workgroup_id,
          spaceID: space.workspace_id,
          // We assume that the user is added as a workspace user by default.
          assignUserEmails: [user.email],
          assignAdminEmails: [],
          unAssignEmails: [],
        },
        {
          onError: (error) => {
            showToast({
              kind: 'negative',
              message: getErrorMessage(error),
            });

            console.log('onDelete rollback', user.email);
            // when error, rollback by call delete
            onDeleteRef(user.email);
          },
        }
      );
    }
  }, [user.isNewAdded, user.email, addMutation, group.workgroup_id, space.workspace_id, onDeleteRef]);

  const onToggleRole = (user: UserInfo, originUser: UserInfo) => {
    onChange(user);

    toggleUser.mutate(
      {
        groupID: group.workgroup_id,
        spaceID: space.workspace_id,
        assignUserEmails: isAdmin ? [user.email] : [],
        assignAdminEmails: !isAdmin ? [user.email] : [],
        unAssignEmails: [],
      },
      {
        onSuccess: () => {},
        onError: (error) => {
          showToast({
            kind: 'negative',
            message: getErrorMessage(error),
          });

          // when error, rollback to origin user
          onChange(originUser);
        },
      }
    );
  };

  const onDeleteUser = () => {
    deleteUser.mutate(
      {
        groupID: group.workgroup_id,
        spaceID: space.workspace_id,
        assignUserEmails: [],
        assignAdminEmails: [],
        unAssignEmails: [user.email],
      },
      {
        onSuccess: () => {
          // only delete after success
          onDelete(user.email);
        },
        onError: (error) => {
          showToast({
            kind: 'negative',
            message: getErrorMessage(error),
          });
        },
      }
    );
  };

  const isInProgress = isAdding || toggleUser.isLoading || deleteUser.isLoading;

  return (
    <div
      className={css({
        display: 'grid',
        columnGap: '6px',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr auto auto 20px',
        padding: '4px 8px',
        ':hover': {
          backgroundColor: '#F4F4F4',
        },
      })}
      ref={ref}
    >
      <Avatar size="compact">{formatName(user)}</Avatar>
      <span>{user.email}</span>
      <StatefulPopover
        triggerType={TRIGGER_TYPE.hover}
        placement={PLACEMENT.top}
        content={isAdmin ? 'Workspace admins have superuser permissions.' : 'Assign roles to this user'}
      >
        <IconButton
          disabled={disabled || isInProgress}
          onClick={() => {
            if (isAdmin) {
              return;
            }
            setShowSetting(true);
          }}
        >
          {isAdmin ? <SettingAdminIcon checkColor={!disabled ? '#30A442' : theme.colors.gray300} /> : <SettingIcon />}
        </IconButton>
      </StatefulPopover>
      <RadioGroup
        align="horizontal"
        value={isAdmin ? 'workspace-admins' : 'workspace-users'}
        disabled={isInProgress}
        onChange={() => {
          const newRoles = user.roles.map((role) => {
            if (isAdmin && role.path === `/${group.workgroup_id}/${space.workspace_id}/workspace-admins`) {
              return {
                ...role,
                name: 'workspace-users',
                path: `/${group.workgroup_id}/${space.workspace_id}/workspace-users`,
              } as UserRole;
            } else if (
              isSpaceUser(user.roles, group.workgroup_id, space.workspace_id) &&
              role.path === `/${group.workgroup_id}/${space.workspace_id}/workspace-users`
            ) {
              return {
                ...role,
                name: 'workspace-admins',
                path: `/${group.workgroup_id}/${space.workspace_id}/workspace-admins`,
              } as UserRole;
            }
            return role;
          });

          onToggleRole(
            {
              ...user,
              roles: newRoles,
            },
            user
          );
        }}
      >
        <Radio value="workspace-admins" overrides={leftRadioOverrides}>
          Admin
        </Radio>
        <Radio value="workspace-users" overrides={rightRadioOverrides}>
          Member
        </Radio>
      </RadioGroup>
      {hover && !isInProgress ? (
        <IconButton onClick={onDeleteUser}>
          <DeleteIcon />
        </IconButton>
      ) : null}
      {isInProgress && <Spinner $size={'16px'} $borderWidth={'2px'} />}
      {showSetting ? (
        <AssignRoles name={user.email} isOpen={showSetting} onClose={() => setShowSetting(false)} workspace={space} />
      ) : null}
    </div>
  );
}

const SettingAdminIcon = (props: SVGProps<SVGSVGElement> & { checkColor: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <mask id="a" width={13} height={17} x={1.502} y={0.785} fill="#000" maskUnits="userSpaceOnUse">
      <path fill="#fff" d="M1.502.785h13v17h-13z" />
      <path d="M13.842 10.453a.48.48 0 0 0-.212-.644 7.394 7.394 0 0 0-1.26-.504 4.073 4.073 0 0 0 1.892-3.44 4.087 4.087 0 0 0-4.08-4.08 4.087 4.087 0 0 0-4.08 4.08c0 1.448.76 2.716 1.896 3.44-3.172.944-5.496 3.884-5.496 7.36 0 .264.216.48.48.48s.48-.216.48-.48c0-3.704 3.016-6.72 6.72-6.72 1.056 0 2.072.24 3.012.72.24.12.528.024.648-.212Zm-6.78-4.588c0-1.72 1.4-3.12 3.12-3.12 1.72 0 3.12 1.4 3.12 3.12 0 1.72-1.4 3.12-3.12 3.12-1.72 0-3.12-1.4-3.12-3.12Z" />
    </mask>
    <path
      fill="currentColor"
      d="M13.842 10.453a.48.48 0 0 0-.212-.644 7.394 7.394 0 0 0-1.26-.504 4.073 4.073 0 0 0 1.892-3.44 4.087 4.087 0 0 0-4.08-4.08 4.087 4.087 0 0 0-4.08 4.08c0 1.448.76 2.716 1.896 3.44-3.172.944-5.496 3.884-5.496 7.36 0 .264.216.48.48.48s.48-.216.48-.48c0-3.704 3.016-6.72 6.72-6.72 1.056 0 2.072.24 3.012.72.24.12.528.024.648-.212Zm-6.78-4.588c0-1.72 1.4-3.12 3.12-3.12 1.72 0 3.12 1.4 3.12 3.12 0 1.72-1.4 3.12-3.12 3.12-1.72 0-3.12-1.4-3.12-3.12Z"
    />
    <path
      fill="currentColor"
      d="m13.63 9.809-.09.178.09-.178Zm-1.26-.504-.107-.169-.37.236.42.125.057-.192Zm-4.372 0 .057.192.42-.125-.37-.236-.107.169Zm5.196 1.36-.09.178h.001l.09-.178Zm.827-.121a.68.68 0 0 0-.3-.913l-.181.356a.28.28 0 0 1 .124.375l.357.182Zm-.3-.913a7.595 7.595 0 0 0-1.294-.518l-.113.384c.42.124.828.288 1.226.49l.181-.356Zm-1.243-.157a4.273 4.273 0 0 0 1.984-3.61h-.4a3.873 3.873 0 0 1-1.799 3.272l.215.338Zm1.984-3.61a4.287 4.287 0 0 0-4.28-4.28v.4a3.887 3.887 0 0 1 3.88 3.88h.4Zm-4.28-4.28a4.287 4.287 0 0 0-4.28 4.28h.4a3.887 3.887 0 0 1 3.88-3.88v-.4Zm-4.28 4.28c0 1.52.798 2.85 1.989 3.61l.215-.338a3.877 3.877 0 0 1-1.804-3.271h-.4Zm2.04 3.25c-3.255.968-5.64 3.984-5.64 7.55h.4c0-3.384 2.264-6.248 5.353-7.167l-.114-.384Zm-5.64 7.55c0 .375.306.68.68.68v-.4a.281.281 0 0 1-.28-.28h-.4Zm.68.68c.375 0 .68-.305.68-.68h-.4c0 .154-.126.28-.28.28v.4Zm.68-.68a6.53 6.53 0 0 1 6.52-6.52v-.4c-3.814 0-6.92 3.106-6.92 6.92h.4Zm6.52-6.52c1.025 0 2.01.234 2.921.699l.182-.356a6.766 6.766 0 0 0-3.103-.742v.4Zm2.923.7c.338.169.746.034.916-.3l-.357-.182a.283.283 0 0 1-.38.124l-.179.358Zm-5.843-4.98c0-1.609 1.31-2.92 2.92-2.92v-.4c-1.83 0-3.32 1.49-3.32 3.32h.4Zm2.92-2.92c1.61 0 2.92 1.311 2.92 2.92h.4c0-1.83-1.49-3.32-3.32-3.32v.4Zm2.92 2.92c0 1.61-1.31 2.92-2.92 2.92v.4c1.83 0 3.32-1.489 3.32-3.32h-.4Zm-2.92 2.92c-1.61 0-2.92-1.31-2.92-2.92h-.4c0 1.831 1.49 3.32 3.32 3.32v-.4Z"
      mask="url(#a)"
    />
    <path
      fill={props.checkColor}
      d="M15.248 11.965c-2.208 0-4 1.792-4 4s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4Zm-1.084 5.716-1.436-1.436a.397.397 0 0 1 .129-.65.4.4 0 0 1 .435.086l1.156 1.152L17.2 14.08a.399.399 0 0 1 .564.564l-3.036 3.036a.399.399 0 0 1-.564 0Z"
    />
  </svg>
);
