import { styled } from '@tigergraph/app-ui-lib/Theme';

export const FormSectionTitle = styled('div', ({ $theme }) => ({
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '24px',
  color: $theme.colors.gray1000,
  marginBottom: '8px',
}));
