import { FormControl } from '@/components/FormControl';
import { CustomTheme, styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Select } from '@tigergraph/app-ui-lib/select';
import { Combobox } from '@tigergraph/app-ui-lib/combobox';
import { ALIGN, Radio, RadioGroup, RadioOverrides } from 'baseui/radio';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import IconButton from '@/components/IconButton';
import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { Result } from '@/lib/type';
import { SpaceIcon } from '@/pages/home/icons';
import { createWorkGroup, getMeta, getWorkGroupDetail } from '@/pages/workgroup/api';
import WorkSpaceSubForm from '@/pages/workgroup/form/WorkSpaceSubForm';
import { useQuery } from 'react-query';
import EditIcon from '@/assets/edit.svg?react';
import { Desc, Form, FormContent, FormContentContainer, FormControllerContainer } from './StyledComponent';
import { FormData } from '@/pages/workgroup/form/type';
import currency from 'currency.js';
import { formatPricePerHour } from '@/utils/format';

import { showToast } from '@/components/styledToasterContainer';
import { useUserGuideContext } from '@/components/userguide/userGuideContext';
import { OnboardingTaskName } from '@/components/userguide/utils';
import { createDatabase, createWorkSpace } from '@/pages/workgroup/api';
import {
  CreateDatabaseRequest,
  CreateWorkSpaceRequest,
  DatabaseT,
  WorkGroupT,
  WorkspaceT,
  WorkSpaceMeta,
  CreateWorkGroupRequest,
} from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import trackUtil, { EventStatus } from '@/utils/analytics-service.ts';
import PriceCalculator from '@/pages/workgroup/form/PriceCalculator';
import WorkspaceAddonSelect from './addons';
import { createWorkspaceEnableAtom, useAddonsPrice } from '@/pages/workgroup/form/addons/store';
import { useResetAtom } from 'jotai/utils';
import { generateDatabaseName, generateWorkspaceName } from '@/pages/workgroup/form/name';
import { useAtomValue } from 'jotai';
import { AddonsMetaID } from '@/components/addons/consts';

export default function WorkSpaceForm() {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const locationState = location.state as {
    createGroupRequest: CreateWorkGroupRequest;
  } | null;
  const createGroupRequest = locationState?.createGroupRequest;

  const params = useParams();
  let workgroup_id = params['groupID'];

  const metaQuery = useQuery<Result<WorkSpaceMeta>, AxiosError>(['workspaceMeta'], getMeta);
  const groupQuery = useQuery<Result<WorkGroupT>, AxiosError>(
    ['group', workgroup_id],
    async () => {
      return getWorkGroupDetail(workgroup_id!);
    },
    {
      enabled: !!workgroup_id,
    }
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    clearErrors,
    setFocus,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      name: 'Workspace-1',
      tg_version: '',
      workspace_type_name: '',
      newDB: 'yes',
      newDBName: `Database-1`,
      database_id: '',
      autoScale: false,
      minCluster: 1,
      maxCluster: 1,
      autoSuspend: true,
      // default 0 means not auto stop instance.
      auto_stop_minutes: 60,
      enable_auto_start: false,
    },
  });

  useEffect(() => {
    if (metaQuery.data?.Result) {
      const { workspaceTypes, tgVersions } = metaQuery.data.Result;
      setValue('workspace_type_name', workspaceTypes[0].typeName);
      setValue('tg_version', tgVersions[tgVersions.length - 1].name);
    }
  }, [metaQuery.data, setValue]);

  // 1. set default workspace name
  // 2. set default database name
  useEffect(() => {
    if (groupQuery.data?.Result) {
      setValue('name', generateWorkspaceName(groupQuery.data.Result));
      setValue('newDBName', generateDatabaseName(groupQuery.data.Result));
    }
  }, [groupQuery.data?.Result, setValue]);

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const newDB = watch('newDB');
  const database_id = watch('database_id');

  const workspace_type_name = watch('workspace_type_name');
  const autoScale = watch('autoScale');
  const autoSuspend = watch('autoSuspend');
  const minCluster = watch('minCluster');
  const maxCluster = watch('maxCluster');
  const auto_stop_minutes = watch('auto_stop_minutes');
  const enable_auto_start = watch('enable_auto_start');

  const isCreatingROWorkspace = useMemo(() => {
    return newDB === 'no';
  }, [newDB]);

  // set default database_id when newDB is no
  useEffect(() => {
    if (newDB === 'no' && !database_id && groupQuery.data?.Result?.tg_databases.length) {
      setValue('database_id', groupQuery.data?.Result?.tg_databases[0].database_id);
    }
  }, [database_id, groupQuery, newDB, setValue]);

  // when attached to existing database
  // 1. set tg_version to the selected database version
  // 2. disable change tg_version on controller
  useEffect(() => {
    if (newDB === 'no' && database_id && groupQuery.data?.Result?.tg_databases) {
      setValue(
        'tg_version',
        groupQuery.data?.Result?.tg_databases.find((db) => db.database_id === database_id)?.tg_version || ''
      );
    }
  }, [newDB, setValue, database_id, groupQuery.data?.Result?.tg_databases]);

  const workspaceType = metaQuery.data?.Result?.workspaceTypes?.find(
    (workspaceType) => workspaceType.typeName === workspace_type_name
  );

  const { completeOnboardingTask } = useUserGuideContext();
  const [addonsParams, setAddonsParams] = useState<Record<string, string | number | boolean>>();
  const addonsPrice = useAddonsPrice(workspaceType?.cost_per_hour || 0);
  const resetEnabledAddons = useResetAtom(createWorkspaceEnableAtom);
  const enabledAddonsMap = useAtomValue(createWorkspaceEnableAtom);

  const getAddonsEnabledParams = () =>
    Object.keys(enabledAddonsMap).map((id) => ({
      addons_id: id,
      enable: enabledAddonsMap[id],
      enable_configuration:
        id === AddonsMetaID.COPILOT
          ? {
              copilot_llm_provider_config_id: String(addonsParams?.copilot_llm_provider_config_id),
            }
          : {},
    }));

  useEffect(() => {
    // clean all enabled addons for creating workspace when component destroy
    return () => {
      resetEnabledAddons();
    };
  }, [resetEnabledAddons]);

  const queryClient = useQueryClient();

  const createGroupMutation = useMutation<Result<WorkGroupT>, AxiosError, CreateWorkGroupRequest>({
    mutationFn: (data) => {
      return createWorkGroup(data);
    },
    mutationKey: ['createGroup', createGroupRequest?.name],
  });

  const createWorkSpaceMutation = useMutation<Result<WorkspaceT>, AxiosError, CreateWorkSpaceRequest>({
    mutationFn: (data) => {
      return createWorkSpace(data);
    },
    mutationKey: ['createWorkspace', createGroupRequest?.name],
  });

  const createDatabaseMutation = useMutation<Result<DatabaseT>, AxiosError, CreateDatabaseRequest>({
    mutationFn: (data) => {
      return createDatabase(data);
    },
    mutationKey: ['createDatabase', createGroupRequest?.name],
  });

  if (groupQuery.isLoading || metaQuery.isLoading) {
    return <LoadingIndicator />;
  }

  const error = groupQuery.error || metaQuery.error;
  if (error) {
    return <ErrorDisplay label="Server Error:" error={error} />;
  }

  if (!metaQuery?.data?.Result) {
    return null;
  }

  const group = groupQuery.data?.Result || { tg_databases: [], workspaces: [] };
  const { tg_databases } = group;

  const { tgVersions, workspaceTypes } = metaQuery.data.Result;
  const tgVersionOptions = tgVersions.map((version) => ({
    label: version.name,
    id: version.name,
  }));

  const createSpace = (formData: FormData, workgroup_id: string, tg_database_id: string) => {
    const addons = getAddonsEnabledParams();
    createWorkSpaceMutation.mutate(
      {
        workspace_type_name: formData.workspace_type_name,
        tg_database_id: tg_database_id,
        tg_version: formData.tg_version,
        name: formData.name,
        size: 1,
        is_rw: formData.newDB === 'yes',
        enable_auto_start: formData.enable_auto_start,
        auto_stop_minutes: formData.autoSuspend ? Number(formData.auto_stop_minutes) : 0,
        addons: addons,
        workgroup_id: workgroup_id,
      },
      {
        onSuccess: async (data) => {
          navigate(`/groups/${workgroup_id}`, { replace: true });
          queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', workgroup_id], (oldData) => {
            if (!oldData?.Result || !data.Result) {
              return oldData;
            }
            let newData = {
              ...oldData,
              Result: {
                ...oldData.Result,
                workspaces: oldData.Result.workspaces.concat(data.Result),
              },
            };
            return newData;
          });

          queryClient.invalidateQueries(['group', workgroup_id]);

          showToast({
            kind: 'positive',
            message: 'Request has been accepted',
          });

          completeOnboardingTask(OnboardingTaskName.createWorkspace);
        },
        onError: (error) => {
          showToast({
            kind: 'negative',
            message: getErrorMessage(error),
          });
        },
      }
    );
  };

  const onSubmit = async (formData: FormData) => {
    let group_id = workgroup_id;
    if (createGroupRequest) {
      try {
        const groupData = await createGroupMutation.mutateAsync(createGroupRequest, {
          onSuccess: async (data) => {
            queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', data.Result?.workgroup_id], () => {
              return {
                ...data,
                Result: {
                  ...data.Result!,
                  // server api return null, we want empty array
                  workspaces: [],
                  tg_databases: [],
                },
              };
            });

            queryClient.invalidateQueries({
              queryKey: ['groups'],
            });
            showToast({
              kind: 'positive',
              message: 'Successfully created the workgroup.',
            });
            setTimeout(() => {
              navigate(`/groups/${data.Result?.workgroup_id!}`, {
                replace: true,
                state: {
                  skipEmptyState: true,
                },
              });
            }, 100);
          },
          onError: (error) => {
            showToast({
              kind: 'negative',
              message: getErrorMessage(error),
            });
          },
        });
        group_id = groupData.Result?.workgroup_id!;
      } catch (error) {
        console.warn('create group error', error);
        return;
      }
    }

    let database_id = formData.database_id;
    if (formData.newDB === 'yes') {
      try {
        const workspaceData = await createDatabaseMutation.mutateAsync(
          {
            name: formData.newDBName,
            tg_version: formData.tg_version,
            workgroup_id: group_id!,
          },
          {
            onSuccess: async (data) => {
              trackUtil.trackCreateWorkspace({ status: EventStatus.Success });

              queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', workgroup_id], (oldData) => {
                if (!oldData?.Result || !data.Result) {
                  return oldData;
                }
                let newData = {
                  ...oldData,
                  Result: {
                    ...oldData.Result,
                    tg_databases: oldData.Result.tg_databases.concat(data.Result),
                  },
                };
                return newData;
              });
            },
            onError: (error) => {
              trackUtil.trackCreateWorkspace({ status: EventStatus.Fail });
              showToast({
                kind: 'negative',
                message: getErrorMessage(error),
              });
            },
          }
        );

        database_id = workspaceData.Result?.database_id!;
      } catch (error) {
        console.warn('create database error', error);
        return;
      }
    }

    createSpace(formData, group_id!, database_id);
  };

  return (
    <Form
      onSubmit={handleSubmit(async (formData) => {
        onSubmit(formData);
      })}
    >
      <FormContentContainer>
        <FormContent>
          <h1 className={css({ ...theme.typography.HeadingMedium })}>Create Workspace</h1>
          <p className={css({ ...theme.typography.Body2, color: '#656565', marginBottom: '8px' })}>
            A workspace is a dedicated environment that provides computing resources and infrastructure for running
            operations on a database.
          </p>
          <FormControllerContainer>
            <div>
              <FormControl label="Workspace Name" error={errors?.name?.message}>
                <Controller
                  rules={{
                    required: 'required',
                  }}
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input placeholder="Enter workspace name" {...field} error={!!errors?.name} autoComplete="off" />
                  )}
                />
              </FormControl>
              <Desc>
                Select a descriptive name that identifies the type of workloads that will be connected to this
                workspace.
              </Desc>
            </div>
            <div>
              <FormControl label="TigerGraph Version">
                <Controller
                  control={control}
                  name="tg_version"
                  render={({ field: { value, onChange, ref, ...field } }) => {
                    return import.meta.env.MODE === 'production' ? (
                      <Select
                        options={tgVersionOptions}
                        value={[{ id: value }]}
                        onChange={(params) => {
                          if (params.value.length > 0) {
                            onChange(params.value[0].id);
                          }
                        }}
                        inputRef={ref}
                        {...field}
                        // disable change version when attached to existing database
                        disabled={newDB === 'no'}
                        clearable={false}
                        deleteRemoves={false}
                        backspaceRemoves={false}
                      />
                    ) : (
                      <Combobox
                        options={tgVersionOptions}
                        value={value}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        inputRef={ref}
                        mapOptionToString={(option) => option.label}
                        {...field}
                        // disable change version when attached to existing database
                        disabled={newDB === 'no'}
                      />
                    );
                  }}
                />
              </FormControl>
              <Desc>
                Select a TigerGraph version that will be used to create the workspace. For details about specific
                versions, see the{' '}
                <a
                  href="https://docs.tigergraph.com/tigergraph-server/current/release-notes/"
                  target="_blank"
                  rel="noreferrer"
                  className={css({
                    color: theme.colors.secondary800,
                  })}
                >
                  release notes
                </a>
                .
              </Desc>
            </div>
            <div>
              <div
                className={css({
                  ...theme.typography.Body1,
                })}
              >
                Workspace Details
              </div>
              <Desc
                $style={{
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              >
                Compute resources consume a fixed number of credits per hour and can be easily scaled up or down to
                enhance or reduce performance according to your specific needs.
              </Desc>
              <div
                className={css({
                  padding: '12px',
                  border: `1px solid ${theme.colors.border}`,
                  borderRadius: '4px',
                  display: 'inline-block',
                })}
              >
                <div
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '0 16px 8px',
                    borderBottom: `1px solid ${theme.colors.border}`,
                    color: theme.colors.secondary800,
                  })}
                >
                  <SpaceIcon />
                  <span
                    className={css({
                      marginRight: 'auto',
                      fontWeight: '700',
                      fontSize: '14px',
                    })}
                  >
                    {workspace_type_name}
                  </span>
                  <IconButton
                    type="button"
                    $style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      padding: '4px 8px',
                      color: 'inherit',
                    }}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <EditIcon width={20} height={20} />
                  </IconButton>
                </div>
                {workspaceType ? (
                  <div
                    className={css({
                      display: 'flex',
                      gap: '32px',
                      padding: '8px',
                      marginTop: '8px',
                    })}
                  >
                    <StyleItem>
                      <span>CPU:</span>
                      <StyleSizeValue>{workspaceType.cpu}</StyleSizeValue>
                    </StyleItem>
                    <StyleItem>
                      <span>MEMORY:</span>
                      <StyleSizeValue>{workspaceType.memory}</StyleSizeValue>
                    </StyleItem>
                    <StyleItem>
                      <span>Cost:</span>
                      <StyleSizeValue>{formatPricePerHour(currency(workspaceType.cost_per_hour / 100))}</StyleSizeValue>
                    </StyleItem>
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={css({
                backgroundColor: '#FEF9C3',
                padding: '8px',
                color: '#CA8A04',
                fontSize: '12px',
                marginTop: '-8px',
                display: 'flex',
                flexDirection: 'column',
                width: 'fit-content',
              })}
            >
              <div
                className={css({
                  fontSize: '14px',
                  fontWeight: 500,
                })}
              >
                Auto suspend is enabled.
              </div>
              <div>
                If there is no activity in the workspace for a certain period of time, we will automatically suspend the
                workspace to conserve resources. You can modify the auto suspend settings later in the workspace
                management settings.
              </div>
            </div>

            <div>
              <h1 className={css({ ...theme.typography.HeadingMedium })}>Database</h1>
              <p className={css({ ...theme.typography.Body2, color: '#656565', marginBottom: '8px' })}>
                When creating a new workspace, you have the option to create a new database or attach to an existing one
                Please note that attaching to an existing database will result in read-only access.
              </p>
              <Controller
                control={control}
                name="newDB"
                render={({ field: { value, onChange, ...field } }) => (
                  <RadioGroup
                    value={value}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      clearErrors('database_id');
                      clearErrors('newDBName');
                      onChange(value);
                    }}
                    align={ALIGN.horizontal}
                    {...field}
                    overrides={{
                      RadioGroupRoot: {
                        style: {
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gap: '16px',
                        },
                      },
                    }}
                  >
                    <Radio value="yes" overrides={radioOverrides}>
                      Create New Database
                    </Radio>
                    <Radio value="no" overrides={radioOverrides}>
                      Attach to Existing Database
                    </Radio>
                  </RadioGroup>
                )}
              />
              <div
                className={css({
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '16px',
                })}
              >
                <FormControl error={errors?.newDBName?.message}>
                  <Controller
                    rules={{
                      validate: (value) => {
                        if (!value && newDB === 'yes') return 'required when new database';
                        return true;
                      },
                    }}
                    control={control}
                    name="newDBName"
                    render={({ field }) => (
                      <Input
                        placeholder="Enter database name"
                        {...field}
                        error={!!errors?.newDBName}
                        disabled={newDB === 'no'}
                      />
                    )}
                  />
                </FormControl>
                <FormControl error={errors?.database_id?.message}>
                  <Controller
                    control={control}
                    name="database_id"
                    rules={{
                      validate: (value) => {
                        if (!value && newDB === 'no') return 'required when select from existing databases';
                        return true;
                      },
                    }}
                    render={({ field: { value, onChange, ref, ...field } }) => (
                      <Select
                        options={tg_databases}
                        value={[{ database_id: value }]}
                        labelKey="name"
                        valueKey="database_id"
                        onChange={(params) => {
                          if (params.value[0]) {
                            onChange(params.value[0].database_id);
                          }
                        }}
                        clearable={false}
                        inputRef={ref}
                        {...field}
                        disabled={newDB === 'yes'}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <WorkspaceAddonSelect
              disableCopilot={isCreatingROWorkspace}
              onChange={(val) => setAddonsParams((p) => ({ ...p, ...val }))}
            />
            {/* <WorkspaceSolutionSelect /> */}
          </FormControllerContainer>
        </FormContent>
      </FormContentContainer>
      {workspaceType ? (
        <PriceCalculator
          computePrice={currency(workspaceType.cost_per_hour).divide(100)}
          storagePrice={currency(newDB === 'yes' ? 0.3 : 0)}
          addOnPrice={addonsPrice.divide(100)}
          isLoading={
            createGroupMutation.isLoading || createWorkSpaceMutation.isLoading || createDatabaseMutation.isLoading
          }
          cancelLabel={createGroupRequest ? 'Back' : 'Cancel'}
          confirmLabel="Create"
        />
      ) : null}

      {isOpen ? (
        <WorkSpaceSubForm
          isOpen={isOpen}
          forceSuspend={true}
          onClose={() => setIsOpen(false)}
          workspaceTypes={workspaceTypes}
          subFormData={{
            workspace_type_name,
            autoScale,
            minCluster,
            maxCluster,
            autoSuspend,
            auto_stop_minutes,
            enable_auto_start,
          }}
          onSubFormDataChanged={(data) => {
            const {
              workspace_type_name,
              autoScale,
              minCluster,
              maxCluster,
              autoSuspend,
              auto_stop_minutes,
              enable_auto_start,
            } = data;
            setValue('workspace_type_name', workspace_type_name);
            setValue('autoScale', autoScale);
            setValue('minCluster', minCluster);
            setValue('maxCluster', maxCluster);
            setValue('autoSuspend', autoSuspend);
            setValue('auto_stop_minutes', auto_stop_minutes);
            setValue('enable_auto_start', enable_auto_start);
          }}
        />
      ) : null}
    </Form>
  );
}

const StyleItem = styled('div', ({ $theme }) => ({
  ...$theme.typography.Body2,
  fontSize: '14px',
  color: $theme.colors.gray700,
}));

const StyleSizeValue = styled('span', ({ $theme }) => ({
  color: $theme.colors.gray1000,
  marginLeft: '4px',
}));

const radioOverrides: RadioOverrides = {
  Label: {
    style: ({ $theme }: { $theme: CustomTheme }) => ({
      ...$theme.typography.Body2,
      marginLeft: '4px',
    }),
  },
  RadioMarkInner: {
    style: ({ $checked }) => ({
      backgroundColor: '#1976D2',
      width: $checked ? '10px' : '0',
      height: $checked ? '10px' : '0',
    }),
  },
  RadioMarkOuter: {
    style: () => ({
      backgroundColor: 'transparent',
      border: `2px solid #1976D2`,
    }),
  },
};
