import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { StarRating } from 'baseui/rating';
import { Button } from '@tigergraph/app-ui-lib/button';
import { MdOutlineFeedback } from 'react-icons/md';
import { useEffect } from 'react';
import { Select } from '@tigergraph/app-ui-lib/select';
import { FormSection } from '@/components/styled';
import { Option } from 'baseui/select';
import { Textarea } from 'baseui/textarea';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Result } from '@/lib/type';
import { AxiosError } from 'axios';
import { axiosController } from '@/lib/network';
import { showToast } from '@/components/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils';
import { useUserGuideContext } from '@/components/userguide/userGuideContext';
import { OnboardingTaskName } from '@/components/userguide/utils';
import { IFormInput, copilotFormFields, normalFormFields } from '@/pages/home/feedback/formData';
import { Input } from '@tigergraph/app-ui-lib/input';
import { FormSectionTitle } from '@/pages/home/feedback/styled';

interface FeedbackDrawerProps {
  isShow: boolean;
  onClose: () => void;
}

const featureOptions: Option[] = [
  {
    id: 'Workspace',
    label: 'Workspace',
    path: /\/groups/,
  },
  {
    id: 'Load Data',
    label: 'Load Data',
    path: /\/ingestion/,
  },
  {
    id: 'GSQL Editor',
    label: 'GSQL Editor',
    path: /\/editor/,
  },
  {
    id: 'CoPilot',
    label: 'CoPilot',
    path: /\/explore\?tab=copilot/,
  },
  {
    id: 'Explore Graph',
    label: 'Explore Graph',
    path: /\/explore/,
  },
  {
    id: 'Solutions',
    label: 'Solutions',
    path: /\/solutions/,
  },
  {
    id: 'Addons',
    label: 'Addons',
    path: /\/addons/,
  },
  {
    id: 'Admin',
    label: 'Admin',
    path: /\/admin/,
  },
];

type AddFeedbackRequst = {
  category: string;
  rowData: string[];
};

function useMutationFeedback() {
  const queryClient = useQueryClient();
  return useMutation<Result<void>, AxiosError, AddFeedbackRequst>(async (data) => {
    const res = await axiosController.post('/v2/feedback', data);
    return res.data;
  });
}

export default function FeedbackDrawer({ isShow, onClose }: FeedbackDrawerProps) {
  const [css] = useStyletron();
  const { completeOnboardingTask } = useUserGuideContext();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IFormInput>({
    defaultValues: [...normalFormFields, ...copilotFormFields].reduce((acc, formField) => {
      acc[formField.name] = formField.defaultValue || '';
      return acc;
    }, {} as IFormInput),
  });

  const category = watch('category');
  const formFields = category !== 'CoPilot' ? normalFormFields : copilotFormFields;

  useEffect(() => {
    if (isShow) {
      reset();
    }
    const path = window.location.href;
    const matchedFeature = featureOptions.find((option) => option.path.test(path));
    if (matchedFeature) {
      setValue('category', matchedFeature.id as string);
    } else {
      setValue('category', featureOptions[0].id as string);
    }
  }, [isShow, setValue, reset]);

  const { mutate, isLoading } = useMutationFeedback();
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const rowData = formFields.map((formField) => {
      return data[formField.name];
    });
    if (data.category !== 'CoPilot') {
      rowData.unshift(data.category);
    }
    const addFeedbackRequest = {
      category: data.category,
      rowData,
    };
    mutate(addFeedbackRequest, {
      onError: (error) => {
        showToast({
          kind: 'negative',
          message: getErrorMessage(error),
        });
      },
      onSuccess: () => {
        showToast({
          kind: 'positive',
          message: 'Feedback submitted successfully',
        });
        completeOnboardingTask(OnboardingTaskName.giveFeedback);
        onClose();
      },
    });
  };

  return (
    <Drawer isOpen={isShow} onClose={onClose} size="auto">
      <DrawerHeader>
        <div className={css({ display: 'flex', alignItems: 'center' })}>
          <MdOutlineFeedback size={24} /> <span className={css({ marginLeft: '8px' })}>Feedback</span>
        </div>
      </DrawerHeader>
      <DrawerBody>
        <form>
          <FormSection>
            <FormSectionTitle>Which features would you submit feedback on?</FormSectionTitle>
            <Controller
              control={control}
              name="category"
              render={({ field: { value, onChange, ref, ...field } }) => (
                <Select
                  options={featureOptions}
                  onChange={({ value }) => onChange(value[0].id)}
                  value={value ? [{ id: value }] : []}
                  clearable={false}
                  inputRef={ref}
                  {...field}
                />
              )}
            />
          </FormSection>
          {formFields.map((formField) => (
            <FormSection key={formField.name}>
              <FormSectionTitle>{formField.question}</FormSectionTitle>
              {formField.inputType === 'rating' ? (
                <Controller
                  control={control}
                  name={formField.name}
                  rules={formField.required ? { required: 'This field is required.' } : {}}
                  render={({ field: { onChange, ref, value, ...field } }) => (
                    <StarRating
                      numItems={5}
                      value={Number(value)}
                      onChange={(data) => onChange(`${data.value}`)}
                      size={22}
                      {...field}
                    />
                  )}
                />
              ) : formField.inputType === 'select' ? (
                <Controller
                  control={control}
                  name={formField.name}
                  rules={formField.required ? { required: 'This field is required.' } : {}}
                  render={({ field: { value, onChange, ref, ...field } }) => (
                    <Select
                      options={formField.selectOptions}
                      onChange={({ value }) => onChange(value[0].id)}
                      value={value ? [{ id: value }] : []}
                      clearable={false}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
              ) : formField.inputType === 'input' ? (
                <Controller
                  control={control}
                  name={formField.name}
                  rules={formField.required ? { required: 'This field is required' } : {}}
                  render={({ field: { ref, ...field } }) => <Input {...field} inputRef={ref} size="compact" />}
                />
              ) : formField.inputType === 'textarea' ? (
                <Controller
                  control={control}
                  name={formField.name}
                  rules={formField.required ? { required: 'This field is required' } : {}}
                  render={({ field: { ref, ...field } }) => <Textarea {...field} autoComplete="off" size="compact" />}
                />
              ) : null}
              {errors[formField.name] && (
                <div className={css({ color: 'rgb(223, 27, 65)', marginTop: '8px' })}>
                  {errors[formField.name]!.message}
                </div>
              )}
            </FormSection>
          ))}
        </form>
      </DrawerBody>
      <DrawerAction>
        <Button kind="tertiary" onClick={onClose} overrides={{ BaseButton: { style: { marginRight: '16px' } } }}>
          Cancel
        </Button>
        <Button isLoading={isLoading} onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </DrawerAction>
    </Drawer>
  );
}
