import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { WorkspaceT } from '@/pages/workgroup/type';
import { axiosCluster } from '@/lib/network';
import { useUserContext } from '@/contexts/userContext';

interface WorkspaceContextValue {
  currentWorkspace: WorkspaceT;
  setCurrentWorkspace(workspace: WorkspaceT): void;
}

const workspaceContext = createContext<WorkspaceContextValue>({} as WorkspaceContextValue);

const useWorkspaceContext = () => {
  const context = useContext(workspaceContext);
  return context;
};

const WorkspaceProvider: FC<PropsWithChildren> = (props) => {
  const [currentWorkspace, setCurrentWorkspace] = useState<WorkspaceT>({} as WorkspaceT);
  const { getUserGraph } = useUserContext();

  const setWorkspace = useCallback(
    (workspace: WorkspaceT) => {
      if (workspace?.nginx_host) {
        axiosCluster.defaults.baseURL = `https://${workspace?.nginx_host}`;
        setCurrentWorkspace(workspace);
        getUserGraph();
      }
    },
    [setCurrentWorkspace, getUserGraph]
  );

  const WorkspaceContextValue: WorkspaceContextValue = useMemo(() => {
    return {
      currentWorkspace,
      setCurrentWorkspace: setWorkspace,
    };
  }, [setWorkspace, currentWorkspace]);

  return <workspaceContext.Provider value={WorkspaceContextValue} {...props} />;
};

export { WorkspaceProvider, useWorkspaceContext };
