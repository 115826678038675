import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { Table } from '@tigergraph/app-ui-lib/table';
import ProvideDatabaseIcon from '@/assets/provide-database.svg?react';
import { Button } from '@tigergraph/app-ui-lib/button';
import { LoadingIndicator } from '@/components/loading-indicator';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { WorkspaceT } from '@/pages/workgroup/type';
import { useListQueries } from '@/pages/workgroup/tab/ConnectionMenu/ConnectionMenuAddons/hooks/useListQueries';
import { isEmpty } from 'lodash-es';
import { useListRegisteredQueries } from '@/pages/workgroup/tab/ConnectionMenu/ConnectionMenuAddons/hooks/useListRegisteredQueries';
import {
  useMutateDeleteRegisterQueries,
  useMutateUpsertRegisterQueries,
} from '@/pages/workgroup/tab/ConnectionMenu/ConnectionMenuAddons/hooks/uesMutateCopilotQueries';
import { Modal, ModalButton, ModalFooter } from '@tigergraph/app-ui-lib/modal';
import { ModalHeader } from 'baseui/modal';
import { GraphSelector } from '@/pages/explore/explore/GraphSelector';
import { useListGraphs } from '@/hooks/useListGraphs';
import { ErrorDisplay } from '@/components/error';
import { isWorkspaceCopilotEnable } from '@/utils/addons/copilot';

const queryNamesToSet = (queryNames: string[]) => new Set(queryNames);

const QueriesTable: FC<{ workspace: WorkspaceT }> = ({ workspace }) => {
  const [selectedGraph, setSelectedGraph] = useState<string>();
  const {
    data: queries,
    isFetching: isLoadingQueries,
    refetch: refetchQueries,
  } = useListQueries(workspace.nginx_host, selectedGraph);
  const {
    data: registeredQueriesMap,
    isFetching: isLoadingRegisterQueries,
    refetch: refetchRegisteredQueries,
  } = useListRegisteredQueries(workspace.nginx_host, selectedGraph, {
    select: queryNamesToSet,
  });
  const [error, setError] = useState<Error>();
  const [modalVisible, setModalVisible] = useState(false);
  const changingGraph = useRef<string>();
  const { data: graphs = [], isLoading: isLoadingGraphs } = useListGraphs(workspace.nginx_host);
  const [queriesMutateMap, setQueriesMutateMap] = useState<Record<string, boolean>>({});
  const { mutateAsync: upsertRegisteredQueries, isLoading: isUpsertRegisteredQueries } =
    useMutateUpsertRegisterQueries();
  const { mutateAsync: deleteRegisteredQueries, isLoading: isDeleteRegisteredQueries } =
    useMutateDeleteRegisterQueries();
  const isWorkspaceCopilotEnabled = isWorkspaceCopilotEnable(workspace);

  const isAllChecked = useMemo(() => {
    if (isEmpty(queriesMutateMap)) {
      return false;
    }
    return Object.values(queriesMutateMap).every(Boolean);
  }, [queriesMutateMap]);

  const isIndeterminate = useMemo(() => {
    if (isEmpty(queriesMutateMap)) {
      return false;
    }
    const m = new Set(Object.values(queriesMutateMap));

    return m.size >= 2;
  }, [queriesMutateMap]);

  const needUpsertQueries = useMemo(() => {
    return Object.entries(queriesMutateMap)
      .filter(([queryName, needRegister]) => needRegister && !registeredQueriesMap?.has(queryName))
      .map(([queryName]) => queryName);
  }, [queriesMutateMap, registeredQueriesMap]);

  const needDeleteQueries = useMemo(() => {
    return Object.entries(queriesMutateMap)
      .filter(([queryName, needReigster]) => !needReigster && registeredQueriesMap?.has(queryName))
      .map(([queryName]) => queryName);
  }, [queriesMutateMap, registeredQueriesMap]);

  const isDirty = needUpsertQueries.length > 0 || needDeleteQueries.length > 0;
  const disableRegister = useMemo(() => {
    if (!isWorkspaceCopilotEnabled) {
      // if copilot haven't enabled copilot cannot register
      return true;
    }
    return false;
  }, [isWorkspaceCopilotEnabled]);

  const isLoading = isLoadingQueries || isLoadingRegisterQueries;
  const isRegistering = isUpsertRegisteredQueries || isDeleteRegisteredQueries;

  // generate new queries based on all queries changed (graph change)
  useEffect(() => {
    if (!registeredQueriesMap || !queries) {
      return;
    }
    const newQueriesMap =
      queries?.reduce((result, { queryName }) => {
        result[queryName] = registeredQueriesMap?.has(queryName) || false;
        return result;
      }, {} as Record<string, boolean>) || {};
    setQueriesMutateMap(newQueriesMap);
    setError(undefined);
  }, [queries, registeredQueriesMap]);

  // select first graph after initializing
  useEffect(() => {
    if (selectedGraph || !graphs.length) {
      return;
    }
    setSelectedGraph(graphs[0]);
  }, [selectedGraph, graphs]);

  const handleGraphChanged = (graphName: string) => {
    if (isDirty) {
      changingGraph.current = graphName;
      setModalVisible(true);
    } else {
      setSelectedGraph(graphName);
    }
  };

  const handleQueryChecked = (queryName: string, checked: boolean) => {
    setQueriesMutateMap((v) => ({ ...v, [queryName]: checked }));
  };

  const handleAllQueriesChecked = () => {
    let checked = true;
    if (isAllChecked) {
      checked = false;
    }
    const newQueriesMap =
      queries?.reduce((result, { queryName }) => {
        result[queryName] = checked;
        return result;
      }, {} as Record<string, boolean>) || {};
    setQueriesMutateMap(newQueriesMap);
  };

  const handleRegister = async () => {
    const common = {
      graphName: selectedGraph as string,
      nginxHost: workspace.nginx_host,
    };
    const deleteQueries = [] as string[];
    const upsertQueries = [] as string[];
    Object.entries(queriesMutateMap).forEach(([queryName, checked]) => {
      if (checked) {
        upsertQueries.push(queryName);
      } else {
        deleteQueries.push(queryName);
      }
    });
    try {
      await Promise.all(
        [
          deleteQueries.length > 0 &&
            deleteRegisteredQueries({
              ...common,
              queries: deleteQueries,
            }),
          upsertQueries.length > 0 &&
            upsertRegisteredQueries({
              ...common,
              queries: upsertQueries,
            }),
        ].filter(Boolean)
      );
      await Promise.all([refetchQueries(), refetchRegisteredQueries()]);
      setError(undefined);
    } catch (e) {
      setError(e instanceof Error ? e : new Error('register error'));
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="font-semibold leading-4 text-sm text-[#3F5870]">Register Queries</h3>
      {error && <ErrorDisplay error={error} />}
      <GraphSelector
        graphs={graphs}
        graph={selectedGraph || ''}
        isFetching={isLoadingGraphs}
        onGraphChanged={handleGraphChanged}
      />
      <div className="h-[300px] overflow-y-auto [&_td]:align-middle [&_th]:align-middle">
        <Table
          columns={[
            <Checkbox
              key="check"
              checked={isAllChecked}
              disabled={isRegistering}
              isIndeterminate={isIndeterminate}
              onChange={handleAllQueriesChecked}
              overrides={{
                Checkmark: {
                  style: {
                    ':hover': {
                      backgroundColor: '#3F5870',
                    },
                    background: isIndeterminate || isAllChecked ? '#3F5870' : undefined,
                  },
                },
              }}
            />,
            'Queries',
            'Description',
          ]}
          data={
            queries?.map((q, idx) => [
              <Checkbox
                key={idx}
                checked={queriesMutateMap[q.queryName]}
                disabled={isRegistering}
                overrides={{
                  Checkmark: {
                    style: {
                      ':hover': {
                        backgroundColor: '#3F5870',
                      },
                      backgroundColor: queriesMutateMap[q.queryName] ? '#3F5870' : undefined,
                    },
                  },
                }}
                onChange={(e) => handleQueryChecked(q.queryName, (e.target as HTMLInputElement).checked)}
              />,
              <div key={idx + 1} className="text-xs">
                {q.queryName}
              </div>,
              <div key={idx + 2} className="text-xs">
                {q.description}
              </div>,
            ]) || []
          }
          isLoading={isLoading}
          loadingMessage={<LoadingIndicator />}
          emptyMessage={
            <div className="mt-8 text-center">
              <ProvideDatabaseIcon className="m-auto [&_path]:stroke-[#94A2AF] [&_path]:stroke-[4px]" />
              <p className="mt-2 mb-1 text-[#656565] text-sm leading-4">No Query Descriptions</p>
            </div>
          }
        />
      </div>
      {!isLoadingQueries && Number(queries?.length) > 0 && (
        <div className="text-right">
          <Button
            kind="tertiary"
            size="compact"
            disabled={disableRegister}
            onClick={handleRegister}
            isLoading={isRegistering}
          >
            Register
          </Button>
        </div>
      )}
      <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
        <ModalHeader>Are you sure to switch graph without mutating registered queries?</ModalHeader>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={() => setModalVisible(false)}>
            Cancel
          </ModalButton>
          <ModalButton
            onClick={() => {
              setSelectedGraph(changingGraph.current);
              setModalVisible(false);
            }}
          >
            Confirm
          </ModalButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QueriesTable;
