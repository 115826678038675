import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { KIND, SHAPE, SIZE } from 'baseui/button';
import { Plus } from 'lucide-react';
import DbUserEmpty from '@/assets/dbuser-empty.png';
import { Body2, Label } from '@tigergraph/app-ui-lib/typography';
import React from 'react';

export function AddHeader({
  onAdd,
  label,
  disabled,
}: {
  onAdd: () => void;
  label: React.ReactNode;
  disabled?: boolean;
}) {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
      })}
    >
      <span
        className={css({
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#294560',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        })}
      >
        {label}
      </span>
      <Button kind={KIND.tertiary} shape={SHAPE.square} size={SIZE.compact} onClick={onAdd} disabled={disabled}>
        <Plus size={16} />
      </Button>
    </div>
  );
}

export function Empty({ title, description }: { title: string; description: string }) {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '32px',
        marginBottom: '32px',
      })}
    >
      <img
        src={DbUserEmpty}
        alt="empty"
        className={css({
          width: '47px',
          height: '57px',
        })}
      />
      <Body2
        $style={{
          marginTop: '8px',
          marginBottom: '4px',
        }}
      >
        {title}
      </Body2>
      <Label
        $style={{
          color: '#909090',
        }}
      >
        {description}
      </Label>
    </div>
  );
}
