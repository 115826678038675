import { useEffect, useMemo, useState } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useUserContext } from '@/contexts/userContext';
import { Select } from '@tigergraph/app-ui-lib/select';
import { GLOBAL_GRAPH_NAME } from '@tigergraph/tools-models';
import { Option } from 'baseui/select';
import { GraphIcon } from '@/pages/home/icons';
import CreateNewGraphModal from './CreateNewGraphModal';
import DropGraphModal from './DropGraphModal';
import { expand } from 'inline-style-expand-shorthand';
import IconButton from '@/components/IconButton';
import { MdDeleteOutline } from 'react-icons/md';

type GraphSelectProps = {
  fromSolution?: boolean;
  graph?: string;
  excludeGlobalGraph?: boolean;
  isLoading?: boolean;
};

export default function GraphSelect(props: GraphSelectProps) {
  const [css, theme] = useStyletron();
  const { fromSolution, graph, excludeGlobalGraph, isLoading } = props;
  const { graphNames, setCurrentGraph } = useUserContext();
  let { currentGraph } = useUserContext();
  if (fromSolution) {
    currentGraph = graph!;
  }

  const CREATE_NEW_GRAPH = 'Create New Graph';

  const [isCreatingGraph, setIsCreatingGraph] = useState(false);
  const [isDeletingGraph, setIsDeletingGraph] = useState(false);
  const [deletingGraphName, setDeletingGraphName] = useState('');

  const curGraphNames = useMemo(() => {
    let names = [...graphNames];
    if (fromSolution) {
      names.push(graph!);
    }
    if (excludeGlobalGraph) {
      return names.filter((name) => name !== GLOBAL_GRAPH_NAME);
    } else {
      return names;
    }
  }, [excludeGlobalGraph, fromSolution, graph, graphNames]);

  useEffect(() => {
    if (curGraphNames.length > 0 && (!currentGraph || (excludeGlobalGraph && currentGraph === GLOBAL_GRAPH_NAME))) {
      setCurrentGraph(curGraphNames[0]);
    }
  }, [curGraphNames, currentGraph, excludeGlobalGraph, setCurrentGraph]);

  const getValueLabel = ({ option, isValue }: { option?: Option; isValue?: boolean }) => {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          justifyContent: isValue ? 'flex-start' : 'space-between',
        })}
      >
        {isValue && (
          <div
            className={css({
              minWidth: '16px',
            })}
          >
            <GraphIcon />
          </div>
        )}
        {option!.label}
        {!isValue && option!.id !== GLOBAL_GRAPH_NAME && (
          <IconButton
            onClick={() => {
              setDeletingGraphName(String(option!.id));
              setIsDeletingGraph(true);
            }}
          >
            <MdDeleteOutline size={16} color={theme.colors.error} />
          </IconButton>
        )}
      </div>
    );
  };

  const getOptionLabel = (p: { option?: Option }) => {
    const { option } = p;
    if (option?.id === CREATE_NEW_GRAPH) {
      return (
        <div
          className={css({
            fontWeight: 4000,
            color: theme.colors.gray900,
          })}
        >
          {option.label}
        </div>
      );
    } else {
      return getValueLabel(p);
    }
  };

  return (
    <div>
      <Select
        size="compact"
        disabled={fromSolution}
        isLoading={isLoading}
        options={
          fromSolution
            ? [{ label: graph, id: graph }]
            : [...curGraphNames, CREATE_NEW_GRAPH].map((name) => ({
                label: name === GLOBAL_GRAPH_NAME ? 'Global' : name,
                id: name,
              }))
        }
        value={
          !curGraphNames.includes(currentGraph)
            ? []
            : [
                {
                  label: currentGraph === GLOBAL_GRAPH_NAME ? 'Global' : currentGraph,
                  id: currentGraph,
                },
              ]
        }
        getValueLabel={({ option }) => getValueLabel({ option, isValue: true })}
        getOptionLabel={getOptionLabel}
        onChange={(params) => {
          if (isDeletingGraph) {
            return;
          }
          const id = params.value[0].id?.toString();
          if (id === CREATE_NEW_GRAPH) {
            setIsCreatingGraph(true);
          } else {
            setCurrentGraph(params.value[0].id?.toString());
          }
        }}
        placeholder="Select a graph"
        clearable={false}
        searchable={false}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: {
                    zIndex: 1,
                  },
                },
              },
            },
          },
          ControlContainer: {
            style: {
              ...expand({
                borderRadius: '2px',
              }),
            },
          },
          DropdownListItem: {
            style: {
              ':last-child': {
                borderTop: `1px solid ${theme.colors.gray200}`,
              },
              ...expand({
                padding: '0',
              }),
            },
          },
          OptionContent: {
            style: {
              ':last-child > :last-child > :last-child': {
                visibility: 'hidden',
              },
              ':hover > :last-child > :last-child > :last-child': {
                visibility: 'visible',
              },
              ...expand({
                padding: '8px',
              }),
            },
          },
        }}
      />
      <CreateNewGraphModal isOpen={isCreatingGraph} onClose={() => setIsCreatingGraph(false)} />
      <DropGraphModal
        isOpen={isDeletingGraph}
        graphName={deletingGraphName}
        onClose={() => {
          setIsDeletingGraph(false);
          setDeletingGraphName('');
        }}
      />
    </div>
  );
}
