import { styled } from '@tigergraph/app-ui-lib/Theme';

const ParagraphLabel = styled('span', ({ $theme }) => {
  return {
    color: '#57686E',
    fontSize: '12px',
    lineHeight: '16px',
  };
});

export default ParagraphLabel;
