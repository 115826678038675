import { RadioOverrides } from 'baseui/radio';
import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';

export const radioOverrides: RadioOverrides = {
  Root: {
    style: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  Label: {
    style: ({ $checked, $theme }: { $checked: boolean; $theme: CustomTheme }) => {
      return {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '3px',
        paddingBottom: '3px',
        borderColor: `${$theme.colors.gray400}`,
        borderLeftWidth: '1px',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderRightWidth: '0px',
        ...$theme.typography.Label,
        color: $checked ? $theme.colors.secondary900 : $theme.colors.gray900,
        // fontWeight: $checked ? '600' : '400',
        backgroundColor: $checked ? $theme.colors.secondary200 : '#fff',
      };
    },
  },
  RadioMarkInner: {
    style: {
      display: 'none',
    },
  },
  RadioMarkOuter: {
    style: {
      display: 'none',
    },
  },
};

export const leftRadioOverrides = mergeOverrides(radioOverrides as Overrides<any>, {
  Label: {
    style: {
      borderRadius: '4px 0px 0px 4px',
    },
  },
});

export const rightRadioOverrides = mergeOverrides(radioOverrides as Overrides<any>, {
  Label: {
    style: {
      borderRadius: '0px 4px 4px 0px',
      borderRightWidth: '1px',
    },
  },
});
