import IconButton from '@/components/IconButton';
import { HelpIcon } from '@/pages/workgroup/icon';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import React from 'react';

export default function TooltipLabel({ label, tooltip }: { label: string; tooltip?: React.ReactNode }) {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '16px',
        color: '#294560',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      })}
    >
      <span>{label}</span>
      {tooltip && (
        <StatefulPopover triggerType={TRIGGER_TYPE.hover} content={tooltip} placement={PLACEMENT.bottom}>
          <IconButton type="button" tabIndex={-1}>
            <HelpIcon />
          </IconButton>
        </StatefulPopover>
      )}
    </div>
  );
}
