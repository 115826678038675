import ShortCut from '../../assets/schema-designer-short-key.png';
import Empty from '../../assets/empty-schema.svg';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
export function EmptySchema({ isLoading }: { isLoading?: boolean }) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        textAlign: 'center',
      })}
    >
      {isLoading ? (
        <img
          src={Empty}
          style={{
            display: 'inline-block',
            width: '596px',
          }}
        />
      ) : (
        <img
          src={ShortCut}
          style={{
            display: 'inline-block',
            width: '596px',
          }}
        />
      )}
    </div>
  );
}
