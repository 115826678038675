import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useOrgContext } from '@/contexts/orgContext';
import { HeadingMenuSelected } from '@tigergraph/app-ui-lib/typography';
import { ParagraphXSmall } from 'baseui/typography';
import { Input } from '@tigergraph/app-ui-lib/input';
import { useEffect, useRef, useState } from 'react';
import { Button } from '@tigergraph/app-ui-lib/button';
import { axiosOrgService } from '@/lib/network';
import { useMutation } from 'react-query';
import { BaseSkeleton } from '@/components/BaseSkeleton';
import Logo from '@/pages/home/logo.svg';
import invalidLogo from '@/pages/home/invalid-logo.svg';
import { showToast } from '@/components/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils.ts';
import { AxiosError } from 'axios';
import { TableContainer } from '@/lib/styled.tsx';

export default function OrgSettings() {
  const [css, theme] = useStyletron();
  const [imgUrl, setImgUrl] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const displayImgRef = useRef<HTMLImageElement>(null);
  const orgNameRef = useRef<HTMLDivElement>(null);
  const { currentOrg, setCurrentOrg } = useOrgContext();
  const [displayName, setDisplayName] = useState('');
  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    if (currentOrg.org_id) {
      setDisplayName(currentOrg?.display_name || '');
      setOrgName(currentOrg.org_name);
      setImgUrl(currentOrg.logo_url || '');
    }
  }, [currentOrg]);

  async function updateOrg() {
    const response = await axiosOrgService.put(`/orgs/${currentOrg.org_id}`, {
      org_name: orgName,
      display_name: displayName,
      logo_url: imgUrl,
    });
    return response.data;
  }

  const updateOrgMutation = useMutation(updateOrg, {
    onSuccess: (data) => {
      showToast({
        kind: 'positive',
        message: 'Update organization successfully.',
      });
      setCurrentOrg({ ...currentOrg, ...data });
    },
    onError: (error: AxiosError) => {
      showToast({
        kind: 'negative',
        message: getErrorMessage(error),
      });
    },
  });

  return (
    <TableContainer>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        })}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            maxWidth: '547px',
          })}
        >
          <div>
            <HeadingMenuSelected>Basic Information</HeadingMenuSelected>
            <ParagraphXSmall>These are basic details needed to set up your new organization.</ParagraphXSmall>
          </div>

          <div>
            <ParagraphXSmall>Organization Name</ParagraphXSmall>
            <Input
              value={orgName}
              onChange={(e) => setOrgName(e.currentTarget.value)}
              overrides={{
                Root: {
                  style: {
                    maxWidth: '100%',
                  },
                },
              }}
            />
            <ParagraphXSmall
              className={css({
                color: `${theme.colors.black03}`,
              })}
            >
              This is a unique organization identifier on TigerGraph Cloud.
            </ParagraphXSmall>
          </div>

          <div>
            <ParagraphXSmall>Display Name</ParagraphXSmall>
            <Input
              value={displayName}
              onChange={(e) => setDisplayName(e.currentTarget.value)}
              overrides={{
                Root: {
                  style: {
                    maxWidth: '100%',
                  },
                },
              }}
            />
            <ParagraphXSmall
              className={css({
                color: `${theme.colors.black03}`,
              })}
            >
              If set, this name will be displayed to users for your organization.
            </ParagraphXSmall>
          </div>
        </div>

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          })}
        >
          <div>
            <HeadingMenuSelected>Branding</HeadingMenuSelected>
            <ParagraphXSmall>These are branding settings associated with your organization.</ParagraphXSmall>
          </div>

          <div
            className={css({
              display: 'flex',
            })}
          >
            <div
              className={css({
                borderRight: `1px solid ${theme.colors.gray200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                paddingRight: '48px',
              })}
            >
              <ParagraphXSmall>Organization Logo Url</ParagraphXSmall>
              {currentOrg.org_id ? (
                <div
                  className={css({
                    borderRadius: '4px',
                    border: `1px solid ${theme.colors.gray400}`,
                    background: `${theme.colors.gray50}`,
                    height: '200px',
                    width: '397px',
                    position: 'relative',
                  })}
                >
                  <div
                    className={css({
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: '40%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    })}
                  >
                    <img
                      ref={imgRef}
                      onError={() => {
                        if (imgRef.current && imgUrl !== '') {
                          imgRef.current.src = invalidLogo;
                        }
                      }}
                      src={imgUrl}
                      className={css({
                        maxWidth: '100%',
                        height: '60px',
                        display: 'inline',
                      })}
                    />
                  </div>
                  <Input
                    placeholder="Your logo URL"
                    value={imgUrl}
                    onChange={(e) => {
                      setImgUrl(e.currentTarget.value);
                      if (e.currentTarget.value !== '') {
                        if (displayImgRef.current) {
                          displayImgRef.current.style.visibility = 'visible';
                          displayImgRef.current.style.height = '60px';
                          displayImgRef.current.style.maxWidth = '100%';
                        }
                        if (orgNameRef.current) {
                          orgNameRef.current.style.visibility = 'hidden';
                        }
                      } else {
                        if (orgNameRef.current) {
                          orgNameRef.current.style.visibility = 'visible';
                        }
                      }
                    }}
                    overrides={{
                      Root: {
                        style: {
                          position: 'absolute',
                          bottom: '0',
                          borderBottomWidth: '0',
                          borderLeftWidth: '0',
                          borderRightWidth: '0',
                          borderTopRightRadius: '0',
                          borderTopLeftRadius: '0',
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <div>
                  <BaseSkeleton height="200px" width="397px" />
                </div>
              )}
            </div>
            <div
              className={css({
                paddingLeft: '48px',
                position: 'relative',
                width: '397px',
              })}
            >
              <ParagraphXSmall>Display Logo</ParagraphXSmall>
              <div
                className={css({
                  display: 'flex',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  alignItems: 'center',
                })}
              >
                {currentOrg.org_id ? (
                  <>
                    <img src={Logo} className={css({ maxWidth: '100%', height: '60px' })} />
                    <div
                      className={css({
                        minWidth: '1px',
                        height: '40px',
                        background: `${theme.colors.gray200}`,
                        margin: '0 8px',
                      })}
                    ></div>
                    {imgUrl && (
                      <img
                        ref={displayImgRef}
                        onError={() => {
                          if (displayImgRef.current) {
                            displayImgRef.current.style.visibility = 'hidden';
                            displayImgRef.current.style.height = '0px';
                            displayImgRef.current.style.maxWidth = '0px';
                          }
                          if (orgNameRef.current) {
                            orgNameRef.current.style.visibility = 'visible';
                          }
                        }}
                        src={imgUrl}
                        className={css({ maxWidth: '100%', height: '60px' })}
                      />
                    )}
                    <div
                      ref={orgNameRef}
                      className={css({
                        maxWidth: '120px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontSize: '14px',
                        visibility: 'hidden',
                      })}
                    >
                      {displayName}
                    </div>
                  </>
                ) : (
                  <BaseSkeleton height="60px" width="200px" />
                )}
              </div>
            </div>
          </div>

          <ParagraphXSmall>
            If set, this logo will be what the users for your organization see and use to recognize the organization.
          </ParagraphXSmall>
        </div>

        <div>
          <Button
            disabled={updateOrgMutation.isLoading || !orgName.trim()}
            isLoading={updateOrgMutation.isLoading}
            onClick={() => {
              updateOrgMutation.mutate();
            }}
            overrides={{
              BaseButton: {
                style: {
                  marginBottom: '8px',
                },
              },
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </TableContainer>
  );
}
