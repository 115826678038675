import { BasicSetupOptions } from '@uiw/react-codemirror';

export enum ApiType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const colorMap = {
  [ApiType.GET]: '#63AEFB',
  [ApiType.POST]: '#4BCB91',
  [ApiType.PUT]: '#4BCB91',
  [ApiType.DELETE]: '#F84545',
};

export const backgroundColorMap = {
  [ApiType.GET]: '#EFF7FF',
  [ApiType.POST]: '#ECFAF4',
  [ApiType.PUT]: '#ECFAF4',
  [ApiType.DELETE]: '#FEEBEB',
};

export const cmOptions: BasicSetupOptions = {
  lineNumbers: false,
  foldGutter: false,
  highlightActiveLine: false,
};

export interface Parameter {
  name: string;
  type: string;
  default?: string;
}
