import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { Tabs as BaseTabs } from '@tigergraph/app-ui-lib/tab';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';
import { Tab, TabOverrides, TabsOverrides, TabsProps } from 'baseui/tabs-motion';

export { Tab } from 'baseui/tabs-motion';

const tabsOverrides: TabsOverrides = {
  TabBorder: {
    style: ({ $theme }) => {
      const customTheme = $theme as CustomTheme;
      return {
        height: '1px',
        backgroundColor: customTheme.colors.gray200,
      };
    },
  },
  TabHighlight: {
    style: () => ({
      backgroundColor: '#FB9A44',
      height: '3px',
      bottom: '3px',
      borderRadius: '2px',
    }),
  },
};

const tabOverrides: TabOverrides = {
  Tab: {
    style: ({ $isActive, $theme }) => {
      const customTheme = $theme as CustomTheme;
      return {
        paddingLeft: '24px',
        paddingTop: '4px',
        paddingRight: '24px',
        paddingBottom: '4px',
        color: $isActive ? customTheme.colors.gray1000 : customTheme.colors.gray900,
        background: 'transparent',
        outline: 'none',
        outlineOffset: '-5px',
        ':focus-visible': {
          outline: `3px solid ${customTheme.colors.outline}`,
        },
        maxHeight: '40px',
        textWrap: 'nowrap',
        fontFamily: 'Urbanist',
        fontWeight: $isActive ? '500' : 'auto',
        fontSize: '16px',
      };
    },
  },
  TabPanel: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
      paddingTop: '16px',
      minHeight: 'calc(100vh - 57px)', // Subtract the top and bottom padding
      backgroundColor: '#f8f8f8',
      boxSizing: 'border-box', // Include padding and border in element's total height and width
      // todo(lin): remove the following line when demo is done
      overflow: 'hidden',
    },
  },
};

// customize tab globally:  https://github.com/uber/baseweb/issues/3548
export function Tabs({ overrides, children, ...props }: TabsProps) {
  // we should not use Children.map method here, as it will break Tab key and init tab selection.
  // https://www.smashingmagazine.com/2021/08/react-children-iteration-methods/
  if (!Array.isArray(children)) {
    children = [children];
  }

  let tabs = (children as any[]).map((child) => {
    if (!child) {
      return;
    }
    let { overrides, ...props } = child.props;
    return (
      <Tab
        {...props}
        key={child.key}
        overrides={mergeOverrides(tabOverrides as Overrides<any>, overrides as Overrides<any>)}
      />
    );
  });

  return (
    <BaseTabs {...props} overrides={mergeOverrides(tabsOverrides as Overrides<any>, overrides as Overrides<any>)}>
      {tabs}
    </BaseTabs>
  );
}
