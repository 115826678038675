import { completeOnboardingTaskReq, getOnboardingTasksReq, OnboardingTask } from '@/components/userguide/utils';
import { useOrgContext } from '@/contexts/orgContext';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

interface UserGuideContextValue {
  onboardingTasks: OnboardingTask[];
  isFetching: boolean;
  completeOnboardingTask: (name: string) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
}

const UserGuideContext = createContext<UserGuideContextValue>({} as UserGuideContextValue);

const useUserGuideContext = () => {
  const context = useContext(UserGuideContext);
  return context;
};

const UserGuideProvider: FC<PropsWithChildren> = (props) => {
  const queryClient = useQueryClient();
  const { data: onboardingTasks = [], isFetching } = useQuery('onboarding-tasks', getOnboardingTasksReq);

  const completeOnboardingTask = useCallback(
    async (name: string) => {
      const task = onboardingTasks.find((task) => task.task_name === name);
      if (task && !task.completed) {
        await completeOnboardingTaskReq(task.id);
        task.completed = true;
        queryClient.setQueryData('onboarding-tasks', [...onboardingTasks]);
        queryClient.invalidateQueries(['user-credits']);
      }
    },
    [onboardingTasks, queryClient]
  );

  const { getLocalStorage } = useOrgContext();
  const [isOpen, setIsOpen] = useState(getLocalStorage('showUserGuide') !== 'false');
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const UserGuideContextValue: UserGuideContextValue = {
    onboardingTasks,
    isFetching,
    completeOnboardingTask,
    isOpen,
    onClose,
  };

  return <UserGuideContext.Provider value={UserGuideContextValue} {...props} />;
};

export { UserGuideProvider, useUserGuideContext };
