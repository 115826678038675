import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useHover from 'ahooks/lib/useHover';
import { Input } from '@tigergraph/app-ui-lib/input';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import IconButton from '@/components/IconButton';
import { PencilIcon } from 'lucide-react';

export type EditableTextHandle = {
  reset: () => void;
};

type EditableTextProps = {
  text: string;
  onTextChange: (text: string) => void;
  compact?: boolean;
  maxWidth?: string;
  disableEdit?: boolean;
};

const EditableText = forwardRef<EditableTextHandle, EditableTextProps>(
  ({ text, onTextChange, compact, maxWidth, disableEdit }, ref) => {
    const nameRef = useRef<HTMLSpanElement | null>(null);

    const isHovering = useHover(nameRef);
    const [isEditing, setIsEditing] = useState(false);
    const [css] = useStyletron();

    const [value, setValue] = useState(text);

    useEffect(() => {
      setValue(text);
    }, [text]);

    const onSubmit = () => {
      setIsEditing(false);

      // do not allow empty string
      if (value.trim() !== '' && value !== text) {
        onTextChange(value);
      } else {
        setValue(text);
      }
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          reset() {
            setValue(text);
          },
        };
      },
      [text]
    );

    return (
      <span
        className={css({
          ...(maxWidth
            ? {
                maxWidth,
              }
            : {
                flexBasis: 0,
                flex: 1,
                minWidth: 0,
              }),
          display: 'inline-flex',
          alignItems: 'center',
        })}
        ref={nameRef}
      >
        {isEditing ? (
          <Input
            autoFocus={true}
            value={value}
            size="compact"
            onBlur={() => {
              onSubmit();
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
            onChange={(e) => setValue(e.currentTarget.value)}
            overrides={{
              Root: {
                style: {
                  width: '200px',
                },
              },
              Input: {
                style: {
                  paddingTop: compact ? '1px' : '6px',
                  paddingBottom: compact ? '1px' : '6px',
                  paddingLeft: compact ? '4px' : '12px',
                  paddingRight: compact ? '4px' : '12px',
                  fontSize: compact ? '14px' : '16px',
                  fontWeight: compact ? 400 : 500,
                  fontFamily: compact ? 'Roboto' : 'Urbanist',
                },
              },
            }}
          />
        ) : (
          <span
            className={css({
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            })}
          >
            {value}
          </span>
        )}
        {!disableEdit ? (
          isHovering && !isEditing ? (
            <IconButton
              $style={{
                padding: '2px',
              }}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <PencilIcon size={12} />
            </IconButton>
          ) : (
            <span
              className={css({
                width: '16px',
              })}
            />
          )
        ) : null}
      </span>
    );
  }
);

export default EditableText;
