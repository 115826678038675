import { SVGProps } from 'react';

export const HelpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#546A80"
        d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667Zm0 15A6.676 6.676 0 0 1 3.333 10 6.676 6.676 0 0 1 10 3.333 6.676 6.676 0 0 1 16.666 10 6.676 6.676 0 0 1 10 16.666Zm-.834-3.334h1.667V15H9.166v-1.667Zm1.342-8.3a3.335 3.335 0 0 0-3.691 2.325.756.756 0 0 0 .724.975h.167c.342 0 .617-.242.733-.558a1.668 1.668 0 0 1 1.917-1.067c.792.167 1.375.942 1.308 1.75-.083 1.117-1.35 1.358-2.041 2.4 0 .008-.008.008-.008.017-.009.017-.017.025-.026.041-.074.125-.15.267-.208.417-.008.025-.025.042-.033.067-.008.016-.008.033-.017.058-.1.284-.167.625-.167 1.042h1.667c0-.35.092-.642.233-.892.017-.025.025-.05.042-.075.067-.117.15-.225.233-.325.009-.008.017-.025.026-.033.083-.1.174-.192.274-.284.8-.758 1.884-1.374 1.659-2.966-.2-1.45-1.342-2.675-2.792-2.892Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
