import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { HeadingSmallBold } from '@tigergraph/app-ui-lib/typography';
import { mergeOverrides } from 'baseui';
import { Drawer as BaseDrawer, DrawerOverrides, DrawerProps } from 'baseui/drawer';
import { Overrides } from 'baseui/overrides';
import React from 'react';

const drawerOverrides: DrawerOverrides = {
  DrawerBody: {
    style: {
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
  },
  Close: {
    style: {
      fill: '#323232',
      top: '16px',
      ':hover': {
        fill: '#323232',
      },
    },
  },
};

export function DrawerHeader({ children, hideLine }: { children: React.ReactNode; hideLine?: boolean }) {
  const [_, theme] = useStyletron();
  return (
    <HeadingSmallBold
      $style={{
        borderBottom: hideLine ? 'none' : `1px solid ${theme.colors.gray200}`,
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '16px',
        paddingRight: '32px',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      {children}
    </HeadingSmallBold>
  );
}

export const DrawerBody = styled('div', {
  width: '658px',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '16px',
  paddingBottom: '24px',
  minHeight: 0,
  flexBasis: 0,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

export const DrawerAction = styled('div', {
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
});

export function Drawer({ overrides, ...props }: DrawerProps) {
  return (
    <BaseDrawer
      size="auto"
      autoFocus={false}
      overrides={mergeOverrides(drawerOverrides as Overrides<unknown>, overrides as Overrides<unknown>)}
      {...props}
    />
  );
}
