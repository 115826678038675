import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { fromJS } from 'immutable';
import * as Immutable from 'immutable';
import { Command } from '@/utils/graphEditor/data';

export enum GraphEditorAction {
  RUN_COMMAND = 'run_command',
  DELETE_COMMAND = 'delete_command',
  SET_ACTIVE_TAB = 'set_active_tab',
  SET_CURRENT_FILE = 'set_current_file',
  SET_ACTIVE_FILES = 'set_active_files',
}

export enum GraphEditorKeys {
  QUERY_RESULTS = 'query_results',
  ACTIVE_TAB = 'active_tab',
  ACTIVE_FILES = 'active_files',
  CURRENT_FILE_ID = 'current_file_id',
}

interface GraphEditorContextValue {
  state: Immutable.Map<string, any>;
  dispatch: (action: { type: string; payload: any }) => void;
  listeners?: Set<(v: { [x: string]: any }) => void>;
}

const reducer = (state: Immutable.Map<string, any>, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GraphEditorAction.RUN_COMMAND: {
      const queryResults = state.get(GraphEditorKeys.QUERY_RESULTS) || [];
      const result = queryResults.find((command: Command) => command.id === action.payload.id) as Command;
      if (result) {
        return state.set(
          GraphEditorKeys.QUERY_RESULTS,
          queryResults.map((command: Command) => (command.id === action.payload.id ? action.payload : command))
        );
      } else {
        return state.set(GraphEditorKeys.QUERY_RESULTS, [action.payload].concat(queryResults));
      }
    }
    case GraphEditorAction.DELETE_COMMAND: {
      const queryResults = state.get(GraphEditorKeys.QUERY_RESULTS) || [];
      const commandId = action.payload;
      return state.set(
        GraphEditorKeys.QUERY_RESULTS,
        queryResults.filter((command: Command) => command.id !== commandId)
      );
    }
    case GraphEditorAction.SET_ACTIVE_TAB: {
      return state.set(GraphEditorKeys.ACTIVE_TAB, action.payload);
    }
    case GraphEditorAction.SET_CURRENT_FILE: {
      return state.set(GraphEditorKeys.CURRENT_FILE_ID, action.payload);
    }
    case GraphEditorAction.SET_ACTIVE_FILES: {
      return state.set(GraphEditorKeys.ACTIVE_FILES, action.payload);
    }
  }
  return state;
};

const initialState = fromJS({});

export const GraphEditorContext = createContext<GraphEditorContextValue>({
  state: fromJS({}),
  dispatch: () => {},
});

export const GraphEditorProvider: FC<PropsWithChildren> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useRef({ state, dispatch, listeners: new Set<(v: { [x: string]: any }) => void>() });

  useLayoutEffect(() => {
    contextValue.current.state = state;
    contextValue.current?.listeners?.forEach((listener: (v: any) => void) => listener(state));
  }, [state]);

  return <GraphEditorContext.Provider value={contextValue.current} {...props} />;
};

export const useGraphEditorStore = (key: string) => {
  const { state, dispatch, listeners } = useContext(GraphEditorContext);
  const [value, setValue] = useState(state.get(key));

  const listener = useCallback(
    (state: { [x: string]: any }) => {
      if (!Object.is(value, state.get(key))) {
        setValue(state.get(key));
      }
    },
    [key, value]
  );

  useLayoutEffect(() => {
    listeners?.add(listener);
    listener(state);

    return () => {
      listeners?.delete(listener);
    };
  }, [listener, listeners, state]);

  const updateValue = useCallback(
    (type: GraphEditorAction, value: any) => {
      dispatch({ type, payload: value });
    },
    [dispatch]
  );

  return [value, updateValue];
};
