import { FormControl } from '@/components/FormControl';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Select } from '@tigergraph/app-ui-lib/select';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { KIND } from 'baseui/button';

import { Form, FormContentContainer, Desc, FormControllerContainer, FormContent } from './StyledComponent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { getMeta } from '@/pages/workgroup/api';
import { CreateWorkGroupRequest, WorkSpaceMeta } from '@/pages/workgroup/type';
import { AxiosError } from 'axios';
import { LoadingIndicator } from '@/components/loading-indicator';
import { Result } from '@/lib/type';
import { useQueryGetGroups } from '@/pages/workgroup/hook';
import { generateWorkgroupName } from '@/pages/workgroup/form/name';
import { ErrorDisplay } from '@/components/error';
import { useOrgContext } from '@/contexts/orgContext';

export default function WorkGroupForm() {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();

  const groupsQuery = useQueryGetGroups();
  const metaQuery = useQuery<Result<WorkSpaceMeta>, AxiosError>(['workspaceMeta'], getMeta);
  const initNameref = useRef<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setFocus,
    setValue,
    watch,
  } = useForm<CreateWorkGroupRequest>({
    // default name from url params
    defaultValues: {
      name: searchParams.get('name') || ``,
      region: searchParams.get('region') || '',
    },
  });

  const name = watch('name');
  const region = watch('region');

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  useEffect(() => {
    if (initNameref.current) {
      return;
    }
    if (groupsQuery.data?.Result) {
      if (!name) {
        setValue('name', generateWorkgroupName(groupsQuery.data.Result));
      }
      initNameref.current = true;
    }
  }, [groupsQuery.data?.Result, setValue, name]);

  const { regions } = metaQuery.data?.Result || { regions: [] };
  const { currentOrg, userInfo } = useOrgContext();

  // set default region
  useEffect(() => {
    if (!region) {
      setValue('region', regions[0]?.name);
    }
  }, [regions, region, setValue]);

  // sync state to url search params
  useEffect(() => {
    if (searchParams.get('name') !== name || searchParams.get('region') !== region) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('name', name);
      newSearchParams.set('region', region);
      setSearchParams(newSearchParams);
    }
  }, [searchParams, setSearchParams, name, region]);

  if (metaQuery.isLoading || groupsQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (metaQuery.isError || groupsQuery.isError) {
    return <ErrorDisplay error={metaQuery.error || groupsQuery.error} label="Error:" />;
  }

  const regionOptions = regions.map((region) => ({
    label: region.description,
    id: region.name,
  }));

  const url = `mailto:beta-support@tigergraph.com?subject=TigerGraph Cloud 4 Beta Support - Request New Region - ${userInfo.email} - ${currentOrg.org_id} - ${currentOrg.org_name}&body=Hi Support Team,%0A%0A%0A%0A%0A%0ARegards,%0A${userInfo.email}`;

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        navigate('spaces/config', {
          state: {
            createGroupRequest: {
              ...data,
              platform: 'AWS',
            },
          },
        });
      })}
    >
      <FormContentContainer>
        <FormContent>
          <h1 className={css({ ...theme.typography.HeadingMedium })}>Create Workgroup</h1>
          <p className={css({ ...theme.typography.Body2, color: '#656565', marginBottom: '8px' })}>
            A workgroup is a group of databases and their workspaces which you can manage together as a unit.
          </p>
          <FormControllerContainer>
            <div>
              <FormControl label="Workgroup Name" error={errors?.name?.message}>
                <Controller
                  rules={{
                    required: 'required',
                  }}
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input placeholder="Enter workgroup name" {...field} error={!!errors?.name} autoComplete="off" />
                  )}
                />
              </FormControl>
              <Desc>Enter a descriptive name for your workgroup.</Desc>
            </div>
            <div>
              <FormControl label="Workgroup Region">
                <Controller
                  control={control}
                  name="region"
                  render={({ field: { value, onChange, ref, ...field } }) => (
                    <Select
                      options={regionOptions}
                      value={[{ id: value }]}
                      onChange={(params) => onChange(params.value[0].id)}
                      clearable={false}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
              </FormControl>
              <Desc>
                Can&#39;t find your region? Please{' '}
                <a className={css({ color: theme.colors.linkText })} href={url} target="_blank" rel="noreferrer">
                  contact us
                </a>{' '}
                if you want to try TigerGraph on another region.
              </Desc>
            </div>
          </FormControllerContainer>
        </FormContent>
      </FormContentContainer>
      <BottomContainer>
        <Button type="button" onClick={() => navigate('/groups', { replace: true })} kind={KIND.tertiary}>
          Cancel
        </Button>
        <Button>Next</Button>
      </BottomContainer>
    </Form>
  );
}

const BottomContainer = styled('div', ({ $theme }) => ({
  borderTop: `1px solid ${$theme.colors.divider}`,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
  padding: '16px 32px',
}));
