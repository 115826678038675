import { AddonsMetaID } from '@/components/addons/consts';
import { atomWithReset } from 'jotai/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import currency from 'currency.js';
import { useCallback } from 'react';

export const createWorkspaceEnableAtom = atomWithReset({} as Record<string, boolean>);

export const useDisableAddonsCopilot = () => {
  const updateAddonsEnableMap = useSetAtom(createWorkspaceEnableAtom);
  const disableCopilot = useCallback(() => {
    updateAddonsEnableMap((m) => ({ ...m, [AddonsMetaID.COPILOT]: false }));
  }, [updateAddonsEnableMap]);

  return disableCopilot;
};

export const useAddonsIsEnabled = (addonsID: string) => {
  const enabledAddonsMap = useAtomValue(createWorkspaceEnableAtom);

  return enabledAddonsMap[addonsID];
};

export const useAddonsEnabledParams = (param: Record<string, string>) => {
  const enabledAddonsMap = useAtomValue(createWorkspaceEnableAtom);

  return {
    enable_copilot: enabledAddonsMap[AddonsMetaID.COPILOT] || false,
  };
};

export const useAddonsPrice = (computePrice: number) => {
  const enabledAddonsMap = useAtomValue(createWorkspaceEnableAtom);
  const computeCurrency = currency(computePrice);

  // for now only copilot is enabled in workspace level, while others like insights enabled in org level
  // TODO fix
  const isInsightsEnable = useAddonsIsEnabled(AddonsMetaID.INSIGHTS);

  let addonsCurrency = currency(0);

  if (enabledAddonsMap[AddonsMetaID.COPILOT]) {
    // copilot price 30% of compute price
    addonsCurrency = addonsCurrency.add(computeCurrency.multiply(0.3));
  }

  if (isInsightsEnable) {
    // insights price 10% of compute price
    addonsCurrency = addonsCurrency.add(computeCurrency.multiply(0.1));
  }

  return addonsCurrency;
};
