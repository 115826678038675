import IconButton from '@/components/IconButton';
import { Sidebar } from '@/components/Sidebar';
import SchemaDesigner from '@/components/schemaDesigner';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useOrgContext } from '@/contexts/orgContext';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { calculateRoleForGroup, calculateRoleForSpace } from '@/pages/admin/user/type';
import WorkSpaceSubForm from '@/pages/workgroup/form/WorkSpaceSubForm';
import {
  useMutationDeleteDatabase,
  useMutationDeleteWorkspace,
  useMutationPauseWorkspace,
  useMutationResumeWorkspace,
  useMutationUpdateWorkspace,
  useQueryGetMeta,
} from '@/pages/workgroup/hook';
import {
  DatabaseT,
  WorkspaceAddons,
  WorkspaceT,
  isStatusActive,
  isStatusError,
  isStatusIdle,
  isStatusPaused,
  isStatusPending,
} from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { Body2, Label } from '@tigergraph/app-ui-lib/typography';
import { Overflow } from 'baseui/icon';
import { useState } from 'react';
import toast from 'react-hot-toast';

import CreateQuickInsights from '@/pages/dashboard/createInsights';
import AccessIcon from './icons/access.svg?react';
import DeleteIcon from './icons/delete.svg?react';
import EditIcon from './icons/edit.svg?react';
import PauseIcon from './icons/pause.svg?react';
import StartIcon from './icons/start.svg?react';
import StopIcon from './icons/stop.svg?react';
import { MdOutlineMonitorHeart } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import SpaceUserManagement from '@/pages/workgroup/tab/iam/SpaceUserManagement';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { SubFormData } from '@/pages/workgroup/form/type';

export function SettingMenuForWorkSpace({ workspace, database }: { workspace: WorkspaceT; database?: DatabaseT }) {
  const { setCurrentWorkspace } = useWorkspaceContext();
  const navigate = useNavigate();
  const [css] = useStyletron();
  const metaQuery = useQueryGetMeta();
  const { userInfo } = useOrgContext();

  const deleteWorkSpaceMutation = useMutationDeleteWorkspace();
  const pauseWorkSpaceMutation = useMutationPauseWorkspace();
  const resumeWorkSpaceMutation = useMutationResumeWorkspace();
  const updateWorkSpaceMutation = useMutationUpdateWorkspace();

  const [showCreateInsights, setShowCreateInsights] = useState(false);
  const [showSchemaDesigner, setShowSchemaDesigner] = useState(false);
  const [showResizeForm, setShowResizeForm] = useState(false);

  const [showSpaceAdminDetail, setShowSpaceAdminDetail] = useState(false);

  const [showConfirmStop, setShowConfirmStop] = useState(false);
  const [showConfirmPause, setShowConfirmPause] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const onResume = () => {
    const promise = resumeWorkSpaceMutation.mutateAsync({
      group_id: workspace.workgroup_id,
      space_id: workspace.workspace_id,
    });

    toast.promise(
      promise,
      {
        loading: 'Starting workspace',
        success: () => 'Request has been accepted',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const onPause = () => {
    const mutate = async () => {
      if (!workspace.enable_auto_start) {
        // set auto start to true
        const promise1 = updateWorkSpaceMutation.mutateAsync({
          group_id: workspace.workgroup_id,
          space_id: workspace.workspace_id,
          data: {
            enable_auto_start: true,
          },
        });

        await promise1;
      }

      const promise2 = pauseWorkSpaceMutation.mutateAsync({
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
      });

      await promise2;
    };

    toast.promise(
      mutate(),
      {
        loading: 'Pausing workspace',
        success: () => 'Request has been accepted',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const onStop = () => {
    const mutate = async () => {
      if (workspace.enable_auto_start) {
        // set auto start to false
        const promise1 = updateWorkSpaceMutation.mutateAsync({
          group_id: workspace.workgroup_id,
          space_id: workspace.workspace_id,
          data: {
            enable_auto_start: false,
          },
        });

        await promise1;
      }

      const promise2 = pauseWorkSpaceMutation.mutateAsync({
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
      });

      await promise2;
    };

    toast.promise(
      mutate(),
      {
        loading: 'Stopping workspace',
        success: () => 'Request has been accepted',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const onTerminate = () => {
    const promise = deleteWorkSpaceMutation.mutateAsync({
      group_id: workspace.workgroup_id,
      space_id: workspace.workspace_id,
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting workspace',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const onUpdate = (data: SubFormData) => {
    const { workspace_type_name, enable_auto_start, auto_stop_minutes, autoSuspend } = data;
    const addons: WorkspaceAddons[] = Object.entries(data.addons || {})
      .filter(([_, val]) => val.enable !== undefined)
      .map(([id, { enable, enable_configuration }]) => ({
        addons_id: id,
        enable,
        enable_configuration: enable_configuration || {},
      }));
    const promise = updateWorkSpaceMutation.mutateAsync({
      group_id: workspace.workgroup_id,
      space_id: workspace.workspace_id,
      data: {
        // RO workspace do not support resize workspace type
        workspace_type_name: workspace.is_rw ? workspace_type_name : undefined,
        enable_auto_start,
        auto_stop_minutes: autoSuspend ? Number(auto_stop_minutes) : 0,
        addons,
      },
    });

    toast.promise(
      promise,
      {
        loading: 'Updating workspace configuration',
        success: (data) => 'Accepted update the workspace',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const { workspaceTypes = [] } = metaQuery?.data?.Result || {};

  const effectRole = calculateRoleForSpace(userInfo.roles, workspace.workgroup_id, workspace.workspace_id);

  return (
    <>
      <DropdownMenu>
        <Sidebar isDrawer={true} isOpen={showSchemaDesigner} onClose={() => setShowSchemaDesigner(false)}>
          <SchemaDesigner title={'Schema Designer'} showHideButton={false} isInDrawer={true} />
        </Sidebar>
        <DropdownMenuTrigger asChild>
          <IconButton
            $style={{
              border: '1px solid #AAB5BF',
              padding: '2px 3px',
            }}
          >
            <Overflow size={16} />
          </IconButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className={css({ width: '190px', marginLeft: '165px' })}>
          {effectRole === 'workspace-admins' && isStatusActive(workspace.status) ? (
            <DropdownMenuItem onClick={() => setShowResizeForm(true)}>
              <EditIcon />
              <Body2>Edit</Body2>
            </DropdownMenuItem>
          ) : null}
          {effectRole === 'workspace-admins' ? (
            <DropdownMenuItem
              onClick={() => {
                setShowSpaceAdminDetail(true);
              }}
            >
              <AccessIcon />
              <Body2>Manage Access</Body2>
            </DropdownMenuItem>
          ) : null}
          <DropdownMenuItem
            onClick={() => {
              navigate(`/groups/${workspace.workgroup_id}?tab=monitor`);
            }}
          >
            <MdOutlineMonitorHeart size={18} color="rgb(87, 104, 110)" />
            <Body2>Monitor</Body2>
          </DropdownMenuItem>
          {effectRole === 'workspace-admins' ? (
            <>
              <DropdownMenuSeparator />
              {isStatusPaused(workspace.status) || isStatusIdle(workspace.status) ? (
                <DropdownMenuItem onClick={onResume}>
                  <StartIcon />
                  <Body2>Start</Body2>
                </DropdownMenuItem>
              ) : null}
              {isStatusActive(workspace.status) ||
              isStatusError(workspace.status) ||
              isStatusPending(workspace.status) ? (
                <>
                  <DropdownMenuItem
                    onClick={() => {
                      if (workspace.enable_auto_start) {
                        setShowConfirmStop(true);
                      } else {
                        onStop();
                      }
                    }}
                  >
                    <StopIcon />
                    <Body2>Stop</Body2>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      if (!workspace.enable_auto_start) {
                        setShowConfirmPause(true);
                      } else {
                        onPause();
                      }
                    }}
                  >
                    <PauseIcon />
                    <Body2>Pause</Body2>
                  </DropdownMenuItem>
                </>
              ) : null}
              <DropdownMenuItem onClick={() => setShowDeleteConfirm(true)}>
                <DeleteIcon />
                <Body2>Terminate</Body2>
              </DropdownMenuItem>
            </>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>
      {showCreateInsights ? (
        <CreateQuickInsights
          isOpen={showCreateInsights}
          onClose={() => setShowCreateInsights(false)}
          databaseName={database?.name}
          workspace_id={workspace.workspace_id}
          workgroup_id={workspace.workgroup_id}
          nginx_host={workspace.nginx_host}
        />
      ) : null}
      {showResizeForm ? (
        <WorkSpaceSubForm
          isOpen={showResizeForm}
          onClose={() => setShowResizeForm(false)}
          workspaceTypes={workspaceTypes}
          subFormData={{
            workspace_type_name: workspace.workspace_type.typeName,

            autoScale: false,
            minCluster: 1,
            maxCluster: 1,

            autoSuspend: workspace.auto_stop_minutes > 0,
            auto_stop_minutes: workspace.auto_stop_minutes || 10,
            enable_auto_start: workspace.enable_auto_start,

            addons: workspace.addons?.reduce((res, cur) => {
              res[cur.addons_id] = cur;

              return res;
            }, {} as Record<string, WorkspaceAddons>),
          }}
          workspace={workspace}
          onSubFormDataChanged={(data) => {
            onUpdate(data);
          }}
          disableResize={!workspace.is_rw}
        />
      ) : null}
      {showSpaceAdminDetail ? (
        <SpaceUserManagement
          space={workspace}
          // @ts-ignore
          group={{ workgroup_id: workspace.workgroup_id }}
          isOpen={showSpaceAdminDetail}
          onClose={() => setShowSpaceAdminDetail(false)}
        />
      ) : null}
      {showConfirmStop ? (
        <ConfirmStop
          isOpen={showConfirmStop}
          onCancel={() => setShowConfirmStop(false)}
          onConfirm={() => {
            setShowConfirmStop(false);
            onStop();
          }}
          name={workspace.name}
        />
      ) : null}
      {showConfirmPause ? (
        <ConfirmPause
          isOpen={showConfirmPause}
          onCancel={() => setShowConfirmPause(false)}
          onConfirm={() => {
            setShowConfirmPause(false);
            onPause();
          }}
          name={workspace.name}
        />
      ) : null}
      {showDeleteConfirm ? (
        <ConfirmDeleteWorkspace
          isOpen={showDeleteConfirm}
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            setShowDeleteConfirm(false);
            onTerminate();
          }}
          name={workspace.name}
          isRW={workspace.is_rw}
        />
      ) : null}
    </>
  );
}

export function SettingMenuForDatabase({ database, disableDelete }: { database: DatabaseT; disableDelete: boolean }) {
  const deleteDatabaseMutation = useMutationDeleteDatabase();
  const { userInfo } = useOrgContext();

  const onDelete = () => {
    const promise = deleteDatabaseMutation.mutateAsync({
      group_id: database.workgroup_id,
      db_id: database.database_id,
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting database',
        success: (result) => result.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const isGroupAdmin = calculateRoleForGroup(userInfo.roles, database.workgroup_id) === 'workgroup-admins';
  if (!isGroupAdmin) {
    return null;
  }
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            $style={{
              border: '1px solid #AAB5BF',
              padding: '2px 3px',
            }}
          >
            <Overflow size={16} />
          </IconButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuItem disabled={disableDelete} onClick={() => setShowDeleteConfirm(true)}>
            <Body2>Terminate</Body2>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {showDeleteConfirm ? (
        <ConfirmDeleteDatabase
          isOpen={showDeleteConfirm}
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            setShowDeleteConfirm(false);
            onDelete();
          }}
          name={database.name}
        />
      ) : null}
    </>
  );
}

type ConfirmProps = {
  isOpen: boolean;
  name: string;
  onCancel: () => void;
  onConfirm: () => void;
  isRW?: boolean;
};

function ConfirmPause({ isOpen, onCancel, onConfirm, name }: ConfirmProps) {
  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Pause the workspace?</ModalHeader>
      <ModalBody>
        Are you sure to pause workspace <strong>{name}</strong>? Pause will leave the Auto Resume to true and stop the
        workspace.
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="tertiary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onConfirm}>Confirm</ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function ConfirmStop({ isOpen, onCancel, onConfirm, name }: ConfirmProps) {
  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Stop the workspace?</ModalHeader>
      <ModalBody>
        Are you sure to stop workspace <strong>{name}</strong>? Stop will set the Auto Resume to false, and stop the
        workspace.
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="tertiary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onConfirm}>Confirm</ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function ConfirmDeleteWorkspace({ isOpen, onCancel, onConfirm, name, isRW }: ConfirmProps) {
  const [isConfirm, setIsConfirm] = useState(false);
  const [isRWConfirm, setIsRWConfirm] = useState(false);
  const [css] = useStyletron();

  let disable = !isConfirm;
  if (isRW) {
    disable = !isConfirm || !isRWConfirm;
  }

  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Delete the workspace?</ModalHeader>
      <ModalBody>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          })}
        >
          <p>
            Are you sure to delete workspace <strong>{name}</strong>?
          </p>
          <p>
            {isRW
              ? 'Deleting the read-write workspace will permanently remove the ability to create a new read-write workspace and link it to the same database. Additionally, all backups associated with the workspace will also be deleted.'
              : 'If you proceed with deleting the read-only workspace, the associated database snapshot will also be permanently deleted. Please note that it cannot be restored later.'}
          </p>
          <div>
            <Checkbox
              checked={isConfirm}
              onChange={(e) => {
                setIsConfirm(e.currentTarget.checked);
              }}
            >
              <Label
                $style={{
                  color: 'rgb(101,101,101)',
                  marginTop: '1px',
                }}
              >
                {isRW
                  ? 'I understand that deleting the workspace will also delete the associated database backups.'
                  : 'I understand that deleting the workspace will also delete the associated database snapshots.'}
              </Label>
            </Checkbox>
            {isRW ? (
              <Checkbox
                checked={isRWConfirm}
                onChange={(e) => {
                  setIsRWConfirm(e.currentTarget.checked);
                }}
              >
                <Label
                  $style={{
                    color: 'rgb(101,101,101)',
                    marginTop: '1px',
                  }}
                >
                  I acknowledge that I will not be able to create a new read-write workspace against the same database.
                </Label>
              </Checkbox>
            ) : null}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="tertiary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onConfirm} disabled={disable}>
          Confirm
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function ConfirmDeleteDatabase({ isOpen, onCancel, onConfirm, name }: ConfirmProps) {
  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Delete the database?</ModalHeader>
      <ModalBody>
        Are you sure to delete database <strong>{name}</strong>? Deleting the database will remove all the data stored
        inside it.
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="tertiary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onConfirm}>Confirm</ModalButton>
      </ModalFooter>
    </Modal>
  );
}

const IconContainer = styled('span', ({ $theme }) => {
  return {
    width: '18px',
    height: '18px',
    display: 'flex',
  };
});
