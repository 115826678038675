import PluginIcon from '@/assets/plugin.svg?react';

const WorkspaceAddonsEmpty = () => (
  <>
    <li className="relative flex flex-col items-center space-x-1 px-2 py-4 border rounded-[2px] w-full h-full">
      <PluginIcon />
      <span className="font-[Roboto] text-xs text-[#909090]">No Add-on enabled</span>
    </li>
  </>
);

export default WorkspaceAddonsEmpty;
