import { Select } from '@tigergraph/app-ui-lib/select';
import { OnChangeParams, Value } from 'baseui/select';
import {
  Attribute,
  supportedAttributeTypes,
  supportedDiscriminatorAttributeTypes,
  supportedPrimaryIdTypes,
} from '@tigergraph/tools-models';
import { useState } from 'react';

export interface AttributeTypeSelectProps {
  attribute: Attribute;
  readOnly?: boolean;
  onChange: (attr: Attribute) => void;
}

export function AttributeTypeSelect({ attribute, readOnly, onChange }: AttributeTypeSelectProps) {
  const [selectedType, setSelectedType] = useState<Value>([{ id: attribute.type.name, label: attribute.type.name }]);

  let options = [];

  const attrOptions = supportedAttributeTypes
    .filter((type) => type !== 'STRING COMPRESS')
    .map((type) => ({ id: type, label: type }));

  const primaryIdTypeOptions = supportedPrimaryIdTypes.map((type) => ({
    id: type,
    label: type,
  }));

  const discriminatorTypeOptions = supportedDiscriminatorAttributeTypes.map((type) => ({
    id: type,
    label: type,
  }));

  if (attribute.isPrimaryKey) {
    options = primaryIdTypeOptions;
  } else if (attribute.isDiscriminator) {
    options = discriminatorTypeOptions;
  } else {
    options = attrOptions;
  }

  const handleChange = (params: OnChangeParams) => {
    setSelectedType(params.value);
    const typeName = params.value[0].id as string;
    attribute.type.name = typeName;
    onChange(attribute);
  };

  return (
    <>
      <Select
        disabled={readOnly}
        maxDropdownHeight={'230px'}
        value={selectedType}
        searchable={false}
        overrides={{
          Root: {
            style: {
              height: '32px',
              backgroundColor: 'inherit',
            },
          },
          ControlContainer: {
            style: {
              backgroundColor: 'inherit',
              height: '32px',
              borderTopStyle: 'none',
              borderLeftStyle: 'none',
              borderRightStyle: 'none',
              borderBottomStyle: 'none',
            },
          },
          ValueContainer: {
            style: {
              paddingLeft: '0px',
              paddingTop: '0px',
              paddingBottom: '0px',
            },
          },
          SingleValue: {
            style: {
              lineHeight: '30px',
            },
          },
          IconsContainer: {
            style: {
              paddingRight: '0px',
              paddingLeft: '0px',
            },
          },
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: {
                    zIndex: 1,
                  },
                },
              },
            },
          },
        }}
        onChange={handleChange}
        options={options}
        clearable={false}
      />
    </>
  );
}
