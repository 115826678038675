import { Select } from '@tigergraph/app-ui-lib/select';
import { Option, SelectProps } from 'baseui/select';

export type StyledSelectProps = {
  options: Option[];
} & SelectProps;

export function StyledSelect({ options, ...props }: StyledSelectProps) {
  return (
    <Select
      searchable={false}
      clearable={false}
      options={options}
      placeholder={'Value type'}
      overrides={{
        Root: {
          style: {
            height: '32px',
            backgroundColor: 'inherit',
          },
        },
        ControlContainer: {
          style: {
            backgroundColor: 'inherit',
            height: '32px',
            borderTopStyle: 'none',
            borderLeftStyle: 'none',
            borderRightStyle: 'none',
            borderBottomStyle: 'none',
          },
        },
        ValueContainer: {
          style: {
            paddingLeft: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },
        SingleValue: {
          style: {
            lineHeight: '30px',
          },
        },
        IconsContainer: {
          style: {
            paddingRight: '0px',
            paddingLeft: '0px',
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  zIndex: 1,
                },
              },
            },
          },
        },
      }}
      {...props}
    />
  );
}
