import {
  SpaceWithGroup,
  assignDBRoles,
  assignGroupAdmin,
  assignUserRole,
  assignWorkspaceAdmin,
  assignWorkspaceUser,
  createDBUser,
  deleteDBUser,
  deleteUser,
  fetchDBRoles,
  fetchDBUserRoles,
  fetchDBUsers,
  fetchUsers,
  getUserDetail,
  inviteUser,
  modifyOrgUserRole,
  removeOrgUserRole,
  resendInvitation,
  revokeDBRoles,
  unAssignGroupAdmin,
  unAssignUserRole,
  unAssignWorkspaceRole,
  updateDBUser,
} from './api';

import { RoleType, UserInfo } from '@/lib/models';
import { getUserFullname } from '@/utils/utils';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  DBRoles,
  DBUser,
  User,
  UserData,
  UserRoles,
  isGroupAdmin,
  isOrgAdmin,
  isSpaceAdmin,
  isSpaceUser,
} from './type';

export function getOrgAdmins(users: User[]): UserInfo[] {
  return users.filter((user) => isOrgAdmin(user.roles)).map((user) => user.userInfo);
}

export function getGroupAdmins(users: User[], groupID: string): UserInfo[] {
  return users.filter((user) => isGroupAdmin(user.roles, groupID)).map((user) => user.userInfo);
}

export function getSpaceAdmins(users: User[], groupID: string, spaceID: string): UserInfo[] {
  return users.filter((user) => isSpaceAdmin(user.roles, groupID, spaceID)).map((user) => user.userInfo);
}

export function getSpaceUsers(users: User[], groupID: string, spaceID: string): UserInfo[] {
  return users.filter((user) => isSpaceUser(user.roles, groupID, spaceID)).map((user) => user.userInfo);
}

export function useQueryListUsers() {
  return useQuery<User[], AxiosError>(['users'], async () => {
    const users = await fetchUsers();
    /**
     * Current user endpoint also returns the db users. But we don't allow user to manage
     * these user in org level. As a workaround, the user endpoint returns a path object
     * for FE to filter out db users.
     * */
    const list = users
      .filter((data) => data?.path === '/')
      .map((data) => {
        const name = getUserFullname(data);
        // filter out solution admins
        data.roles = data.roles.filter((role) => (role.name as string) !== 'solution-admins');
        let user: User = {
          userId: data.id,
          name,
          email: data.email,
          roles: data.roles,
          created: data.created,
          userInfo: data,
        };
        if (data.enabled && data.metadata && data.metadata.lastLogin) {
          const date = data.metadata.lastLogin;
          user.lastActive = date;
        }
        return user;
      });

    return list;
  });
}

export function useQueryGetUserDetail(userId: string) {
  return useQuery<UserInfo, AxiosError>(['user', userId], async () => {
    return getUserDetail(userId);
  });
}

export function useMutationDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { userId: string }>(
    ({ userId }) => {
      return deleteUser(userId);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['users']);
      },
    }
  );
}

export function useMutationInviteUser() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { users: UserData[] }>(
    ({ users }) => {
      return inviteUser(users);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['users']);
      },
    }
  );
}

export function useMutationModifyUserRole() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { userId: string; role: RoleType }>(
    ({ userId, role }) => {
      return modifyOrgUserRole(userId, role);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['users']);
      },
    }
  );
}

export function useMutationRemoveUserRole() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { orgId: string; role: RoleType }>(
    ({ orgId, role }) => {
      return removeOrgUserRole(orgId, role);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['users']);
      },
    }
  );
}

export function useMutationResendInvitation() {
  return useMutation<void, AxiosError, { orgId: string; email: string }>(({ orgId, email }) => {
    return resendInvitation(orgId, email);
  });
}

export function useMutationAssignGroupAdmin() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { groupID: string; assignUserEmails: string[]; unAssignUserEmails: string[] }>(
    async ({ groupID, assignUserEmails, unAssignUserEmails }) => {
      if (unAssignUserEmails.length > 0) {
        await unAssignGroupAdmin(groupID, unAssignUserEmails);
      }
      if (assignUserEmails.length > 0) {
        await assignGroupAdmin(groupID, assignUserEmails);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
      },
    }
  );
}

export function useMutationAssignSpaceRole() {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    AxiosError,
    {
      groupID: string;
      spaceID: string;
      assignAdminEmails: string[];
      assignUserEmails: string[];
      unAssignEmails: string[];
    }
  >(
    async ({ groupID, spaceID, assignAdminEmails, assignUserEmails, unAssignEmails }) => {
      if (unAssignEmails.length > 0) {
        await unAssignWorkspaceRole(groupID, spaceID, unAssignEmails);
      }
      if (assignUserEmails.length > 0) {
        await assignWorkspaceUser(groupID, spaceID, assignUserEmails);
      }
      if (assignAdminEmails.length > 0) {
        await assignWorkspaceAdmin(groupID, spaceID, assignAdminEmails);
      }
    },
    {
      onSuccess: (_, { assignAdminEmails, unAssignEmails, assignUserEmails }) => {
        // after change user, remove the user_roles query
        for (let u of assignAdminEmails) {
          queryClient.removeQueries(['user_roles', u]);
        }
        for (let u of unAssignEmails) {
          queryClient.removeQueries(['user_roles', u]);
        }
        for (let u of assignUserEmails) {
          queryClient.removeQueries(['user_roles', u]);
        }
      },
    }
  );
}

export type GroupDiff = {
  groupRole?: RoleType;
  adminSpaces: string[];
  userSpaces: string[];
  revokedSpaces: string[];
};

export type TreeDiff = {
  orgRole?: RoleType;
  revokeBillingRole: boolean;
  assignBillingRole?: RoleType;
  groups: Map<string, GroupDiff>;
};

export function useMutationAssignUserRole() {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    AxiosError,
    {
      diff: TreeDiff;
      userId: string;
      userEmail: string;
    }
  >(
    async ({ diff, userId, userEmail }) => {
      const { orgRole, groups, revokeBillingRole, assignBillingRole } = diff;
      if (orgRole) {
        await modifyOrgUserRole(userId, orgRole);
      }

      const addedWorkgroupAdmins: string[] = [];
      const revokedWorkgroupAdmins: string[] = [];
      const assignedWorkspaceAdmins: SpaceWithGroup[] = [];
      const assignedWorkspaceUsers: SpaceWithGroup[] = [];
      const revokedWorkspaceUsers: SpaceWithGroup[] = [];

      for (let [groupID, groupDiff] of groups) {
        const { groupRole, adminSpaces, userSpaces, revokedSpaces } = groupDiff;
        if (groupRole === 'workgroup-admins') {
          addedWorkgroupAdmins.push(groupID);
          continue;
        }
        if (groupRole === 'users') {
          revokedWorkgroupAdmins.push(groupID);
        }

        adminSpaces.forEach((spaceID) => {
          assignedWorkspaceAdmins.push(`${groupID}/${spaceID}`);
        });

        userSpaces.forEach((spaceID) => {
          assignedWorkspaceUsers.push(`${groupID}/${spaceID}`);
        });

        revokedSpaces.forEach((spaceID) => {
          revokedWorkspaceUsers.push(`${groupID}/${spaceID}`);
        });
      }

      if (revokedWorkgroupAdmins.length + revokedWorkspaceUsers.length > 0 || revokeBillingRole) {
        await unAssignUserRole(userEmail, revokedWorkgroupAdmins, revokedWorkspaceUsers, revokeBillingRole);
      }

      if (
        addedWorkgroupAdmins.length + assignedWorkspaceAdmins.length + assignedWorkspaceUsers.length > 0 ||
        assignBillingRole
      ) {
        await assignUserRole(
          userEmail,
          addedWorkgroupAdmins,
          assignedWorkspaceAdmins,
          assignedWorkspaceUsers,
          assignBillingRole
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
      },
    }
  );
}

export function useQueryListDBUser(groupID: string, spaceID: string) {
  return useQuery<DBUser[], AxiosError>(['dbusers', groupID, spaceID], async () => {
    return fetchDBUsers(groupID, spaceID);
  });
}

export function useMutationCreateDBUser() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { groupID: string; spaceID: string; username: string; password: string }>(
    ({ groupID, spaceID, username, password }) => {
      return createDBUser(groupID, spaceID, username, password);
    },
    {
      onSuccess: async (_, { groupID, spaceID }) => {
        queryClient.invalidateQueries(['dbusers', groupID, spaceID]);
      },
    }
  );
}

export function useMutationUpdateDBUser() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { groupID: string; spaceID: string; username: string; password: string }>(
    ({ groupID, spaceID, username, password }) => {
      return updateDBUser(groupID, spaceID, username, password);
    },
    {
      onSuccess: async (_, { groupID, spaceID }) => {
        queryClient.invalidateQueries(['dbusers', groupID, spaceID]);
      },
    }
  );
}

export function useMutationDeleteDBUser() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { groupID: string; spaceID: string; username: string }>(
    ({ groupID, spaceID, username }) => {
      return deleteDBUser(groupID, spaceID, username);
    },
    {
      onSuccess: async (_, { groupID, spaceID }) => {
        queryClient.invalidateQueries(['dbusers', groupID, spaceID]);
      },
    }
  );
}

export function useQueryListDBRoles() {
  return useQuery<DBRoles, AxiosError>(['db_roles'], async () => {
    return fetchDBRoles();
  });
}

export function useQueryListDBUserRoles(name: string) {
  return useQuery<UserRoles, AxiosError>(
    ['user_roles', name],
    async () => {
      return fetchDBUserRoles(name);
    },
    {
      // disable cache
      cacheTime: 0,
    }
  );
}

export function useMutationAssignDBRoles() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { username: string; roles: string[]; graphName: string }>(
    ({ roles, username, graphName }) => {
      return assignDBRoles(roles, username, graphName);
    },
    {
      onSuccess: async (_, { username }) => {
        queryClient.invalidateQueries(['user_roles', username]);
      },
    }
  );
}

export function useMutationRevokeDBRoles() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, { roles: string[]; username: string; graphName: string }>(
    ({ roles, username, graphName }) => {
      return revokeDBRoles(roles, username, graphName);
    },
    {
      onSuccess: async (_, { username }) => {
        queryClient.invalidateQueries(['user_roles', username]);
      },
    }
  );
}
