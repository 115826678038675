import { RoleType, UserInfo, UserRole } from '@/lib/models';

export interface User {
  userId: string;
  name: string;
  created: string;
  email: string;
  roles: UserRole[];
  lastActive?: string;
  userInfo: UserInfo;
}

export interface DBUser {
  username: string;
  password: string;
}

export type DBRoles = {
  builtIn: {
    global: string[];
    local: string[];
  };
  userDefinedRoles: {
    [key: string]: string[];
  };
};

export type UserRoles = {
  privileges: {
    [key: string]: string[];
  };
  roles: {
    [key: string]: string[];
  };
};

export function isOrgAdmin(roles: UserRole[]) {
  return roles.map((role) => role.name).includes('super-admins');
}

export function isBillingAdmin(roles: UserRole[]) {
  return roles.map((role) => role.name).includes('billing-admins');
}

export function isBillingViewer(roles: UserRole[]) {
  return roles.map((role) => role.name).includes('billing-viewers');
}

export function isGroupAdmin(roles: UserRole[], groupID: string) {
  return roles.map((role) => role.path).includes(`/${groupID}/workgroup-admins`);
}

export function isSpaceAdmin(roles: UserRole[], groupID: string, spaceID: string) {
  return roles.map((role) => role.path).includes(`/${groupID}/${spaceID}/workspace-admins`);
}

export function hasSpaceAdminRole(roles: UserRole[]) {
  if (isOrgAdmin(roles)) {
    return true;
  }
  return roles
    .map((role) => role.path)
    .some((path) => path.includes('/workspace-admins') || path.includes('/workgroup-admins'));
}

export function isSpaceUser(roles: UserRole[], groupID: string, spaceID: string) {
  return roles.map((role) => role.path).includes(`/${groupID}/${spaceID}/workspace-users`);
}

export function calculateRoleForBilling(roles: UserRole[]): RoleType {
  if (isOrgAdmin(roles)) {
    return 'billing-admins';
  }
  if (isBillingAdmin(roles)) {
    return 'billing-admins';
  }
  if (isBillingViewer(roles)) {
    return 'billing-viewers';
  }
  return 'users';
}

export function calculateRoleForGroup(roles: UserRole[], groupID: string): RoleType {
  if (isOrgAdmin(roles)) {
    return 'workgroup-admins';
  }
  if (isGroupAdmin(roles, groupID)) {
    return 'workgroup-admins';
  }
  // users means not specified
  return 'users';
}

export function calculateRoleForSpace(roles: UserRole[], groupID: string, spaceID: string): RoleType {
  if (isOrgAdmin(roles)) {
    return 'workspace-admins';
  }
  if (isGroupAdmin(roles, groupID)) {
    return 'workspace-admins';
  }
  if (isSpaceAdmin(roles, groupID, spaceID)) {
    return 'workspace-admins';
  }
  if (isSpaceUser(roles, groupID, spaceID)) {
    return 'workspace-users';
  }
  // users means not specified
  return 'users';
}

export interface UserData {
  email: string;
  roles: RoleType[];
}

export function labelForRole(role: RoleType) {
  switch (role) {
    case 'super-admins':
      return 'Organization Admin';
    case 'billing-admins':
      return 'Billing Admin';
    case 'billing-viewers':
      return 'Billing Viewer';
    case 'workgroup-admins':
      return 'Workgroup Admin';
    case 'workspace-admins':
      return 'Workspace Admin';
    case 'workspace-users':
      return 'Workspace User';
    default:
      return '';
  }
}

export function isRoleType(role: RoleType): boolean {
  if (labelForRole(role) === '') {
    return false;
  }
  return true;
}

export const roles: { label: string; id: RoleType }[] = [
  {
    label: 'Organization Admin',
    id: 'super-admins',
  },
  {
    label: 'Workgroup Admin',
    id: 'workgroup-admins',
  },
  {
    label: 'Workspace Admin',
    id: 'workspace-admins',
  },
  {
    label: 'Workspace User',
    id: 'workspace-users',
  },
  {
    label: 'Billing Admin',
    id: 'billing-admins',
  },
  {
    label: 'Billing Viewer',
    id: 'billing-viewers',
  },
];
