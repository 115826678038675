import { ID_TOKEN_KEY } from '@/contexts/userContext';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

export const useListRegisteredQueries = <T = string[]>(
  nginxHost: string,
  graphName?: string,
  opt?: UseQueryOptions<string[], AxiosError, T, (string | undefined)[]>
) => {
  return useQuery({
    ...opt,
    queryKey: ['list', 'registered', 'queries', nginxHost, graphName],
    queryFn: async () => {
      const res = await axios.get<{ query: {} } | [{ queryName: string }]>(
        `https://copilot-${nginxHost}/${graphName}/list_registered_queries`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          },
        }
      );
      if (Array.isArray(res.data)) {
        return Object.values(res.data).map((i) => i.queryName);
      }
      return [];
    },
    enabled: Boolean(nginxHost && graphName),
  });
};
