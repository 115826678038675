import { Parameter } from '@/pages/workgroup/tab/restPP/type.ts';

export function generateJsonFromParams(params: Parameter[]) {
  const map: { [key: string]: any } = {
    STRING: '',
    BOOL: false,
    UNIT32: 0,
    INT32: 0,
    TYPENAME: '',
    EDGETYPENAME: '',
    INT64: 0,
  };
  const json: { [key: string]: any } = {};
  params.forEach((param) => {
    json[param.name] = param.default ?? map[param.type];
  });
  return JSON.stringify(json, null, 2);
}
