import { useOrgContext } from '@/contexts/orgContext';
import { AppConfig, useConfig } from '@/lib/config';
import { MyOrg } from '@/lib/models';
import { WorkspaceT } from '@/pages/workgroup/type';

// This hook is used to generate the tools link for the workspace.
export default function useToolsLink(workspace: WorkspaceT, path: string) {
  const { appConfig } = useConfig();
  const { currentOrg } = useOrgContext();

  return getToolsLink(appConfig, currentOrg, workspace, path);
}

export function getToolsLink(appConfig: AppConfig, currentOrg: MyOrg, workspace: WorkspaceT, path: string) {
  const params = new URLSearchParams();
  params.set('user_opt_in', 'true');
  const host = workspace.nginx_host.match(
    /tg-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.[a-zA-Z0-9.-]+?\.(privatelink|i)/i
  );
  if (!host) {
    console.error('Invalid host', workspace.nginx_host);
    return '';
  }
  params.set('domain', host[0]);
  params.set('groupid', workspace.workgroup_id);
  params.set('clusterid', workspace.workspace_id);
  params.set('orgName', currentOrg.org_name);
  params.set('cloudVersion', 'v4');
  if (import.meta.env.MODE !== 'production') {
    // we don't pass cloudEnv in production
    // tools should determine the prod environment by the domain(e.g. tgcloud.io)
    params.set('cloudEnv', import.meta.env.MODE);
  }

  const toolsOrigin =
    appConfig.WhiteList[workspace.workspace_id] ||
    appConfig.Releases[workspace.tg_version] ||
    import.meta.env.VITE_TOOLS_ORIGIN;

  const url = `${toolsOrigin}${path}/#/?${params.toString()}`;

  return url;
}
