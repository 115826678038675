import { PopoverOverrides, StatefulPopoverProps } from 'baseui/popover';
import { StatefulPopover as BaseStatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';
import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';

export const statefulPopOverrides: PopoverOverrides = {
  Body: {
    style: {
      backgroundColor: '#fff',
      marginTop: '8px',
    },
  },
  Inner: {
    style: ({ $theme }) => {
      const customTheme = $theme as CustomTheme;
      return {
        backgroundColor: '#fff',
        color: customTheme.colors.subText,
        fontSize: '12px',
      };
    },
  },
  Arrow: {
    style: {
      backgroundColor: '#fff',
    },
  },
};

export function StatefulTipsPopover({ overrides, ...props }: StatefulPopoverProps) {
  return (
    <BaseStatefulPopover
      {...props}
      overrides={mergeOverrides(statefulPopOverrides as Overrides<any>, overrides as Overrides<any>)}
    />
  );
}
