import { ID_TOKEN_KEY } from '@/contexts/userContext';
import { fetchGraphList } from '@/lib/instance_api';
import { useQuery } from 'react-query';

export const useListGraphs = (nginxHost?: string) => {
  return useQuery({
    queryKey: ['graph_list', nginxHost],
    queryFn: async () => {
      if (nginxHost === undefined) return [];
      return fetchGraphList(nginxHost, sessionStorage.getItem(ID_TOKEN_KEY) as string);
    },
    enabled: Boolean(nginxHost),
  });
};
