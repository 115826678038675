import axios from 'axios';

const instance = axios.create({});

interface Response<T> {
  error: boolean;
  message: string;
  results: T;
}

function prepare(nginx_host: string, idToken: string) {
  instance.defaults.baseURL = `https://${nginx_host}`;
  instance.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
}

export async function fetchGraphList(
  nginx_host: string,
  idToken: string,
  targetPrivileges: string[] = ['READ_SCHEMA', 'READ_QUERY', 'READ_DATA', 'WRITE_QUERY'],
  includeGlobalGraph = false
) {
  prepare(nginx_host, idToken);
  const response = (await instance.get<Response<any>>('/api/gsql-server/gsql/simpleauth')).data;
  const graphList: string[] = [];
  if (!response['error']) {
    const results = response['results'];
    const privileges = results['privileges'];

    let hasGlobalPrivileges = false;
    if (
      Object.keys(privileges).includes('1') &&
      ((Array.isArray(privileges['1']) && hasPrivileges(privileges['1'], targetPrivileges)) ||
        hasPrivileges(privileges['1']['privileges'], targetPrivileges))
    ) {
      hasGlobalPrivileges = true;
      if (includeGlobalGraph) {
        graphList.push('1');
      }
    }

    for (const graphName of Object.keys(privileges).filter((q) => q !== '1')) {
      if (hasGlobalPrivileges) {
        graphList.push(graphName);
      } else {
        const graphPrivileges = privileges[graphName];

        if (
          (Array.isArray(graphPrivileges) && hasPrivileges(graphPrivileges, targetPrivileges)) ||
          hasPrivileges(graphPrivileges['privileges'], targetPrivileges)
        ) {
          graphList.push(graphName);
        }
      }
    }
  }
  return graphList;
}

function hasPrivileges(privileges: Array<string>, targetPrivileges: Array<string>): boolean {
  if (privileges && targetPrivileges.every((privilege) => privileges.includes(privilege))) {
    return true;
  }
  return false;
}
