import { RefObject, useEffect } from 'react';
import { GraphResultRef } from '@/components/graphEditor/GraphResult';
import ConfirmModal from '@/components/ConfirmModal';

interface SchemaDesignerDeactiveDialogProps {
  graphResultRef: RefObject<GraphResultRef>;
  showSchemaChangeModal?: boolean;
  showPrompt?: boolean;
  onConfirmCloseSchemaDesigner?: () => void;
  onCancelCloseSchemaDesigner?: () => void;
}

export default function SchemaDesignerDeactiveDialog(props: SchemaDesignerDeactiveDialogProps) {
  const {
    graphResultRef,
    showSchemaChangeModal,
    showPrompt,
    onConfirmCloseSchemaDesigner,
    onCancelCloseSchemaDesigner,
  } = props;

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (showPrompt && graphResultRef.current?.isSchemaChanged) {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [graphResultRef, showPrompt]);

  return (
    <ConfirmModal
      open={!!showSchemaChangeModal}
      header="Warning"
      body={'You have unsaved changes that will be lost. Do you still want to exit schema designer?'}
      onConfirm={onConfirmCloseSchemaDesigner}
      onCancel={onCancelCloseSchemaDesigner}
    />
  );
}
