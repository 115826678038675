import * as React from 'react';
import { useStyletron, styled } from 'baseui';
import { Override } from 'baseui/overrides';
// @ts-ignore
import { getOverride, getOverrideProps } from 'baseui/helpers/overrides';

import tgCloudLogo from '@/assets/tgcloud-logo.png';

export interface ContainerOverrides {
  Root?: Override<any>;
}

const StyledRoot = styled('div', ({ $theme }) => {
  return {
    marginLeft: 'auto',
    marginRight: 'auto',
    [$theme.mediaQuery.small]: {
      maxWidth: '92vw',
      paddingTop: '48px',
      paddingBottom: '24px',
    },
    [$theme.mediaQuery.medium]: {
      maxWidth: '1048px',
    },
  };
});

export default function Container(props: { children: React.ReactNode; overrides?: ContainerOverrides }) {
  const { children, overrides = {} } = props;

  const [css, theme] = useStyletron();

  const { Root: RootOverride } = overrides;

  const Root = getOverride(RootOverride) || StyledRoot;

  return (
    <Root {...getOverrideProps(RootOverride)}>
      <img
        src={tgCloudLogo}
        alt=""
        className={css({
          width: '161px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
          [theme.mediaQuery.small]: {
            paddingBottom: '12px',
          },
          [theme.mediaQuery.large]: {
            paddingBottom: '24px',
          },
        })}
      />
      {children}
    </Root>
  );
}
