import { WorkGroupT } from '@/pages/workgroup/type';

function findMaxIndex(prefix: string, names: string[]): number {
  const reg = new RegExp(`^${prefix}(\\d+)$`);
  let max = 0;
  names.forEach((name) => {
    const match = name.match(reg);
    if (match) {
      const index = parseInt(match[1], 10);
      if (index > max) {
        max = index;
      }
    }
  });
  return max;
}

export function generateWorkgroupName(groups: WorkGroupT[]): string {
  const names = groups.map((group) => group.name);
  const max = findMaxIndex('Workgroup-', names);
  return `Workgroup-${max + 1}`;
}

export function generateWorkspaceName(group: WorkGroupT): string {
  const names = group.workspaces.map((space) => space.name);
  const max = findMaxIndex('Workspace-', names);
  return `Workspace-${max + 1}`;
}

export function generateDatabaseName(group: WorkGroupT): string {
  const names = group.tg_databases.map((database) => database.name);
  const max = findMaxIndex('Database-', names);
  return `Database-${max + 1}`;
}
