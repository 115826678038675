import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@tigergraph/app-ui-lib/modal';
import ConfirmButtons from '@/components/ConfirmButtons';
import { expand } from 'inline-style-expand-shorthand';

export interface DeleteTypeModalProps {
  isOpen: boolean;
  types: string[];
  onClose: () => void;
  onConfirm: () => void;
}
const schemaURL = '/api/gsql-server/gsql/schema';

export default function DeleteTypeModal(props: DeleteTypeModalProps) {
  const [css] = useStyletron();
  const { isOpen, types, onClose, onConfirm } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <div
      className={css({
        display: 'flex',
        width: '100%',
      })}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onConfirm();
        }
      }}
    >
      <Modal
        onClose={handleClose}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: {
              zIndex: 2,
            },
          },
        }}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Urbanist',
            fontSize: '16px',
            fontWeight: 600,
            ...expand({
              padding: '0',
            }),
          })}
        >
          Delete Type
        </ModalHeader>
        <ModalBody>
          <div
            className={css({
              marginBottom: '8px',
              fontSize: '14px',
              fontWeight: 400,
            })}
          >
            {`Are you sure you want to delete the following type${types.length > 0 ? 's' : ''}?`}
          </div>
          {types.map((type) => (
            <div key={type}>{`- ${type}`}</div>
          ))}
        </ModalBody>
        <ModalFooter>
          <ConfirmButtons confirmLabel={'Confirm'} cancelLabel={'Cancel'} onConfirm={onConfirm} onCancel={onClose} />
        </ModalFooter>
      </Modal>
    </div>
  );
}
