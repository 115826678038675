import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';

// import { formatBytes } from '@/utils/format';
import { WorkGroupT } from '@/pages/workgroup/type';
import { TableContainer } from '@/lib/styled';
import { SettingMenuForDatabase } from '@/pages/workgroup/tab/SettingMenu';
import clsx from 'clsx';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { actionColumnOverrides } from '@/components/table';

export default function Database({ group }: { group: WorkGroupT }) {
  const { tg_databases, workspaces } = group;
  const [css] = useStyletron();

  return (
    <TableContainer>
      <TableBuilder data={tg_databases}>
        <TableBuilderColumn header="Database Name" id="name">
          {(row) => row.name}
        </TableBuilderColumn>
        {/* <TableBuilderColumn header="Region" id="region">
          {(row) => row.region}
        </TableBuilderColumn> */}
        <TableBuilderColumn header="Database ID" id="id">
          {(row) => row.database_id}
        </TableBuilderColumn>
        <TableBuilderColumn header="TigerGraph Verions" id="size">
          {(row) => row.tg_version}
        </TableBuilderColumn>
        {/* <TableBuilderColumn header="Total Vertex" id="totalVertex">
          {(row) => row.totalVertex}
        </TableBuilderColumn>
        <TableBuilderColumn header="Total Edge" id="totalEdge">
          {(row) => row.totalEdge}
        </TableBuilderColumn> */}
        <TableBuilderColumn overrides={actionColumnOverrides} header="Actions">
          {(row) => (
            <div
              className={clsx(
                css({
                  display: 'flex',
                  gap: '6px',
                })
                // 'hoverToShow'
              )}
            >
              <SettingMenuForDatabase
                database={row}
                disableDelete={workspaces.some((w) => w.database_id === row.database_id)}
              />
            </div>
          )}
        </TableBuilderColumn>
      </TableBuilder>
    </TableContainer>
  );
}
