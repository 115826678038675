import { toaster } from 'baseui/toast';
import StyledToast, { StyledToastProps } from './styledToast';
import { expand } from 'inline-style-expand-shorthand';

const showToast = (props: StyledToastProps) => {
  let { autoHideDuration, ...rest } = props;
  if (autoHideDuration === undefined) {
    autoHideDuration = 3000;
    if (rest.kind === 'negative') {
      autoHideDuration = 5000;
    }
  }
  toaster.show(<StyledToast {...rest} autoHideDuration={autoHideDuration} />, {
    overrides: {
      Body: {
        style: {
          width: 'fit-content',
          height: 'fit-content',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          ...expand({
            padding: '0',
          }),
        },
      },
      CloseIcon: <></>,
      InnerContainer: {
        style: {
          ...expand({
            margin: '0',
          }),
        },
      },
    },
  });
};

export default showToast;
