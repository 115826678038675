import { styled } from '@tigergraph/app-ui-lib/Theme';

const IconButton = styled('button', ({ $theme }) => {
  return {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: 0,
    lineHeight: 0,
    borderRadius: '2px',
    color: $theme.colors.gray900,
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: $theme.colors.gray50,
    },
    ':active': {
      backgroundColor: $theme.colors.gray200,
    },
    ':focus-visible': {
      outline: `2px solid ${$theme.colors.borderSelected}`,
    },
    ':disabled': {
      cursor: 'not-allowed',
      color: $theme.colors.gray200,
      backgroundColor: 'transparent',
    },
  };
});

export default IconButton;
