import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Outlet, ScrollRestoration, useLocation, useMatch, useNavigate } from 'react-router-dom';

import IconButton from '@/components/IconButton';
import {
  AddIcon,
  AdminIcon,
  BillIcon,
  Community,
  DatabaseIcon,
  Docs,
  EditorIcon,
  ExplorerIcon,
  GraphIcon,
  GroupIcon,
  IngestionIcon,
  IntegrationIcon,
  MenuCloseIcon,
  MenuOpenIcon,
  ReleaseNotes,
  SettingsIcon,
  SpaceIcon,
  Support,
  UserIcon,
  WorkgroupIcon,
} from '@/pages/home/icons';
import { ChevronDown, ChevronUp, LogInIcon, PackagePlusIcon, StoreIcon } from 'lucide-react';
import Logo from './logo.svg';
import Bg from './icons/bg.svg';

import { getCallbackURL } from '@/lib/auth';
import {
  NavIcon,
  NavIconLevel3,
  NavLabel,
  NavLabelLevel3,
  NavLi,
  NavLink,
  NavLinkLevel2,
  NavLinkLevel3,
  NavOl,
} from '@/pages/home/styled';
import { WorkGroupT } from '@/pages/workgroup/type';

import { useAuth0 } from '@auth0/auth0-react';

import { Avatar, formatName } from '@/components/Avatar';
import { Drawer, DrawerBody, DrawerHeader } from '@/components/Drawer.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import UserGuide from '@/components/userguide';
import { useOrgContext } from '@/contexts/orgContext';
import useProfile from '@/lib/useProfile';
import { isBillingAdmin, isBillingViewer, isOrgAdmin } from '@/pages/admin/user/type';
import { QuickInsightsPoll } from '@/pages/home/QuickInsightsPoll';
import { WorkspacesPoll } from '@/pages/home/WorkspacesPoll';
import { SolutionsPoll } from '@/pages/marketplace/solution/solution_poll';
import { useQueryGetGroups } from '@/pages/workgroup/hook';
import trackUtil from '@/utils/analytics-service.ts';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { Badge, COLOR, StyledRoot } from 'baseui/badge';
import { TriangleDown } from 'baseui/icon';
import { getRequestMailBody, logoutClearance } from '@/utils/utils.ts';
import { TutorialPopover } from '@/components/tutorial/TutorialPopover.tsx';
import { TutorialMenu } from '@/components/tutorial/TutorialMenu.tsx';
import { CongratulationPopover } from '@/components/tutorial/CongratulationPopover.tsx';
import { useTutorialContext } from '@/components/tutorial/tutorialContext.tsx';
import { Landing } from '@/components/landing/Landing.tsx';
import { Tutorial } from '@/components/tutorial/type.ts';
import { Tutorials } from '@/components/tutorial/mock.tsx';
import {
  StyledTable,
  StyledTableHead,
  StyledTableHeadRow,
  StyledTableHeadCell,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableBodyCell,
} from 'baseui/table-semantic';

import FeedbackDrawer from '@/pages/home/feedback/FeedbackDrawer';
import { MdOutlineFeedback } from 'react-icons/md';
import { useAtom } from 'jotai';
import { feedbackOpenAtom } from '@/pages/home/feedback/atom';
import LegalTerms from '@/components/landing/LegalTerms.tsx';

export default function Layout() {
  const [css, theme] = useStyletron();
  const [closeNavBar, setCloseNavBar] = useState(false);
  const location = useLocation();
  const [shouldHideUserGuide, setShouldHideUserGuide] = useState(false);
  const isBeta = true;

  useEffect(() => {
    // trigger dashboard grid re-layout
    window.dispatchEvent(new CustomEvent('resize'));
  }, [closeNavBar]);

  useEffect(() => {
    // close user guide in GSQL Editor
    setShouldHideUserGuide(location.pathname.includes('/editor'));
  }, [location]);

  const { currentOrg, userInfo } = useOrgContext();

  const showAdmin = isOrgAdmin(userInfo.roles) || isBillingAdmin(userInfo.roles) || isBillingViewer(userInfo.roles);

  return (
    <div
      className={css({
        display: 'flex',
        height: '100vh',
      })}
    >
      <div
        className={css({
          borderRight: `1px solid ${theme.colors.divider}`,
          width: closeNavBar ? '64px' : '306px',
          flexShrink: 0,
          flexGrow: 0,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <div
          className={css({
            borderBottom: `1px solid ${theme.colors.divider}`,
            marginTop: '8px',
            padding: closeNavBar ? '12px 20px' : '8px 16px',
            display: 'flex',
            position: 'relative',
          })}
        >
          {closeNavBar || !isBeta ? null : (
            <div
              className={css({
                left: '-20px',
                top: '-8px',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '90px',
                height: '16px',
                transform: 'rotate(-45deg)',
                padding: '0 19px',
                backgroundColor: '#EE7419',
              })}
            >
              <span
                className={css({
                  fontSize: '10px',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  color: '#FFFFFE',
                  position: 'relative',
                  left: '-11px',
                  letterSpacing: '0.5px',
                  transform: 'rotate(0.419deg)',
                })}
              >
                Beta
              </span>
            </div>
          )}

          {closeNavBar ? null : (
            <div
              className={css({
                marginRight: 'auto',
                display: 'flex',
                alignItems: 'center',
              })}
            >
              <img
                className={css({
                  height: '32px',
                  display: 'inline-block',
                  marginRight: '8px',
                })}
                src={Logo}
              />
              {currentOrg?.logo_url ? (
                <img
                  src={currentOrg.logo_url}
                  className={css({
                    borderLeft: `1px solid ${theme.colors.divider}`,
                    height: '32px',
                    display: 'inline-block',
                    paddingLeft: '8px',
                  })}
                />
              ) : (
                <div
                  className={css({
                    borderLeft: `1px solid ${theme.colors.divider}`,
                    display: 'inline-block',
                    paddingLeft: '8px',
                    fontSize: '14px',
                    maxWidth: '120px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  })}
                >
                  {currentOrg?.display_name || currentOrg.org_name}
                </div>
              )}
            </div>
          )}
          <IconButton
            className={css({
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
            onClick={() => setCloseNavBar((prev) => !prev)}
          >
            {closeNavBar ? <MenuOpenIcon /> : <MenuCloseIcon />}
          </IconButton>
        </div>
        <nav
          className={css({
            flexBasis: 0,
            minWidth: 0,
            flexGrow: 1,
            paddingTop: '12px',
            alignSelf: 'stretch',
            overflowY: 'auto',
            overscrollBehavior: 'contain',
            '::-webkit-scrollbar': {
              width: '5px',
              backgroundColor: '#d4dadf',
              borderRadius: '20px',
            },

            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#aab5bf',
              borderRadius: '20px',
            },
          })}
        >
          <ol
            className={css({
              margin: 0,
              padding: 0,
              listStyle: 'none',
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              height: '100%',
            })}
          >
            <GroupItems closeNavBar={closeNavBar} />
            <ListItem
              to="/design-schema"
              onClick={() => {
                trackUtil.trackEnterSchemaDesigner();
              }}
              icon={<GraphIcon />}
              closeNavBar={closeNavBar}
            >
              Design Schema
            </ListItem>
            <ListItem
              to="/ingestion"
              onClick={() => {
                trackUtil.trackEnterDataIngestion();
              }}
              icon={<IngestionIcon />}
              closeNavBar={closeNavBar}
            >
              Load Data
            </ListItem>
            <ListItem
              to="/editor"
              onClick={() => {
                trackUtil.trackEnterGSQLEditor('home');
              }}
              icon={<EditorIcon />}
              closeNavBar={closeNavBar}
            >
              GSQL Editor
            </ListItem>
            <ListItem
              to="/explore"
              onClick={() => {
                trackUtil.trackEnterExploreGraph();
              }}
              icon={
                <div>
                  <ExplorerIcon />
                  {closeNavBar ? (
                    <span
                      className={css({
                        backgroundColor: '#FF6D00',
                        fontSize: '6px',
                        padding: '1px 2px',
                        color: '#FFFFFE',
                        fontWeight: 400,
                        position: 'absolute',
                        left: '0px',
                        top: 0,
                      })}
                    >
                      CoPilot
                    </span>
                  ) : null}
                </div>
              }
              closeNavBar={closeNavBar}
            >
              Explore Graph
              <span
                className={css({
                  backgroundColor: '#FF6D00',
                  borderRadius: '2px',
                  fontSize: '10px',
                  padding: '2px 4px',
                  color: '#FFFFFE',
                  marginLeft: '8px',
                  position: 'relative',
                  top: '-2px',
                  fontWeight: 400,
                })}
              >
                CoPilot
              </span>
            </ListItem>
            <MarketplaceListItem closeNavBar={closeNavBar} />
            {showAdmin ? <AdminListItem closeNavBar={closeNavBar} /> : null}
          </ol>
        </nav>
        <Bottom closeNavBar={closeNavBar} isBeta={isBeta} />
      </div>
      <main
        className={css({
          flexBasis: 0,
          flexGrow: 1,
          height: '100%',
          overflowY: 'auto',
          overscrollBehavior: 'contain',
          backgroundColor: '#f8f8f8',
        })}
      >
        <Outlet />
      </main>
      {currentOrg.org_id && !shouldHideUserGuide && <UserGuide />}
      <Landing />
      <ScrollRestoration />
      <WorkspacesPoll />
      <QuickInsightsPoll />
      <SolutionsPoll />
    </div>
  );
}

function LinkItem({ icon, label }: { icon: ReactNode; label: string }) {
  const { userInfo, currentOrg } = useOrgContext();
  const [css] = useStyletron();
  let url = '';
  switch (label) {
    case 'Community': {
      url = 'https://dev.tigergraph.com/forum/c/tigergrah-cloud/';
      break;
    }
    case 'Feedback': {
      url = 'https://forms.gle/PbdwSBpRKbwabAqE7';
      break;
    }
    case 'Support': {
      url = `mailto:beta-support@tigergraph.com?subject=TigerGraph Cloud 4 Beta Support - ${userInfo.email} - ${
        currentOrg.org_id
      } - ${currentOrg.org_name}&body=${getRequestMailBody()}`;
      break;
    }
    case 'Docs': {
      url = 'https://docs.tigergraph.com/';
      break;
    }
    case 'Release notes': {
      url = 'https://docs.tigergraph.com/cloud/release-notes/';
      break;
    }
  }

  return (
    <IconButton $style={{ width: '100%' }}>
      <a
        onClick={(event) => {
          window.open(url, '_blank');
          event.preventDefault();
        }}
        href={url}
        className={css({
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          fontSize: '12px',
          padding: '8px 12px 8px 0px',
        })}
      >
        {icon} {label}
      </a>
    </IconButton>
  );
}

function Links({ closeNavBar }: { closeNavBar: boolean }) {
  const [css, theme] = useStyletron();
  const [isTutorialPopoverOpen, setIsTutorialPopoverOpen] = useState(false);
  const tutorialRef = useRef<{ clearState: () => void; stepToSubChapter: (tutorial?: Tutorial) => void }>(null);
  const { shouldShowCongratulationModal, setShouldShowCongratulationModal } = useTutorialContext();

  const [isFeedbackOpen, setIsFeedbackOpen] = useAtom(feedbackOpenAtom);

  if (closeNavBar) {
    return null;
  }
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        color: '#757B8C',
        justifyContent: 'flex-start',
        gap: '8px',
        borderTop: `1px solid ${theme.colors.divider}`,
      })}
    >
      <span
        className={css({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '4px',
          width: '100%',
          paddingTop: '8px',
          paddingBottom: '8px',
        })}
      >
        <LinkItem icon={<Community />} label="Community" />
        <IconButton $style={{ width: '100%' }}>
          <span
            onClick={(event) => {
              setIsFeedbackOpen(true);
            }}
            className={css({
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              fontSize: '12px',
              padding: '8px 12px 8px 0px',
            })}
          >
            <MdOutlineFeedback size={16} /> Feedback
          </span>
        </IconButton>
        <LinkItem icon={<Docs />} label={'Docs'} />
        <LinkItem icon={<ReleaseNotes />} label={'Release notes'} />
        <LinkItem icon={<Support />} label={'Support'} />
        <TutorialMenu
          onClick={() => {
            if (isTutorialPopoverOpen) {
              tutorialRef.current?.clearState();
              setIsTutorialPopoverOpen(false);
            } else {
              setIsTutorialPopoverOpen(true);
            }
          }}
        />
      </span>
      <CongratulationPopover
        showModal={shouldShowCongratulationModal}
        handleClose={() => {
          setShouldShowCongratulationModal(false);
        }}
        onTakeTour={() => {
          setShouldShowCongratulationModal(false);
          setIsTutorialPopoverOpen(true);
          const startTutorial = Tutorials.find((tutorial) => tutorial.id === '0');
          tutorialRef.current?.stepToSubChapter(startTutorial);
        }}
      />
      <TutorialPopover
        ref={tutorialRef}
        isOpen={isTutorialPopoverOpen}
        onClose={() => setIsTutorialPopoverOpen(false)}
      />
      <FeedbackDrawer isShow={isFeedbackOpen} onClose={() => setIsFeedbackOpen(false)} />
    </div>
  );
}

function Bottom({ closeNavBar, isBeta }: { closeNavBar: boolean; isBeta: boolean }) {
  const [css, theme] = useStyletron();
  const { currentOrg, orgList, setCurrentOrg } = useOrgContext();
  const { loginWithRedirect } = useAuth0();
  const [showSwitchDrawer, setShowSwitchDrawer] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const { data: profile } = useProfile();

  let userEmail = '';
  if (profile) {
    userEmail = profile.email;
  }

  const { logout } = useAuth0();
  let isSwitchingOrg = false;

  const handleSwitchClassic = () => {
    let classicUrl = '';
    if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging') {
      classicUrl = 'https://tgcloud.io/';
    } else {
      classicUrl = 'https://tgcloud-dev.com/';
    }
    window.location.href = classicUrl;
  };

  return (
    <div
      className={css({
        padding: closeNavBar ? '16px 16px 16px 16px' : '16px 16px 16px 16px',
      })}
    >
      <Drawer
        isOpen={showSwitchDrawer}
        onClose={() => setShowSwitchDrawer(false)}
        overrides={{
          Root: {
            style: {
              zIndex: 100,
            },
          },
        }}
      >
        <DrawerHeader>Switch Organization</DrawerHeader>
        <DrawerBody
          $style={{
            maxHeight: 'calc(100vh - 57px)',
            paddingTop: '8px',
            paddingBottom: '8px',
            overflowY: 'auto',
          }}
        >
          <StyledRoot>
            <StyledTable>
              <StyledTableHead>
                <StyledTableHeadRow>
                  <StyledTableHeadCell className="w-[200px]">Organization Id</StyledTableHeadCell>
                  <StyledTableHeadCell>Display Name</StyledTableHeadCell>
                  <StyledTableHeadCell className="w-[50px]">Action</StyledTableHeadCell>
                </StyledTableHeadRow>
              </StyledTableHead>
              <StyledTableBody>
                {orgList?.map((org) => (
                  <StyledTableBodyRow className="group" key={org.org_id}>
                    <StyledTableBodyCell>
                      {org.org_name}
                      {org.org_id === currentOrg.org_id && (
                        <Badge
                          content={'Current'}
                          hierarchy={'secondary'}
                          color={COLOR.positive}
                          overrides={{
                            Badge: {
                              style: {
                                padding: '4px 12px',
                                borderRadius: '20px',
                                height: '24px',
                                marginLeft: '8px',
                              },
                            },
                          }}
                        />
                      )}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>{org.display_name ?? org.org_name}</StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <div className="opacity-0 group-hover:opacity-100">
                        <div className="flex space-x-1">
                          <IconButton
                            tabIndex={-1}
                            className="flex items-center"
                            disabled={org.org_id === currentOrg.org_id}
                            onClick={() => {
                              setCurrentOrg(org);
                              loginWithRedirect({
                                authorizationParams: {
                                  organization: org.org_id,
                                  redirect_uri: getCallbackURL('/'),
                                },
                              });
                            }}
                          >
                            <LogInIcon className="cursor-pointer" />
                          </IconButton>
                        </div>
                      </div>
                    </StyledTableBodyCell>
                  </StyledTableBodyRow>
                ))}
              </StyledTableBody>
            </StyledTable>
          </StyledRoot>
        </DrawerBody>
      </Drawer>
      {!closeNavBar && isBeta ? (
        <div
          className={css({
            marginBottom: '16px',
            padding: '12px 20px 12px 22px',
            backgroundColor: '#FFFAF6',
            backgroundImage: `url(${Bg})`,
          })}
        >
          <div
            className={css({
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: 600,
              fontFamily: 'Urbanist',
              color: '#3F5870',
            })}
          >
            Welcome to <br />
            TigerGraph Cloud 4 Beta!
          </div>
          <div
            className={css({
              fontSize: '10px',
              lineHeight: '16px',
              color: '#909090',
            })}
          >
            Try out our latest features and improvements.
          </div>
        </div>
      ) : null}
      <Links closeNavBar={closeNavBar} />
      <div
        className={css({
          paddingTop: '16px',
          borderTop: `1px solid ${theme.colors.divider}`,
          display: 'flex',
          justifyContent: closeNavBar ? 'center' : 'flex-start',
        })}
      >
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <IconButton
              className={css({
                display: 'flex',
                alignItems: 'center',
              })}
            >
              <Avatar size="compact">{profile ? formatName(profile) : ''}</Avatar>
              {!closeNavBar ? (
                <>
                  <span
                    className={css({
                      paddingLeft: '8px',
                      paddingRight: '4px',
                      fontSize: '14px',
                      color: '#57686E',
                      lineHeight: 1.4,
                      maxWidth: '15em',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    })}
                  >
                    {userEmail}
                  </span>
                  <TriangleDown />
                </>
              ) : null}
            </IconButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              onSelect={() => {
                handleSwitchClassic();
              }}
            >
              <span>Go to TigerGraph Cloud Classic</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setShowTerms(true);
              }}
            >
              <span>Terms and Conditions</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setShowSwitchDrawer(true);
              }}
            >
              <span>Switch Organization</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                logoutClearance();
                logout({
                  logoutParams: { returnTo: window.location.origin },
                });
              }}
            >
              <span>Logout</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <LegalTerms isViewMode={true} showModal={showTerms} onClose={() => setShowTerms(false)} />
      </div>
    </div>
  );
}

function ListItem({
  to,
  children,
  icon,
  level = 1,
  closeNavBar,
  onClick,
}: {
  to: string;
  children: ReactNode;
  icon: ReactNode;
  level?: 1 | 2 | 3;
  closeNavBar?: boolean;
  onClick?: () => void;
}) {
  let Link = NavLink;
  let Label = NavLabel;
  let Icon = NavIcon;
  if (level === 2) {
    Link = NavLinkLevel2;
  }
  if (level === 3) {
    Link = NavLinkLevel3;
    Label = NavLabelLevel3;
    Icon = NavIconLevel3;
  }

  const match = useMatch(to);
  return (
    <NavLi>
      <Link to={to} onClick={onClick}>
        <Icon>{icon}</Icon>
        {!closeNavBar ? <Label $match={!!match}>{children}</Label> : null}
      </Link>
    </NavLi>
  );
}

function MarketplaceListItem({ closeNavBar }: { closeNavBar: boolean }) {
  const location = useLocation();
  const [showSubItems, setShowSubItems] = useState(location.pathname.startsWith('/admin'));
  const { userInfo } = useOrgContext();
  const isOrgAdminRole = isOrgAdmin(userInfo.roles);

  return (
    <NavLi>
      <NavLink
        to="/marketplace"
        onClick={(e) => {
          e.preventDefault();
          setShowSubItems(!showSubItems);
        }}
      >
        <NavIcon>
          <StoreIcon size="16px" />
        </NavIcon>
        {!closeNavBar ? (
          <>
            <NavLabel $match={false} $style={{ flexGrow: 1, maxWidth: 'none' }}>
              Marketplace
            </NavLabel>
            {!showSubItems ? <ChevronDown size="16px" /> : <ChevronUp size="16px" />}
          </>
        ) : null}
      </NavLink>
      {showSubItems && !closeNavBar ? (
        <NavOl>
          <ListItem to="/marketplace/solutions" icon={<IntegrationIcon />} level={2}>
            Solutions
          </ListItem>
          <ListItem to="/marketplace/addons" icon={<PackagePlusIcon size="16px" />} level={2}>
            Add-Ons
          </ListItem>
        </NavOl>
      ) : null}
    </NavLi>
  );
}

function AdminListItem({ closeNavBar }: { closeNavBar: boolean }) {
  const location = useLocation();
  const [showSubItems, setShowSubItems] = useState(location.pathname.startsWith('/admin'));
  const { userInfo } = useOrgContext();
  const isOrgAdminRole = isOrgAdmin(userInfo.roles);

  return (
    <NavLi>
      <NavLink
        to="/admin"
        onClick={(e) => {
          e.preventDefault();
          setShowSubItems(!showSubItems);
        }}
      >
        <NavIcon>
          <AdminIcon />
        </NavIcon>
        {!closeNavBar ? (
          <>
            <NavLabel $match={false} $style={{ flexGrow: 1, maxWidth: 'none' }}>
              Admin
            </NavLabel>
            {!showSubItems ? <ChevronDown size="16px" /> : <ChevronUp size="16px" />}
          </>
        ) : null}
      </NavLink>
      {showSubItems && !closeNavBar ? (
        <NavOl>
          {isOrgAdminRole ? (
            <ListItem to="/admin/users" icon={<UserIcon />} level={2}>
              User
            </ListItem>
          ) : null}
          {/* <ListItem to="/admin/activities" icon={<ActivityIcon />} level={2}>
            Activity
          </ListItem> */}
          <ListItem to="/admin/bills" icon={<BillIcon />} level={2}>
            Billing
          </ListItem>
          {isOrgAdminRole ? (
            <>
              <ListItem to="/admin/settings" icon={<SettingsIcon />} level={2}>
                Setting
              </ListItem>
            </>
          ) : null}
        </NavOl>
      ) : null}
    </NavLi>
  );
}

function GroupItems({ closeNavBar }: { closeNavBar: boolean }) {
  const [css, theme] = useStyletron();
  const match = useMatch('/groups');
  const navigate = useNavigate();

  const { userInfo } = useOrgContext();
  const isOrgAdminRole = isOrgAdmin(userInfo.roles);

  const { isLoading, data } = useQueryGetGroups();

  const [showSubItems, setShowSubItems] = useState(false);

  const groups = data?.Result || [];

  return (
    <NavLi
      className={css({
        position: 'relative',
        // we want
        // 1. do not take all available space in case the total height of GroupItem is small
        // 2. take all the available space and do local scroll in GroupItem when the total height of GroupItem is large
        // currently, seems no css feature to support this behavior
        // so we may need to dynamically calculate
        //    1. available height in parent
        //    2. it's own height
        // ...(showSubItems && !closeNavBar
        //   ? {
        //       flexGrow: 1,
        //       overflowY: 'auto',
        //       '::-webkit-scrollbar': {
        //         width: '5px',
        //         backgroundColor: '#d4dadf',
        //         borderRadius: '20px',
        //       },

        //       '::-webkit-scrollbar-thumb': {
        //         backgroundColor: '#aab5bf',
        //         borderRadius: '20px',
        //       },
        //     }
        //   : {}),
      })}
    >
      <NavLink
        to="/groups"
        onClick={(e) => {
          e.preventDefault();
          if (closeNavBar) {
            return;
          }
          setShowSubItems(!showSubItems);

          // if no group, redirect to create group page
          if (!isLoading && groups.length === 0) {
            navigate('/groups');
          }
        }}
      >
        <NavIcon>
          <WorkgroupIcon />
        </NavIcon>
        {!closeNavBar && (
          <>
            <NavLabel $match={!!match} $style={{ marginRight: '6px' }}>
              Workgroups
            </NavLabel>
            {isOrgAdminRole ? (
              <IconButton
                // @ts-ignore
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate('/groups/new');
                }}
              >
                <AddIcon />
              </IconButton>
            ) : null}
          </>
        )}
      </NavLink>
      {groups.length > 0 ? (
        <IconButton
          className={css({
            position: 'absolute',
            top: '12px',
            right: '12px',
          })}
          onClick={() => {
            setShowSubItems(!showSubItems);
          }}
        >
          {!closeNavBar && (!showSubItems ? <ChevronDown size="16px" /> : <ChevronUp size="16px" />)}
        </IconButton>
      ) : null}
      {!closeNavBar && showSubItems
        ? groups.map((group) => <GroupItem group={group} key={group.workgroup_id} />)
        : null}
      {isLoading && !closeNavBar ? (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '8px',
          })}
        >
          <Spinner $size="16px" $borderWidth="2px" $color={theme.colors.primary900} />
        </div>
      ) : null}
    </NavLi>
  );
}

function GroupItem({ group }: { group: WorkGroupT }) {
  const [css] = useStyletron();
  const to = `/groups/${group.workgroup_id}`;
  const match = useMatch(to);

  const [showSubItems, setShowSubItems] = useState(false);

  return (
    <NavOl
      className={css({
        position: 'relative',
        rowGap: '0px',
      })}
    >
      <NavLinkLevel2 to={to}>
        <NavIcon>
          <GroupIcon />
        </NavIcon>
        <NavLabel $match={!!match}>{group.name}</NavLabel>
      </NavLinkLevel2>
      {group.workspaces.length + group.tg_databases.length > 0 ? (
        <IconButton
          className={css({
            position: 'absolute',
            top: '12px',
            right: '12px',
          })}
          onClick={() => {
            setShowSubItems(!showSubItems);
          }}
        >
          {!showSubItems ? <ChevronDown size="16px" /> : <ChevronUp size="16px" />}
        </IconButton>
      ) : null}
      {showSubItems ? (
        <NavOl>
          {group.workspaces.map((space) => (
            <ListItem
              key={space.name}
              to={`/groups/${group.workgroup_id}?tab=workspaces`}
              icon={<SpaceIcon />}
              level={3}
            >
              {space.name}
            </ListItem>
          ))}
          {group.tg_databases.map((database) => (
            <ListItem
              key={database.name}
              to={`/groups/${group.workgroup_id}?tab=databases`}
              icon={<DatabaseIcon />}
              level={3}
            >
              {database.name}
            </ListItem>
          ))}
        </NavOl>
      ) : null}
    </NavOl>
  );
}
