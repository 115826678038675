import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { expand } from 'inline-style-expand-shorthand';
import ConfirmButtons from '@/components/ConfirmButtons';
import { ReactNode } from 'react';

export interface ConfirmModalProps {
  header: ReactNode;
  body: ReactNode;
  open: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const [css, theme] = useStyletron();
  const { header, body, open, onConfirm, onCancel } = props;
  return (
    <Modal isOpen={open} onClose={onCancel ?? onConfirm}>
      <ModalHeader
        className={css({
          fontFamily: 'Urbanist',
          fontSize: '18px',
          fontWeight: 600,
          color: theme.colors.gray1000,
          ...expand({
            padding: '0',
          }),
        })}
      >
        {header}
      </ModalHeader>
      <ModalBody
        className={css({
          marginBottom: '8px',
          fontSize: '14px',
          fontWeight: 400,
        })}
      >
        {body}
      </ModalBody>
      <ModalFooter>
        <ConfirmButtons {...props} />
      </ModalFooter>
    </Modal>
  );
}
