import { ToasterContainer, PLACEMENT } from 'baseui/toast';

const StyledToasterContainer = () => (
  <ToasterContainer
    placement={PLACEMENT.bottomLeft}
    overrides={{
      Root: {
        style: {
          zIndex: 9999,
          maxWidth: '416px',
        },
      },
      ToastInnerContainer: {
        style: {
          width: '100%',
        },
      },
    }}
  />
);

export default StyledToasterContainer;
