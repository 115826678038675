import { AddonsMetaID } from '@/components/addons/consts';
import {
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@/components/ui/dropdown-menu';
import { useOrgContext } from '@/contexts/orgContext';
import { useAddonsMeta } from '@/hooks/useAddonsMeta';
import { EnabledAddonsType, useListEnabledAddons } from '@/hooks/useListEnabledAddons';
import { useConfig } from '@/lib/config';
import { WorkspaceT } from '@/pages/workgroup/type';
import trackUtil, { AppToolType } from '@/utils/analytics-service';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { FC, useCallback, useMemo } from 'react';
import { PackagePlusIcon } from 'lucide-react';
import { getToolsLink } from '@/hooks/useToolsLink';
import { Link } from 'react-router-dom';

interface AddonsMenuCommon {
  name: string;
  addonID: string;
  enabled?: boolean;
  alwaysEnabled?: boolean;
}

interface AddonsMenuItemToolsLink extends AddonsMenuCommon {
  interationType: 'tools-link';
  path: string;
}

interface AddonsMenuItemLink extends AddonsMenuCommon {
  interationType: 'link';
  url: string;
}

type AddonsMenuItemType = AddonsMenuItemToolsLink | AddonsMenuItemLink;

const presetToolsLinkPathMap = new Map<string, string>([
  [AddonsMetaID.ADMIN_PORTAL, '/admin'],
  [AddonsMetaID.GRAPH_STUDIO, '/studio'],
  [AddonsMetaID.INSIGHTS, '/insights'],
  [AddonsMetaID.GRAPHQL, '/graphql'],
]);
const presetLinkURLMap = new Map<string, string>([[AddonsMetaID.COPILOT, '/']]);

const IconContainer = styled('span', ({ $theme }) => {
  return {
    width: '18px',
    height: '18px',
    display: 'flex',
  };
});

const ConnectionMenuAddons: FC<{ workspace: WorkspaceT; onOpenDrawer?(): void }> = ({ workspace, onOpenDrawer }) => {
  const { currentOrg } = useOrgContext();
  const { appConfig } = useConfig();
  const { data: addonsMeta } = useAddonsMeta();
  const { data: addonsEnabledMap } = useListEnabledAddons({
    select: useCallback((data?: EnabledAddonsType[]) => new Map(data?.map((item) => [item.AddonsID, item])), []),
  });

  const [css] = useStyletron();

  const addonsMenuItems = useMemo<AddonsMenuItemType[]>(() => {
    const workspaceAddonsEnabledMap = new Map(workspace.addons?.map((a) => [a.addons_id, a.enable]));
    const result: AddonsMenuItemType[] =
      addonsMeta?.AddonsList.filter((m) => {
        if (m.ID === AddonsMetaID.COPILOT && !workspace.is_rw) {
          return false;
        }
        return addonsEnabledMap?.get(m.ID)?.Enabled;
      }).map((m) => {
        const common = {
          name: m.Title,
          addonID: m.ID,
          enabled: workspaceAddonsEnabledMap.get(m.ID) || false,
        };
        if (m.ID === AddonsMetaID.COPILOT) {
          return {
            ...common,
            interationType: 'link',
            url: `/explore?tab=copilot&workspace_id=$${workspace.workspace_id}`,
          };
        }
        return {
          ...common,
          interationType: 'tools-link',
          path: presetToolsLinkPathMap.get(m.ID) || '',
        };
      }) || [];
    result.unshift({
      interationType: 'tools-link',
      name: AppToolType.AdminPortal,
      addonID: AddonsMetaID.ADMIN_PORTAL,
      alwaysEnabled: true,
      path: '/admin',
    });
    return result;
  }, [addonsEnabledMap, addonsMeta, workspace.addons, workspace.workspace_id, workspace.is_rw]);

  const getLink = (menuItem: AddonsMenuItemToolsLink) => {
    return getToolsLink(appConfig, currentOrg, workspace, menuItem.path);
  };

  const handleMenuItemClick = (menuItem: AddonsMenuItemType) => {
    switch (menuItem.interationType) {
      case 'link':
        menuItem.name in AppToolType && trackUtil.trackEnterAppTools(menuItem.name as AppToolType);
        break;
    }
  };

  const isMenuItemDisabled = (menuItem: AddonsMenuItemType) => {
    if (menuItem.alwaysEnabled) {
      return false;
    }

    return !menuItem.enabled;
  };

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <IconContainer>
          <PackagePlusIcon size="16px" />
        </IconContainer>
        <Body2 className={css({ marginLeft: '8px' })}>Connect from Add-Ons</Body2>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {addonsMenuItems.map((menuItem) => (
            <DropdownMenuItem
              key={menuItem.name}
              onClick={() => handleMenuItemClick(menuItem)}
              disabled={isMenuItemDisabled(menuItem)}
            >
              {menuItem.interationType === 'tools-link' && (
                <a target="_blank" href={getLink(menuItem)} rel="noreferrer noopener">
                  {menuItem.name}
                </a>
              )}
              {menuItem.interationType === 'link' && <Link to={menuItem.url}>{menuItem.name}</Link>}
            </DropdownMenuItem>
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};

export default ConnectionMenuAddons;
