import { RoleType, UserInfo } from '@/lib/models';
import { axiosCluster, axiosController, axiosOrgService } from '@/lib/network';
import { Result, Return } from '@/lib/type';
import { DBRoles, DBUser, UserData, UserRoles } from '@/pages/admin/user/type';

export async function fetchUsers(): Promise<UserInfo[]> {
  const response = await axiosOrgService.get('/users');
  return response.data;
}

export async function getUserDetail(userId: string): Promise<UserInfo> {
  const response = await axiosOrgService.get(`/users/${userId}`);
  return response.data;
}

export async function inviteUser(users: UserData[]): Promise<void> {
  const response = await axiosOrgService.post('/users/batch_invite?is_new_cloud=true', { users });
  return response.data;
}

export async function deleteUser(userId: string): Promise<void> {
  const response = await axiosOrgService.delete(`/users/${userId}`);
  return response.data;
}

export async function resendInvitation(orgId: string, email: string): Promise<void> {
  const response = await axiosOrgService.post(`/orgs/${orgId}/resend_invitation?is_new_cloud=true`, { email });
  return response.data;
}

export async function modifyOrgUserRole(userId: string, role: RoleType): Promise<void> {
  const response = await axiosOrgService.put(`/users/${userId}/roles`, {
    roles: [role],
  });
  return response.data;
}

export async function removeOrgUserRole(userId: string, role: RoleType): Promise<void> {
  const response = await axiosOrgService.delete(`/users/${userId}/roles`, {
    data: {
      roles: [role],
    },
  });
  return response.data;
}

export async function assignGroupAdmin(groupID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/admins/add`, {
    userEmails,
  });
  return response.data;
}

export async function unAssignGroupAdmin(groupID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/admins/remove`, {
    userEmails,
  });
  return response.data;
}

export async function assignWorkspaceAdmin(groupID: string, spaceID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/workspaces/${spaceID}/admins/add`, {
    userEmails,
  });
  return response.data;
}

export async function assignWorkspaceUser(groupID: string, spaceID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/workspaces/${spaceID}/users/add`, {
    userEmails,
  });
  return response.data;
}

export async function unAssignWorkspaceRole(groupID: string, spaceID: string, userEmails: string[]): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/workspaces/${spaceID}/roles/remove`, {
    userEmails,
  });
  return response.data;
}

export type SpaceWithGroup = string;

export async function assignUserRole(
  userEmail: string,
  workgroupsToAssignAdmin: string[],
  workspacesToAssignAdmin: SpaceWithGroup[],
  workspacesToAssignUser: SpaceWithGroup[],
  billingRoleToAssign?: RoleType
): Promise<void> {
  const response = await axiosController.post('/v2/roles/assign', {
    userEmail,
    workgroupsToAssignAdmin,
    workspacesToAssignAdmin,
    workspacesToAssignUser,
    billingRoleToAssign: billingRoleToAssign || '',
  });
  return response.data;
}

export async function unAssignUserRole(
  userEmail: string,
  workgroupsToRevokeAdmin: string[],
  workspacesToRevokeRoles: SpaceWithGroup[],
  billingRoleToRevoke: boolean
): Promise<void> {
  const response = await axiosController.post('/v2/roles/revoke', {
    userEmail,
    workgroupsToRevokeAdmin,
    workspacesToRevokeRoles,
    billingRoleToRevoke: billingRoleToRevoke,
  });
  return response.data;
}

export async function fetchDBUsers(groupID: string, spaceID: string): Promise<DBUser[]> {
  const response = await axiosController.get<Result<DBUser[]>>(
    `/v2/workgroups/${groupID}/workspaces/${spaceID}/dbuser`
  );
  return response.data.Result || [];
}

export async function createDBUser(
  groupID: string,
  spaceID: string,
  username: string,
  password: string
): Promise<void> {
  const response = await axiosController.post(`/v2/workgroups/${groupID}/workspaces/${spaceID}/dbuser`, {
    username,
    password,
  });
  return response.data;
}

export async function updateDBUser(
  groupID: string,
  spaceID: string,
  username: string,
  password: string
): Promise<void> {
  const response = await axiosController.put(`/v2/workgroups/${groupID}/workspaces/${spaceID}/dbuser`, {
    username,
    password,
  });
  return response.data;
}

export async function deleteDBUser(groupID: string, spaceID: string, username: string): Promise<void> {
  const response = await axiosController.delete(`/v2/workgroups/${groupID}/workspaces/${spaceID}/dbuser`, {
    data: {
      username,
    },
  });
  return response.data;
}

export async function fetchDBRoles(): Promise<DBRoles> {
  const response = await axiosCluster.get<Return<DBRoles>>(`/api/gsql-server/gsql/roles`);
  return (
    response.data.results || {
      builtIn: {
        global: [],
        local: [],
      },
      userDefinedRoles: {
        1: [],
      },
    }
  );
}

export async function fetchDBUserRoles(name: string): Promise<UserRoles> {
  const response = await axiosCluster.get<Return<UserRoles>>(
    `/api/gsql-server/gsql/users?name=${encodeURIComponent(name)}`
  );
  return (
    response.data.results || {
      privileges: {},
      roles: {},
    }
  );
}

export async function assignDBRoles(roles: string[], username: string, graphName: string): Promise<void> {
  const response = await axiosCluster.post(
    `/api/gsql-server/gsql/user${graphName !== '1' ? `?graph=${encodeURIComponent(graphName)}` : ''}`,
    {
      roles,
      usernames: [username],
    }
  );
  return response.data;
}

export async function revokeDBRoles(roles: string[], username: string, graphName: string): Promise<void> {
  const params = new URLSearchParams();
  for (let role of roles) {
    params.append('role', role);
  }
  params.append('username', username);
  if (graphName !== '1') {
    params.append('graph', graphName);
  }

  const response = await axiosCluster.delete(`/api/gsql-server/gsql/user?${params.toString()}`);
  return response.data;
}

// simple auth -> get user list

// gap/auth.service.ts

/*
  //  Return all the graphs the user can manage based on required privileges.
  getAdminGraphList(privileges: GSQLPrivilege[]): string[] {
    return this.getGraphList().filter(graphName => this.hasAllPrivileges(privileges, graphName));
  }

*/
