import { styled } from '@tigergraph/app-ui-lib/Theme';

const TextButton = styled('button', ({ $theme }) => {
  return {
    outline: 'none',
    cursor: 'pointer',
    padding: '2px 8px',
    border: '1px solid #AAB5BF',
    borderRadius: '2px',
    color: $theme.colors.gray900,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: $theme.colors.gray50,
    },
    ':active': {
      backgroundColor: $theme.colors.gray200,
    },
    ':focus-visible': {
      outline: `2px solid ${$theme.colors.borderSelected}`,
    },
    ':disabled': {
      cursor: 'not-allowed',
      backgroundColor: $theme.colors.gray200,
    },
  };
});

export default TextButton;
