import { Tab, Tabs } from '@/components/Tab';
import { ErrorDisplay } from '@/components/error';
import { Result } from '@/lib/type';
import QuickInsightLists from '@/pages/dashboard/quickInsightLists';
import SolutionList from '@/pages/marketplace/solution/solution_list';
import EmptyState from '@/pages/workgroup/EmptyState';
import { deleteWorkGroup, getWorkGroupDetail } from '@/pages/workgroup/api';
import { workSpacesAtom } from '@/pages/workgroup/atom';
import Database from '@/pages/workgroup/tab/Database';
import General from '@/pages/workgroup/tab/General';
// import Maintenance from '@/pages/workgroup/tab/Maintenance';
import Workspace from '@/pages/workgroup/tab/Workspace';
import AccessManagement from '@/pages/workgroup/tab/iam/AccessManagement';
import { getErrorMessage, shouldShowBackupRestore } from '@/utils/utils';
import { WorkGroupT, isRefreshIng, isStatusPending } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import Skeleton from './icons/skeleton.svg';
import { useSearchParams, useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import { BackupRestore } from '@/pages/workgroup/tab/backupRestore/BackupRestore';
import { Suspense, lazy } from 'react';
import { LoadingIndicator } from '@/components/loading-indicator';
import NoPermission from '@/components/NoPermission.tsx';
import { Monitor } from '@/pages/workgroup/tab/Monitor.tsx';
import { useOrgContext } from '@/contexts/orgContext.tsx';
import { NewsFeed } from '@/components/newsFeed.tsx';

const QuickInsights = lazy(
  () =>
    import(
      /* webpackChunkName: "data-insights" */
      '../../pages/dashboard/quickInsights.tsx'
    )
);

export default function WorkGroupPage() {
  const [css] = useStyletron();
  const params = useParams();
  const navigate = useNavigate();
  const { userInfo } = useOrgContext();

  const [searchParams] = useSearchParams();
  const groupID = params.groupID!;

  const location = useLocation();
  const locationState = location.state as {
    skipEmptyState: boolean;
  } | null;

  const [workspaces, setWorkspaces] = useAtom(workSpacesAtom);
  const groupQuery = useQuery<Result<WorkGroupT>, AxiosError>(
    ['group', groupID],
    async () => {
      return getWorkGroupDetail(groupID);
    },
    {
      onSuccess: (data) => {
        // check workspace status and add to polling list if needed.
        const results = data?.Result?.workspaces || [];
        for (let workspace of results) {
          if (isStatusPending(workspace.status) || isRefreshIng(workspace.refresh_status)) {
            if (!workspaces.find((item) => item.workspace_id === workspace.workspace_id)) {
              setWorkspaces((workspaces) => workspaces.concat(workspace));
            }
          }
        }
      },
    }
  );

  const queryClient = useQueryClient();
  const deleteGroupMutation = useMutation<Result<void>, AxiosError, { group_id: string }>(
    (data) => {
      return deleteWorkGroup(data.group_id);
    },
    {
      onSuccess: async (_, data) => {
        queryClient.setQueryData<Result<WorkGroupT[]> | undefined>(['groups'], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: oldData.Result.filter((item) => item.workgroup_id !== data.group_id),
          };
          return newData;
        });
        navigate('/groups', {
          replace: true,
        });
      },
    }
  );

  if (groupQuery.isLoading) {
    return (
      <img
        src={Skeleton}
        className={css({
          marginTop: '14px',
        })}
      />
    );
  }

  if (groupQuery.isError) {
    if (getErrorMessage(groupQuery.error).indexOf('operation denied') > -1) {
      return <NoPermission />;
    }
    return <ErrorDisplay label="Server Error:" error={groupQuery.error} />;
  }

  const group = groupQuery.data?.Result;
  if (!group) {
    return null;
  }

  const onDelete = () => {
    const promise = deleteGroupMutation.mutateAsync({ group_id: group.workgroup_id });
    toast.promise(
      promise,
      {
        loading: 'Deleting workgroup',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  if (group.tg_databases.length === 0 && group.workspaces.length === 0 && !locationState?.skipEmptyState) {
    return <EmptyState toPage="spaces/config" onDelete={onDelete} group_id={group.workgroup_id} />;
  }

  return (
    <div
      className={css({
        padding: '16px 0 0',
        height: '56px',
        backgroundColor: '#fff',
      })}
    >
      <Tabs
        activeKey={searchParams.get('tab') || 'general'}
        onChange={({ activeKey }) => {
          navigate(`/groups/${group.workgroup_id}?tab=${activeKey}`, {
            replace: true,
          });
        }}
        activateOnFocus
      >
        <Tab title="General" key="general">
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: 'calc(100vh - 89px)',
            })}
          >
            <General group={group} />
            <NewsFeed />
          </div>
        </Tab>
        <Tab title="Workspace" key="workspaces">
          <Workspace group={group} />
        </Tab>
        <Tab title="Database" key="databases">
          <Database group={group} />
        </Tab>
        <Tab title="Monitor" key="monitor">
          <Monitor group={group} />
        </Tab>
        <Tab title="Solution" key="solution">
          <SolutionList group={group} />
        </Tab>
        <Tab title="Data Profile" key="dataprofiles">
          <Routes>
            <Route path="/" element={<QuickInsightLists group={group} />} />
            <Route
              path="dataprofiles/:appID"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <QuickInsights />
                </Suspense>
              }
            />
          </Routes>
        </Tab>
        <Tab title="Access Management" key="access_management">
          <AccessManagement group={group} />
        </Tab>
        {/* <Tab title="Maintenance" key="maintenance">
          <Maintenance group={group} />
        </Tab> */}
        {shouldShowBackupRestore(userInfo, groupID) && (
          <Tab title="Backup And Restore" key="backup_and_restore">
            <BackupRestore group={group} />
          </Tab>
        )}
      </Tabs>
    </div>
  );
}
