import { useCallback, useEffect, useState } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { useSchemaDesignerContext } from '@/contexts/schemaDesignerContext';
import { GSQLEdgeJson, TypeCheckedStatus } from '@tigergraph/tools-models/topology';
import { GLOBAL_GRAPH_NAME, HelperFunctions } from '@tigergraph/tools-models';
import { useSchema } from '@/utils/graphEditor/useMetaHook';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { expand } from 'inline-style-expand-shorthand';
import { AiOutlineGlobal } from 'react-icons/ai';
import { Spinner } from 'baseui/spinner';

const items = [{ label: 'Run selection' }];

interface GlobalTypePopoverProps {
  disabled: boolean;
  updateGlobalTypes: (globalVertexTypes: TypeCheckedStatus[], globalEdgeTypes: TypeCheckedStatus[]) => void;
}

export default function GlobalTypePopover(props: GlobalTypePopoverProps) {
  const [css, theme] = useStyletron();
  const [globalVertexTypes, setGlobalVertexTypes] = useState<{ [name: string]: TypeCheckedStatus }>({});
  const [globalEdgeTypes, setGlobalEdgeTypes] = useState<{ [name: string]: TypeCheckedStatus }>({});
  const { disabled, updateGlobalTypes } = props;

  const { designerService } = useSchemaDesignerContext();
  const { data: globalSchema, isFetching } = useSchema(GLOBAL_GRAPH_NAME);

  const disableGlobalEdgeTypeCheckbox = (edgeType: GSQLEdgeJson, localTypes: string[]): boolean => {
    if (edgeType.EdgePairs) {
      for (const pair of edgeType.EdgePairs) {
        if (localTypes.includes(pair.From) || localTypes.includes(pair.To)) {
          return true;
        }
      }
    }
    return localTypes.includes(edgeType.FromVertexTypeName) || localTypes.includes(edgeType.ToVertexTypeName);
  };

  const resetGlobalTypes = useCallback(() => {
    if (!globalSchema || isFetching) {
      return;
    }
    const globalTypesInGraph = designerService.getAllGlobalTypes();
    const localTypes = designerService.getAllLocalTypes();
    setGlobalVertexTypes(
      globalSchema.VertexTypes.reduce((prev, vertex) => {
        prev[vertex.Name] = {
          vertexOrEdgeType: vertex,
          selected: globalTypesInGraph.includes(vertex.Name),
          disabled: localTypes.includes(vertex.Name),
        };
        return prev;
      }, {} as { [name: string]: TypeCheckedStatus })
    );
    setGlobalEdgeTypes(
      globalSchema.EdgeTypes.reduce((prev, edge) => {
        prev[edge.Name] = {
          vertexOrEdgeType: edge,
          selected: globalTypesInGraph.includes(edge.Name),
          disabled: localTypes.includes(edge.Name) || disableGlobalEdgeTypeCheckbox(edge, localTypes),
        };
        return prev;
      }, {} as { [name: string]: TypeCheckedStatus })
    );
  }, [designerService, globalSchema, isFetching]);

  useEffect(() => {
    resetGlobalTypes();
  }, [resetGlobalTypes]);

  return (
    <StatefulPopover
      showArrow={false}
      placement={PLACEMENT.bottomRight}
      onOpen={() => {
        resetGlobalTypes();
      }}
      content={({ close }) => {
        if (isFetching) {
          return <Spinner />;
        }
        return (
          <div>
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                columnGap: '16px',
                padding: '8px 10px 10px 16px',
                borderBottom: `1px solid ${theme.colors.gray200}`,
                maxHeight: '400px',
                overflow: 'auto',
              })}
            >
              {Object.keys(globalVertexTypes).length + Object.keys(globalEdgeTypes).length === 0 && (
                <div>No global types</div>
              )}
              {Object.keys(globalVertexTypes).length > 0 && (
                <div
                  className={css({
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '16px',
                  })}
                >
                  Global Vertex Types
                </div>
              )}
              {Object.values(globalVertexTypes).map((item) => (
                <Checkbox
                  key={item.vertexOrEdgeType.Name}
                  checked={item.selected}
                  onChange={(e) => {
                    const tempTypeCheckedStatus = { ...item, selected: !item.selected };
                    const tempGlobalEdgeTypes = Object.values(globalEdgeTypes);
                    HelperFunctions.toggleCheckStatus(
                      [tempTypeCheckedStatus],
                      Object.values(tempGlobalEdgeTypes),
                      true
                    );
                    setGlobalVertexTypes({
                      ...globalVertexTypes,
                      [item.vertexOrEdgeType.Name]: tempTypeCheckedStatus,
                    });
                    setGlobalEdgeTypes(
                      tempGlobalEdgeTypes.reduce((prev, item) => {
                        prev[item.vertexOrEdgeType.Name] = item;
                        return prev;
                      }, {} as { [name: string]: TypeCheckedStatus })
                    );
                  }}
                  overrides={{
                    Root: {
                      style: {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        alignItems: 'center',
                      },
                    },
                    Label: {
                      style: {
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: theme.colors.black01,
                      },
                    },
                  }}
                >
                  {item.vertexOrEdgeType.Name}
                </Checkbox>
              ))}
              {Object.keys(globalEdgeTypes).length > 0 && (
                <div
                  className={css({
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '16px',
                  })}
                >
                  Global Edge Types
                </div>
              )}
              {Object.values(globalEdgeTypes).map((item) => (
                <Checkbox
                  key={item.vertexOrEdgeType.Name}
                  checked={item.selected}
                  onChange={(e) => {
                    const tempTypeCheckedStatus = { ...item, selected: !item.selected };
                    const tempGlobalVertexTypes = Object.values(globalVertexTypes);
                    HelperFunctions.toggleCheckStatus([tempTypeCheckedStatus], tempGlobalVertexTypes);
                    setGlobalEdgeTypes({
                      ...globalEdgeTypes,
                      [item.vertexOrEdgeType.Name]: tempTypeCheckedStatus,
                    });
                    setGlobalVertexTypes(
                      tempGlobalVertexTypes.reduce((prev, item) => {
                        prev[item.vertexOrEdgeType.Name] = item;
                        return prev;
                      }, {} as { [name: string]: TypeCheckedStatus })
                    );
                  }}
                  overrides={{
                    Root: {
                      style: {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        alignItems: 'center',
                      },
                    },
                    Label: {
                      style: {
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: theme.colors.black01,
                      },
                    },
                  }}
                >
                  {item.vertexOrEdgeType.Name}
                </Checkbox>
              ))}
            </div>
            {Object.keys(globalVertexTypes).length + Object.keys(globalEdgeTypes).length > 0 && (
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'flex-end',
                })}
              >
                <Button
                  isText
                  onClick={() => {
                    updateGlobalTypes(Object.values(globalVertexTypes), Object.values(globalEdgeTypes));
                    close();
                  }}
                  overrides={{
                    BaseButton: {
                      style: {
                        color: theme.colors.primary1000,
                        fontSize: '14px',
                        fontWeight: 600,
                      },
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        );
      }}
      overrides={{
        Body: {
          style: {
            backgroundColor: 'white',
            ...expand({
              borderRadius: '2px',
            }),
          },
        },
        Inner: {
          style: {
            backgroundColor: 'white',
          },
        },
      }}
    >
      <Button kind="tertiary" size="compact" disabled={disabled}>
        <AiOutlineGlobal size={16} />
      </Button>
    </StatefulPopover>
  );
}
