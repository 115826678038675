import { BlockProps } from 'baseui/block';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body2, HeadingMenuSelected } from '@tigergraph/app-ui-lib/typography';

export const StyledTitle = ({ children }: BlockProps) => {
  const [css, theme] = useStyletron();
  return (
    <HeadingMenuSelected color={theme.colors.black02}>
      <>{children}</>
    </HeadingMenuSelected>
  );
};

export const StyledSubTitle = ({ children }: BlockProps) => {
  const [css, theme] = useStyletron();
  return (
    <Body2 as="span" color={theme.colors.black02} $style={{ fontWeight: 700 }}>
      <>{children}</>
    </Body2>
  );
};

export const StyledText = ({ children }: BlockProps) => {
  const [css, theme] = useStyletron();
  return (
    <Body2 color={theme.colors.black02}>
      <>{children}</>
    </Body2>
  );
};

export const InlineBlock = styled('div', {
  display: 'flex',
});
