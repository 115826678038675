import { SelectOverrides } from 'baseui/select';
import { TabOverrides, TabsOverrides } from 'baseui/tabs-motion';
import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';

export const StyledSelectStyleObject = {
  Root: {
    style: {
      width: '100%',
    },
  },
  ControlContainer: {
    style: {
      minHeight: '40px',
    },
  },
  ValueContainer: {
    style: {
      paddingRight: '90px',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  SelectArrow: {
    props: {
      overrides: {
        Svg: {
          style: () => ({
            display: 'none',
          }),
        },
      },
    },
  },
  Placeholder: {
    style: {
      fontSize: '14px',
    },
  },
  DropdownListItem: {
    style: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '12px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#222',
      fontWeight: 'normal',
      backgroundColor: '#fff',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
} as SelectOverrides;

export const ChartSearchSelectStyleObject = {
  Root: {
    style: ({ $isOpen }) => ({
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: $isOpen ? '#0A88E4' : 'transparent',
      fontSize: '14px',
      height: '24px',
      paddingLeft: '8px',
      paddingRight: '8px',
    }),
  },
  IconsContainer: {
    style: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '8px',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  SingleValue: {
    style: ({ $isOpen }) => ({
      ':hover': {
        color: '#1564B1',
      },
      color: $isOpen ? '#1564B1' : '#222222',
    }),
  },
  DropdownListItem: {
    style: () => ({
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '12px',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#222',
      fontWeight: 'normal',
    }),
  },
} as SelectOverrides;

export const FileTabsStyleObject = (theme: CustomTheme) =>
  ({
    Root: {
      style: {
        height: '100%',
        transform: 'none', // to fix codemirror autocomplete tooltip position, see https://github.com/codemirror/dev/issues/324
      },
    },
    TabHighlight: {
      style: {
        height: '2px',
        backgroundColor: 'white',
      },
    },
    TabBorder: {
      style: {
        height: '1px',
        backgroundColor: `${theme.colors.divider}`,
        marginTop: '-1px',
      },
    },
    TabList: {
      style: {
        height: '52px',
        paddingTop: '12px',
        paddingBottom: '0px',
        marginRight: '16px',
        marginLeft: '16px',
        marginBottom: '0px',
        minWidth: 'calc(50% - 16px)',
        overflowX: 'scroll',
        backgroundColor: `white`,
      },
    },
  } as TabsOverrides);

export const FileTabStyleObject = (theme: CustomTheme) =>
  ({
    Tab: {
      style: ({ $isActive }) => ({
        fontSize: '12px',
        fontWeight: $isActive ? 'bold' : 'regular',
        background: $isActive ? 'white' : `transparent !important`,
        boxShadow: $isActive ? `0px -4px 0px ${theme.colors.primaryAction}` : 'none',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderRightWidth: '1px',
        borderRightStyle: 'solid',
        borderLeftWidth: $isActive ? '1px' : '0px',
        borderLeftStyle: 'solid',
        borderBottomWidth: $isActive ? '0px' : '1px',
        borderBottomStyle: 'solid',
        position: 'relative',
        paddingTop: '0px',
        paddingBottom: '0px',
        marginRight: $isActive ? '0px' : '-1px',
        borderImage: $isActive
          ? `linear-gradient(to bottom, #ffffff, ${theme.colors.divider} 20%, ${theme.colors.divider} 90%, #ffffff 99%, #ffffff 100%) 1`
          : `linear-gradient(to bottom, #ffffff, #ffffff 30%, ${theme.colors.divider} 30%, ${theme.colors.divider} 70%, #ffffff 70%, #ffffff 90%, ${theme.colors.divider} 100%) 1`,
        ':first-child': {
          borderLeftWidth: '1px',
        },
      }),
    },
    TabPanel: {
      style: {
        height: 'calc(100% - 52px)',
        paddingRight: '0px',
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
  } as TabOverrides);

export const tabOverrides: TabOverrides = {
  Tab: {
    style: {
      height: '57px',
    },
  },
  TabPanel: {
    style: {
      paddingLeft: '0',
      paddingRight: '0',
      paddingBottom: '0',
      paddingTop: '0',
      backgroundColor: '#fff',
      boxSizing: 'border-box', // Include padding and border in element's total height and width
      position: 'relative',
      height: 'calc(100% - 41px)',
      minHeight: 'calc(100% - 41px)',
    },
  },
};

export const tabsOverrides: TabsOverrides = {
  Root: {
    style: {
      paddingTop: '16px',
      height: '100%',
    },
  },
  TabList: {
    style: {},
  },
};
