import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { useNavigate } from 'react-router-dom';
import { MdOutlineAddToQueue } from 'react-icons/md';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import NoWorkspaceIcon from '@/assets/no-workspace.svg';
import NoWorkgroupIcon from '@/assets/no-workgroup.svg';
import NoPermissionGroupIcon from '@/assets/no-permission-workgroup.svg';
import NoPermissionSpaceIcon from '@/assets/no-permission-workspace.svg';
import { useOrgContext } from '@/contexts/orgContext';
import { isGroupAdmin, isOrgAdmin } from '@/pages/admin/user/type';

export default function EmptyState({
  toPage,
  createWorkGroup,
  onDelete,
  group_id,
}: {
  toPage?: string;
  createWorkGroup?: boolean;
  onDelete?: () => void;
  group_id?: string;
}) {
  const navigate = useNavigate();
  const [css] = useStyletron();
  const { userInfo } = useOrgContext();
  const orgAdmin = isOrgAdmin(userInfo.roles);
  const groupAdmin = isGroupAdmin(userInfo.roles, group_id || '');

  // only org admin can create workgroup
  const canCreateGroup = orgAdmin;
  // only org admin and group admin can create workspace
  const canCreateSpace = orgAdmin || groupAdmin;

  const groupIcon = canCreateGroup ? NoWorkgroupIcon : NoPermissionGroupIcon;
  const spaceIcon = canCreateSpace ? NoWorkspaceIcon : NoPermissionSpaceIcon;

  return (
    <Container>
      {onDelete || !createWorkGroup ? <img src={spaceIcon} /> : <img src={groupIcon} />}
      <Body2>
        {onDelete ? (
          canCreateSpace ? (
            'Get started by creating your first workspace in your workgroup.'
          ) : (
            <>
              You do not have permission to create a workspace.
              <br />
              Please request access from the workgroup admin or organization admin.
            </>
          )
        ) : canCreateGroup ? (
          'Get started by creating your first workgroup and workspace.'
        ) : (
          <>
            You do not have permission to create workgroup.
            <br />
            Please request access from the organization admin.
          </>
        )}
      </Body2>
      {canCreateGroup || canCreateSpace ? (
        <Button onClick={() => navigate(toPage || '/groups/new')}>
          <MdOutlineAddToQueue size={20} />
          <span
            className={css({
              paddingLeft: '8px',
              fontSize: '14px',
              fontWeight: 600,
              whiteSpace: 'nowrap',
            })}
          >
            {onDelete ? 'Create Workspace' : 'Get Started Now'}
          </span>
        </Button>
      ) : null}

      {onDelete && canCreateGroup ? (
        <>
          <Body2 $style={{ color: '#A5A5A8', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Line />
            or
            <Line />
          </Body2>
          <ConfirmStatefulPopover confirmLabel="Confirm to delete" onConfirm={onDelete}>
            <Button
              kind="secondary"
              isDestructive
              overrides={{
                BaseButton: {
                  style: {
                    width: '178px',
                  },
                },
              }}
            >
              Delete Workgroup
            </Button>
          </ConfirmStatefulPopover>
        </>
      ) : null}
    </Container>
  );
}

const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: $theme.colors.gray1000,
  ...$theme.typography.Body1,
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  height: '100%',
  textAlign: 'center',
  gap: '16px',
}));

const Line = styled('span', ({ $theme }) => ({
  backgroundColor: '#EAECEF',
  height: '1px',
  width: '123px',
}));
