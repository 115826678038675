import clsx from 'clsx';
import { forwardRef, ComponentPropsWithRef } from 'react';
import { useMatch, Link, LinkProps } from 'react-router-dom';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';

type NavLinkProps = LinkProps & { to: string };

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ to, className, ...props }, ref) => {
  const match = useMatch(to);
  const [css] = useStyletron();
  return (
    <Link
      {...props}
      to={to}
      ref={ref}
      className={clsx(
        css({
          backgroundColor: match ? '#FFF5EC' : 'transparent',
          fontWeight: match ? '700' : '500',
          ':hover': {
            backgroundColor: '#eee',
          },

          borderLeft: `2px solid ${match ? '#FB9A44' : 'transparent'}`,
          padding: '12px 8px 8px 22px',
          color: match ? '#FB9A44' : '#57686E',
          textDecoration: 'none',

          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }),
        className
      )}
    />
  );
});

export const NavLinkLevel2 = forwardRef<HTMLAnchorElement, NavLinkProps>(({ className, ...props }, ref) => {
  const [css] = useStyletron();
  return (
    <NavLink
      {...props}
      ref={ref}
      className={clsx(
        css({
          padding: '8px 12px 8px 46px',
        }),
        className
      )}
    />
  );
});

export const NavLinkLevel3 = forwardRef<HTMLAnchorElement, NavLinkProps>(({ className, ...props }, ref) => {
  const [css] = useStyletron();
  return (
    <NavLink
      {...props}
      ref={ref}
      className={clsx(
        css({
          padding: '4px 4px 4px 86px',
        }),
        className
      )}
    />
  );
});

export const NavOl = styled('ol', {
  margin: 0,
  padding: 0,
  listStyle: 'none',
  display: 'grid',
  rowGap: '4px',
});

export const NavLi = styled('li', {
  width: '100%',
  overflow: 'hidden',
  flexShrink: 0,
});

export const NavIcon = styled('span', {
  width: '24px',
  fontSize: '24px',
});

export const NavIconLevel3 = styled('span', {
  width: '32px',
  fontSize: '24px',
});

type NavLabelProps = ComponentPropsWithRef<'span'> & { $match: boolean };

export const NavLabel = styled<'span', NavLabelProps>('span', ({ $match }) => {
  return {
    fontSize: '12px',
    color: $match ? '#222222' : '#57686E',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px',
  };
});

export const NavLabelLevel3 = styled<'span', NavLabelProps>('span', ({ $match }) => {
  return {
    fontSize: '12px',
    color: $match ? '#222222' : '#57686E',
    marginRight: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '170px',
  };
});
