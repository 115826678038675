import { styled } from '@tigergraph/app-ui-lib/Theme';

export const Tag = styled('span', () => {
  return {
    padding: '2px 12px',
    borderRadius: '20px',
    backgroundColor: '#F4F5F6',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#3F5870',
  };
});
