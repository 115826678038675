import { useEffect, useState, forwardRef, useRef } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import GraphResult, { GraphResultRef } from '@/components/graphEditor/GraphResult';
import { useSchema, useSchemaStyle } from '@/utils/graphEditor/useMetaHook';
import { ID_TOKEN_KEY, useUserContext } from '@/contexts/userContext';
import { Option } from 'baseui/select';
import { EmptySchema } from '@/components/schemaDesigner/EmptySchema';
import { useSchemaDesignerContext } from '@/contexts/schemaDesignerContext';
import { FullScreenButton, FullScreenExit, KeyboardIcon, MenuHide } from '@/pages/home/icons';
import GraphIcon from '@/assets/graph-schema.svg';
import { GraphEvents } from '@tigergraph/tools-ui/graph/type';
import IconButton from '@/components/IconButton';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { expand } from 'inline-style-expand-shorthand';
import { ShortcutsMenu } from '@tigergraph/app-ui-lib/shortcuts-menu';
import { buildShortcut } from '@/utils/schemaDesigner';
import { PLACEMENT } from 'baseui/popover';
import { Color } from '@tigergraph/tools-models/gvis/insights';
import { DBVertexStyleJson } from '@tigergraph/tools-models/topology';
import GraphSelect from '@/components/graphSelect';
import { GLOBAL_GRAPH_NAME } from '@tigergraph/tools-models';
import { useWorkspaceContext } from '@/contexts/workspaceContext.tsx';
import { setBaseURL } from '@tigergraph/tools-ui/insights/config/config-interface';
import axios from 'axios';

export interface SchemaDesignerProps {
  title?: string;
  width?: string;
  height?: string;
  graphEvents?: GraphEvents;
  enableEditor?: boolean;
  enableCreate?: boolean;
  enableSave?: boolean;
  enableDelete?: boolean;
  enableShortcuts?: boolean;
  enableGraphSelect?: boolean;
  enableFullScreen?: boolean;
  enableHeader?: boolean;
  hideSearch?: boolean;
  handleCreateVertex?: () => void;
  confirmDeleteVerticesOrEdges?: () => void;
  showHideButton?: boolean;
  showCreateEdgeTooltip?: boolean;
  onHideSchema?: () => void;
  schemaJson?: any;
  styleJSON?: any;
  isInDrawer?: boolean;
}

const SchemaDesigner = forwardRef<GraphResultRef, SchemaDesignerProps>((props, ref) => {
  const {
    title = 'Schema Designer',
    width = '662px',
    height = '100vh',
    enableEditor = true,
    enableCreate = true,
    enableDelete = true,
    enableSave = true,
    enableShortcuts = true,
    enableGraphSelect = true,
    enableFullScreen = true,
    enableHeader = true,
    hideSearch,
    graphEvents,
    handleCreateVertex,
    confirmDeleteVerticesOrEdges,
    showHideButton = false,
    showCreateEdgeTooltip = false,
    isInDrawer = false,
    onHideSchema,
    schemaJson,
    styleJSON,
  } = props;
  const { graphNames, currentGraph, setCurrentGraph } = useUserContext();
  const { currentWorkspace } = useWorkspaceContext();
  const [css, theme] = useStyletron();
  const [graphOptions, setGraphOptions] = useState<Option[]>([]);
  const { data: schema, isFetching } = useSchema(currentGraph, schemaJson);
  const { data: schemaStyle } = useSchemaStyle(currentGraph, styleJSON);
  const { data: globalSchemaStyle } = useSchemaStyle(GLOBAL_GRAPH_NAME);
  const { designerService } = useSchemaDesignerContext();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const schemaDesignerContainerRef = useRef<HTMLDivElement>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  useEffect(() => {
    if (currentWorkspace) {
      const workspaceURL = `https://${currentWorkspace?.nginx_host}`;
      let idToken = sessionStorage.getItem(ID_TOKEN_KEY)!;
      setBaseURL(axios.create({}), workspaceURL, {
        idToken,
      });
    }
  }, [currentWorkspace]);

  useEffect(() => {
    if (graphNames && graphNames.length > 0) {
      setGraphOptions(
        graphNames.map((graphName) => ({
          id: graphName,
          label: graphName === '1' ? 'global' : graphName,
        }))
      );
    }
  }, [graphNames, setCurrentGraph]);

  useEffect(() => {
    if (schema && schema.GraphName) {
      try {
        let currentSchemaStyle = schemaStyle;
        if (!currentSchemaStyle || Object.keys(currentSchemaStyle?.vertexStyles).length === 0) {
          const sqrt = Math.round(Math.sqrt(schema.VertexTypes.length));
          const color = new Color();
          currentSchemaStyle = {
            vertexStyles: schema.VertexTypes.reduce(
              (prev, v, i) => {
                prev[v.Name] = {
                  fillColor: color.getColor(v.Name),
                  x: 300 * Math.floor(i / sqrt),
                  y: 300 * (i % sqrt),
                  other: {},
                };
                return prev;
              },
              {} as {
                [vertexType: string]: DBVertexStyleJson;
              }
            ),
            edgeStyles: {},
          };
        }
        designerService.setSchemaAndStyle(schema, currentSchemaStyle);
        setErrorMsg('');
      } catch (e: any) {
        setErrorMsg(e?.message ?? 'Can not render schema');
        console.error(e?.message ?? 'Can not render schema');
      }
    }
  }, [designerService, schema, schemaStyle]);

  const toggleFullScreen = () => {
    if (schemaDesignerContainerRef.current) {
      if (isFullScreen) {
        setIsFullScreen(false);
        schemaDesignerContainerRef.current.style.position = 'relative';
        if (!isInDrawer) {
          schemaDesignerContainerRef.current.style.width = width;
        } else {
          schemaDesignerContainerRef.current.style.width = '662px';
        }
        schemaDesignerContainerRef.current.style.height = height;
      } else {
        schemaDesignerContainerRef.current.style.width = '100vw';
        schemaDesignerContainerRef.current.style.height = '100vh';
        if (!isInDrawer) schemaDesignerContainerRef.current.style.position = 'fixed';
        schemaDesignerContainerRef.current.style.top = '0';
        schemaDesignerContainerRef.current.style.left = '0';
        setIsFullScreen(true);
      }
    }
  };

  return (
    <>
      <div
        ref={schemaDesignerContainerRef}
        className={css({
          width: width,
          height: height,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.colors.white,
        })}
      >
        {enableHeader && (
          <h2
            className={css({
              fontSize: '16px',
              fontWeight: 500,
              fontFamily: 'Urbanist',
              color: `${theme.colors.gray1000}`,
              height: '57px',
              lineHeight: '57px',
              borderBottom: `1px solid ${theme.colors.gray200}`,
              paddingLeft: '16px',
              paddingRight: '26px',
              display: 'flex',
              justifyContent: 'space-between',
            })}
          >
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              })}
            >
              <img src={GraphIcon} />
              {title}
            </div>
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
              })}
            >
              {enableFullScreen && (
                <IconButton
                  $style={{
                    border: `none`,
                    backgroundColor: '#fff',
                    height: '24px',
                    width: '24px',
                    marginRight: '8px',
                  }}
                  onClick={() => {
                    toggleFullScreen();
                  }}
                >
                  {isFullScreen ? <FullScreenExit /> : <FullScreenButton />}
                </IconButton>
              )}
              {showHideButton && (
                <IconButton
                  onClick={() => {
                    onHideSchema && onHideSchema();
                  }}
                  $style={{
                    height: '24px',
                    width: '24px',
                  }}
                >
                  <MenuHide />
                </IconButton>
              )}
            </div>
          </h2>
        )}
        <div
          className={css({
            position: 'relative',
            backgroundColor: '#fff',
            flexGrow: 1,
            flexBasis: 0,
            minHeight: 0,
          })}
        >
          {currentGraph && !!schema && !isFetching && !errorMsg && currentWorkspace.status === 'Active' && (
            <GraphResult
              ref={ref}
              id={currentGraph}
              schema={schema}
              graphName={currentGraph}
              isSchemaGraph={true}
              graphEvents={graphEvents}
              enableEditor={enableEditor && currentWorkspace.is_rw}
              enableCreate={enableCreate && currentWorkspace.is_rw}
              enableDelete={enableDelete && currentWorkspace.is_rw}
              enableSave={enableSave && currentWorkspace.is_rw}
              enableShortcuts={enableShortcuts}
              hideSearch={hideSearch}
              schemaStyle={schemaStyle}
              globalSchemaStyle={globalSchemaStyle}
              showCreateEdgeTooltip={showCreateEdgeTooltip}
              enableGraphSelect={enableGraphSelect}
              handleCreateVertex={handleCreateVertex}
              confirmDeleteVerticesOrEdges={confirmDeleteVerticesOrEdges}
            />
          )}
          {currentWorkspace.is_rw && (
            <StatefulPopover
              placement={PLACEMENT.rightBottom}
              ignoreBoundary={false}
              showArrow={false}
              popoverMargin={8}
              overrides={{
                Body: {
                  style: {
                    ...expand({
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      borderRadius: '5px',
                    }),
                    backgroundColor: '#fff',
                  },
                },
                Inner: {
                  style: {
                    backgroundColor: '#fff',
                    ...expand({
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }),
                  },
                },
              }}
              content={() => {
                return (
                  <div
                    className={css({
                      boxSizing: 'content-box',
                    })}
                  >
                    <ShortcutsMenu shortcuts={buildShortcut({ enableSave })} column={1} />
                  </div>
                );
              }}
            >
              <IconButton
                $style={{
                  position: 'absolute',
                  top: 'calc(100vh - 106px)',
                  left: '16px',
                  border: `1px solid ${theme.colors.gray600}`,
                  borderRadius: '2px',
                  backgroundColor: '#fff',
                }}
              >
                <KeyboardIcon />
              </IconButton>
            </StatefulPopover>
          )}
          {enableGraphSelect && graphOptions.length > 0 && (
            <div
              className={css({
                width: '160px',
                position: 'absolute',
                top: '8px',
                left: '8px',
              })}
            >
              <GraphSelect />
            </div>
          )}
          {currentWorkspace.workspace_id && currentWorkspace.status !== 'Active' && (
            <div
              className={css({
                width: '100%',
                height: '100%',
                background: '#cccccc',
                opacity: '0.7',
                position: 'relative',
                zIndex: 2,
                textAlign: 'center',
              })}
            >
              <div
                className={css({
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                })}
              >
                Your workspace is in idle status.
              </div>
            </div>
          )}
          {isFetching && (
            <div
              className={css({
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '596px',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
              })}
            >
              <EmptySchema isLoading={true} />
            </div>
          )}
          {errorMsg && (
            <div
              className={css({
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: theme.colors.gray900,
                width: '100%',
                paddingTop: '16px',
                paddingLeft: '16px',
              })}
            >
              {errorMsg}
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default SchemaDesigner;
