import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { SpaceIconSM } from '@/pages/home/icons';
import { StatusIndicator } from '@/pages/workgroup/tab/General';
import { WorkGroupT, WorkspaceT, isStatusActive, isStatusIdle, isStatusPending } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { expand } from 'inline-style-expand-shorthand';
import { Select } from '@tigergraph/app-ui-lib/select';
import { OnChangeParams, Option } from 'baseui/select';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryGetGroups } from '@/pages/workgroup/hook';

export interface WorkspaceSelectorProps {
  workgroupId?: string;
  filterWorkspace?: (workspace: WorkspaceT) => boolean;
  width?: string;
  showPending?: boolean;
}

export function WorkspaceSelector({ workgroupId, width, filterWorkspace, showPending }: WorkspaceSelectorProps) {
  const [css, theme] = useStyletron();
  const { currentWorkspace, setCurrentWorkspace } = useWorkspaceContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, data } = useQueryGetGroups();

  const getWorkspaces = useCallback(
    (workgroups: WorkGroupT[] | undefined) => {
      if (!workgroups) {
        return [];
      }
      return workgroups
        .filter((group) => (workgroupId ? group.workgroup_id === workgroupId : true))
        .map((group) =>
          group.workspaces.map((ws) => {
            ws.workgroup_name = group.name;
            return ws;
          })
        )
        .flat()
        .filter(
          (workspace) =>
            isStatusActive(workspace.status) ||
            isStatusIdle(workspace.status) ||
            (showPending && isStatusPending(workspace.status))
        )
        .filter(filterWorkspace || (() => true));
    },
    [workgroupId, filterWorkspace, showPending]
  );

  useEffect(() => {
    let filteredWorkGroups = data?.Result || [];
    const workspaces = getWorkspaces(filteredWorkGroups);
    const urlWorkspaceId = searchParams.get('workspace_id');
    const urlWorkspace = workspaces.find((workspace) => workspace.workspace_id === urlWorkspaceId);
    if (searchParams.get('workspace_id') && urlWorkspace) {
      setCurrentWorkspace(urlWorkspace);
    } else if (
      !currentWorkspace.workspace_id ||
      !workspaces.find((ws) => ws.workspace_id === currentWorkspace.workspace_id)
    ) {
      if (workspaces.length > 0) {
        setCurrentWorkspace(workspaces[0] as WorkspaceT);
      }
    }
  }, [data, getWorkspaces, searchParams, setCurrentWorkspace, workgroupId, currentWorkspace.workspace_id]);

  const handleSelect = (params: OnChangeParams) => {
    setCurrentWorkspace(params.value[0] as WorkspaceT);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('workspace_id', `${params.value[0].workspace_id}`);
    setSearchParams(newSearchParams);
  };

  const getLabel = ({ option, isValue }: { option?: Option; isValue?: boolean }) => {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        })}
      >
        {isValue && (
          <div
            className={css({
              minWidth: '12px',
              paddingLeft: '8px',
              paddingRight: '8px',
              color: theme.colors.gray800,
            })}
          >
            <SpaceIconSM />
          </div>
        )}
        <span
          className={css({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          })}
        >
          <span
            className={css({
              color: '#909090',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            })}
          >
            {option!.workgroup_name}
            <span
              className={css({
                paddingLeft: '6px',
                paddingRight: '6px',
              })}
            >
              /
            </span>
          </span>
          <span
            className={css({
              fontSize: '14px',
            })}
          >
            {option!.name}
          </span>
        </span>
        <StatusIndicator status={option!.status} />
      </div>
    );
  };

  return (
    <Select
      getOptionLabel={getLabel}
      getValueLabel={({ option }) => getLabel({ option, isValue: true })}
      isLoading={isLoading}
      options={getWorkspaces(data?.Result)}
      value={currentWorkspace.workspace_id ? [currentWorkspace] : []}
      valueKey="workspace_id"
      labelKey="name"
      onChange={handleSelect}
      clearable={false}
      searchable={false}
      overrides={{
        Root: {
          style: {
            width: width || '308px',
            marginRight: '16px',
            height: '32px',
          },
        },
        ControlContainer: {
          style: {
            ...expand({
              borderRadius: '2px',
            }),
          },
        },
        ValueContainer: {
          style: {
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '14px',
            paddingLeft: '5px',
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  zIndex: 1,
                },
              },
            },
          },
        },
      }}
    ></Select>
  );
}
