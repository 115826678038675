import { Select } from 'baseui/select';
import { Table } from 'baseui/table-semantic';
import { useSchemaDesignerContext } from '@/contexts/schemaDesignerContext';
import { DeleteButton } from '@/pages/home/icons';
import IconButton from '@/components/IconButton';

export interface FromToVertexPairTableProps {
  fromToVertexTypePairs: { from: string; to: string }[];
  onChange: (pairs: { from: string; to: string }[]) => void;
  readOnly?: boolean;
}

export function FromToVertexPairTable({ fromToVertexTypePairs, onChange, readOnly }: FromToVertexPairTableProps) {
  const { designerService } = useSchemaDesignerContext();
  const pairs = [...fromToVertexTypePairs];
  const vertexOptions = designerService.getAllVertexTypes().map((vertexType) => {
    return {
      label: vertexType,
      id: vertexType,
    };
  });

  return (
    <>
      <Table
        divider={'horizontal'}
        overrides={{
          TableHeadCell: {
            style: {
              backgroundColor: `#F4F8FD`,
              paddingTop: '8px',
              paddingBottom: '8px',
              paddingLeft: '4px',
              paddingRight: '44px',
              minWidth: '70px',
            },
          },
          TableBodyCell: {
            style: {
              paddingTop: '2px',
              paddingBottom: '2px',
              paddingLeft: '4px',
              paddingRight: '4px',
            },
          },
        }}
        columns={['Source vertex types', 'Target vertex types', '']}
        data={pairs.map((pair, index) => {
          return [
            <Select
              onChange={(params) => {
                pairs[index].from = params.value[0].id as string;
                onChange([...pairs]);
              }}
              options={vertexOptions}
              searchable={false}
              clearable={false}
              key={index}
              value={[{ label: pair.from, id: pair.from }]}
              disabled={readOnly}
              overrides={{
                Root: {
                  style: {
                    height: '32px',
                    backgroundColor: 'inherit',
                  },
                },
                ControlContainer: {
                  style: {
                    backgroundColor: 'inherit',
                    height: '32px',
                    borderTopStyle: 'none',
                    borderLeftStyle: 'none',
                    borderRightStyle: 'none',
                    borderBottomStyle: 'none',
                  },
                },
                ValueContainer: {
                  style: {
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  },
                },
                SingleValue: {
                  style: {
                    lineHeight: '30px',
                  },
                },
                IconsContainer: {
                  style: {
                    paddingRight: '0px',
                    paddingLeft: '0px',
                  },
                },
                Popover: {
                  props: {
                    overrides: {
                      Body: {
                        style: {
                          zIndex: 1,
                        },
                      },
                    },
                  },
                },
              }}
            />,
            <Select
              onChange={(params) => {
                pairs[index].to = params.value[0].id as string;
                onChange([...pairs]);
              }}
              options={vertexOptions}
              searchable={false}
              clearable={false}
              key={index}
              value={[{ label: pair.to, id: pair.to }]}
              disabled={readOnly}
              overrides={{
                Root: {
                  style: {
                    height: '32px',
                    backgroundColor: 'inherit',
                  },
                },
                ControlContainer: {
                  style: {
                    backgroundColor: 'inherit',
                    height: '32px',
                    borderTopStyle: 'none',
                    borderLeftStyle: 'none',
                    borderRightStyle: 'none',
                    borderBottomStyle: 'none',
                  },
                },
                ValueContainer: {
                  style: {
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  },
                },
                SingleValue: {
                  style: {
                    lineHeight: '30px',
                  },
                },
                IconsContainer: {
                  style: {
                    paddingRight: '0px',
                    paddingLeft: '0px',
                  },
                },
                Popover: {
                  props: {
                    overrides: {
                      Body: {
                        style: {
                          zIndex: 1,
                        },
                      },
                    },
                  },
                },
              }}
            />,
            <IconButton
              onClick={() => {
                pairs.splice(index, 1);
                onChange([...pairs]);
              }}
              key={index}
              $style={{
                height: '32px',
                lineHeight: '32px',
                width: '20px',
                display: readOnly ? 'none' : 'block',
              }}
            >
              <DeleteButton />
            </IconButton>,
          ];
        })}
      />
    </>
  );
}
