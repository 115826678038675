import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import {
  ExternalGraph,
  ExternalLink,
  ExternalNode,
  GLOBAL_GRAPH_NAME,
  HelperFunctions,
  ValidateResult,
  Vertex,
} from '@tigergraph/tools-models';
import { Color } from '@tigergraph/tools-models/gvis/color';
import Graph from '@tigergraph/tools-ui/graph';
import {
  CytoscapeExtensions,
  GraphEvents,
  GraphRef,
  NodePositions,
  Schema,
  SettingType,
} from '@tigergraph/tools-ui/graph/type';
import { Core } from 'cytoscape';
import {
  forwardRef,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Result } from '@/lib/type';
import { AxiosError } from 'axios';

import { EdgeEditor } from '@/components/schemaDesigner/EdgeEditor';
import { VertexEditor } from '@/components/schemaDesigner/VertexEditor';
import { useSchemaDesignerContext } from '@/contexts/schemaDesignerContext';
import { GLOBAL_VIEW, useUserContext } from '@/contexts/userContext';
import { axiosCluster } from '@/lib/network';
import { convertSchemaToGraph } from '@/lib/schema';
import ErrorMessage from '@/components/ErrorMessage';
import { SaveButton, SaveButtonDisable } from '@/pages/home/icons';
import { initStyle, randomString } from '@/utils/schemaDesigner';
import { SchemaSaveButton } from '@/utils/schemaDesigner/styleObject';
import { Button } from '@tigergraph/app-ui-lib/button';
import {
  DBGraphStyleJson,
  Edge,
  EdgeStyle,
  GSQLEdgeJson,
  GSQLVertexJson,
  SchemaChange,
  TypeCheckedStatus,
} from '@tigergraph/tools-models/topology';
import { LayoutType } from '@tigergraph/tools-ui/graph/layouts';
import { getUserUploadedIconPathPrefix } from '@tigergraph/tools-ui/insights/utils/path';
import '@tigergraph/tools-ui/style.css';
import { Layer } from 'baseui/layer';
import { Spinner } from 'baseui/spinner';
import { cloneDeep } from 'lodash';
import { useMutation } from 'react-query';
import isEqual from 'lodash-es/isEqual';
import { FiPlus } from 'react-icons/fi';
import { GRAPH_ICON } from '@tigergraph/tools-ui/graph/popper/styledGraphIcon';
import DeleteTypeModal from '@/components/graphEditor/DeleteTypeModal';
import omit from 'lodash-es/omit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import GlobalTypePopover from '@/components/graphEditor/GlobalTypePopover';
import { showToast } from '@/components/styledToasterContainer';
import { KIND } from 'baseui/toast';
import { getErrorMessage } from '@/utils/utils';
import { EmptySchema } from '@/components/schemaDesigner/EmptySchema';
import useSize from 'ahooks/lib/useSize';
import { StyleObject } from 'styletron-standard';
import ConfirmModal from '@/components/ConfirmModal';

export interface GraphResultProps {
  id: string;
  graph?: ExternalGraph;
  layout?: LayoutType;
  schema: Schema;
  graphName: string;
  isSchemaGraph?: boolean;
  graphEvents?: GraphEvents;
  enableEditor?: boolean;
  enableCreate?: boolean;
  enableDelete?: boolean;
  enableSave?: boolean;
  enableShortcuts?: boolean;
  hideSearch?: boolean;
  handleCreateVertex?: () => void;
  confirmDeleteVerticesOrEdges?: () => void;
  showCreateEdgeTooltip?: boolean;
  enableGraphSelect?: boolean;
  schemaStyle?: DBGraphStyleJson;
  globalSchemaStyle?: DBGraphStyleJson;
}

export interface GraphResultRef {
  vertex: Vertex | undefined;
  edge: Edge | undefined;
  schema: Schema;
  graph: ExternalGraph | undefined;
  isAddingVertex: boolean;
  isSchemaChanged: boolean;
  handleCreateVertex: (position?: { x: number; y: number }) => Vertex;
  handleCreateEdge: (source: ExternalNode, target: ExternalNode | ExternalLink) => Edge;
  handleCreateNodeAndEdge: (source: ExternalNode, position: { x: number; y: number }) => [Vertex, Edge] | undefined;
  handleAddVertex: (vertex: Vertex) => ValidateResult;
  handleUpdateVertex: (updateVertex: Vertex, vertexTypeName: string) => ValidateResult;
  handleAddEdge: (updateEdge: Edge) => ValidateResult;
  handleUpdateEdge: (edge: Edge, edgeTypeName: string) => ValidateResult;
  confirmDeleteVerticesOrEdges: (items: (ExternalNode | ExternalLink)[]) => void;
  handleChangePosition: () => void;
  renderChart: () => void;
  graphRef: MutableRefObject<GraphRef | null>;
  cyRef: MutableRefObject<(Core & CytoscapeExtensions) | null>;
}

const GraphSizeLimit = 5000;
const GlobalIconNumberLimit = 200;

const primaryIdName = 'id';
const primaryIdTypeDefaultValue = 'STRING';

const GraphResult = forwardRef<GraphResultRef, GraphResultProps>((props, ref) => {
  const {
    graphName,
    id,
    isSchemaGraph,
    schema,
    graph,
    layout,
    enableEditor,
    enableCreate,
    enableDelete,
    enableSave,
    enableShortcuts,
    hideSearch,
    schemaStyle,
    globalSchemaStyle,
    showCreateEdgeTooltip = false,
    enableGraphSelect,
  } = props;
  const { currentGraph } = useUserContext();
  const [css, theme] = useStyletron();
  const [isAddingVertex, setIsAddingVertex] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [schemaCopy, setSchemaCopy] = useState<Schema>(schema);
  const [graphCopy, setGraphCopy] = useState<ExternalGraph | undefined>(graph);
  const [vertexPopoverVisible, setVertexPopoverVisible] = useState(false);
  const [edgePopoverVisible, setEdgePopoverVisible] = useState(false);
  const [vertex, setVertex] = useState<Vertex | undefined>(undefined);
  const [edge, setEdge] = useState<Edge | undefined>(undefined);
  const [selectedVertexName, setSelectedVertexName] = useState<string>('');
  const [selectedEdgeName, setSelectedEdgeName] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState('');
  const [InitialStyle, setInitialStyle] = useState<DBGraphStyleJson | undefined>(
    schemaStyle ?? {
      vertexStyles: {},
      edgeStyles: {},
    }
  );
  const [isOpenDeleteTypeModal, setIsOpenDeleteTypeModal] = useState(false);
  const [deleteItems, setDeleteItems] = useState<(ExternalNode | ExternalLink)[]>([]);
  const [unsavedWarning, setUnsavedWarning] = useState<string[] | undefined>();
  const [deleteWarning, setDeleteWarning] = useState<ReactNode | string | undefined>();
  const [focusGraph, setFocusGraph] = useState(false);
  const globalUpdateIndicesRef = useRef<number[]>([]);
  const divRef = useRef<HTMLDivElement | undefined>(undefined);
  const graphNameRef = useRef<string>('');
  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const editorSize = useSize(divRef);

  const isGlobalView = useMemo(() => {
    return currentGraph === GLOBAL_GRAPH_NAME;
  }, [currentGraph]);

  const color = useMemo(() => {
    return new Color();
  }, []);

  const cyRef = useRef<(Core & CytoscapeExtensions) | null>(null);
  const graphRef = useRef<GraphRef | null>(null);

  const { changeService, designerService } = useSchemaDesignerContext();
  const { graph: graphInService } = designerService;

  useEffect(() => {
    if (graphNameRef.current !== graphName) {
      centerGraph();
      graphNameRef.current = graphName;
    }
  }, [graphName]);

  useEffect(() => {
    if (!schemaCopy) {
      return;
    } else if (isSchemaGraph) {
      setGraphCopy(convertSchemaToGraph(schemaCopy as Schema));
    }
    setTimeout(() => {
      graphRef.current?.setDrawMode(!!enableCreate);
    }, 500);
  }, [enableCreate, isSchemaGraph, schemaCopy]);

  const changeSchema = async (job: SchemaChange, isGlobalChange: boolean) => {
    return isGlobalChange
      ? axiosCluster.put(`/api/gsql-server/gsql/schema/change`, { ...job })
      : axiosCluster.put(`/api/gsql-server/gsql/schema/change?graph=${graphName}&reinstall=false`, { ...job });
  };

  const saveSchemaStyle = async (json: DBGraphStyleJson) => {
    const url = isGlobalView ? `/api/graph-styles/global` : `/api/graph-styles/local/${graphName}`;
    const res = await axiosCluster.put(url, json);
    return res.data;
  };

  const { mutateAsync: saveSchemaStyleClient } = useMutation<Result<void>[], AxiosError, DBGraphStyleJson>(
    'saveSchemaStyle',
    saveSchemaStyle,
    {
      onSuccess: (response, data) => {
        setInitialStyle(data);
      },
      onError: (error) => {
        showToast({
          kind: KIND.negative,
          message: getErrorMessage(error),
        });
      },
    }
  );

  const { mutateAsync: saveSchema, isLoading: saveSchemaLoading } = useMutation<
    Result<void>[],
    AxiosError,
    SchemaChange[]
  >(
    'SchemaChangeJob',
    async (jobs) => {
      const result = [];
      for (let job of jobs) {
        const response = await changeSchema(job, isGlobalView || job.graphs !== undefined);
        result.push(response.data);
      }
      return result;
    },
    {
      onSuccess: () => {
        showToast({
          kind: KIND.positive,
          message: 'Schema change succeeded.',
        });
      },
      onError: (error) => {
        showToast({
          kind: KIND.negative,
          message: getErrorMessage(error),
        });
      },
    }
  );

  const schemaStyleChanged = useCallback(() => {
    const dbGraphStyle = designerService.getDBGraphStyleJson();
    const upsertedSchemaStyle = JSON.parse(JSON.stringify(dbGraphStyle));
    return upsertedSchemaStyle && !isEqual(InitialStyle, upsertedSchemaStyle);
  }, [InitialStyle, designerService]);

  const disableButton = saveSchemaLoading;
  const history = designerService?.getHistory();
  const isSchemaChanged =
    changeService?.getSchemaChangeJobs(history, currentGraph === GLOBAL_VIEW).length !== 0 || schemaStyleChanged();
  const disableSaveButton = disableButton || !isSchemaChanged;
  const [settings, setSettings] = useState<SettingType>({
    layout: layout || 'Preset',
  });

  const renderChart = useCallback(() => {
    initStyle(graphInService, color);
    const curSchema = graphInService.dumpToGSQLJson() as Schema;
    if (!curSchema) {
      return;
    }
    curSchema.VertexTypes.forEach((vertex) => {
      vertex['style'] = graphInService.getVertex(vertex.Name)?.style;
    });
    curSchema.EdgeTypes.forEach((edge) => {
      edge['style'] = graphInService.getEdge(edge.Name)?.style;
    });
    setSchemaCopy(curSchema);
  }, [color, graphInService]);

  const generateDeleteWarningMsg = useCallback((type: Vertex | Edge) => {
    return `${'primaryId' in type ? 'Vertex' : 'Edge'} type ${type.name} is used on graph ${type.usage.join(', ')}.`;
  }, []);

  const handleChangePosition = useCallback(() => {
    let vertexPositions: [string, number, number][] = [];
    if (graphRef.current) {
      const positions = graphRef.current?.graphPositions();
      vertexPositions = vertexPositions.concat(
        positions.map((p) => {
          const type = p[0].split('#').shift() as string;
          return [type, p[1], p[2]];
        })
      );
      designerService.updateVerticesPositions(vertexPositions);
      renderChart();
    }
  }, [designerService, renderChart]);

  const updateGlobalTypesIcon = useCallback(() => {
    setTimeout(() => {
      const globalTypes = designerService.getAllGlobalTypes();
      if (!isGlobalView && globalTypes.length <= GlobalIconNumberLimit) {
        const vertices = graphRef.current?.getNodesByTypes(globalTypes);
        vertices?.forEach((item) => graphRef.current?.displayNodeIcon(item, GRAPH_ICON.global));
        const edges = graphRef.current?.getLinksByTypes(globalTypes);
        edges?.forEach((item) => graphRef.current?.displayLinkIcon(item, GRAPH_ICON.global));
      }
    }, 100);
  }, [designerService, isGlobalView]);

  const flattenSchemaChangeJobs = (schemaChangeJobs: SchemaChange[]): SchemaChange => {
    return schemaChangeJobs.reduce((result, job) => {
      const resultKeyList = Object.keys(result);
      const jobKeyList = Object.keys(job);
      const keyList = resultKeyList.length >= jobKeyList.length ? resultKeyList : jobKeyList;
      // @ts-ignore
      keyList.forEach((key) => (result[key] = (result[key] || []).concat(job[key] || [])));
      return result;
    });
  };

  const setWarningForUnsavedChanges = useCallback(() => {
    if (!enableCreate) {
      return;
    }
    if (changeService?.getSchemaChangeJobs(history, currentGraph === GLOBAL_VIEW).length > 0) {
      if (history.getRecord(0).dumpToGSQLJson().VertexTypes.length === 0) {
        setUnsavedWarning(['You have not saved your changes to the schema.']);
      } else {
        const schemaChangeJobs = changeService.getSchemaChangeJobs(history, isGlobalView);

        if (schemaChangeJobs.length > 0) {
          const warnings = ['You have not saved your changes to the schema.'];

          const flatten = flattenSchemaChangeJobs(schemaChangeJobs);

          let hasDrop = flatten.dropVertexTypes.length + flatten.dropEdgeTypes.length;
          if (flatten.graphs) {
            hasDrop += flatten.graphs[0].dropVertexTypes.length + flatten.graphs[0].dropEdgeTypes.length;
          }

          if (hasDrop) {
            let change = 'Saving the changes will erase data for';
            const vertexChange = [];
            if (flatten.dropVertexTypes.length > 0) {
              vertexChange.push(...flatten.dropVertexTypes);
            }
            if (flatten.graphs && flatten.graphs[0].dropVertexTypes.length > 0) {
              vertexChange.push(...flatten.graphs[0].dropVertexTypes);
            }
            if (vertexChange.length > 0) {
              change += ` vertex type "${HelperFunctions.joinStrAsPara(vertexChange)}"`;
            }
            const edgeChange = [];
            if (flatten.dropEdgeTypes.length > 0) {
              edgeChange.push(...flatten.dropEdgeTypes);
            }
            if (flatten.graphs && flatten.graphs[0].dropEdgeTypes.length > 0) {
              edgeChange.push(...flatten.graphs[0].dropEdgeTypes);
            }
            if (edgeChange.length > 0) {
              change += ` edge type "${HelperFunctions.joinStrAsPara(edgeChange)}"`;
            }
            change += '.';

            warnings.push(change);
            warnings.push('You can reload data to affected vertex and edge types ' + 'after saving the changes.');
          }
          setUnsavedWarning(warnings);
        }
      }
    } else if (schemaStyleChanged()) {
      setUnsavedWarning(['You have not saved your changes to the schema style.']);
    } else {
      setUnsavedWarning(undefined);
    }
  }, [changeService, currentGraph, enableCreate, history, isGlobalView, schemaStyleChanged]);

  useEffect(() => {
    renderChart();
    updateGlobalTypesIcon();
    setWarningForUnsavedChanges();
  }, [currentGraph, renderChart, schema, updateGlobalTypesIcon, setWarningForUnsavedChanges]);

  const initializeEdge = useCallback((): Edge => {
    const edge = new Edge();
    edge.name = `edge_type_${randomString(2)}`;
    edge.style = new EdgeStyle();
    edge.style.fillColor = color.getColor(randomString(32));
    if (isGlobalView) {
      edge.isLocal = false;
      edge.usage = [];
    }
    return edge;
  }, [color, isGlobalView]);

  const handleDelete = useCallback(() => {
    // Remove the selected vertices and edge from the graph.

    let vertexTypes: string[] = [];
    let edgeTypes: { from: string; to: string; type: string }[] = [];
    const selectedElements = graphRef.current?.selectedElements();
    selectedElements?.nodes.forEach((node) => vertexTypes.push(node.type));
    selectedElements?.links.forEach((link) =>
      edgeTypes.push({
        from: link.source.type,
        to: link.target.type,
        type: link.type,
      })
    );

    // If in a graph, global types cannot be deleted.
    if (!isGlobalView) {
      const globalTypes = designerService.getAllGlobalTypes();
      vertexTypes = vertexTypes.filter((vertex) => !globalTypes.includes(vertex));
      edgeTypes = edgeTypes.filter((edge) => !globalTypes.includes(edge.type));
    } else {
      // Block deleting global vertex or edge types that is used in certain graph(s).
      const vertexTypesDisableRemove: Vertex[] = [];
      const edgeTypesDisableRemove: Edge[] = [];
      vertexTypes = vertexTypes.filter((name) => {
        const vertex = designerService.getVertex(name);
        if (vertex && vertex.usage.length > 0) {
          vertexTypesDisableRemove.push(vertex);
          return;
        } else {
          return name;
        }
      });
      edgeTypes = edgeTypes.filter((edgeType) => {
        const edge = designerService.getEdge(edgeType.type);
        if (edge && edge.usage.length > 0) {
          edgeTypesDisableRemove.push(edge);
        } else {
          return edgeType;
        }
      });

      if (vertexTypesDisableRemove.length > 0 || edgeTypesDisableRemove.length > 0) {
        const messages: string[] = [];
        vertexTypesDisableRemove.forEach((type) => {
          messages.push('- ' + generateDeleteWarningMsg(type));
        });
        edgeTypesDisableRemove.forEach((type) => {
          messages.push('- ' + generateDeleteWarningMsg(type));
        });
        messages.push('Please drop from the graph(s) first.');
        setDeleteWarning(messages.map((m) => <div key={m}>{m}</div>));
      }
    }

    // Only do removal if there is something to delete.
    if (vertexTypes.length > 0 || edgeTypes.length > 0) {
      // Add forward edge type if any selected edge type is reverse edge type.
      edgeTypes.forEach((edgeType) => {
        const reverseEdgeType = designerService.getReverseEdgeType(edgeType.type);
        if (reverseEdgeType) {
          edgeTypes.push({
            from: edgeType.to,
            to: edgeType.from,
            type: reverseEdgeType,
          });
        }
      });
      designerService.remove(vertexTypes, edgeTypes);
      // Reset selected items
      graphRef?.current?.selectNodesByTypes([]);
      graphRef?.current?.selectEdgesByTypes([]);
      renderChart();
    }
  }, [designerService, generateDeleteWarningMsg, isGlobalView, renderChart]);

  const updateGlobalTypes = useCallback(
    (globalVertexTypes: TypeCheckedStatus[], globalEdgeTypes: TypeCheckedStatus[]) => {
      const currentTypes = designerService.getAllVertexTypes().concat(designerService.getAllEdgeTypes());
      const verticesToRemove = Object.values(globalVertexTypes)
        .filter((vertex) => !vertex.disabled && !vertex.selected)
        .map((vertex) => vertex.vertexOrEdgeType.Name)
        .filter((vertex) => currentTypes.includes(vertex));
      const edgesToRemove = Object.values(globalEdgeTypes)
        .filter((edge) => !edge.disabled && !edge.selected)
        .map((edge) => edge.vertexOrEdgeType.Name)
        .filter((edge) => currentTypes.includes(edge));
      const verticesToAdd: Vertex[] = [];
      const edgesToAdd: Edge[] = [];
      globalVertexTypes.forEach((type) => {
        if (type.selected && !currentTypes.includes(type.vertexOrEdgeType.Name)) {
          const vertex = new Vertex();
          const typeJson = omit(type.vertexOrEdgeType, 'Usage');
          vertex.loadFromGSQLJson(typeJson as GSQLVertexJson);
          verticesToAdd.push(vertex);
        }
      });
      globalEdgeTypes.forEach((type) => {
        if (type.selected && !currentTypes.includes(type.vertexOrEdgeType.Name)) {
          const edge = new Edge();
          const typeJson = omit(type.vertexOrEdgeType, 'Usage');
          edge.loadFromGSQLJson(typeJson as GSQLEdgeJson);
          edgesToAdd.push(edge);
        }
      });
      // TODO: Due to current schema change mapping logic,
      // If user assign and drop types in one single operation, split the assign and drop into two history.
      // Need to think about this in the future.
      const split =
        (verticesToRemove.length > 0 || edgesToRemove.length > 0) &&
        (verticesToAdd.length > 0 || edgesToAdd.length > 0);
      const res = split
        ? designerService.updateGlobalTypes(verticesToRemove, edgesToRemove, [], []) &&
          designerService.updateGlobalTypes([], [], verticesToAdd, edgesToAdd)
        : designerService.updateGlobalTypes(verticesToRemove, edgesToRemove, verticesToAdd, edgesToAdd);

      if (res.success) {
        if (split) {
          const curHistoryIndex = designerService.curHistoryPointer;
          globalUpdateIndicesRef.current.push(curHistoryIndex - 1, curHistoryIndex);
        }

        verticesToAdd.forEach((vertex) => {
          const vertexStyle = globalSchemaStyle?.vertexStyles[vertex.name];
          if (vertexStyle) {
            designerService.updateVertexStyleFromJson(vertex.name, vertexStyle);
          } else {
            designerService.updateVertexStyle(vertex.name, designerService.getNewVertexStyle(vertex.name, color));
          }
        });
        edgesToAdd.forEach((edge) => {
          const edgeStyle = globalSchemaStyle?.edgeStyles[edge.name];
          const edgeColor = edgeStyle
            ? edgeStyle.fillColor
            : designerService.getNewEdgeStyle(edge.name, color).fillColor;
          designerService.updateEdgeStrokeColor(edge.name, edgeColor);
        });
        graphRef?.current?.removeAllIcons();
      } else {
        setErrorMsg(res.message || 'Error');
      }
    },
    [color, designerService, globalSchemaStyle]
  );

  const deleteGlobalVerticesOrEdges = useCallback(
    (items: (ExternalNode | ExternalLink)[]) => {
      const globalTypesInGraph = designerService.getAllGlobalTypes();

      const graphGSQL = designerService.getGSQLGraphJson();

      const globalVertexTypes = graphGSQL.VertexTypes.filter((vertex) => !vertex.IsLocal).map((vertex) => ({
        vertexOrEdgeType: vertex,
        selected: globalTypesInGraph.includes(vertex.Name),
        disabled: false,
      }));
      const globalEdgeTypes = graphGSQL.EdgeTypes.filter((edge) => !edge.IsLocal).map((edge) => ({
        vertexOrEdgeType: edge,
        selected: globalTypesInGraph.includes(edge.Name),
        disabled: false,
      }));
      for (const item of items) {
        if ('id' in item) {
          const target = globalVertexTypes.filter((vertexType) => vertexType.vertexOrEdgeType.Name === item.type);
          if (!target) {
            continue;
          }
          target[0].selected = false;
          HelperFunctions.toggleCheckStatus(target, globalEdgeTypes, true);
        } else if ('source' in item) {
          const target = globalEdgeTypes.filter((edgeType) => edgeType.vertexOrEdgeType.Name === item.type);
          if (!target) {
            continue;
          }
          target[0].selected = false;
          HelperFunctions.toggleCheckStatus(target, globalVertexTypes);
        }
      }
      updateGlobalTypes(globalVertexTypes, globalEdgeTypes);
    },
    [designerService, updateGlobalTypes]
  );

  const confirmDeleteVerticesOrEdges = useCallback(
    (items: (ExternalNode | ExternalLink)[]) => {
      if (!isGlobalView) {
        const globalTypesInGraph = designerService.getAllGlobalTypes();
        const removeItems = items.filter((item) => globalTypesInGraph.includes(item.type));
        if (removeItems.length > 0) {
          deleteGlobalVerticesOrEdges(removeItems);
        }
      }
      handleDelete();
      props.confirmDeleteVerticesOrEdges?.();
    },
    [deleteGlobalVerticesOrEdges, designerService, handleDelete, isGlobalView, props]
  );

  const handleSave = useCallback(async () => {
    const semanticCheck = designerService?.semanticCheckGraph();
    if (semanticCheck?.success) {
      const history = designerService?.getHistory();
      const jobs = changeService?.getSchemaChangeJobs(history, currentGraph === GLOBAL_VIEW);
      const graphStyle = designerService?.getDBGraphStyleJson();
      await saveSchema(jobs);
      await saveSchemaStyleClient(graphStyle);
      designerService.reset();
    } else {
      console.log('semanticCheck error:', semanticCheck?.message);
    }
  }, [changeService, currentGraph, designerService, saveSchema, saveSchemaStyleClient]);

  const centerGraph = () => {
    setTimeout(() => {
      graphRef?.current?.centerGraph();
    }, 200);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!enableShortcuts || vertexPopoverVisible || edgePopoverVisible || !focusGraph) {
        return;
      }
      if ((event.key.toLowerCase() === 'backspace' || event.key.toLowerCase() === 'delete') && enableDelete) {
        // Delete selected vertices and edges when pressing delete key.
        const nodes = graphRef.current?.selectedNodes() || [];
        const edges = graphRef.current?.selectedEdges() || [];
        if (nodes?.length > 0 || edges!.length > 0) {
          setDeleteItems([...nodes, ...edges]);
          setIsOpenDeleteTypeModal(true);
          setVertex(undefined);
          setEdge(undefined);
          setVertexPopoverVisible(false);
          setEdgePopoverVisible(false);
        }
      } else if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 's' && enableSave) {
        handleSave();
        event.preventDefault();
      } else if (event.key.toLowerCase() === 'v') {
        setIsAddingVertex(true);
      } else if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'z') {
        designerService.redo();
        if (globalUpdateIndicesRef.current.includes(designerService.curHistoryPointer)) {
          designerService.redo();
        }
        renderChart();
      } else if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'z') {
        designerService.undo();
        if (globalUpdateIndicesRef.current.includes(designerService.curHistoryPointer)) {
          designerService.undo();
        }
        renderChart();
      } else if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'r') {
        centerGraph();
        event.preventDefault();
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === 'v') {
        setIsAddingVertex(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    confirmDeleteVerticesOrEdges,
    designerService,
    edgePopoverVisible,
    enableDelete,
    enableSave,
    enableShortcuts,
    focusGraph,
    handleSave,
    renderChart,
    vertexPopoverVisible,
  ]);

  const handleVertexPopoverVisible = useCallback(
    (visible: boolean) => {
      if (enableEditor) {
        setVertexPopoverVisible(visible);
      }
    },
    [enableEditor]
  );

  const handleEdgePopoverVisible = useCallback(
    (visible: boolean) => {
      if (enableEditor) {
        setEdgePopoverVisible(visible);
      }
    },
    [enableEditor]
  );

  const initializeVertex = useCallback(
    (position?: { x: number; y: number }): Vertex => {
      const vertex = new Vertex();
      vertex!.name = `vertex_type_${randomString(4)}`;
      vertex.primaryId.type.name = primaryIdTypeDefaultValue;
      vertex.primaryId.name = primaryIdName;
      vertex.primaryId.isPrimaryKey = true;
      vertex.style = designerService.getNewVertexStyle(randomString(32), color);

      if (position) {
        vertex.style.x = position.x;
        vertex.style.y = position.y;
      }
      if (isGlobalView) {
        vertex.isLocal = false;
        vertex.usage = [];
      }
      return vertex;
    },
    [color, designerService, isGlobalView]
  );

  const handleCreateVertex = useCallback(
    (position?: { x: number; y: number }): Vertex => {
      const cy = cyRef.current;
      const container = cy?.container();
      let viewportCenterX = 0;
      let viewportCenterY = 0;

      if (cy && container) {
        const zoom = cy.zoom();
        const pan = cy.pan();

        const viewportWidth = container.clientWidth;
        const viewportHeight = container.clientHeight;
        viewportCenterX = (viewportWidth / 2 - pan.x) / zoom;
        viewportCenterY = (viewportHeight / 2 - pan.y) / zoom;
      }
      const newVertex = initializeVertex(position ? position : { x: viewportCenterX, y: viewportCenterY });
      const vertexName = newVertex!.name;
      setVertex(newVertex);
      designerService?.addVertex(newVertex!);
      renderChart();

      setTimeout(() => {
        handleVertexPopoverVisible(true);
        graphRef?.current?.selectNodesByTypes([vertexName]);
      }, 500);
      return newVertex;
    },
    [designerService, handleVertexPopoverVisible, initializeVertex, renderChart]
  );

  const handleCreateEdge = useCallback(
    (source: ExternalNode, target: ExternalNode | ExternalLink): Edge => {
      const newEdge = initializeEdge();
      newEdge.fromToVertexTypePairs = [{ from: source.type, to: target.type }];
      designerService.addEdge(newEdge);
      setEdge(newEdge);
      setSchemaCopy(graphInService.dumpToGSQLJson() as Schema);

      setTimeout(() => {
        graphRef?.current?.selectEdges([
          {
            type: newEdge.name,
            source,
            target: target as ExternalNode,
          },
        ]);
      }, 1000);
      return newEdge;
    },
    [designerService, graphInService, initializeEdge]
  );

  const handleCreateNodeAndEdge = useCallback(
    (source: ExternalNode, position: { x: number; y: number }): [Vertex, Edge] | undefined => {
      if (!position) {
        return;
      }
      setIsDragging(true);
      const newVertex = initializeVertex(position);
      const vertexName = newVertex!.name;
      const newEdge = initializeEdge();
      newEdge.fromToVertexTypePairs = [{ from: source.type, to: vertexName || '' }];
      designerService.addVertex(newVertex!);
      designerService.addEdge(newEdge);
      setVertex(newVertex.clone());
      setEdge(undefined);
      setSelectedVertexName(vertexName);
      setSchemaCopy(graphInService.dumpToGSQLJson() as Schema);
      renderChart();
      setTimeout(() => {
        handleVertexPopoverVisible(true);
        graphRef?.current?.selectNodesByTypes([vertexName]);
      }, 500);
      return [newVertex, newEdge];
    },
    [designerService, graphInService, handleVertexPopoverVisible, initializeEdge, initializeVertex, renderChart]
  );

  const presetNodePositions: NodePositions = useMemo((): NodePositions => {
    const graph = designerService?.graph;
    const curSchema = graph.dumpToGSQLJson();
    const positions: NodePositions = {};
    curSchema.VertexTypes.forEach((v) => {
      const style = graph?.getVertex(v.Name)?.style;
      positions[`${v.Name}#${v.Name}`] = {
        x: style?.x,
        y: style?.y,
      };
    });
    return positions;
  }, [designerService?.graph]);

  const handleAddVertex = (vertex: Vertex, isFromGlobal = false) => {
    // Record the temporary graph in case add element failed.
    const tmpGraph = cloneDeep(designerService.graph);

    // Remove temporary vertex/edge to continue semantic check when is not from global.
    if (!isFromGlobal) {
      designerService.unreversibleUndo();
      if (isDragging) {
        designerService.unreversibleUndo();
      }
    }

    const vertexRes = designerService.addVertex(vertex);

    if (isDragging) {
      edge!.fromToVertexTypePairs[0].to = vertex.name;
      const edgeRes = designerService.addEdge(edge!);
      if (edgeRes.success && vertexRes.success) {
        color.setColor(vertex.name, vertex.style.fillColor);
        color.setColor(edge!.name, edge!.style.fillColor);
        handleVertexPopoverVisible(false);
        setErrorMsg('');
        renderChart();
      } else {
        designerService.updateGraph(tmpGraph);
      }
    } else {
      if (vertexRes.success) {
        color.setColor(vertex.name, vertex.style.fillColor);
        setErrorMsg('');
        renderChart();
      } else {
        const copy = vertex.clone();
        setVertex(copy);
        setErrorMsg(vertexRes.message || 'create vertex failed');
        designerService.updateGraph(tmpGraph);
      }
    }

    setIsDragging(false);
    return vertexRes;
  };

  const handleUpdateVertex = (updateVertex: Vertex, vertexTypeName?: string): ValidateResult => {
    const res = designerService.updateVertex(vertexTypeName ?? selectedVertexName, updateVertex.clone());
    if (res.success) {
      color.setColor(selectedVertexName, updateVertex.style.fillColor);
      setErrorMsg('');
      setSelectedVertexName(updateVertex.name);
      renderChart();
    } else {
      setErrorMsg(res.message || 'update vertex failed');
    }
    const copy = updateVertex.clone();
    setVertex(copy);
    return res;
  };

  const handleCloseVertexPopover = useCallback(() => {
    handleVertexPopoverVisible(false);
    setErrorMsg('');
    renderChart();
  }, [handleVertexPopoverVisible, renderChart]);

  const handleCloseEdgePopover = useCallback(() => {
    handleEdgePopoverVisible(false);
    setErrorMsg('');
    renderChart();
  }, [handleEdgePopoverVisible, renderChart]);

  const handleAddEdge = (edge: Edge): ValidateResult => {
    // Record the temporary graph in case add element failed.
    const tmpGraph = cloneDeep(designerService.graph);

    // Remove temporary vertex/edge to continue semantic check.
    designerService.unreversibleUndo();

    edge.setDiscriminatorProperties();
    const res = designerService.addEdge(edge);

    if (res.success) {
      color.setColor(edge.name, edge.style.fillColor);
      handleEdgePopoverVisible(false);
      setErrorMsg('');
      renderChart();
    } else {
      setErrorMsg(res.message || 'create edge failed');
      designerService.updateGraph(tmpGraph);
    }
    return res;
  };

  const handleUpdateEdge = (updateEdge: Edge, edgeTypeName?: string): ValidateResult => {
    const res = designerService.updateEdge(edgeTypeName ?? selectedEdgeName, updateEdge.clone());
    if (res.success) {
      color.setColor(updateEdge.name, updateEdge.style.fillColor);
      setSelectedEdgeName(updateEdge.name);
      setErrorMsg('');
      renderChart();
    } else {
      setErrorMsg(res.message || 'update edge failed');
    }
    return res;
  };

  const handleGraphChartPointer = useCallback(
    (item?: ExternalNode | ExternalLink, isMoving = false) => {
      if (item) {
        if ('id' in item) {
          handleVertexPopoverVisible(true);
          handleEdgePopoverVisible(false);
          setErrorMsg('');
          const vertex = designerService?.getVertex(item.id);
          setVertex(vertex);
          setEdge(undefined);
          setSelectedVertexName(vertex?.name || '');
        } else if ('source' in item) {
          handleEdgePopoverVisible(true);
          handleVertexPopoverVisible(false);
          setErrorMsg('');
          const edge = designerService?.getEdge(item.type);
          setEdge(edge);
          setVertex(undefined);
          setSelectedEdgeName(edge?.name || '');
        }
      }
      if (isMoving) {
        handleChangePosition();
      }
    },
    [designerService, handleChangePosition, handleEdgePopoverVisible, handleVertexPopoverVisible]
  );

  // Close the vertex editor popover and edge editor popover
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // @ts-ignore
      if (editorWrapperRef.current && !editorWrapperRef.current.contains(event.target)) {
        vertexPopoverVisible && handleCloseVertexPopover();
        edgePopoverVisible && handleCloseEdgePopover();
      }
    };
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [edgePopoverVisible, handleCloseEdgePopover, handleCloseVertexPopover, vertexPopoverVisible]);

  // Close the vertex editor popover and edge editor popover
  useEffect(() => {
    const clickGraphDiv = (event: MouseEvent) => {
      // @ts-ignore
      if (divRef.current && divRef.current.contains(event.target)) {
        setFocusGraph(true);
      } else {
        setFocusGraph(false);
      }
    };
    document.addEventListener('mousedown', clickGraphDiv, false);
    return () => {
      document.removeEventListener('mousedown', clickGraphDiv, false);
    };
  }, []);

  useImperativeHandle(
    ref,
    (): GraphResultRef => ({
      vertex,
      edge,
      schema: schemaCopy,
      graph: graphCopy,
      isAddingVertex,
      isSchemaChanged,
      handleCreateVertex,
      handleCreateEdge,
      handleCreateNodeAndEdge,
      handleAddVertex,
      handleUpdateVertex,
      handleAddEdge,
      handleUpdateEdge,
      confirmDeleteVerticesOrEdges,
      handleChangePosition,
      renderChart,
      graphRef: graphRef,
      cyRef,
    })
  );

  const graphEvents: GraphEvents = useMemo(
    () => ({
      onClick: (item: ExternalNode | ExternalLink | undefined, position?: { x: number; y: number }) => {
        if (item) {
          // Single click a vertex
          handleGraphChartPointer(item);
        } else if (isAddingVertex && !item) {
          handleCreateVertex(position);
        }
        setFocusGraph(true);
      },
      onMouseOver: (item: ExternalNode | ExternalLink) => {
        if (!enableDelete) {
          return;
        }
        if ('id' in item) {
          graphRef.current?.displayNodeIcon(item, GRAPH_ICON.delete);
        } else if ('source' in item) {
          graphRef.current?.displayLinkIcon(item, GRAPH_ICON.delete);
        }
      },
      onMouseOut: (item: ExternalNode | ExternalLink) => {
        const globalTypes = designerService.getAllGlobalTypes();
        const showGlobalIcon =
          !isGlobalView && globalTypes.length <= GlobalIconNumberLimit && globalTypes.includes(item.type);
        if ('id' in item) {
          if (showGlobalIcon) {
            graphRef.current?.displayNodeIcon(item, GRAPH_ICON.global);
          } else {
            graphRef.current?.removeNodeIcon(item);
          }
        } else if ('source' in item) {
          if (showGlobalIcon) {
            graphRef.current?.displayLinkIcon(item, GRAPH_ICON.global);
          } else {
            graphRef.current?.removeLinkIcon(item);
          }
        }
      },
      onDelete: (item: ExternalNode | ExternalLink) => {
        graphRef.current?.unselectElements();
        if ('id' in item) {
          graphRef.current?.selectNodes([item]);
        } else if ('source' in item) {
          graphRef.current?.selectEdges([item]);
        }
        setDeleteItems([item]);
        setIsOpenDeleteTypeModal(true);
        setVertex(undefined);
        setEdge(undefined);
        setVertexPopoverVisible(false);
        setEdgePopoverVisible(false);
      },
      onCreateLink: (source: ExternalNode, target: ExternalNode | ExternalLink) => {
        const newEdge = handleCreateEdge(source, target);
        setSelectedEdgeName(newEdge.name);
        handleEdgePopoverVisible(true);
        handleVertexPopoverVisible(false);
        setErrorMsg('');
      },
      onCreateLinkCancelled: (source: ExternalNode, position) => {
        handleCreateNodeAndEdge(source, position);
      },
      onDragOutBound: () => {
        graphRef?.current?.fitGraph();
      },
      onDragFree: () => {
        // setTimeout(() => {
        //   graphRef.current?.centerGraph();
        // }, 200);
      },
      onPositionChange: () => {
        handleGraphChartPointer(undefined, true);
      },
      ...props.graphEvents,
    }),
    [
      designerService,
      enableDelete,
      handleCreateEdge,
      handleCreateNodeAndEdge,
      handleCreateVertex,
      handleEdgePopoverVisible,
      handleGraphChartPointer,
      handleVertexPopoverVisible,
      isAddingVertex,
      isGlobalView,
      props.graphEvents,
    ]
  );

  const editorLayout = useMemo(() => {
    const defaultStyle: StyleObject = {
      position: 'absolute',
      right: '0',
      bottom: '0',
      transition: 'height 0.2s ease',
      width: '100%',
      height: vertexPopoverVisible || edgePopoverVisible ? '400px' : '0',
      padding: vertexPopoverVisible || edgePopoverVisible ? '0 8px' : '0',
      boxSizing: 'border-box',
      overflow: 'auto',
      borderTop: `1px solid ${theme.colors.border}`,
      backgroundColor: `${theme.colors.white}`,
      zIndex: 1,
    };
    if (editorSize && editorSize.width > 1000) {
      return {
        ...defaultStyle,
        transition: 'width 0.2s ease',
        height: vertexPopoverVisible || edgePopoverVisible ? '100%' : '0',
        width: vertexPopoverVisible || edgePopoverVisible ? '560px' : '0',
        overflow: 'auto',
        borderTop: '0',
        borderLeft: `1px solid ${theme.colors.border}`,
      } as StyleObject;
    }
    return defaultStyle;
  }, [edgePopoverVisible, editorSize, theme, vertexPopoverVisible]);

  const graphWidget = useMemo(() => {
    if (!graphCopy || graphCopy.nodes.length + graphCopy.links.length === 0) {
      return (
        <div
          className={css({
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '596px',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none',
          })}
        >
          <EmptySchema isLoading={false} />
        </div>
      );
    }

    if (graphCopy!.nodes.length + graphCopy!.links.length > GraphSizeLimit) {
      return <div>The graph&#39;s size exceeds the limitation. The graph will not be rendered.</div>;
    }

    return (
      <Graph
        ref={graphRef}
        // @ts-ignore
        parentRef={cyRef}
        {...graphEvents}
        showEdgeHandler={() => {
          return !!enableCreate;
        }}
        userUploadedIconPathPrefix={getUserUploadedIconPathPrefix(true)}
        schemaMode={enableCreate ? 'edit' : 'view'}
        schema={schemaCopy}
        graph={graphCopy!}
        id={id}
        hideContextMenu={true}
        isSchemaGraph={isSchemaGraph}
        presetNodePositions={presetNodePositions}
        hideLeftUI={true}
        hideSearch={hideSearch}
        graphName={graphName}
        onGraphChange={() => {}}
        settings={settings}
        showGhostNode={true}
        showCreateEdgeTooltip={showCreateEdgeTooltip}
        onSettingUpdate={(key, value) => {
          setSettings({
            ...settings,
            [key]: value,
          });
        }}
        // graph widget in tools-ui contains a StyleToastContainer
        // use this props to hide the StyleToastContainer in graph widget
        insights={true}
      />
    );
  }, [
    graphCopy,
    css,
    graphEvents,
    enableCreate,
    schemaCopy,
    id,
    isSchemaGraph,
    presetNodePositions,
    hideSearch,
    graphName,
    settings,
    showCreateEdgeTooltip,
  ]);

  return (
    <div
      ref={divRef as RefObject<HTMLDivElement>}
      className={css({
        height: '100%',
        width: '100%',
        boxSizing: 'content-box',
      })}
    >
      <Layer mountNode={divRef.current}>
        <div
          className={css({
            position: 'absolute',
            right: '8px',
            top: '8px',
            display: 'flex',
            columnGap: '8px',
          })}
        >
          {enableCreate && (
            <Button
              kind="tertiary"
              size="compact"
              disabled={disableButton}
              onClick={() => {
                if (props.handleCreateVertex) {
                  props.handleCreateVertex();
                } else {
                  handleCreateVertex();
                }
              }}
            >
              <FiPlus size={16} />
              Create New Vertex
            </Button>
          )}
          {!isGlobalView && enableCreate && (
            <GlobalTypePopover
              disabled={disableButton}
              updateGlobalTypes={(globalVertexTypes, globalEdgeTypes) => {
                updateGlobalTypes(globalVertexTypes, globalEdgeTypes);
                renderChart();
                centerGraph();
              }}
            />
          )}
          {enableSave && (
            <Button disabled={disableSaveButton} onClick={handleSave} overrides={SchemaSaveButton(theme)}>
              {saveSchemaLoading ? (
                <Spinner $size={'20px'} $borderWidth={'2px'} />
              ) : disableSaveButton ? (
                <SaveButtonDisable />
              ) : (
                <SaveButton />
              )}
            </Button>
          )}
        </div>
      </Layer>
      <div
        className={css({
          height: '100%',
          width: '100%',
          transition: 'height 0.2s ease',
          overflow: 'hidden',
          cursor: isAddingVertex ? 'copy' : 'default',
        })}
      >
        {graphWidget}
      </div>
      <ConfirmModal
        header="Warning"
        body={deleteWarning}
        open={!!deleteWarning}
        confirmLabel="OK"
        onConfirm={() => {
          setDeleteWarning(undefined);
        }}
      />
      <DeleteTypeModal
        isOpen={isOpenDeleteTypeModal}
        types={deleteItems.map((item) => item.type)}
        onClose={() => {
          setIsOpenDeleteTypeModal(false);
          setDeleteItems([]);
        }}
        onConfirm={() => {
          setIsOpenDeleteTypeModal(false);
          confirmDeleteVerticesOrEdges(deleteItems);
          setDeleteItems([]);
        }}
      />
      {enableEditor && (
        <div ref={editorWrapperRef} className={css(editorLayout)}>
          {errorMsg && (
            <div
              className={css({
                position: 'relative',
                top: '-20px',
              })}
            >
              <ErrorMessage message={errorMsg ?? ''} />
            </div>
          )}
          {vertex && (
            <VertexEditor
              editorWrapperRef={editorWrapperRef}
              isOpen={vertexPopoverVisible}
              initVertex={vertex}
              onClose={handleCloseVertexPopover}
              onSave={(newVertex) => handleUpdateVertex(newVertex, selectedVertexName)}
              readOnly={!isGlobalView && !vertex?.isLocal}
            />
          )}
          {edge && (
            <EdgeEditor
              initEdge={edge}
              isOpen={edgePopoverVisible}
              onClose={handleCloseEdgePopover}
              onSave={(newEdge) => handleUpdateEdge(newEdge, selectedEdgeName)}
              readOnly={!isGlobalView && !edge?.isLocal}
            />
          )}
        </div>
      )}
      {unsavedWarning && enableCreate && (
        <div
          className={css({
            position: 'absolute',
            left: '0',
            top: enableGraphSelect ? '40px' : '8px',
            display: 'flex',
            columnGap: '8px',
            fontSize: '12px',
          })}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color={theme.colors.error}
            className={css({
              marginTop: '2px',
            })}
          />
          <div
            className={css({
              color: theme.colors.error,
            })}
          >
            {unsavedWarning.map((warning) => (
              <div key={warning}>{warning}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

export default GraphResult;
