import { Tabs, Tab } from '@/components/Tab';
import OrgSettings from '@/pages/admin/settings/org_settings';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useSearchParams } from 'react-router-dom';

export default function Settings() {
  const [css] = useStyletron();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      className={css({
        padding: '16px 0 0',
        height: '56px',
        backgroundColor: '#fff',
      })}
    >
      <Tabs
        activeKey={searchParams.get('tab') || 'org_settings'}
        onChange={({ activeKey }) => {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set('tab', `${activeKey}`);
          setSearchParams(newSearchParams);
        }}
        activateOnFocus
      >
        <Tab
          title="Organization Setting"
          key="org_settings"
          overrides={{
            TabPanel: {
              style: {
                paddingBottom: '0',
              },
            },
          }}
        >
          <OrgSettings />
        </Tab>
      </Tabs>
    </div>
  );
}
