import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import TutorialIcon from '@/assets/tutorial.svg';
import { TutorialGroup } from '@/components/tutorial/TutorialGroup.tsx';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { SubChapter, Tutorial } from '@/components/tutorial/type.ts';
import { ChevronRight } from 'baseui/icon';
import { TutorialSubChapters } from '@/components/tutorial/TutorialSubChapters.tsx';
import { SubChapterDetail } from '@/components/tutorial/SubChapterDetail.tsx';
import { Header } from '@/components/tutorial/style.tsx';
import { Close } from '@/components/tutorial/icons.tsx';
import IconButton from '@/components/IconButton.tsx';
import { Tutorials } from '@/components/tutorial/mock.tsx';

export interface TutorialPopoverProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TutorialPopover = forwardRef((props: TutorialPopoverProps, ref) => {
  const { isOpen, onClose } = props;
  const [css, theme] = useStyletron();
  const [currentTutorial, setCurrentTutorial] = useState<Tutorial | null>(null);
  const [currentSubChapter, setCurrentSubChapter] = useState<SubChapter | null>(null);
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (isOpen && !currentTutorial) {
      const routerKey = window.location.pathname.split('/')[1];
      const tutorial = Tutorials.find((item) => item.routerKey === routerKey);
      if (tutorial) {
        setCurrentTutorial(tutorial);
        setStep(1);
      }
    }
  }, [isOpen, currentTutorial]);

  useImperativeHandle(
    ref,
    () => {
      return {
        clearState() {
          setStep(0);
          setCurrentTutorial(null);
          setCurrentSubChapter(null);
        },
        stepToSubChapter(tutorial?: Tutorial) {
          setStep(1);
          tutorial && setCurrentTutorial(tutorial);
        },
      };
    },
    []
  );

  return (
    <>
      {isOpen && (
        <div
          className={css({
            zIndex: 1000,
            position: 'absolute',
            bottom: '16px',
            left: '320px',
            width: currentTutorial?.id === '0' && step !== 0 ? '931px' : '480px',
            height: currentTutorial?.id === '0' && step !== 0 ? '675px' : '600px',
            backgroundColor: 'white',
            boxShadow: '-2px -2px 16px 0px rgba(0, 0, 0, 0.10), 2px 2px 16px 0px rgba(0, 0, 0, 0.10)',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '12px',
          })}
        >
          <Header
            className={css({
              lineHeight: '32px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '16px',
              borderBottom: `1px solid ${theme.colors.gray200}`,
              color: currentTutorial && step !== 0 ? theme.colors.gray400 : theme.colors.gray1000,
            })}
          >
            <img src={TutorialIcon} />
            <span
              className={css({
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                flexGrow: 1,
              })}
            >
              <span
                onClick={() => {
                  setStep(0);
                }}
                className={css({
                  cursor: 'pointer',
                })}
              >
                Tutorials
              </span>
              {currentTutorial && step !== 0 && <ChevronRight size={16} color={theme.colors.gray1000} />}
              {currentTutorial && step !== 0 && (
                <span style={{ color: theme.colors.gray1000 }}>{currentTutorial.title}</span>
              )}
            </span>
            <IconButton
              onClick={() => {
                setStep(0);
                setCurrentTutorial(null);
                setCurrentSubChapter(null);
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </Header>
          <div
            className={css({
              padding: ' 0px 16px',
              flexGrow: 1,
            })}
          >
            {step === 0 && (
              <TutorialGroup
                onSelectTutorial={(tutorial) => {
                  console.log(tutorial);
                  setCurrentTutorial(tutorial);
                  setStep(1);
                }}
              />
            )}
            {step === 1 && currentTutorial && (
              <TutorialSubChapters
                onClose={onClose}
                tutorial={currentTutorial}
                onSelectSubChapter={(subChapter) => {
                  setCurrentSubChapter(subChapter);
                  setStep(2);
                }}
              />
            )}
            {step === 2 && currentSubChapter && <SubChapterDetail subChapter={currentSubChapter} />}
          </div>
          {step !== 0 && (
            <div
              className={css({
                borderTop: `1px solid ${theme.colors.gray200}`,
                padding: '16px',
              })}
            >
              <button
                className={css({
                  padding: '8px 16px',
                  borderRadius: '2px',
                  backgroundColor: 'white',
                  color: theme.colors.black01,
                  border: `2px solid ${theme.colors.gray200}`,
                  lineHeight: '16px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  fontWeight: 500,
                })}
                onClick={() => {
                  setStep((prev) => prev - 1);
                }}
              >
                Back
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
});
