import React from 'react';
import { StatefulPopover, Popover } from '@tigergraph/app-ui-lib/popover';
import { PopoverOverrides } from 'baseui/popover';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { PLACEMENT } from 'baseui/popover';
import { Button } from '@tigergraph/app-ui-lib/button';
import { KIND, SIZE } from 'baseui/button';

type StatefulPopoverProps = {
  placement?: keyof typeof PLACEMENT;
  children: React.ReactNode;
  confirmLabel: React.ReactNode;
  onConfirm: () => void;
};

type PopoverProps = {
  isOpen: boolean;
  onClose: () => void;
} & StatefulPopoverProps;

const popoverOverrides: PopoverOverrides = {
  Body: {
    style: {
      backgroundColor: '#fff',
      marginTop: '8px',
    },
  },
  Inner: {
    style: {
      backgroundColor: '#fff',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  Arrow: {
    style: {
      backgroundColor: '#fff',
    },
  },
};

function ConfirmBody({
  confirmLabel,
  onConfirm,
  close,
}: {
  confirmLabel: React.ReactNode;
  onConfirm: () => void;
  close: () => void;
}) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        padding: '4px 12px',
        maxWidth: '320px',
        color: theme.colors.subText,
        ...theme.typography.Body2,
      })}
    >
      {confirmLabel}
      <div
        className={css({
          paddingTop: '8px',
          display: 'flex',
          justifyContent: 'flex-end',
        })}
      >
        <Button
          kind={KIND.tertiary}
          size={SIZE.compact}
          onClick={close}
          overrides={{
            BaseButton: {
              style: {
                marginRight: '12px',
              },
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            close();
            onConfirm();
          }}
          kind={KIND.primary}
          size={SIZE.compact}
        >
          OK
        </Button>
      </div>
    </div>
  );
}

export function ConfirmStatefulPopover({
  placement = PLACEMENT.top,
  children,
  confirmLabel,
  onConfirm,
}: StatefulPopoverProps) {
  return (
    <StatefulPopover
      showArrow={true}
      content={({ close }) => <ConfirmBody confirmLabel={confirmLabel} onConfirm={onConfirm} close={close} />}
      overrides={popoverOverrides}
      placement={placement}
    >
      {children}
    </StatefulPopover>
  );
}

export function ConfirmPopover({
  isOpen,
  onClose,
  placement = PLACEMENT.top,
  children,
  confirmLabel,
  onConfirm,
}: PopoverProps) {
  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={() => onClose()}
      onEsc={() => onClose()}
      showArrow={true}
      content={() => <ConfirmBody confirmLabel={confirmLabel} onConfirm={onConfirm} close={onClose} />}
      overrides={popoverOverrides}
      placement={placement}
    >
      {children}
    </Popover>
  );
}
