import { useRef } from 'react';
import { SchemaDesignerLogicService } from './services/schema-designer-logic.service';
import { SchemaChangeLogicService } from './services/schema-change-logic.service';

export const useSchemaDesignerLogicService = () => {
  const schemaDesignerLogicServiceRef = useRef<SchemaDesignerLogicService>();
  if (!schemaDesignerLogicServiceRef.current) {
    schemaDesignerLogicServiceRef.current = new SchemaDesignerLogicService();
  }
  return schemaDesignerLogicServiceRef.current;
};

export const useSchemaChangeLogicService = () => {
  const schemaChangeLogicServiceRef = useRef<SchemaChangeLogicService>();
  if (!schemaChangeLogicServiceRef.current) {
    schemaChangeLogicServiceRef.current = new SchemaChangeLogicService();
  }
  return schemaChangeLogicServiceRef.current;
};
