import { AddonsMetaID } from '@/components/addons/consts';
import { MetaAddonsType } from '@/hooks/useAddonsMeta';
import { EnabledAddonsType } from '@/hooks/useListEnabledAddons';
import AddonsConfigureDrawerCopilot from '@/pages/workgroup/form/addons/edit-drawer/copilot';
import { SubFormData } from '@/pages/workgroup/form/type';
import { WorkspaceT } from '@/pages/workgroup/type';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type AddonsConfigureDrawerConfigComponent = FC<{
  form: UseFormReturn<SubFormData>;
  workspace: WorkspaceT;
  onClose?(): void;
  selectedAddonsMeta?: MetaAddonsType;
  selectedEnabledAddons?: EnabledAddonsType;
}>;

export const drawerConfigComponentMap = new Map<string, AddonsConfigureDrawerConfigComponent>([
  [AddonsMetaID.COPILOT, AddonsConfigureDrawerCopilot],
]);
