import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { WorkspaceBackup } from '@/pages/workgroup/tab/backupRestore/workspaceBackup';
import { useState } from 'react';
import { Button } from '@tigergraph/app-ui-lib/button';
import { BackupDrawer } from '@/pages/workgroup/tab/backupRestore/BackupDrawer';
import { WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { useQueryClient } from 'react-query';
import { WorkspaceSelector } from '@/components/graphEditor/WorkspaceSelector';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { TableContainer } from '@/lib/styled';
import EmptyState from '@/pages/workgroup/EmptyState';
import { useOrgContext } from '@/contexts/orgContext.tsx';
import { isSpaceUser } from '@/pages/admin/user/type.ts';

export function BackupRestore({ group }: { group: WorkGroupT }) {
  const [css] = useStyletron();
  const [isBackupDrawerOpen, setIsBackupDrawerOpen] = useState(false);
  const queryClient = useQueryClient();
  const { currentWorkspace } = useWorkspaceContext();
  const { userInfo } = useOrgContext();

  const workspaceFilter = (workspace: WorkspaceT) => {
    if (!workspace.is_rw) {
      return false;
    }
    return !isSpaceUser(userInfo.roles, group.workgroup_id, workspace.workspace_id);
  };

  return (
    <>
      {group.workspaces.filter((q) => q.is_rw).length == 0 && (
        <TableContainer>
          <EmptyState createWorkGroup={false} />
        </TableContainer>
      )}
      {group.workspaces.length > 0 && (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
          })}
        >
          <WorkspaceSelector workgroupId={group.workgroup_id} filterWorkspace={workspaceFilter} />
          <Button
            disabled={!currentWorkspace || currentWorkspace.status !== 'Active'}
            onClick={() => {
              setIsBackupDrawerOpen(true);
            }}
            overrides={{
              BaseButton: {
                style: {
                  height: '32px',
                  fontSize: '14px',
                  fontWeight: '500',
                },
              },
            }}
          >
            Backup Now
          </Button>
        </div>
      )}
      {group.workspaces.length > 0 && currentWorkspace && (
        <WorkspaceBackup workgroup={group} workspace={currentWorkspace as WorkspaceT} />
      )}
      {group.workspaces.length > 0 && isBackupDrawerOpen && (
        <BackupDrawer
          onBackupSuccess={() => {
            setIsBackupDrawerOpen(false);
            queryClient.fetchQuery(['getBackupList', currentWorkspace?.workspace_id]);
          }}
          isOpen={isBackupDrawerOpen}
          onClose={() => setIsBackupDrawerOpen(false)}
          workgroupId={group.workgroup_id}
          workspaceId={currentWorkspace?.workspace_id as string}
        />
      )}
    </>
  );
}
