import { NotPrimaryKey, PrimaryKey } from '@/pages/home/icons';
import { Checkbox } from 'baseui/checkbox';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Attribute } from '@tigergraph/tools-models';

export interface AttributeKeyProps {
  attribute: Attribute;
  readOnly?: boolean;
  onChangeKey: (attribute: Attribute) => void;
}

export function AttributeKey({ attribute, readOnly, onChangeKey }: AttributeKeyProps) {
  const [css] = useStyletron();

  const handleChange = (checked: boolean) => {
    if (attribute.isPrimaryKey) {
      attribute.primaryIdAsAttribute = checked;
      onChangeKey(attribute);
    } else {
      attribute.hasIndex = checked;
      onChangeKey(attribute);
    }
  };

  return (
    <>
      {attribute.isPrimaryKey ? (
        <div
          className={css({
            display: 'flex',
            lineHeight: '35px',
            height: '35px',
            alignItems: 'center',
          })}
        >
          <PrimaryKey />
          <Checkbox
            disabled={readOnly}
            overrides={{
              Label: {
                style: {
                  minWidth: '95px',
                  paddingLeft: '0px',
                },
              },
            }}
            checked={attribute.primaryIdAsAttribute}
            onChange={(e) => {
              handleChange(e.currentTarget.checked);
            }}
          >
            As attribute
          </Checkbox>
        </div>
      ) : (
        <div
          className={css({
            display: 'flex',
            lineHeight: '35px',
            height: '35px',
            alignItems: 'center',
          })}
        >
          <NotPrimaryKey />
          {/* <Checkbox
            disabled={readOnly}
            overrides={{
              Label: {
                style: {
                  paddingLeft: '0px',
                },
              },
            }}
            checked={attribute.hasIndex}
            onChange={(e) => {
              handleChange(e.currentTarget.checked);
            }}
          >
            Index
          </Checkbox> */}
        </div>
      )}
    </>
  );
}
