import IconButton from '@/components/IconButton';
import { LoadingIndicator } from '@/components/loading-indicator';
import { useUserGuideContext } from '@/components/userguide/userGuideContext';
import { OnboardingTask, OnboardingTaskName } from '@/components/userguide/utils';
import { useOrgContext } from '@/contexts/orgContext';
import { feedbackOpenAtom } from '@/pages/home/feedback/atom';
import { Desc } from '@/pages/workgroup/form/StyledComponent';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button, ButtonProps } from '@tigergraph/app-ui-lib/button';
import { mergeOverrides } from 'baseui';
import { StatefulPanel } from 'baseui/accordion';
import { KIND, SIZE } from 'baseui/button';
import { Overrides } from 'baseui/overrides';
import { expand } from 'inline-style-expand-shorthand';
import { useAtom } from 'jotai';
import { sumBy } from 'lodash-es';
import { useEffect } from 'react';
import { MdCheck, MdClose, MdKeyboardArrowDown, MdKeyboardArrowUp, MdBook } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

enum BillingTabKeys {
  Overview = 'Overview',
  PaymentMethods = 'PaymentMethods',
  Invoices = 'Invoices',
}

export default function UserGuide() {
  const [css] = useStyletron();

  const { onboardingTasks, isFetching, completeOnboardingTask, isOpen, onClose } = useUserGuideContext();

  useEffect(() => {
    // trigger dashboard grid re-layout
    window.dispatchEvent(new CustomEvent('resize'));
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={css({
        background: '#fff',
        ...expand({ margin: 0, borderLeft: '1px solid #D4DADF' }),
        boxSizing: 'border-box',
        width: '408px',
      })}
    >
      <div className={css({ height: '100%' })}>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #D4DADF',
            height: '57px',
            paddingLeft: '16px',
            paddingRight: '12px',
          })}
        >
          <div
            className={css({
              fontSize: '18px',
              fontFamily: 'Urbanist',
              fontWeight: 600,
              lineHeight: 'normal',
              color: '#294560',
              display: 'flex',
              alignItems: 'center',
            })}
          >
            <MdBook size="24" />
            <span>User Guide</span>
          </div>
          <IconButton onClick={onClose}>
            <MdClose size={20} />
          </IconButton>
        </div>
        <div className={css({ height: 'calc(100% - 57px)', overflowY: 'auto' })}>
          {isFetching ? (
            <LoadingIndicator />
          ) : onboardingTasks.length ? (
            <OnboardingTaskList
              onboardingTasks={onboardingTasks}
              onClose={onClose}
              completeOnboardingTask={completeOnboardingTask}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function StyledButton({ children, overrides = {}, ...props }: ButtonProps) {
  return (
    <Button
      kind={KIND.secondary}
      size={SIZE.mini}
      overrides={mergeOverrides(
        {
          BaseButton: {
            style: {
              color: '#222',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '16px',
              alignSelf: 'flex-end',
            },
          },
        },
        overrides as Overrides<any>
      )}
      {...props}
    >
      {children}
    </Button>
  );
}

type OnboardingTaskListProps = {
  onboardingTasks: OnboardingTask[];
  onClose: () => void;
  completeOnboardingTask: (taskName: OnboardingTaskName) => void;
};

function OnboardingTaskList({ onboardingTasks, onClose, completeOnboardingTask }: OnboardingTaskListProps) {
  const [css] = useStyletron();
  const { setLocalStorage } = useOrgContext();

  useEffect(() => {
    completeOnboardingTask(OnboardingTaskName.signup);
  }, [completeOnboardingTask]);

  const tasksToDisplay: OnboardingTask[] = onboardingTasks.map((task) => ({
    ...task,
    completed: task.completed || task.task_name === OnboardingTaskName.signup,
  }));
  const totalCredits = sumBy(tasksToDisplay, 'credits');
  const earnedCredits = sumBy(tasksToDisplay, (task: OnboardingTask) => (task.completed ? task.credits : 0));

  const neverShow = () => {
    onClose();
    setLocalStorage('showUserGuide', 'false');
  };

  return (
    <>
      <div className={css({ position: 'relative', height: '100%', ...expand({ padding: '24px' }) })}>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          })}
        >
          <div className={css({ fontWeight: 600 })}>New User To-do List</div>
        </div>
        <div
          className={css({
            marginBottom: '15px',
          })}
        >
          <Desc>
            Earn free credits and unlock exciting features with TigerGraph Cloud! Each task below earns you credits, and
            each credit is worth 8 CPU core-hours (e.g. 2 hours of an 4-CPU workspace). You can also use credits for
            premium add-ons. Start earning and make the most of your credits today!
          </Desc>
        </div>
        <div>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '8px',
            })}
          >
            <div className={css({ color: '#57686E' })}>Onboarding progress</div>
            <div className={css({ fontSize: '14px' })}>{`${earnedCredits}/${totalCredits} Credits`}</div>
          </div>
          <div className={css({ background: '#D6DCE8', ...expand({ borderRadius: '20px' }) })}>
            <div
              className={css({
                height: '6px',
                background: '#FB9A44',
                width: `${(earnedCredits / totalCredits) * 100}%`,
                ...expand({ borderRadius: '20px' }),
              })}
            ></div>
          </div>
        </div>
        {tasksToDisplay.map((task, index) => (
          <OnboardingTaskItem task={task} key={task.task_name} index={index + 1} />
        ))}
        <div
          className={css({
            textAlign: 'right',
            color: '#1976D2',
            fontWeight: 600,
            ':hover': {
              cursor: 'pointer',
            },
            fontSize: '12px',
            position: 'absolute',
            bottom: '24px',
            right: '24px',
          })}
          onClick={neverShow}
        >
          Never Show
        </div>
      </div>
    </>
  );
}

function OnboardingTaskItem({ task, index }: { task: OnboardingTask; index: number }) {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const [_, setIsFeedbackOpen] = useAtom(feedbackOpenAtom);

  const handleTask = (task: OnboardingTask) => {
    switch (task.task_name) {
      case OnboardingTaskName.createWorkspace:
        navigate('/groups/new');
        break;
      case OnboardingTaskName.loadData:
        navigate('/ingestion');
        break;
      case OnboardingTaskName.addPayment:
        navigate(`/admin/bills?addCreditCard=true&tab=${BillingTabKeys.PaymentMethods}`);
        break;
      case OnboardingTaskName.inviteMember:
        navigate('/admin/users?invite=true');
        break;
      case OnboardingTaskName.giveFeedback:
        setIsFeedbackOpen(true);
        break;
    }
  };

  const buttonTexts: { [index: string]: string } = {
    [OnboardingTaskName.createWorkspace]: 'Create Workspace',
    [OnboardingTaskName.addPayment]: 'Add Payment Method',
    [OnboardingTaskName.inviteMember]: 'Invite Members',
    [OnboardingTaskName.loadData]: 'Load Data',
    [OnboardingTaskName.giveFeedback]: 'Give Feedback',
  };

  return (
    <StatefulPanel
      title={
        <div className={css({ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'space-between' })}>
          <div className={css({ display: 'flex', alignItems: 'center' })}>
            <div
              className={css({
                width: `${task.completed ? '20px' : '10px'}`,
                height: `${task.completed ? '20px' : '10px'}`,
                margin: `${task.completed ? '0' : '5px'}`,
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: `${task.completed ? '#FF9900' : '#AAB5BF'}`,
                ...expand({ borderRadius: '50%' }),
              })}
            >
              <div>{task.completed && <MdCheck />}</div>
            </div>
            <div className={css({ letterSpacing: '-0.28px', marginLeft: '10px', fontWeight: 400 })}>
              {task.task_name}
            </div>
            {task.task_name === OnboardingTaskName.signup && (
              <div
                className={css({
                  padding: '2px 4px',
                  fontWeight: 400,
                  fontSize: '10px',
                  border: '1px',
                  borderRadius: '1px',
                  color: theme.colors.white,
                  background: theme.colors.gray800,
                  marginLeft: '8px',
                  lineHeight: 'normal',
                })}
              >
                Beta Offer
              </div>
            )}
          </div>
          <div
            className={css({
              justifySelf: 'flex-end',
              background: '#FF9900',
              height: '16px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '8px',
              fontWeight: 600,
              color: '#fff',
              letterSpacing: '0.24px',
              textTransform: 'uppercase',
              textAlign: 'center',
              marginRight: '10px',
              ...expand({ borderRadius: '40px', padding: '4px 10px' }),
            })}
          >{`${task.credits} credits`}</div>
        </div>
      }
      initialState={{
        expanded: false,
      }}
      overrides={{
        Content: {
          style: {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 400,
            color: '#909090',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '-0.24px',
            rowGap: '8px',
            ...expand({
              padding: '0 0 20px 30px',
            }),
          },
        },
        Header: {
          style: {
            fontSize: '14px',
            fontWeight: 600,
            fontFamily: 'Roboto',
            ...expand({ padding: '16px 0' }),
          },
        },
        ToggleIcon: ({ $expanded }) =>
          $expanded ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />,
      }}
    >
      {task.description}
      {buttonTexts[task.task_name] && (
        <StyledButton onClick={() => handleTask(task)}>{buttonTexts[task.task_name]}</StyledButton>
      )}
    </StatefulPanel>
  );
}
